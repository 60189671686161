import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpModule } from '@angular/http';
// import { DatauxviewComponent } from 'datauxview';
import { DatauxviewModule } from 'datauxview';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { KHASystemService } from './services/systems/kha-systems.service';

import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatListModule } from '@angular/material/list';
import { MatRadioModule } from '@angular/material/radio';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle'
import { MatSliderModule } from '@angular/material/slider';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { GestureConfig } from '@angular/material';
import {
  MatToolbarModule,
  MatChipsModule,
  MatSnackBarModule,
  MatDialogModule,
  MatFormFieldModule,
  MatDividerModule,
  MatNativeDateModule,
  MatSidenavModule,
  MatGridListModule
} from '@angular/material';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import 'hammerjs';

//components
import { HeaderComponent } from './header/header.component';
import { SidepanelComponent, DocumentDialogModel } from './sidepanel/sidepanel.component';
import { SpeedDialFabComponent } from './speed-dial-fab/speed-dial-fab.component'
import { TagComponent } from './tag/tag.component';
import { NavyappComponent } from './navyapp/navyapp.component';
import { ControltoolsComponent } from './controltools/controltools.component';
import { NetworkModelComponent } from './network-model/network-model.component';
import { ShipDetailComponent } from './ship-detail/ship-detail.component';
import { PositiontoolsComponent } from './positiontools/positiontools.component';
import { LandingScreenComponent, PopupComponent } from './landing-screen/landing-screen.component';
import { PdfViewerSearchComponent } from './pdf-viewer-search/pdf-viewer-search.component';
import { CoursePanelComponent } from './course-panel/course-panel.component';
import { SlmsSidepanelComponent } from './slms-sidepanel/slms-sidepanel.component';

//service
import { ConfigService } from './services/config.service';
import { DropFileService } from './services/dropfile.service';
import { AnimatorService } from './services/animator.service';
import { customInterceptor } from './services/http.interceptor';

//plugins
import { ColorPickerModule } from 'ngx-color-picker';
import { Ng2GoogleChartsModule, GoogleChartsSettings } from 'ng2-google-charts';
import { DfxeventDirective } from './navyapp/dfxevent.directive';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
// import { NgxYoutubePlayerModule } from 'ngx-youtube-player';
import { PdfViewerModule } from 'ng2-pdf-viewer';

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, '/assets/i18n/', '-lang.json');
}

@NgModule({
  declarations: [
    AppComponent,
    // DatauxviewComponent,
    PositiontoolsComponent,
    SidepanelComponent,
    DocumentDialogModel,
    DfxeventDirective,
    SpeedDialFabComponent,
    HeaderComponent,
    TagComponent,
    ControltoolsComponent,
    NavyappComponent,
    PopupComponent,
    NetworkModelComponent,
    ShipDetailComponent,
    LandingScreenComponent,
    PdfViewerSearchComponent,
    CoursePanelComponent,
    SlmsSidepanelComponent
  ],
  imports: [
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatTableModule,
    MatTabsModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatCardModule,
    MatListModule,
    MatProgressSpinnerModule,
    MatButtonToggleModule,
    MatMenuModule,
    MatRadioModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatSlideToggleModule,
    ColorPickerModule,
    Ng2GoogleChartsModule,
    DragDropModule,
    MatSliderModule,
    MatToolbarModule,
    MatChipsModule,
    MatSnackBarModule,
    MatDialogModule,
    MatFormFieldModule,
    MatDividerModule,
    MatNativeDateModule,
    MatSidenavModule,
    MatGridListModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatProgressBarModule,
    DatauxviewModule,
    PdfViewerModule
    // NgxYoutubePlayerModule
  ],
  entryComponents: [PopupComponent, DocumentDialogModel],
  exports: [
    BrowserModule,
    HttpModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatTableModule,
    MatTabsModule,
    MatButtonModule,
    MatTooltipModule,
    MatDialogModule,
    MatIconModule,
    MatInputModule,
    MatCardModule,
    MatListModule,
    DragDropModule,
    MatSelectModule,
    DatauxviewModule
  ],
  providers: [
    ConfigService,
    DropFileService,
    AnimatorService,
    KHASystemService,
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: GestureConfig
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: customInterceptor,
      multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
