import { HostListener } from '@angular/core';
import { getProperty, getVertexProperty } from "./util";
import { ForceDirectedLayout } from "./graphFDL";
import { CommunicationService } from "../../services/communication.service";

declare var BABYLON;
const constTagWidth = -16; // 1555 - window.innerWidth;
const constTagHeight = 25; // 815 - window.innerHeight;

export class GraphicsManager {
  graph;
  spec;
  layoutManager;
  dfx;
  datascape
  spheremodel;
  cubemodel
  fdl;
  meshes;
  scene;
  multiTagElem: any = []
  distance: any = 100;
  broadcastSubs
  mouseEvent: any;
  isDrag = false;
  nodeUnSelect: boolean = false;
  ern = "";
  constructor(graph1, spec, private CommunicationServ: CommunicationService) {
    this.meshes = [];
    this.graph = graph1;
    this.spec = spec;
    this.dfx = this.graph.dfx;
    this.datascape = this.dfx.getDatascape();
    this.scene = this.datascape.getCamera().getScene();
    this.fdl = ForceDirectedLayout;
    this.addSphereModel();
    this.addRectModel();
    //this.addLabelModel();
    this.initialize(spec);
    window['gscope'] = this;
    var canvas = document;
    canvas.addEventListener("pointerdown", (event) => {
      //       this.mousestate = 'down';
      this.isDrag = true;
      if (event.which == 3) {
        if (this.labelElem) {
          this.offHover();
        }
      }
      // console.log(this.mousestate,event);
      // this.mouseEvent = event;
      // console.log(this.mousestate,this.isDrag);
    })
    canvas.addEventListener("mousemove", (event) => {
      this.mouseEvent = event;
      // if(this.mouseEvent){
      //   if(this.mouseEvent.clientX !== event.clientX && this.mouseEvent.clientY !== event.clientY){
      //     this.isDrag = true;
      //     console.log("mousemove",event);
      //   }
      // }

    })
    canvas.addEventListener("pointerup", (event) => {
      setTimeout(() => {
        this.isDrag = false;
      }, 500);

      this.mousestate = 'up';
      // if (this.mouseEvent.clientX === event.clientX && this.mouseEvent.clientY === event.clientY) {
      //   this.isDrag = false;
      // } else {
      //   this.isDrag = true;
      // }
      // console.log(this.mousestate,this.isDrag);
    })

    this.broadcastSubs = this.CommunicationServ.getInstance()
      .subscribe((data: any) => {
        if (data.src === "networkmodel") {
          if (data.event === "updatelastelem") {
            // if (data['data']) {
            this.pickElem = data['data'];
            // }
          }
        }
      })
  }

  initialize(spec) {
    this.disposeAllMeshes();
    this.spec = spec;
    let layoutType = getProperty(spec, "layout", "xForce Directed");
    if (layoutType === "Force Directed") {
      this.layoutManager = new ForceDirectedLayout(this.graph, 0.1, 10, 0.5, 0.01);
    } else {
      this.layoutManager = null;
    }
  };
  addSphereModel() {
    let opt = {
      shape: 'sphere',
      id: 'node_sphere',
      size: 1,
      profiles: {
        regular: {
          material: 'grey',
          transition: 0,
          visible: true
        },
        grey: {
          material: 'grey',
          transition: 0,
          visible: true
        },
        red: {
          material: 'see_me_red',
          transition: 0,
          visible: true
        },
        yellow: {
          material: 'see_me_yel',
          transition: 0,
          visible: true
        },
        blue: {
          material: 'see_me_blue',
          transition: 0,
          visible: true
        },
        pink: {
          material: 'see_me_pink',
          transition: 0,
          visible: true
        },
        violet: {
          material: 'see_me_violet',
          transition: 0,
          visible: true
        },
        lgrey: {
          material: 'see_me_grey_light',
          transition: 0,
          visible: true
        },
        hidden: {
          material: 'grey',
          transition: 0,
          visible: false
        },
        transparent: {
          material: 'transparent',
          transition: 0,
          visible: true
        }
      }
    }
    this.spheremodel = this.datascape.model(opt);
  }
  addRectModel() {
    let opt = {
      shape: 'cube',
      id: 'node_cube',
      size: 1,
      profiles: {
        regular: {
          material: 'grey',
          transition: 0,
          visible: true
        },
        grey: {
          material: 'grey',
          transition: 0,
          visible: true
        },
        red: {
          material: 'see_me_red',
          transition: 0,
          visible: true
        },
        yellow: {
          material: 'see_me_yel',
          transition: 0,
          visible: true
        },
        blue: {
          material: 'see_me_blue',
          transition: 0,
          visible: true
        },
        pink: {
          material: 'see_me_pink',
          transition: 0,
          visible: true
        },
        violet: {
          material: 'see_me_violet',
          transition: 0,
          visible: true
        },
        lgrey: {
          material: 'see_me_grey_light',
          transition: 0,
          visible: true
        },
        hidden: {
          material: 'grey',
          transition: 0,
          visible: false
        },
        transparent: {
          material: 'transparent',
          transition: 0,
          visible: true
        }
      }
    }
    this.cubemodel = this.datascape.model(opt);
  }
  lab_base_model
  addLabelModel() {
    let opt = {
      shape: 'labbase',
      id: 'lbase',
      size: 1,
      profiles: {
        regular: {
          material: 'grey',
          transition: 0,
          visible: true
        },
        hidden: {
          material: 'grey',
          transition: 0,
          visible: false
        },
        transparent: {
          material: 'transparent',
          transition: 0,
          visible: true
        }
      }
    }
    this.lab_base_model = this.datascape.model(opt);
  }
  getProfileFromHex(hex, model = null) {
    let map = {};//{'#FF0000':"red",'#A6A6A6':'grey','#6CB7EA':'blue','#D9D9D9':'lgrey','#F5E4A9':'yellow','#F2A4FF':'pink','#7E84D2':'violet'}
    let prf = map[hex];
    if (!prf) {
      prf = this.addNewProfileColor(hex, true, "", model || this.spheremodel)
    }
    return prf;
  }
  addVertexLabel(vid, text) {
    let prf = this.addLabelTexture(vid + "_tex", text, 'labprofile_', this.lab_base_model, 0.3, false)
    let m = this.datascape.add({
      id: 'vert_' + vid + '_lab',
      model: this.lab_base_model,
      profile: 'regular',
      geometry: { position: { x: 1, y: 0, z: 0 }, orientation: { x: 90, y: 180, z: 0 }, size: 1 }
    });
    this.datascape.modify(m, { profile: prf });
    return m;
  }
  addVertexMesh(v) {
    let vid = v.vid;
    let newMesh = v.mesh;
    let vtx = this.spec.vertices[v["vid"] - 1];

    let shape = vtx.shape || 'sphere';
    let model = shape === 'rectangle' ? this.cubemodel : this.spheremodel;
    let color = this.getProfileFromHex(vtx.color, model) || 'regular';
    let pos = getVertexProperty(this.spec, vid, "position", [0, 0, 0]);
    if (newMesh) {
      v.mesh = newMesh;
    } else {
      newMesh = this.datascape.add({
        id: 'vert_' + vid,
        model: shape === 'rectangle' ? this.cubemodel : this.spheremodel,

        geometry: { position: { x: pos[0].x, y: pos[0].y, z: pos[0].z }, size: 4 },
        tag: { default_prf: color }
      });
      this.datascape.modify(newMesh, { profile: color });

      v.mesh = newMesh;
      let s = this.datascape.getElementMesh(newMesh);
      if (shape === 'rectangle') {
        this.datascape.setElementScale(newMesh, [9.5, 2.5, 0.7])
        s.billboardMode = 7
      }
      /* let lab=this.addVertexLabel(vid,vtx.label);
      this.datascape.setAsParent(newMesh,[lab]); */
      this.meshes.push(s);
      this.applyLabel(v);
    }

    //this.updateLayout();
  };
  onHoverElem
  pickElem;
  tagElem;
  pickObj
  selLab
  applyLabel(data) {
    let el = data["mesh"];
    let props = this.datascape.props(el);
    this.dfx._allElements[props.id] = el;
    let vtx = this.spec.vertices[data["vid"] - 1];
    let lab = vtx['index'] + ": " + (vtx['label'] || data["vid"]);
    this.datascape.attach(el, {
      actions: {
        pickDown: [(evt, elem) => {
          if (evt.button === 0) {
            this.isDrag = true;
          }
        }],
        pickUp: [(evt, elem) => {
          let props1 = this.datascape.props(elem);
          let vid = props1.id.split('_')[1];
          /* this.broadcastInfo({ src: 'graphicNode', event: 'nscHideSubBranch',id:props1.id.split('_')[1], boo:true });
          this.broadcastInfo({ src: 'graphicNode', event: 'nscShowPath',id:props1.id.split('_')[1], boo:true });
 */
          if (this.mouseEvent) {
            let dx = Math.abs(this.mouseEvent.clientX - evt.clientX) < 1;
            let dy = Math.abs(this.mouseEvent.clientY - evt.clientY) < 1;
            if (dx && dy) {
              this.isDrag = false;
            } else {
              this.isDrag = true;
            }
          }
          if (evt.button === 0 && !this.isDrag) {
            if (this.pickElem) {
              let props1 = this.datascape.props(elem);
              let props2 = this.datascape.props(this.pickElem);
              if (props1.id !== props2.id) {
                if (document.getElementById(this.ern + "nmMiniAdd_" + props2['id'].split('_')[1])) {
                  if (document.getElementById(this.ern + "nmMaxLabel_" + props2['id'].split('_')[1]).style.display != 'none') {
                    this.CommunicationServ.nodeUnSelect = false;
                    document.getElementById(this.ern + "nmMiniAdd_" + props2['id'].split('_')[1]).click();
                  }
                }
              } else {
                if(document.getElementById(this.ern + "nmMaxLabel_" + props1['id'].split('_')[1]).style.display != 'none'){
                  this.nodeUnSelect = true;
                  this.CommunicationServ.nodeUnSelect = true;
                  this.broadcastInfo({ src: 'graphicNode', event: 'nodeunselected', id: Number(vid), key: '' });
                }else{
                  this.nodeUnSelect = false;
                  this.CommunicationServ.nodeUnSelect = false;
                }                
              }

              this.pickElem = null;
              this.pickObj = null;
              this.selLab = "";
              // this.hideTag(props2);
              // }else{
              delete this.tagElem;
              this.pickElem = elem;
              this.pickObj = elem;
              this.selLab = lab;
              // }
            } else {

              this.pickElem = elem;
              this.pickObj = elem;
              this.selLab = lab;
            }
            if (this.pickElem && this.pickObj) {

              this.hightlightNode(false, this.pickElem);
              if (this.onHoverElem) {
                let props1 = this.datascape.props(elem);
                let props2 = this.dfx.getDatascape().props(this.onHoverElem);
                if (props1.id === props2.id) {
                  this.offHover();
                }
              }
              if (!this.nodeUnSelect) {
                this.showTag(this.pickElem, this.selLab);
                this.broadcastInfo({ src: 'graphicNode', event: 'nodeSelected', id: Number(vid), key: true });
              } else {
                this.nodeUnSelect = false;
              }
              // this.pickElem = null;
              this.pickObj = null;
              this.selLab = "";
              delete this.tagElem;
            }
          }
        }
          // pickLeft: [(evt, elem) => { }
        ],
        hoverOn: [(evt, elem) => {
          // console.log("offhover", evt, elem)
          if (this.mousestate == 'down') {
            if (this.onHoverElem) {
              this.offHover();
            }
            return
          }
          this.onHoverElem = elem;
          // setTimeout(() => {
          // console.log("onhover")
          if (this.onHoverElem && !this.isDrag) {
            let props1 = this.dfx.getDatascape().props(elem);
            let props2 = this.dfx.getDatascape().props(this.onHoverElem);
            // console.log("props", props1)
            if (props1.id === props2.id) {
              this.onHover(elem, vtx);
            }
          }
          // }, 500)
        }],
        hoverOff: [(evt, elem) => { this.onHoverElem = elem; this.offHover(); }],
      }
    });
  }

  /* * * * *
* method for communicate event instance with data to access all components
* * * * * */
  broadcastInfo(data: any) {
    this.CommunicationServ.getInstance().next(data);
  }
  /* * * * *
 * sphere onhover tag will show if lable control is off
 * * * * * */
  onHover(elem, data) {
    // console.log("hover", this.dfx.isDragmove);
    this.objObserver(elem, data)
    // this.addObserver(elem);
  }

  /* * * * *
  * sphere offhover shown tag will hide
  * * * * * */
  labelElem: any;
  offHover() {
    let ldom: any = this.labelElem;
    if (ldom) {
      ldom.style.display = "none";
      delete this.onHoverElem;
      delete this.labelElem;
    }
    // if (!this.multipleTagStatus) {
    //   let el = this.datauxview.getElementId(elem);
    //   let dom = document.getElementById('tooltip_' + this.hoverTagDetail.id);
    //   if (dom) {
    //     dom.style.display = "none";
    //     this.hoverTagDetail = '';
    //     this.removeObserver(el);
    //   }
    // }
  }
  mousestate = 'up';
  @HostListener('mousedown', ['$event']) onMouseDown(event) {
    // console.log('mousedown', event);
    this.mousestate = "down";

  }
  mouseDown: any = false;
  @HostListener('mouseup', ['$event']) onMouseUp(event) {
    this.mousestate = "up";
  }


  /* * * *
  * observable small Tag will show respected position
  * * * */
  objObserver(el, data) {
    if (!this.labelElem) {
      let props1 = this.dfx.getDatascape().props(el);
      this.labelElem = document.getElementById(this.ern + "nmLabel_" + props1['id'].split('_')[1]);

      var labelContentBg = document.getElementById(this.ern + "nmLabelContent_" + props1['id'].split('_')[1]);
    }
    let offB = -4;//30;
    let offL = 5;
    let dfx = this.datascape
    let ldom: any = this.labelElem;
    let pobj = dfx.getElementPosition(el);
    if (ldom != null) {
      if (pobj.visibility) {
        let pt = pobj.position2D;
        ldom.style.left = (pt[0] - offL) + 'px';
        ldom.style.bottom = (pt[1] - offB) + 'px';
        ldom.style.display = "block";
        labelContentBg.style.backgroundColor = data.color;
      } else {
        ldom.style.display = "none";
      }
    }
  };
  showTag(el, lab) {
    if (this.pickObj) {
      let props1 = this.datascape.props(el);
      // document.getElementById("nmMiniAdd_" + props1['id'].split('_')[1]).click();
      var miniElem = document.getElementById(this.ern + "nmMiniLabel_" + props1['id'].split('_')[1]);
      // this.focusElement(el);
      this.hightlightNode(true, el);
      // if(this.multiTagElem.length>0){
      //   let idx =   this.multiTagElem.findIndex(e=>e.id==props1.id);
      //   if(idx==-1){
      //     this.multiTagElem.push(props1)
      //   }
      // }else{
      //   this.multiTagElem.push(props1)
      // }

      // this.configService.addMaxPopup(this.multiTagElem);
      if (!this.tagElem) {
        // this.tagElem=document.getElementsByClassName("nm_label")[0];
        this.tagElem = document.getElementById(this.ern + "nmMaxLabel_" + props1['id'].split('_')[1]);
      }
      let offB = -6;//30;
      let offL = 5;
      let dfx = this.datascape
      let ldom: any = this.tagElem;
      let pobj = dfx.getElementPosition(el);
      if (ldom != null) {
        if (pobj.visibility) {
          let pt = pobj.position2D;
          // ldom.style.left=(pt[0]-offL)+'px';
          // ldom.style.bottom=(pt[1]-offB)+'px';
          // tagElemContent.innerHTML=lab;
          ldom.style.display = "inline-block";
          if (miniElem) {
            ldom.style.display = "none";
          }
        } else {
          ldom.style.display = "none";
        }
      }
    }

  };
  hideTag(props) {
    // let idx =this.multiTagElem.findIndex(e=> e.id==props.id);
    // if(idx>-1){
    //   this.multiTagElem.splice(idx,1);
    // }
    // this.configService.addMaxPopup(this.multiTagElem);
    let ldom: any = this.tagElem;
    if (ldom) {
      ldom.style.display = "none";
    }
  };
  updateTag() {
    if (this.pickElem) {
      this.showTag(this.pickElem, this.selLab);
    }
  }
  addEdgeMesh(e) {
    // The layout manager will call setEdgeMesh() when the vertices'
    // positions are set.
    if (this.layoutManager != null) {
      this.layoutManager.updateLayout();
      return;
    }
    this.setEdgeMesh(e);
  };

  setEdgeMesh(e) {
    let path = [];
    let m1 = this.datascape.getElementMesh(e.v1.mesh)
    let m2 = this.datascape.getElementMesh(e.v2.mesh)
    path.push(m1.position);
    path.push(m2.position);

    this.createTube(e, path);
  };

  /* createTube(e, path) {

    let eid = e.eid;
    if(!this.graph.edges[eid].mesh)
    if(e.v1.vid===1){
      console.log(e.v1.vid,e.v2.vid)
    }
      this.graph.edges[eid].mesh=this.datascape.connect({id:'edge_'+eid,source:path[0],target:path[1],color:{r:200,g:200,b:200}})

  }; */
  createTube(e, path) {

    let eid = e.eid;

    let radius = getProperty(this.spec, "edgeMesh:args:radius", .3);
    let tess = getProperty(this.spec, "edgeMesh:args:tesselation", 8);

    if (this.graph.edges[e.eid].mesh != null) {
      this.graph.edges[eid].mesh = BABYLON.MeshBuilder.CreateTube(null, { path: path, radius: radius, instance: this.graph.edges[eid].mesh }, this.scene);
      return;
    }

    let mesh = BABYLON.MeshBuilder.CreateTube("e" + eid, { path, radius, tessellation: tess, updatable: true }, this.scene);
    let m1 = this.graph.edges[e.eid].v2.mesh;
    let vmesh = this.datascape.getElementMesh(m1);
    mesh.material = vmesh.material;//this.datascape.getStandardMaterial('mat_'+eid);

    this.graph.edges[eid].mesh = mesh;
    this.meshes.push(mesh);
  };
  updateLayout() {
    if (this.layoutManager != null) {
      this.layoutManager.updateLayout();
    }
    for (let m in this.graph.edges) {
      let e = this.graph.edges[m];
      this.setEdgeMesh(e);
    }

  };

  removeVertexMesh(vid) {
    for (let m in this.graph.meshes) {
      if (this.graph.meshes[m].name === "v" + vid) {
        this.graph.meshes[m].dispose();
        return;
      }
    }
  };

  removeEdgeMesh(eid) {
    for (let m in this.graph.meshes) {
      if (this.graph.meshes[m].name === "e" + eid) {
        console.log("disposing mesh: " + this.graph.meshes[m].name);

        this.graph.meshes[m].dispose();
        return;
      }
    }
  };
  destroy() {
    this.disposeAllMeshes();
    this.meshes = [];
    this.graph = null;
    this.spec = null;
    this.dfx = null;
    this.datascape = null;
    this.scene = null;
    this.fdl = null;
  }
  disposeAllMeshes() {
    this.meshes.forEach((mesh) => {
      mesh.dispose();
    });
    this.meshes = [];

  };
  texCAN
  color_map = {}
  createContext(width, height) {
    var canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    return canvas;
  }
  getTexture(w = 8, h = 8, col = "#ffffff", tex = null) {
    if (!this.texCAN) {
      this.texCAN = this.createContext(w, h)
    }
    let ctx = this.texCAN.getContext("2d");
    ctx.clearRect(0, 0, w, h);
    ctx.beginPath();
    ctx.rect(0, 0, w, h);
    ctx.fillStyle = col;
    ctx.fill();
    if (tex) {
      ctx.font = "10px Arial";
      ctx.textAlign = "center";
      ctx.fillText(tex, w / 2, h / 2);
    }
    let png = this.texCAN.toDataURL();
    return png;
  }
  addNewProfileToModels(prf, model = null) {
    let dfx = this.datascape;
    let prfs = { profiles: {} }
    let _p = {
      material: "",
      visible: true,
      selectable: true,
      transition: 0
    };
    let p = this.clone(_p);
    p.material = prf;
    prfs.profiles[prf] = p;
    p = this.clone(_p);
    p.material = prf + "_transparent";
    prfs.profiles[prf + "_transparent"] = p;
    if (model) {
      dfx.addProfiles(model, prfs.profiles);
    }
  }
  clone(obj) {
    return JSON.parse(JSON.stringify(obj));
  }
  addLabelTexture(col, text = '0', prefix = "", model = null, trans = 0.3, bfc = true) {
    if (!col) {
      return;
    }
    let pcol = this.color_map[col];
    if (!pcol) {
      let dfx = this.datascape;
      let obj = { "id": "dfx_col1", "diffuse": [1, 1, 1], "specular": [0, 0, 0], "specularPower": 25, "ambient": [1, 1, 1], "backFaceCulling": bfc }
      let obj_transp = { "id": "dfx_col2_transparent", "diffuse": [1, 1, 1], "alpha": trans, "specular": [0, 0, 0], "specularPower": 25, "ambient": [1, 1, 1], "backFaceCulling": bfc }
      obj.id = prefix + "dfx_" + col;
      obj['diffuseTexture'] = { "name": this.getTexture(64, 64, '#00000000', text), "hasAlpha": true }
      let mat = [obj];
      dfx.addNewMaterials(mat);
      obj_transp.id = prefix + "dfx_" + col + "_transparent";
      obj_transp.diffuse = obj.diffuse;
      obj_transp.ambient = obj.ambient;
      obj_transp['diffuseTexture'] = obj['diffuseTexture'];
      obj_transp.alpha = trans;
      let mat2 = [obj_transp];
      dfx.addNewMaterials(mat2);
      this.addNewProfileToModels(prefix + "dfx_" + col, model);
      this.color_map[col] = prefix + "dfx_" + col;
    }
    return this.color_map[col];
  }
  getHexToRGB(hex) {
    var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function (m, r, g, b) {
      return r + r + g + g + b + b;
    });
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? [parseInt(result[1], 16) / 255, parseInt(result[2], 16) / 255, parseInt(result[3], 16) / 255] : [0, 0, 0];
  }
  addNewProfileColor(col, addTex = true, prefix = "", model = null, trans = 0.3, bfc = true) {
    if (!col) {
      return;
    }
    let pcol = this.color_map[col];
    if (!pcol) {
      let dfx = this.datascape;
      let obj = { "id": "dfx_col1", "diffuse": [1, 1, 1], "specular": [0, 0, 0], "specularPower": 25, "ambient": [1, 1, 1], "emissive": [0, 0, 0], "backFaceCulling": bfc }
      let obj_transp = { "id": "dfx_col2_transparent", "diffuse": [1, 1, 1], "alpha": trans, "specular": [0, 0, 0], "specularPower": 25, "ambient": [1, 1, 1], "emissive": [0, 0, 0], "backFaceCulling": bfc }
      obj.id = prefix + "dfx_" + col;
      obj.diffuse = this.getHexToRGB(col);
      obj.ambient = obj.diffuse;//[obj.diffuse[0] / 4, obj.diffuse[1] / 4, obj.diffuse[2] / 4]
      obj.emissive = [obj.diffuse[0] / 6, obj.diffuse[1] / 6, obj.diffuse[2] / 6]
      if (addTex) {
        obj['diffuseTexture'] = { "name": this.getTexture(8, 8, col) }
      } else {

      }
      //obj.ambient = obj.diffuse;
      let mat = [obj];
      dfx.addNewMaterials(mat);
      obj_transp.id = prefix + "dfx_" + col + "_transparent";
      obj_transp.diffuse = obj.diffuse;
      obj_transp.ambient = obj.ambient;
      obj_transp.emissive = obj.emissive;
      if (addTex) {
        obj_transp['diffuseTexture'] = obj['diffuseTexture']
      } else {

      }
      //obj_transp.ambient = obj.diffuse;
      obj_transp.alpha = trans;
      let mat2 = [obj_transp];
      dfx.addNewMaterials(mat2);

      this.addNewProfileToModels(prefix + "dfx_" + col, model);
      this.color_map[col] = prefix + "dfx_" + col
    } else {
      if (model) {
        this.addNewProfileToModels(this.color_map[col], model);
      }
    }
    return this.color_map[col];
  }
  focusElement(elem) {
    if (elem) {
      let dfx = this.dfx.getDatascape();
      let def = { "target": dfx.getAbsElemCenter(elem), "distance": this.distance };
      dfx.moveCamera(def);
    }
  }
  
  hightlightNode(boo, el, col = [1, 0.5, 0], w = 0.15) {
    let dfx = this.dfx.getDatascape();
    let mesh = dfx.getElementMesh(el);
    if (boo) {
      mesh.outlineColor.r = col[0] || 0;
      mesh.outlineColor.g = col[1] || 0;
      mesh.outlineColor.b = col[2] || 0;
      mesh.outlineWidth = w;
      if (!this.CommunicationServ.showConnector) {
        this.focusElement(el);
      }
    } else {
      mesh.outlineWidth = 0;
    }
    mesh.renderOutline = boo;
  }
  /**
  show highlight:
  hightlightNode(true,element)
  remove highlight:
  hightlightNode(false,element)
  */
}
