import { Directive, ElementRef, HostListener, EventEmitter, Output, Input } from '@angular/core';

@Directive({
  selector: '[appDfxevent]'
})
export class DfxeventDirective {
  @Input()
  flagDrag: boolean = !false;
  @Output()
  change: EventEmitter<any> = new EventEmitter<any>();
  isSingleClick:boolean=false;
  lastEvent: any;
  constructor() { }

  @HostListener('click', ['$event'])
  onMouseup(event) {
    /* this.isSingleClick = true;
        setTimeout(()=>{
            if(this.isSingleClick){
                 this.execEvent(event);
            }
         },250) */
         this.execEvent(event);
  }

@HostListener('mousemove', ['$event'])
    onMouseMove(event) {
        this.lastEvent = event;
        // console.log('onMouseMove'  + event.clientX + ',' + event.clientY);
    }
/* @HostListener('dblclick', ['$event'])
handleDblClickEvent(event: MouseEvent) {
  this.isSingleClick = !true;
  this.change.emit('dblclick');
} */

execEvent(event){
  if ( ( this.lastEvent.clientX === event.clientX) && ( this.lastEvent.clientY === event.clientY) && (event.srcElement.id === 'renderCanvas')) {
    this.flagDrag = !true;
    // emit select event
    this.change.emit(this.flagDrag);
    // console.log('emil flagDrag:' + this.flagDrag);
  } else {
    this.flagDrag = !false;
    this.change.emit(this.flagDrag);
    // console.log('set to flase flagDrag:' + this.flagDrag);
  }
  this.lastEvent = event;
}

}
