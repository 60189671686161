import { Injectable, ViewChild } from '@angular/core';
import * as systems from "../../assets/scs/data/kha_systems.json";
import { CommunicationService } from "../services/communication.service";

@Injectable({
  providedIn: 'root'
})
export class ControllerService {
  datauxview: any;
  dfx: any;
  shipHazardList: any = "";
  selectedKha: any;
  selectedErn: any = 'ern-damagecontrol';
  selectedAops: any = 'aops-damagecontrol';
  selectedSysComp: any = [];
  showSysComp: any = {};
  isAppLoaded: boolean = false;
  isNscAppLoaded: boolean = false;
  isMaterialAppLoaded:boolean=false;
  khaSystems = systems['default'];
  matCurrentTab: any = "system";
  ernDisplay: boolean = false;
  selectedCompComments: any = {};
  selectedSysComments: any = {};

  profiles: {
    see_me_yel: {
      material: 'see_me_yel',
      transition: 500,   // in milliseconds
      visible: true,
      shadow: true,
      selectable: true
    },
  }
  ernMappingList: any = [
    { name: "Damage Control Systems", key: 'ern-damagecontrol' },
    { name: "Main Propulsion System", key: 'ern-populasion' },
    { name: "Electrical Power", key: 'ern-electricalpower' },
    { name: "Marine Engineering Aux Equipment Group", key: 'ern-marine', status: 'active' },
    { name: "Deck & Hull Equipment Group", key: 'ern-deckhull' },
    { name: "Hull System & Fittings", key: 'ern-hullsystems' },
    { name: "Main Refrigeration &  HVAC Systems", key: 'ern-hvac' },
    { name: "Machinery Control & Surveillance Systems", key: '' }
  ]

  mbsStatusList: any = [
    { 'id': 'acceptable', 'status': 'Acceptable', 'color': '#00b050' },
    { 'id': 'acceptablewithreview', 'status': 'Acceptable with review', 'color': '#00b0f0' },
    { 'id': 'undesirable', 'status': 'Undesirable', 'color': '#ffff00' },
    { 'id': 'intolerable', 'status': 'Intolerable', 'color': '#ff0000' }
  ];
  statusList: any = [
    { 'id': 'completed', 'status': 'Completed', 'color': '#99ccff' },
    { 'id': 'noissues', 'status': 'No Issues', 'color': '#00b050' },
    { 'id': 'ontrackminorissues', 'status': 'On Track minor issues', 'color': '#99cc00' },
    { 'id': 'significantissuessuccessfuldeliveryathighrisk', 'status': 'Significant Issues successful delivery at high risk', 'color': '#ffc000' },
    { 'id': 'majorissuespreventingcompletion', 'status': 'Major Issues preventing completion', 'color': '#ff6600' },
    { 'id': 'criticalissuespreventingcompletion', 'status': 'Critical issues preventing completion', 'color': '#ff0000' },
    { 'id': 'notstarted', 'status': 'Not Started', 'color': '#ffffff' }
  ];
  safetyStatusList: any = [
    { 'id': 'acceptable', 'status': 'Acceptable', 'color': '#00b050' },
    { 'id': 'acceptable-with-review', 'status': 'Acceptable with review', 'color': '#5faff3' },
    { 'id': 'undesirable', 'status': 'Undesirable', 'color': '#fefb1c' },
    { 'id': 'intolerable', 'status': 'Intolerable', 'color': '#d61800' }
  ];
  comps = [
    {
      "component": "NSC KHA", "compList": [
        { "system": "Structure", "key": "structure", "systemList": [], "status": "inactive" },
        {
          "system": "Buoyancy", "key": "buoyancy",
          "systemList": [
            { "name": "Eductors", "color": "#FFF", "status": "active", "model": ["Eductors"], 'targetStatus': false, 'visible': false }
          ],
          "status": "inactive"
        },
        { "system": "Engineering", "key": "engineering", "systemList": [], "status": "inactive" },
        { "system": "Seamanship", "key": "seamanship", "systemList": [], "status": "inactive" },
        {
          "system": "Fire Safety", "key": "firesafety",
          "systemList": [{ "name": "HALON System", "color": "#FFF", "status": "active", "model": ["HALON System"], 'targetStatus': false, 'visible': false },
          { "name": "Automatic Water Spray", "color": "#FFF", "status": "active", "model": ["Automatic Water Spray"], 'targetStatus': false, 'visible': false },
          { "name": "AFFF System", "color": "#FFF", "status": "active", "model": ["AFFF System"], 'targetStatus': false, 'visible': false },
          { "name": "Twin Agent (TAU)", "color": "#FFF", "status": "active", "model": ["Twin Agent (TAU)"], 'targetStatus': false, 'visible': false },
          { "name": "Fine Water Spray", "color": "#FFF", "status": "active", "model": ["Fine Water Spray"], 'targetStatus': false, 'visible': false },
          { "name": "Galley FSS (KIDDE)", "color": "#FFF", "status": "active", "model": ["Galley FSS (KIDDE)"], 'targetStatus': false, 'visible': false },
          { "name": "MDFP", "color": "#FFF", "status": "active", "model": ["MDFP"], 'targetStatus': false, 'visible': false },
          { "name": "DDFP", "color": "#FFF", "status": "active", "model": ["DDFP"], 'targetStatus': false, 'visible': false },
          { "name": "Firemain", "color": "#FFF", "status": "active", "model": ["Firemain"], 'targetStatus': false, 'visible': false },
          { "name": "Hoses, Nozzles & Fittings", "color": "#FFF", "status": "active", "model": ["Hoses, Nozzles & Fittings"], 'targetStatus': false, 'visible': false },
          { "name": "Dry Chemical Extinguishers", "color": "#FFF", "status": "active", "model": ["Dry Chemical Extinguishers"], 'targetStatus': false, 'visible': false },
          { "name": "CO2 Extinguishers", "color": "#FFF", "status": "active", "model": ["CO2 Extinguishers"], 'targetStatus': false, 'visible': false },
          { "name": "Water Extinguishers", "color": "#FFF", "status": "active", "model": ["Water Extinguishers"], 'targetStatus': false, 'visible': false },
          { "name": "SCBA/Charging System/PDC", "color": "#FFF", "status": "active", "model": ["SCBA/Charging System/PDC"], 'targetStatus': false, 'visible': false },
          { "name": "SCBA/Charging System/HPFA", "color": "#FFF", "status": "active", "model": ["SCBA/Charging System/HPFA"], 'targetStatus': false, 'visible': false },
          { "name": "Locker (Damage Control)", "color": "#FFF", "status": "active", "model": ["Locker (Damage Control)"], 'targetStatus': false, 'visible': false }],
          "status": "inactive"
        },
        {
          "system": "EER", "key": "eer", "systemList": [
            { "name": "EEBDs", "color": "#FFF", "status": "active", "model": ["EEBDs"], 'targetStatus': false, 'visible': false },
            { "name": "Life Rafts", "color": "#FFF", "status": "active", "model": ["Life Rafts"], 'targetStatus': false, 'visible': false },
            { "name": "Life Buoys", "color": "#FFF", "status": "active", "model": ["Life Buoys"], 'targetStatus': false, 'visible': false }
          ], "status": "inactive"
        },
        { "system": "Comms", "key": "comms", "systemList": [], "status": "inactive" },
        { "system": "Navigation", "key": "navigation", "systemList": [], "status": "inactive" },
        { "system": "Dangerous Goods", "key": "dangerousgoods", "systemList": [], "status": "inactive" },

        { "system": "NSSC", "key": "nssc", "systemList": [], "status": "inactive" },
        { "system": "General Requirements", "key": "generalrequirements", "systemList": [], "status": "inactive" },
        { "system": "Aviation", "key": "aviation", "systemList": [], "status": "inactive" },
        { "system": "Seamanship", "key": "seamanship", "systemList": [], "status": "inactive" },
      ]
    },
    { "component": "Systems", "compList": [] },
    // { "component": "Compartments", "compList": [] }
    // { "component": "Sections", "compList": [] }
  ]
  shipComp = { "component": "Compartments", "compList": [] };
  service: any = {
    "nsc_certificate": "03 March 2021", "key": "Key Hazard Area Certificates",
    "hazardList":
      [
        { "activity": "Not Started", 'order': "", "area": "NSSC", "key": "nssc", "nscStatus": "inactive", "mbsStatus": "Acceptable", "shipStatus": "inactive", "date": "03 Mar 2021", "scc": "inactive", "mbslink": "" },
        { "activity": "Not Started", 'order': "I", "area": "General Requirements", "key": "generalrequirements", "nscStatus": "inactive", "mbsStatus": "Acceptable", "shipStatus": "inactive", "date": "03 Mar 2021", "scc": "inactive", "mbslink": "" },
        { "activity": "Not Started", 'order': "II", "area": "Structure", "key": "structure", "nscStatus": "inactive", "mbsStatus": "Acceptable", "shipStatus": "inactive", "date": "03 Mar 2021", "scc": "inactive", "mbslink": "" },
        { "activity": "Completed", 'order': "III", "area": "Buoyancy", "key": "buoyancy", "nscStatus": "active", "mbsStatus": "Acceptable with review", "shipStatus": "inactive", "date": "03 Mar 2021", "scc": "active", "mbslink": "" },
        { "activity": "Not Started", 'order': "IV", "area": "Engineering", "key": "engineering", "nscStatus": "inactive", "mbsStatus": "Undesirable", "shipStatus": "inactive", "date": "03 Mar 2021", "scc": "inactive", "mbslink": "" },
        { "activity": "Not Started", 'order': "V", "area": "Seamanship", "key": "seamanship", "nscStatus": "inactive", "mbsStatus": "Intolerable", "shipStatus": "inactive", "date": "03 Mar 2021", "scc": "inactive", "mbslink": "" },
        { "activity": "Significant Issues successful delivery at high risk", 'order': "VI", "area": "Fire Safety", "key": "firesafety", "nscStatus": "active", "mbsStatus": "Undesirable", "shipStatus": "inactive", "date": "03 Mar 2021", "scc": "active", "mbslink": "../../assets/doc/fire_safety_MBS_systems.xlsx#MBS" },
        { "activity": "Major Issues preventing completion", 'order': "VII", "area": "EER", "key": "eer", "nscStatus": "active", "mbsStatus": "Intolerable", "shipStatus": "inactive", "date": "03 Mar 2021", "scc": "active", "mbslink": "" },
        { "activity": "Not Started", 'order': "VIII", "area": "Comms", "key": "comms", "nscStatus": "inactive", "mbsStatus": "Undesirable", "shipStatus": "inactive", "date": "03 Mar 2021", "scc": "inactive", "mbslink": "" },
        { "activity": "Not Started", 'order': "IX", "area": "Navigation", "key": "navigation", "nscStatus": "inactive", "mbsStatus": "Intolerable", "shipStatus": "inactive", "date": "03 Mar 2021", "scc": "inactive", "mbslink": "" },
        { "activity": "Not Started", 'order': "X", "area": "Dangerous Goods", "key": "dangerousgoods", "nscStatus": "inactive", "mbsStatus": "Acceptable", "shipStatus": "inactive", "date": "03 Mar 2021", "scc": "inactive", "mbslink": "" },
        { "activity": "Not Started", 'order': "XI", "area": "Aviation", "key": "aviation", "nscStatus": "inactive", "mbsStatus": "Acceptable", "shipStatus": "inactive", "date": "03 Mar 2021", "scc": "inactive", "mbslink": "" }
      ],
    "safetyArea":
      [
        { "activity": "Acceptable", 'order': "II", "area": "Structure", "key": "structure", "nscStatus": "inactive", "mbsStatus": "Acceptable", "shipStatus": "inactive", "date": "03 Mar 2021", "summary": "active", "detail": "inactive", "mbslink": "" },
        { "activity": "Acceptable with review", 'order': "IV", "area": "Engineering Systems", "key": "engineering", "nscStatus": "inactive", "mbsStatus": "Undesirable", "shipStatus": "inactive", "date": "03 Mar 2021", "summary": "active", "detail": "inactive", "mbslink": "" },
        { "activity": "Undesirable", 'order': "VI", "area": "Fire Safety", "key": "firesafety", "nscStatus": "active", "mbsStatus": "Undesirable", "shipStatus": "inactive", "date": "03 Mar 2021", "summary": "active", "detail": "active", "mbslink": "../../assets/doc/fire_safety_MBS_systems.xlsx#MBS" },
        { "activity": "Acceptable with review", 'order': "VII", "area": "EER", "key": "eer", "nscStatus": "active", "mbsStatus": "Intolerable", "shipStatus": "inactive", "date": "03 Mar 2021", "summary": "active", "detail": "inactive", "mbslink": "" },
        { "activity": "Undesirable", 'order': "VIII", "area": "Radio Communications", "key": "comms", "nscStatus": "inactive", "mbsStatus": "Undesirable", "shipStatus": "inactive", "date": "03 Mar 2021", "summary": "active", "detail": "inactive", "mbslink": "" },
        // { "activity": "Intolerable", 'order': "IX", "area": "Navigation & Seamanship", "key": "navigation", "nscStatus": "inactive", "mbsStatus": "Intolerable", "shipStatus": "inactive", "date": "03 Mar 2021","summary": "active", "detail": "inactive", "mbslink": "" },
        { "activity": "Acceptable with review", 'order': "X", "area": "Dangerous Goods", "key": "dangerousgoods", "nscStatus": "inactive", "mbsStatus": "Acceptable", "shipStatus": "inactive", "date": "03 Mar 2021", "summary": "active", "detail": "inactive", "mbslink": "" }
      ],
    "certificate_strategy": [
      { 'status': 'Completed', 'cert': 'ConOpS', 'link': '../../assets/doc/HALIFAX-Class_Naval_Ship_Code_Concept_of_Operations_Statement_-_Ship_(NSC_CONOPS-S).PDF - Copy.pdf' },
      { 'status': 'Completed', 'cert': 'ERN Family', 'link': 'ERN' },
      { 'status': 'Completed', 'cert': 'Standards Plan', 'link': '' },
      { 'status': 'Not Started', 'cert': 'Certification Plan (build)', 'link': '' },
      { 'status': 'Completed', 'cert': 'Certification Plan (in-Svc)', 'link': '' }
    ],
    "assessed_class": "<name>", "initial_lr_class": "03 Mar 2021", "design_auth": "<name>", "regulatory_auth": "NMRA", "scheduled_refit": "June 2005", "last_refit": "June 2005", "certificate": "03 Mar 2021"
  };


  NSSC_INSC_hazardList: any = [
    { "activity": "Not Started", 'order': "", "area": "NSC Table Of Contents", "key": "insa-nsctable", "nscStatus": "active", "mbsStatus": "Acceptable", "shipStatus": "inactive", "date": "03 Mar 2021", "scc": "inactive", "mbslink": "" },
    { "activity": "Not Started", 'order': "", "area": "NSSC", "key": "insa-nssc", "nscStatus": "active", "mbsStatus": "Acceptable", "shipStatus": "inactive", "date": "03 Mar 2021", "scc": "inactive", "mbslink": "" },
    { "activity": "Not Started", 'order': "I", "area": "General Requirements", "key": "insa-generalrequirements", "nscStatus": "active", "mbsStatus": "Acceptable", "shipStatus": "inactive", "date": "03 Mar 2021", "scc": "inactive", "mbslink": "" },
    { "activity": "Not Started", 'order': "II", "area": "Structure", "key": "insa-structure", "nscStatus": "active", "mbsStatus": "Acceptable", "shipStatus": "inactive", "date": "03 Mar 2021", "scc": "inactive", "mbslink": "" },
    { "activity": "Completed", 'order': "III", "area": "Buoyancy", "key": "insa-buoyancy", "nscStatus": "active", "mbsStatus": "Acceptable with review", "shipStatus": "inactive", "date": "03 Mar 2021", "scc": "active", "mbslink": "" },
    { "activity": "Not Started", 'order': "IV", "area": "Engineering", "key": "insa-engineering", "nscStatus": "active", "mbsStatus": "Undesirable", "shipStatus": "inactive", "date": "03 Mar 2021", "scc": "inactive", "mbslink": "" },
    { "activity": "Not Started", 'order': "V", "area": "Seamanship", "key": "insa-seamanship", "nscStatus": "active", "mbsStatus": "Intolerable", "shipStatus": "inactive", "date": "03 Mar 2021", "scc": "inactive", "mbslink": "" },
    { "activity": "Significant Issues successful delivery at high risk", 'order': "VI", "area": "Fire Safety", "key": "insa-firesafety", "nscStatus": "active", "mbsStatus": "Undesirable", "shipStatus": "inactive", "date": "03 Mar 2021", "scc": "active", "mbslink": "../../assets/doc/fire_safety_MBS_systems.xlsx#MBS" },
    { "activity": "Major Issues preventing completion", 'order': "VII", "area": "EER", "key": "insa-eer", "nscStatus": "active", "mbsStatus": "Intolerable", "shipStatus": "inactive", "date": "03 Mar 2021", "scc": "active", "mbslink": "" },
    { "activity": "Not Started", 'order': "VIII", "area": "Comms", "key": "insa-comms", "nscStatus": "active", "mbsStatus": "Undesirable", "shipStatus": "inactive", "date": "03 Mar 2021", "scc": "inactive", "mbslink": "" },
    { "activity": "Not Started", 'order': "IX", "area": "Navigation", "key": "insa-navigation", "nscStatus": "active", "mbsStatus": "Intolerable", "shipStatus": "inactive", "date": "03 Mar 2021", "scc": "inactive", "mbslink": "" },
    { "activity": "Not Started", 'order': "X", "area": "Dangerous Goods", "key": "insa-dangerousgoods", "nscStatus": "active", "mbsStatus": "Acceptable", "shipStatus": "inactive", "date": "03 Mar 2021", "scc": "inactive", "mbslink": "" },
    { "activity": "Not Started", 'order': "XI", "area": "Aviation", "key": "insa-aviation", "nscStatus": "active", "mbsStatus": "Acceptable", "shipStatus": "inactive", "date": "03 Mar 2021", "scc": "inactive", "mbslink": "" }
  ];

  constructor(private CommunicationServ: CommunicationService) {
    this.updateMaterialActiveComponent();
    this.updateMaterialActiveList();
  }

  /* * * * *
  * method for communicate event instance with data to access all components
  * * * * * */
  broadcastInfo(data: any) {
    this.CommunicationServ.getInstance().next(data);
  }

  /* * * *
  * init dataux
  * * * */
  init(_datauxview) {
    this.datauxview = _datauxview;
    this.dfx = _datauxview.getDatascape();
  }
  
  /* * * *
  * update hazard area for NSSC data
  * * * */
  updateNSSCHazardData(){
    this.service['hazardList'] = this.NSSC_INSC_hazardList;
  }

  /* * *
  * update system comps
  * * */
  updateComps(_comps) {
    this.comps[0]["compList"].forEach(item => {
      if (item['key'] === this.selectedKha.split(" ").join("").toLowerCase()) {
        item['systemList'].forEach(sys => {
          if (sys['model']) {
            sys['model'].forEach(comp => {
              let found = _comps.some(el => comp.endsWith(el))
              if (found) {
                sys['status'] = 'active';
              } else {
                sys['status'] = 'inactive';
              }
            });
          }
        })
      }
    })
    // console.log(this.comps[0]["compList"]);
  }

  updateSysComps(_comps, boo) {
    let showAll;
    this.showSysComp[this.selectedKha] = [];
    this.comps[0]["compList"].forEach(item => {
      if (item['key'] === this.selectedKha.split(" ").join("").toLowerCase()) {
        item['systemList'].forEach(sys => {
          if (sys['model']) {
            sys['model'].forEach(comp => {
              console.log(_comps);
              let found = _comps.some(el => comp.endsWith(el))
              if (found) {
                sys['status'] = boo ? "active" : 'inactive';
              }
              if (sys['status'] === 'active') {
                this.showSysComp[this.selectedKha].push(comp);
              }
            });
          }
        })
        showAll = item['systemList'].some(sys => sys['status'] == 'inactive');
      }
    });
    let _boo = showAll ? false : true;
    this.broadcastInfo({ src: 'ctrlServ', event: 'update-show-all', data: _boo, key: "" });
    // console.log(this.comps[0]["compList"], this.showSysComp);
  }

  /* * *
   * update material kha component based on json
   * * */
  updateMaterialActiveComponent() {
    this.comps.forEach(comp => {
      if (comp['component'].toLowerCase() === 'nsc kha') {
        comp['compList'].forEach(item => {
          let name = item['system'].split(" ").join("").toLowerCase();
          if (this.khaSystems[name]) {
            item['status'] = 'active';
          }
        })
      }
    });
  }

  /* * *
   * update material kha list based on json
   * * */
  updateMaterialActiveList() {
    this.service['hazardList'].forEach(item => {
      let name = item['area'].split(" ").join("").toLowerCase();
      if (this.khaSystems[name]) {
        item['shipStatus'] = 'active';
      }
    });
  }

  /* * * *
  * show/hide the object
  * * * */
  show(element, flag) {
    if (element) {
      var showObj = (flag ? 'regular' : 'invisible');
      var _obj = { "profile": showObj };
      this.dfx.modify(element, _obj);
    }
  }

  /* * * *
  * move the object position
  * * * */
  move(element, posObj) {
    if (element) {
      let props = this.dfx.props(element);
      var data = { "time": "1", "op": "moveTo", "name": props.id, "duration": 1.720, "place": { "pos": posObj } }
      this.datauxview.modifyElement(data);
    }
  }

  /* * * *
  * rotate the object
  * * * */
  rotate(element, ang) {
    if (element) {
      let props = this.dfx.props(element);
      var data = { "time": "1", "op": "moveTo", "name": props.id, "duration": 1.720, "place": { "rot": ang } }
      this.datauxview.modifyElement(data);
    }
  }

  /* * * *
* check if object is visible or not
* * * */
  isObjVisible(c) {
    let comp = this.datauxview.getElementId(c);
    if (comp) {
      let props = this.dfx.props(comp);
      let vis = props.profile.visible;
      return vis;
    } else {
      return null;
    }
  }

  /* * * * *
  * show particluar element
  * * * * * */
  showElement(name) {
    let element = this.datauxview.getElementId(name);
    if (element) {
      var _obj = { "profile": 'regular' };
      this.dfx.modify(element, _obj);
    }
  }

  /* * * * *
  * hide particluar element
  * * * * * */
  hideElement(name) {
    let show = this.isObjVisible(name);
    if (show) {
      let element = this.datauxview.getElementId(name);
      var _obj = { "profile": 'invisible' };
      this.dfx.modify(element, _obj);
    }
  }

  /* * * * *
  * transparent particluar element
  * * * * * */
  transparentElement(name) {
    let show = this.isObjVisible(name);
    if (show) {
      let element = this.datauxview.getElementId(name);
      var _obj = { "profile": 'transparent' };
      this.dfx.modify(element, _obj);
    }
  }

}
