import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { CommunicationService } from '../services/communication.service';
import { ConfigService } from '../services/config.service';
import { ControllerService } from "../services/controller.service";

@Component({
  selector: 'app-course-panel',
  templateUrl: './course-panel.component.html',
  styleUrls: ['./course-panel.component.scss']
})

export class CoursePanelComponent implements OnInit {
  @Input() selectedKha: any = '';
  broadcastSubs: any;
  coursePanelOpen: boolean = true;
  courseHandleToggleOpen: boolean = false;
  controlPosition: any = 'Right';
  courseMenuList: any = [];
  showHeadingName: any;
  networkData: any = [];
  courseMenuChildList: any = [];
  activeIndex: any = [];
  selectedNode: any;

  constructor(private cdr: ChangeDetectorRef, private ctrlServ: ControllerService, private communicationServ: CommunicationService, private configService: ConfigService,) { }

  /* * * * *
  * method for communicate event instance with data to access all components
  * * * * * */
  broadcastInfo(data: any) {
    this.communicationServ.getInstance().next(data);
  }

  ngOnInit(): void {
    this.selectedKha = this.ctrlServ.selectedKha;
    this.courseMenuList = this.ctrlServ.service.hazardList || [];
    this.setHeadingMenu();

    this.broadcastSubs = this.communicationServ.getInstance()
      .subscribe((data: any) => {
        if (data.src === "networkmodel") {
          if (data.event == 'updatenetworkdata') {
            let networkData = JSON.parse(JSON.stringify(data['data']));
            this.networkData = networkData['vertices'] || [];
            this.getCourseChildList();
          }
          if (data.event === 'unselectednode') {
            let key = data['data'];
            if (data['key']) {
              this.selectedNode = key;
            } else {
              delete this.selectedNode;
            }
          }
          if (data.event === 'updateselectednode') {
            let key = data['data'];
            if (key) {
              if (this.selectedNode == key) {
                delete this.selectedNode;
              } else {
                this.selectedNode = key;
              }
            }
          }
        }
      })
  }

  /**
   * method for set course header
   */
  setHeadingMenu() {
    let heading = this.courseMenuList.find(menu => menu.key == this.selectedKha);
    if (heading) {
      this.showHeadingName = heading.area;
    }
  }

  /**
   * method for change kha list
   */
  onChangekhaList(pageData, i) {
    let selectedMenu = pageData.key;
    this.selectedKha = selectedMenu;
    this.ctrlServ.selectedKha = this.selectedKha;
    delete this.selectedNode;
    this.setHeadingMenu();
    this.broadcastInfo({ src: 'sidepanel', event: 'changensccomp', data: pageData, key: '' });
  }

  /**
   * method for toggle course panel
   */
  courseToggleOpenHanddle() {
    this.courseHandleToggleOpen = !this.courseHandleToggleOpen;
  }

  /**
  * method for panel card width
  */
  getCardwidth(e) {
    let rawElem = document.getElementById(e);
    let width = 0;
    if (rawElem != null) {
      width = Math.round(rawElem.offsetWidth / 1.5);
    }   
    return width + 'px';
  } 

  /**
  * method for course child list
  */
  getCourseChildList() {
    this.courseMenuChildList = [];
    this.courseMenuChildList = this.networkData.filter(node => node.level === 2);

    this.courseMenuChildList.forEach(parentNode => {
      parentNode.childList = [];
      const childNode = this.networkData.find(el => el.parentID === parentNode.orderID);
      if (childNode) {
        const found = parentNode.childList.some(ele => ele.id === childNode.id);
        if (!found) {
          parentNode.childList.push(childNode);
        }
      }

      this.networkData.forEach(childList => {
        if (parentNode.childList.some(el => el.orderID == childList.parentID)) {          
          const found = parentNode.childList.some(ele => ele.id === childList.id);
          if (!found) {
            parentNode.childList.push(childList);
          }
        }
      });     
    });   
  }

  /**
  * method for select node
  */
  onSelectNode(node) {
    if (this.selectedNode == node.id) {
      delete this.selectedNode;
      this.communicationServ.nodeUnSelect = true;
      this.broadcastInfo({ src: 'sidepanel', event: 'selectedindex', id: node['id'], key: false });
    } else {
      this.selectedNode = node.id;
      this.communicationServ.nodeUnSelect = false;
      this.broadcastInfo({ src: 'sidepanel', event: 'selectedindex', id: node['id'], key: true });
    }
  }

  /**
  * method for hide solutions card
  */
  hideSolutions(level) {
    let levelTrees = ['insa-structure', 'insa-buoyancy'];
    if (levelTrees.includes(this.selectedKha)) {    
      return level == 7 ? false : true;
    } else {
      return level == 6 ? false : true;
    }   
  }

}
