import { Component, OnInit, AfterViewChecked, ChangeDetectorRef, Input, Output, EventEmitter, HostListener, AfterViewInit, ViewChildren, QueryList, Inject } from '@angular/core';
import { ColorPickerService, Cmyk } from 'ngx-color-picker';
import { CommunicationService } from "../services/communication.service";
// import * as  shipData from "../../assets/appData/shipObjects.json";
// import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { ControllerService } from '../services/controller.service';
import { ConfigService } from '../services/config.service';
import { MatTabGroup } from '@angular/material';
import { LanguageService } from '../services/language.service';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { KHASystemService } from "../services/systems/kha-systems.service";
import { MatIconRegistry } from "@angular/material/icon";

@Component({
  selector: 'app-sidepanel',
  templateUrl: './sidepanel.component.html',
  styleUrls: ['./sidepanel.component.scss']
})
export class SidepanelComponent implements OnInit, AfterViewInit {
  @ViewChildren("allTabs") allTabs: MatTabGroup;
  @Input() shipMetaData: any = [];
  @Input() oceanSurfaceState: any = false;
  @Input() shipDragState: any = true;
  @Input() selectedKha: any = '';
  @Input() set shipPanelToggle(v: any) {
    if (v) {
      this.shipHandleToggleOpen = v;
      this.drawerHandleToggleOpen = v;
    }
  }
  @Input() set languageChange(v: any) {
    if (v) {
      console.log(v)
      var lang = v == 'fr' ? 'English' : 'French';
      this.onChangeLanguage(lang)
    }
  }
  @Input() selectedCompartment: any = "";
  @Input() show_ern: boolean = false;
  @Input() ernDisplay: boolean = false;
  @Input() aposDisplay: boolean = false;
  @Input() nsscRightPanelOpen: boolean = false;
  slms_mode: boolean = false;
  public selectedIndex: any = { value: 0 };
  hideUnselectedState: boolean = false;
  hideSubBranchState: boolean = false;
  showPathtoggleState: boolean = false;
  pyramidPopup: any = false;
  selectedClass: any = -1;
  filterComp: any = "fleet"
  item: any;
  infoPanel: any;
  selectedErn: any = 'ern-damagecontrol';
  selectedAops: any = 'aops-damagecontrol';
  ERN_list: any = [
    // { name: "ERN Tree", key: 'ern', status: 'active' },
    { name: "Damage Control Systems", key: 'ern-damagecontrol', status: 'active' },
    { name: "Propulsion System", key: 'ern-populasion', status: 'active' },
    { name: "Electrical Power", key: 'ern-electricalpower', status: 'active' },
    { name: "Marine", key: 'ern-marine', status: 'active' },
    { name: "Deck & Hull Equipment", key: 'ern-deckhull', status: 'active' },
    { name: "Hull Systems", key: 'ern-hullsystems', status: 'active' },
    { name: "HVAC", key: 'ern-hvac', status: 'active' }
  ]
  aops_list: any = [
    { name: "Damage Control Systems", key: 'aops-damagecontrol', status: 'active' },
    { name: "Propulsion System", key: 'aops-populasion', status: 'active' },
    { name: "Electrical Power", key: 'aops-electricalpower', status: 'active' },
    { name: "Marine", key: 'aops-marine', status: 'active' },
    { name: "Deck & Hull Equipment", key: 'aops-deckhull', status: 'active' },
    { name: "Hull Systems", key: 'aops-hullsystems', status: 'active' },
    { name: "HVAC", key: 'aops-hvac', status: 'active' },
    { name: "2ND Electrical", key: 'aops-2electrical', status: 'active' },
    { name: "Aircraft Support", key: 'aops-aircraftsupport', status: 'active' },
    { name: "NAVIS", key: 'aops-navis', status: 'active' },
    { name: "IMPS", key: 'aops-imps', status: 'active' },
    { name: "Workshop", key: 'aops-workshop', status: 'active' },
    { name: "Communication", key: 'aops-communication', status: 'active' },
    { name: "Navysys", key: 'aops-navysys', status: 'active' },
    { name: "C&C", key: 'aops-c&c', status: 'active' },
    { name: "Weapons", key: 'aops-weapons', status: 'active' },
    { name: "Domestic", key: 'aops-domestic', status: 'active' }
  ]
  @Input() set showPathState(v: any) {
    this.showPathtoggleState = v;
    // this.showPath()
    this.broadcastInfo({ src: 'sidepanel', event: 'nscShowPath', data: this.showPathtoggleState, key: this.showPathtoggleState });
  }
  handleToggleOpen: boolean = false;
  controlPosition: any = 'Right';
  selected_demo: string = "";
  handleDToggleOpen: boolean = false;
  global_pause = false;
  broadcastSubs: any;
  shipHandleToggleOpen: boolean = false;
  shipControlPosition: any = 'Right';
  search: any = "";
  nscSearch: any = "";
  shipshowPanelOpen: any = false;
  drawershowPanelOpen: any = false;
  drawerHandleToggleOpen: boolean = false;
  insHandleToggleOpen: boolean = false;
  insshowPanelOpen: any = false;
  toppingList: any = ['Status', 'CFB', 'Ship Number'];
  shipClassList: any = [];
  shipActiveList: any = [];
  showDControlPanelOpen: any = true;
  reflectionsState: boolean = false;
  nscInfoShowPanelOpen: boolean = false;
  handleshipCompToggleOpen: boolean = false;
  showShipDetailPanelOpen: boolean = true;
  parentCatListIdx: any;
  hideShipClassList: any = [];
  selectedShipid: any;
  formData = new FormData();
  selectedFile: any = 'No file chosen...';
  filterKey: any = ['ship', 'number', 'class'];
  process_txt = "Processing...";
  showBlockLoading: boolean = false;
  NSCTab: any;
  // ernMode = false;
  /***
   * Color picker start
   */
  // settings
  handleToolsToggleOpen: any = false;
  showToolsPanelOpen: any = true;
  controlDPosition: any = 'Left';
  matrialShip: any;
  public toggle: boolean = false;
  public rgbaText: string = 'rgba(165, 26, 214, 0.2)';
  public colorList = [
    { key: "flame", value: "#e45a33", friendlyName: "Flame" },
    { key: "orange", value: "#fa761e", friendlyName: "Orange" },
    { key: "infrared", value: "#ef486e", friendlyName: "Infrared" },
    { key: "male", value: "#4488ff", friendlyName: "Male Color" },
    { key: "female", value: "#ff44aa", friendlyName: "Female Color" },
    { key: "paleyellow", value: "#ffd165", friendlyName: "Pale Yellow" },
    { key: "gargoylegas", value: "#fde84e", friendlyName: "Gargoyle Gas" },
    { key: "androidgreen", value: "#9ac53e", friendlyName: "Android Green" },
    { key: "carribeangreen", value: "#05d59e", friendlyName: "Carribean Green" },
    { key: "bluejeans", value: "#5bbfea", friendlyName: "Blue Jeans" },
    { key: "cyancornflower", value: "#1089b1", friendlyName: "Cyan Cornflower" },
    { key: "warmblack", value: "#06394a", friendlyName: "Warm Black" },
  ];
  public presetValues: string[] = [];
  public selectedColor: string = 'color1';
  public cmykColor: Cmyk = new Cmyk(0, 0, 0, 0);
  disableTransCtrl: boolean = false;

  getColorValues() {
    return this.colorList.map(c => c.value);
  }

  /***
   * Color picker end
   */
  networkDataDefault: any;
  showPanelOpen: any = true;
  showDPanelOpen: any;
  showControlPanelOpen: any = false;
  shipDetailPanelOpen: any = false;
  shipCompPanelOpen:any = false;
  shipDetailHandleToggleOpen: any = false;
  shipCompHandleToggleOpen:any=false;
  nscShowPanelOpen: any = false;
  ernShowPanelOpen: any = false;
  ernHandleToggleOpen: any = false;
  aopsErnShowPanelOpen: any = false;
  aopsErnHandleToggleOpen: any = false;
  handleControlToggleOpen: any = false;
  handleDControlToggleOpen: any = false;
  nscHandleToggleOpen: any = false;
  nsscRightHandleToggleOpen: any = false;
  showPathData: any;
  panelBG = "#333333";
  cardBG = "#4a4a4a";
  shadowActive: boolean = false;
  activeComp: any = "";
  networkData: any = [];
  activeIndex: any = [];
  @Input() set controlPanelState(v: any) {
    this.handleDControlToggleOpen = v;
  }
  docked = false;
  @Input() set showDPanel(v: any) {
    this.handleDToggleOpen = v;
    this.showDPanelOpen = v;
    // this.showDControlPanelOpen = v;
    // this.handleDControlToggleOpen = v;
  };
  @Input() set showSSPanel(v: any) {
    if (!this.handleDControlToggleOpen) {
      // this.handleToggleOpen = v;
    }
    this.showPanelOpen = v;
    this.showControlPanelOpen = v;
    this.handleControlToggleOpen = false;
  };
  @Input() set resetShipCompsCntls(v: any) {
    if (v) {
      this.groupCheckArray = this.resetgroupCheckArray;
    }
  }
  multiTagStatus: any = false;
  @Input() set multipleTagStatus(v: any) {
    this.multiTagStatus = v
  }
  groupCheckArray: any;
  resetgroupCheckArray: any;
  @Input() set _disableTrans(v: any) {
    this.disableTransCtrl = v;
  }
  _view = 'solid';
  viewmode = { 'solid': false, 'texture': true, "wireframe": false }
  @Input() set view(v: any) {
    if (v !== this._view) {
      this._view = v;
    }
  }
  @Input() set loadMaterial(v: any) {
    if (v) {
      if (v.key == '') {
        if (v['mode']) {
          this.loadComponent('', 'nsc', v['mode']);
        } else {
          this.loadComponent('', 'nsc');
        }
      } else {
        this.loadComponent(v.key, 'ship')
      }
    }
  }
  shipData: any = '';
  classOrderList = ['Victoria', 'Halifax', 'Dewolf', 'Kingston', 'Orca', 'Asterix', 'CSC', 'Protecteur']
  statusList: any = [];
  mbsStatusList: any = [];
  comps: any = [];
  shipComp:any;
  service: any = {};

  @Input() handleShipDetailToggleOpen: boolean = false;
  @Input() set shipDetailPanelData(v: any) {
    this.shipData = '';
    if (v != '') {
      this.shipData = v;
      this.shipData = Object.assign(this.service, this.shipData);
      this.shipData = this.updateShipHighStatus(this.shipData);
      if (this.activeComp == 'nsc') {
        this.handleShipDetailToggleOpen = false;
        this.shipHandleToggleOpen = false;
        this.drawerHandleToggleOpen = false;
        this.insHandleToggleOpen = false;
      } else if (this.activeComp == 'ship') {
        this.handleShipDetailToggleOpen = false;
        this.shipHandleToggleOpen = false;
        this.drawerHandleToggleOpen = false;
        this.insHandleToggleOpen = false;
      } else {
        this.handleShipDetailToggleOpen = true;
      }
    } else {
      this.shipData = this.service;
      // this.shipData = this.updateShipHighStatus(this.service);
      // var shipData = this.updateShipHighStatus(this.service);
      this.parentCatListIdx = null;
      this.handleShipDetailToggleOpen = false;
    }
  }
  @Input() set activeComponent(v: any) {
    this.activeComp = v;
    this.shipDetailPanelOpen = false;
    this.shipCompPanelOpen=false;
    this.shipDetailHandleToggleOpen = false;
    this.nscShowPanelOpen = false;
    this.ernShowPanelOpen = false;
    this.aopsErnShowPanelOpen = false;
    this.nscHandleToggleOpen = false;
    this.shipshowPanelOpen = false;
    this.drawershowPanelOpen = false;
    this.insshowPanelOpen = false;
    this.shipHandleToggleOpen = false;
    this.drawerHandleToggleOpen = false;
    this.insHandleToggleOpen = false;
    // this.showDControlPanelOpen = false;
    this.handleDControlToggleOpen = false
    this.showShipDetailPanelOpen = false;
    this.nscInfoShowPanelOpen = false;
    if (v != '') {
      if (this.activeComp == 'nsc') {
        this.nscShowPanelOpen = true;
        this.nscHandleToggleOpen = true;
        this.shipshowPanelOpen = true;
        this.drawershowPanelOpen = true;
        this.insshowPanelOpen = true;
        this.ernShowPanelOpen = false;
        this.showShipDetailPanelOpen = true;
        this.handleShipDetailToggleOpen = false;
        this.shipData = this.service;
        console.log("qwer--", this.shipData.hazardList);
        if (!this.selectedKha) {
          this.nscShowPanelOpen = true;
          if (!this.ernDisplay) {
            // this.nscHandleToggleOpen = true;
          }
        }
        if (this.ernDisplay) {
          this.nscShowPanelOpen = false;
          this.nscHandleToggleOpen = false;
          this.ernShowPanelOpen = true;
          this.ernHandleToggleOpen = true;
        }
        if(this.aposDisplay){
          this.nscShowPanelOpen = false;
          this.nscHandleToggleOpen = false;
          this.aopsErnShowPanelOpen = true;
          this.aopsErnHandleToggleOpen = true;
        }
      } else if (this.activeComp == 'ship') {
        cancelAnimationFrame(animID);
        let animation = (timestamp) => {
          if (this.ctrlServe.isMaterialAppLoaded) {
            cancelAnimationFrame(animID);
            // this.shipCompPanelOpen=true;
            this.shipshowPanelOpen = true;
            this.drawershowPanelOpen = true;
            this.insshowPanelOpen = true;
            this.showShipDetailPanelOpen = true;
            this.shipDetailPanelOpen = true;
            this.shipData = this.service;
            this.handleShipDetailToggleOpen = false;
            this.shipDetailHandleToggleOpen = true;
          } else {
            animID = window.requestAnimationFrame(animation);
          }
        }
        var animID = window.requestAnimationFrame(animation);
      } else {
        this.shipHandleToggleOpen = false;
        this.drawerHandleToggleOpen = false;
        this.insHandleToggleOpen = false;
      }
      if (this.activeComp == 'fleet') {
        this.shipshowPanelOpen = true;
        this.drawershowPanelOpen = true;
        this.insshowPanelOpen = true;
        this.shipHandleToggleOpen = false;
        this.drawerHandleToggleOpen = false;
        this.insHandleToggleOpen = false;
        this.showShipDetailPanelOpen = true;
        if (this.shipData['ship']) {
          this.handleShipDetailToggleOpen = true;
        }
      }
    } else {
      this.shipshowPanelOpen = false;
      this.drawershowPanelOpen = false;
      this.insshowPanelOpen = false;
      // this.shipHandleToggleOpen = false;
      // this.showDControlPanelOpen = true;
      this.showShipDetailPanelOpen = true;
    }
  }
  // @Input() animStatus;
  @Input() shipConfig: any = {};
  @Input() shipOrderKey: any = 'default';
  matKhaTabIndex: any = 0;
  @Input() set compTabState(v: any) {
    if (v) {
      this.setComponentPanel();
      setTimeout(() => {
        this.shipCompHandleToggleOpen = true;
        this.shipCompLoad();
      }, 3000);
    } else {
      this.onKHATabChanged({ 'index': 0 })
    }
  }
  // @Output() controlChange: EventEmitter<any> = new EventEmitter<any>();
  // @Output() cameraTargetClick: EventEmitter<any> = new EventEmitter<any>();
  // @Output() showAllCompCtrl: EventEmitter<any> = new EventEmitter<any>();
  @Output() shipDetailTag: EventEmitter<any> = new EventEmitter<any>();
  @Output() oceanSurfaceToggle: EventEmitter<any> = new EventEmitter<any>();
  @Output() reflectionsToggle: EventEmitter<any> = new EventEmitter<any>();
  @Output() settingPanelState: EventEmitter<any> = new EventEmitter<any>();
  @Output() shipDetailPanel: EventEmitter<any> = new EventEmitter<any>();
  @Output() loadComp = new EventEmitter<object>();
  @Output() hideShip = new EventEmitter<object>();
  @Output() hideClassAllShip = new EventEmitter<object>();
  @Output() detailPanelClose = new EventEmitter<object>();
  @Output() allowDragShip = new EventEmitter<object>();
  @Output() showFilterships: EventEmitter<any> = new EventEmitter<any>();
  @Output() changeShipOrder: EventEmitter<any> = new EventEmitter<any>();
  @Output() openDialog: EventEmitter<any> = new EventEmitter<any>();
  @Output() ChangeCoast: EventEmitter<any> = new EventEmitter<any>();
  languageSelected: any = 'English';
  activeComps: any = "";
  cancelSystemLoad = false;

  constructor(public khaService: KHASystemService, protected html_sanitizer: DomSanitizer, private configService: ConfigService, private translate: TranslateService, private language: LanguageService, private cdr: ChangeDetectorRef, private ctrlServe: ControllerService, private cpService: ColorPickerService, private CommunicationServ: CommunicationService, private dialog: MatDialog, private matIconRegistry: MatIconRegistry) {
    this.statusList = this.ctrlServe.statusList;
    this.mbsStatusList = this.ctrlServe.mbsStatusList;
    this.comps = this.ctrlServe.comps;
    this.shipComp = this.ctrlServe.shipComp;
    this.service = this.ctrlServe.service;
    this.presetValues = this.getColorValues();
    this.translate.addLangs(['en', 'fr']);
    this.translate.setDefaultLang('en');
    this.translate.use('en');
    this.sortCompartment('name');
    this.multiKhaOption();
    matIconRegistry.addSvgIcon(
      '2d-icon',
      html_sanitizer.bypassSecurityTrustResourceUrl("../assets/images/2d-icon.svg")
    );
    matIconRegistry.addSvgIcon(
      '3d-icon',
      html_sanitizer.bypassSecurityTrustResourceUrl("../assets/images/3d-icon.svg")
    );
    // matIconRegistry.addSvgIcon(
    //   'roof-icon',
    //   html_sanitizer.bypassSecurityTrustResourceUrl("../assets/images/roof.svg")
    // );
    // matIconRegistry.addSvgIcon(
    //   'roof-slash-icon',
    //   html_sanitizer.bypassSecurityTrustResourceUrl("../assets/images/roof-slash.svg")
    // );
    this.broadcastSubs = this.CommunicationServ.getInstance()
      .subscribe((data: any) => {
        if (data.src === "landing") {
          if (data.event === 'activeComp') {
            console.log("data id : ", data.data)
            this.activeComps = data.data;
          }
        }
        if (data.src == 'main') {
          if (data.event == 'selectedKha') {
            this.onChangekhaList(data.key);
          }
          if (data.event == 'selectedKhaMateriel') {
            // this.onChangekhaComp(data.key);
            this.shipCompPanelOpen=false;
            this.onKHATabChanged({ 'index': 1 });
          }
          if (data.event == 'selectedErn') {
            this.onChangeErnList(data.key);
          }
          if (data.event == 'selectedCompartment') {
            this.loadCompartment(data.key)
          }
        }
        if (data.src === "material") {
          if (data.event === 'onChangeKHASystem') {
            if (data['data']) {
              this.onChangeKHASystem(data['data'], data['comp']);
            }
          }
          if(data.event=='material_init_load'){
            console.log(data);
            if(!data.state){
              // setTimeout(() => {
                this.sortCompartment('name');
              // }, 1500);

            }
          }
        }
        if (data.src === "khaservice") {
          if (data.event == 'showMatObject') {
            // this.showMatObjTag(data.sub,data.state);
          }
          if (data.event == 'showhull') {
            this.showhull = data['data'];
          }
        }
        if (data.src === "networkmodel") {
          if (data.event == 'updatepathdata') {
            console.log("data path : ", data.data)
            // var data =  data.data;
            this.showPathData = data.data;
          }
        }
        if (data.src === "networkmodel") {
          if(data.event=='updatepathdata'){
            console.log("data path : ", data.data)
            // var data =  data.data;
            this.showPathData = data.data;
          }
        }
      })
  }

  setComponentPanel() {
    cancelAnimationFrame(animID);
    let anim = (timestamp) => {
      if (this.ctrlServe.isMaterialAppLoaded) {
        cancelAnimationFrame(animID);
        setTimeout(() => {
          this.shipDetailHandleToggleOpen = false;
          this.shipDetailPanelOpen = false;
          this.shipCompPanelOpen = true;
          let comp = this.shipComp["compList"].find(el=> el.code === this.selectedCompartment);
          if(comp){
            this.loadCompartment(comp);
          }
        });
      } else {
        animID = window.requestAnimationFrame(anim);
      }
    }
    var animID = window.requestAnimationFrame(anim);
  }

  multiSelectKha:any=[];
  khaList:any;
  multiKhaOption() {
    this.khaList = [];
    console.log(this.khaList);
    this.comps.forEach(element => {
      if (element.component == 'NSC KHA') {
        element.compList.forEach(ele => {
          if (this.selectedKha) {
            if (ele.status == 'active' && this.selectedKha != ele.key) {
              this.khaList.push(ele);
            }
          } else {
            if (ele.status == 'active') {
              this.khaList.push(ele);
            }
          }
        });
      }
    });
  }

  onKhaSelect() {
    let khaList = [];
    this.khaList.forEach(sys => {
      const found = this.multiSelectKha.some(el => el === sys.key);
      let item = { kha: sys.key, status: found };
      khaList.push(item);
    });
    // console.log("onKhaSelect ", this.multiSelectKha);
    this.broadcastInfo({ src: 'sidepanel', event: 'show-multiple-systems', data: khaList, key: "" });
  }

  sortComp:any;
  sortCompartment(e) {
    this.sortComp = e;
    // this.comps.forEach((element,i) => {
    //   if(element.component=='Compartments'){
    if (this.shipComp.compList.length > 0) {
      this.shipComp.compList.sort((a, b) => a[e] < b[e] ? -1 : a[e] > b[e] ? 1 : 0);
    }
    //   }
    // });
  }
  documentDialog(item) {
    this.dialog.open(DocumentDialogModel, { data: item });
    // console.log("Item: ",item);
  }
  onChangeLanguage(langName) {
    // let langKey;
    if (langName == 'English') {
      this.language.changeLanguage('fr');
      this.translate.use('fr');
      this.languageSelected = 'French';
      // this.nxtLanguage = 'French';
    }
    if (langName == 'French') {
      this.language.changeLanguage('en');
      this.translate.use('en');
      // this.nxtLanguage = 'English';
      this.languageSelected = 'English';
    }
  }
  /* * *
  * init ship data
  * * * */
  initShipData() {
    this.arrangeShipData(this.shipMetaData);
    // this.arrangeClass(this.shipMetaData);
    this.mappingCategory();
  }

  ngAfterViewInit() {
    this.initShipData();
    this.cdr.detectChanges();
    this.allTabs.selectedIndex = 0;
    this.compSort();
  }

  tabChange(tabChangeEvent: number) {
    console.log('tab selected: ' + tabChangeEvent);
    this.selectedIndex['value'] = tabChangeEvent;
  }

  /******
   * Ship data color status based arranged
   */
  arrangeShipData(e) {
    var arrArg = []
    this.statusList.forEach(state => {
      e.forEach(element => {
        if (element['_state'] == state.status) {
          arrArg.push(element);
        }
      });
    });
    this.shipUpdatedData = arrArg;
    this.shipActiveList = arrArg;
  }

  /* * * * *
  * Class mapping ship meta data
  * * * * * */
  mappingCategory() {
    this.shipClassList = [];
    if (this.shipUpdatedData.length > 0) {
      this.classOrderList.forEach(ele => {
        this.shipUpdatedData.forEach(element => {
          if (this.shipClassList.indexOf(element.class) == -1) {
            if (ele == element.class) {
              this.shipClassList.push(element.class);
            }
          }
        });
      })
    }
  }
  //shipe panel start
  shipToggleOpenHanddle() {
    this.shipHandleToggleOpen = !this.shipHandleToggleOpen;
  }
  drawerToggleOpenHanddle() {
    this.drawerHandleToggleOpen = !this.drawerHandleToggleOpen;

  }
  insToggleOpenHanddle(){
    this.insHandleToggleOpen = !this.insHandleToggleOpen;
  }
  nscToggleOpenHanddle() {
    this.nscHandleToggleOpen = !this.nscHandleToggleOpen;
    if (this.ernDisplay) {
      this.broadcastInfo({ src: 'sidepanel', event: 'toggle_network_tree', data: !true, key: "" });
    }
  }
  nsscRightToggleOpenHanddle() {
    this.nsscRightHandleToggleOpen = !this.nsscRightHandleToggleOpen;    
  }
  ernToggleOpenHanddle(data = null) {
    this.ernHandleToggleOpen = data ? true : !this.ernHandleToggleOpen;
    if (!this.ernDisplay) {
      this.broadcastInfo({ src: 'sidepanel', event: 'toggle_network_tree', data: true, key: "" });
    }
  }
  aopsErnToggleOpenHanddle(data = null) {
    this.aopsErnHandleToggleOpen = data ? true : !this.aopsErnHandleToggleOpen;
    // if (!this.ernDisplay) {
    //   this.broadcastInfo({ src: 'sidepanel', event: 'toggle_network_tree', data: true, key: "" });
    // }
  }
  onChangeErnList(key) {
    this.selectedErn = key;
    this.ctrlServe.selectedErn = this.selectedErn;
    if (!this.ernDisplay) {
      this.broadcastInfo({ src: 'sidepanel', event: 'toggle_network_tree', data: true, key: "" });
    }
    this.broadcastInfo({ src: 'sidepanel', event: 'load_ern_data', data: true, key: this.selectedErn });
  }
  onChangeAOPSList(key) {
    this.selectedAops = key;
    this.ctrlServe.selectedAops = this.selectedAops;
    // if (!this.aposDisplay) {
    //   this.broadcastInfo({ src: 'sidepanel', event: 'toggle_aops_tree', data: true, key: "" });
    // }
    this.broadcastInfo({ src: 'sidepanel', event: 'load_aops_data', data: true, key: this.selectedAops });
  }

  shipDate: any = 1;
  formatLabel(value: number) {
    if (value <= 10) {
      return Math.round(value) + '';
    }
    this.shipDate = value;
    return value;
  }
  shipUpdatedData: any = [];
  searchValue(e) {
    var filterData = [];
    if (this.search == '') {
      filterData = this.shipMetaData;
      this.arrangeShipData(this.shipMetaData);

      this.arrangeShipData(filterData);
      this.mappingCategory();
      this.hideShipClass();

    } else {
      if (this.shipMetaData.length > 0) {
        for (let i = 0; i < this.shipMetaData.length; i++) {
          this.filterKey.forEach((key) => {
            if (this.shipMetaData[i][key].toLowerCase().indexOf(this.search.toLowerCase()) > -1) {
              const found = filterData.some(ship => ship.id === this.shipMetaData[i]['id'])
              if (!found) {
                filterData.push(this.shipMetaData[i]);
              }
            }
          })
        }
      }
      this.shipUpdatedData = filterData;
      this.arrangeShipData(this.shipUpdatedData);
      this.mappingCategory();
    }
    this.broadcastInfo({ src: 'sidepanel', event: 'showfilteredships', data: filterData, key: "" });
    this.hideShipClass();
  }

  searchClear() {
    this.search = "";
    this.shipUpdatedData = this.shipMetaData;
    this.arrangeShipData(this.shipUpdatedData);
    this.mappingCategory();
    this.broadcastInfo({ src: 'sidepanel', event: 'showfilteredships', data: this.shipMetaData, key: "" });
    this.hideShipClass();
  }

  selectedShipIdx: any;
  // parentCatListIdx: any;
  activeShip(i, item) {
    // this.shipActiveList = [];
    const found = this.shipActiveList.some(el => el === item.name);
    if (!found) {
      this.shipActiveList.push(item.name);
    }
    return found;
    // this.selectedShipIdx = idx;
    // this.parentCatListIdx = i;
    // this.shipHandleToggleOpen = !this.shipHandleToggleOpen;
    // this.handleToggleOpen = !this.handleToggleOpen;
    // this.showPanelOpen = true;
    // this.shipDetailTag.emit(item)
  }

  /* * *
  * update ship status
  * * */
  updateShipStatus(ship) {
    let shipName = ship['ship'].toLowerCase().split(" ").join("").replace(".", "").replace("'", "");
    this.service['hazardList'].forEach((elem) => {
      let shipConfig = this.shipConfig[elem['order']];
      Object.keys(shipConfig).forEach((key) => {
        let keyName = key.toLowerCase().split(" ").join("").replace(".", "").replace("'", "");
        let value = shipConfig[key].toLowerCase().split(" ").join("").replace(".", "").replace("'", "");
        if (shipName === keyName) {
          let statusArr = this.statusList.filter(el => el.id === value);
          if (statusArr.length > 0) {
            elem['activity'] = statusArr[0]['status'];
          }
        }
      });
    });
  }
  updateShipHighStatus(ship) {
    console.log("updateShipHighStatus", ship);
    var state = '';
    ship['hazardList'].forEach((element, i) => {
      // if(!state){
      if (element['area'] == "Buoyancy" || element['area'] == "Fire Safety" || element['area'] == "EER") {
        if (element['activity'] == 'Not Started') {
          if (state == 'Critical issues preventing completion' || state == 'Major Issues preventing completion' || state == 'Significant Issues successful delivery at high risk' || state == 'On Track minor issues' || state == 'No Issues' || state == 'Completed' || state == '') {
            state = element['activity'];
          }
        }
        if (element['activity'] == 'Critical issues preventing completion') {
          if (state == 'Major Issues preventing completion' || state == 'Significant Issues successful delivery at high risk' || state == 'On Track minor issues' || state == 'No Issues' || state == 'Completed' || state == '') {
            state = element['activity'];
          }
        }
        if (element['activity'] == 'Major Issues preventing completion') {
          if (state == 'Significant Issues successful delivery at high risk' || state == 'On Track minor issues' || state == 'No Issues' || state == 'Completed' || state == '') {
            state = element['activity'];
          }
        }
        if (element['activity'] == 'Significant Issues successful delivery at high risk') {
          if (state == 'On Track minor issues' || state == 'No Issues' || state == 'Completed' || state == '') {
            state = element['activity'];
          }
        }
        if (element['activity'] == 'On Track minor issues') {
          if (state == 'No Issues' || state == 'Completed' || state == '') {
            state = element['activity'];
          }
        }
        if (element['activity'] == 'No Issues') {
          if (state == 'Completed' || state == '') {
            state = element['activity'];
          }
        }
        if (element['activity'] == 'Completed') {
          if (state == '') {
            state = element['activity'];
          }
        }
      }
      // }
    });
    ship['_state'] = state;
    return ship;
  }

  /* * *
  * selected ship
  * * */
  selectedShip(i, item) {
    if (this.parentCatListIdx === i) {
      this.parentCatListIdx = null;
      this.shipDetailPanel.emit({ checked: false, data: item });
    } else {
      if (item['id']) {
        let visible = this.ctrlServe.isObjVisible(item.id);
        if (visible) {
          this.parentCatListIdx = i;
          this.selectedShipid = item.id;
          this.updateShipStatus(item);
          if (this.activeComp === 'nsc') {
            // this.shipHandleToggleOpen = false;
            // this.nscHandleToggleOpen = false;
            this.shipDetailPanel.emit({ checked: true, data: item, comp: "nsc" });
          } else {
            this.shipDetailPanel.emit({ checked: true, data: item });
          }
        }
      } else {
        this.parentCatListIdx = i;
        this.selectedShipid = item['name'];
        this.updateShipStatus(item);
        if (this.activeComp === 'nsc') {
          this.shipDetailPanel.emit({ checked: true, data: item, comp: "nsc" });
        } else {
          this.shipDetailPanel.emit({ checked: true, data: item });
        }
      }
    }
  }

  /* * *
  * method for multiple active list maintain
  * * */
  getActiveStatus(shipClass) {
    let foundClass = this.hideShipClassList.some(el => el === shipClass);
    if (!foundClass) {
      return true;
      // const found = this.shipActiveList.some(el => el.name === item.name);
      // return found;
    } else {
      return false;
    }
  }

  /* * *
  * hide ship
  * * */
  onHideShip(e, ship) {
    if (ship.id === this.selectedShipid) {
      this.selectedShipid = null;
      this.parentCatListIdx = null;
      this.detailPanelClose.emit();
    }
    // this.hideShip.emit({ show: e.checked, ship: ship });
    let obj = { show: e.checked, ship: ship };
    this.broadcastInfo({ src: 'sidepanel', event: 'hideship', data: obj, key: "" });
  }
  hideShipClass() {
    if (this.hideShipClassList.length > 0) {
      this.shipUpdatedData.forEach((element, i) => {
        let idx = this.hideShipClassList.findIndex(e => e == element.class);
        if (idx > -1) {
          let id = this.shipClassList.findIndex(e => e == element.class);
          this.onHideAllShip({ checked: false }, element.class, id);

        }
      });
    }


  }
  /* * *
  * hide all ship
  * * */
  onHideAllShip(e, shipClass, i) {
    let checked = e.checked;
    let shipData = this.shipActiveList.filter(ship => ship.class === shipClass);
    if (checked) {
      let idx = this.hideShipClassList.findIndex(el => el === shipClass);
      if (idx > -1) {
        this.hideShipClassList.splice(idx, 1);
      }
    } else {
      let found = this.hideShipClassList.some(el => el === shipClass);
      if (!found) {
        this.hideShipClassList.push(shipClass);
      }
    }
    //if selected ship going to means unselect the ship
    let foundShip = shipData.some(el => el.id === this.selectedShipid);
    if (foundShip) {
      this.selectedShipid = null;
      this.parentCatListIdx = null;
      this.detailPanelClose.emit();
    }
    // this.hideClassAllShip.emit({ show: e.checked, ship: shipData });
    this.expandCurrentClass(null, i, shipClass);
    let obj = { show: e.checked, ship: shipData };
    this.broadcastInfo({ src: 'sidepanel', event: 'hideclassallship', data: obj, key: "" });
  }

  headerList = [];
  headerListCheck(item) {
    // console.log(item,this.headerList);
    if (this.headerList.length > 0) {
      // if(this.headerList.length==2){
      //   this.headerList=[];return;
      // }
      let idx = this.headerList.findIndex(e => e == item);
      if (idx > -1) {
        this.headerList.splice(idx, 1);
        // return false;
      } else {
        this.headerList.push(item);
        return true;
      }

    } else {
      this.headerList.push(item);
      return true;
    }
  }

  /* * * * *
  * method for on change order
  * * * * * */
  onChangeOrder(key) {
    this.changeShipOrder.emit(this.shipOrderKey);
  }
  onChangeCoast() {
    this.ChangeCoast.emit(this.filterComp);
  }

  /* * * * *
  * method for communicate event instance with data to access all components
  * * * * * */
  broadcastInfo(data: any) {
    this.CommunicationServ.getInstance().next(data);
  }
  //ship panel end
  ngOnInit() {
    this.selectedKha = this.ctrlServe.selectedKha;
    this.broadcastSubs = this.CommunicationServ.getInstance()
      .subscribe((data: any) => {
        if (data.src === "main") {
          if (data.event === 'showparts') {
            this.showSSPanel = true;
            this.shipToggleOpenHanddle()
          }
          if (data.event === 'showships') {
            this.shipToggleOpenHanddle()
          }
          if (data.event === 'showclickedship') {
            this.shipUpdatedData.forEach((ship, index) => {
              if (ship.name === data.key) {
                // this.activeShip(index, ship);
                this.selectedShip(index, ship);
                // this.parentCatListIdx = index;
              }
            });
          }
        }
        if (data.src === "home") {
          if (data.event === 'updateshiplist') {
            this.shipUpdatedData = data['data']
            this.arrangeShipData(this.shipUpdatedData);
            this.mappingCategory();
          }
        }
        if (data.src === "tag") {
          if (data.event === 'closealllevel') {
            let idx = this.shipActiveList.findIndex(name => name == data['ship']['name']);
            if (idx > -1) {
              this.shipActiveList.splice(idx, 1);
            }
          }
        }
        if (data.src == 'networkPanel') {
          if (data.event === 'pyramidPop') {
            this.pyramidPopup = data.data;
          }
        }
        if (data.src === "networkmodel") {
          if (data.event === "show-ern") {
            // this.ernMode = true;
            this.loadComp.emit(data['data']);
          }
          //ernToggleOpenHanddle
          if (data.event === "trigger-search-ern") {
            this.ernToggleOpenHanddle(data['data']);
          }
          if (data.event === "search-ern") {
            this.nscSearch = (data['data']);
            this.nscSearchValue({});
          }
          if (data.event === 'updatenetworkdata') {
            let networkData = JSON.parse(JSON.stringify(data['data']));
            this.networkData = networkData['vertices'];
            this.networkDataDefault = networkData['vertices'];
            // this.nscSearch = data['key'];
            this.orderTreeData();
            // if (this.nscSearch) {
            //   setTimeout(() => {
            //     this.nscSearchValue({});
            //   }, 1000);
            // }
            console.log("updatenetworkdata", this.networkData)
          }
          if (data.event === 'unselectednode') {
            let key = data['data'];
            if (data['key']) {
              let idx = this.networkData.findIndex(el => el.id == key);
              if (idx > -1) {
                const found = this.activeIndex.some(el => el === idx);
                if (!found) {
                  this.activeIndex.push(idx);
                }
              }
            } else {
              let idx = this.networkData.findIndex(el => el.id == key);
              if (idx > -1) {
                const foundIdx = this.activeIndex.findIndex(el => el === idx);
                if (foundIdx > -1) {
                  this.activeIndex.splice(foundIdx, 1);
                }
              }
            }
          }
          if (data.event === 'updateselectednode') {
            let key = data['data'];
            if (key) {
              let idx = this.networkData.findIndex(el => el.id == key);
              if (idx > -1) {
                this.ActiveIndex(this.networkData[idx], idx);
              }
            }
          }
          if (data.event == 'sidepanel') {
            this.infoToggleOpenHandle();
          }
          if (data.event == 'updateErn') {
            if (data['data']) {
              this.selectedErn = data['data'];
            }
          }
          if (data.event == 'updateAOPS') {
            if (data['data']) {
              this.selectedAops = data['data'];
            }
          }
        }
        if (data.src === "khaservice") {
          if (data.event === 'update-compartments') {
            this.shipComp['compList']=data['data'];
            // this.comps.forEach((element, i) => {
            //   if (element.component.toLowerCase() == 'compartments') {
            //     this.comps[i].compList = data['data'];
            //   }
            // });
          }
          if(data.event=='appmode'){
            this.slms_mode=data['data'];
          }
          if (data.event === 'showloader') {
            this.cancelSystemLoad = false;
          }
          if (data.event === 'cancel-loading') {
            this.cancelSystemLoad = true;
          }
        }
        if (data.src === "graphicNode") {
          if (data.event === 'nodeunselected') {
            let vid = data['id'];
            this.networkData.forEach((node, i) => {
              if (vid === node['id']) {
                this.ActiveIndex(node, i);
              }
            });
          }
        }
        if (data.src === "ctrlServ") {
          if (data.event === 'update-show-all') {
            this.showAllSystem = data['data'];
          }
        }
        if (data.src === "material") {
          if (data.event === 'change-material-status') {
            if(this.statusMode){
              let obj = { red: this.systemRedStatusSate, yellow: this.systemYellowStatusSate };
              this.broadcastInfo({ src: 'controlpanel', event: 'showsystemcolors', data: obj, key: 'status' });
            }
          }
        }
      });

  }
  selectedLevel: any;
  networDataTree: any = []
  listData = [];
  orderStatusList = []
  orderTreeData() {
    const root = [];
    this.networDataTree = [];

    this.networkData.forEach(node => {
      // No parentId means top level
      if (!node.parentID) return root.push(node);
      if (node.orderID != 2) {
        node['expand'] = false;
      }
      // Insert node as child of parent in flat array
      const parentIndex = this.networkData.findIndex(el => el.orderID === node.parentID);
      if (!this.networkData[parentIndex].childList) {
        return this.networkData[parentIndex].childList = [node];
      }
      const found = this.networkData[parentIndex].childList.some(ele => ele.id === node.id);
      if (!found) {
        this.networkData[parentIndex].childList.push(node);
      }
    });
    this.networDataTree = root;   
  }

  expandCurrentLevel(level) {
    if (this.selectedLevel == level) {
      delete this.selectedLevel;
    } else {
      this.selectedLevel = level;
    }
  }
  ToggleControlOpenHanddle() {
    this.handleControlToggleOpen = !this.handleControlToggleOpen;
  }
  ToggleDOpenHanddle() {
    this.handleDToggleOpen = !this.handleDToggleOpen;
  }

  cameraTarget(camera) {
    // this.cameraTargetClick.emit(camera);
  }

  //   partPosition(e){
  // let elem = document.getElementById(e)
  // if(elem){
  //   console.log(elem.offsetWidth);
  //   return elem.offsetWidth+"px"
  // }
  //   }

  @HostListener('document:mouseover', ['$event'])
  mouseover(event) {
    if (event.target.matches('.shipHoverPanel')) {
      // alert('hover to editor div')

      // this.shipHandleToggleOpen =true;
      //   var elem = document.getElementsByClassName('side_panel');
      // elem[0].classList.add('open');
    }
    if (event.target.matches('.partHoverPanel')) {
    }
  }
  panelWidth: any = '0px';
  getPanelwidth(e) {
    let elem = document.getElementById(e);
    if (this.nscInfoShowPanelOpen) {
      var ele = this.handleToolsToggleOpen ? 'shipPanel' : this.drawerHandleToggleOpen ? 'shipPanel' : this.insHandleToggleOpen ? 'shipPanel' : this.shipHandleToggleOpen ? 'shipPanel' : this.nscHandleToggleOpen ? 'nscPanel' : this.shipDetailHandleToggleOpen ? 'shipDetailPanel' : this.ernHandleToggleOpen ? 'ernPanel' : this.nsscRightHandleToggleOpen ? 'nsscRightPanel' : '';
      // var ele = this.drawerHandleToggleOpen ? 'shipPanel' : this.nscHandleToggleOpen ? 'nscPanel' : this.shipDetailHandleToggleOpen ? 'shipDetailPanel' : this.ernHandleToggleOpen ? 'ernPanel' : '';
      if (ele != '') {
        let elem = document.getElementById(ele);
        this.panelWidth = elem.offsetWidth + 'px';
      }

    }
    // if (e == 'toolPanel') {
    //   let rawElem = document.getElementById(e);
    //   this.panelWidth = elem.offsetWidth + 'px';
    //   return 'translateX(' + elem.offsetWidth + 'px)';
    // }
    return 'translateX(' + elem.offsetWidth + 'px)';
  }

  getPanelwidthSetting(e) {
    // if (e == 'filterPanel') {
    //   let rawElem = document.getElementById('shipPanel');
    //   width = rawElem.offsetWidth;
    //   return width + 'px';
    // } else {
    //   let elem = document.getElementById(e);
    //   var width = elem.offsetWidth;
    //   if (e == 'toolPanel') {
    //     let rawElem = document.getElementById(e);
    //     width = rawElem.offsetWidth;
    //     return width + 'px';
    //   }
    //   return 'translateX(' + width + 'px)';
    // }
    let elem = document.getElementById(e);
    var width = elem.offsetWidth;
    if (e == 'toolPanel') {
      let rawElem = document.getElementById(e);
      width = rawElem.offsetWidth;
      return width + 'px';
    }
  }

  changePanelBG(event) {
    let partPanel: any = document.getElementById('partPanel');
    let shipPanel: any = document.getElementById('shipPanel');
    let nscPanel: any = document.getElementById('nscPanel');
    let nscInfo: any = document.getElementById('nscInfoPanel');
    let shipDetail: any = document.getElementById('shipDetailPanel');
    this.panelBG = event.target.value;

    if (partPanel) {
      partPanel.style.backgroundColor = this.panelBG;
      partPanel.style.color = this.getContrastCol(this.panelBG);
    }
    if (shipPanel) {
      shipPanel.style.backgroundColor = this.panelBG;
      shipPanel.style.color = this.getContrastCol(this.panelBG);
    }
    if (nscPanel) {
      nscPanel.style.backgroundColor = this.panelBG;
      nscPanel.style.color = this.getContrastCol(this.panelBG);
    }
    if (nscInfo) {
      nscInfo.style.backgroundColor = this.panelBG;
      nscInfo.style.color = this.getContrastCol(this.panelBG);
    }
    if (shipDetail) {
      shipDetail.style.backgroundColor = this.panelBG;
      shipDetail.style.color = this.getContrastCol(this.panelBG);
    }
  }

  changeCardBG(event) {
    this.cardBG = event.target.value;
    let panels: any = document.querySelectorAll(".shipParentList .catList");
    panels.forEach((panel) => {
      panel.style.backgroundColor = this.cardBG;
      panel.style.color = this.getContrastCol(this.cardBG);
    });
    let panelList: any = document.querySelectorAll(".contentTabSection");
    panelList.forEach((panel) => {
      panel.style.backgroundColor = this.cardBG;
      panel.style.color = this.getContrastCol(this.cardBG);
    });
  }

  toggleBackgroundColor(e) {
    var color = e.checked ? 'black' : 'darkblue';
    this.broadcastInfo({ src: 'sidepanel', event: 'toggle-bg', data: color, key: "" });
  }

  // changeShadowBG(event) {
  //   this.shadowBG = event.target.value;
  //   let panels: any = document.querySelectorAll(".shipParentList .catList");
  //   panels.forEach((panel) => {
  //     panel.style.color=  this.shadowBG;
  //   })
  // }

  shadowAdd(e) {
    this.shadowActive = e.checked
  }
  settingPanelClose() {
    this.handleDControlToggleOpen = false;
    this.settingPanelState.emit(false);
  }
  getContrastCol(hexcolor) {
    hexcolor = hexcolor.replace("#", "");
    if (hexcolor.length === 3) {
      hexcolor = hexcolor + hexcolor;
    }
    var r = parseInt(hexcolor.substr(0, 2) || "00", 16);
    var g = parseInt(hexcolor.substr(2, 2) || "00", 16);
    var b = parseInt(hexcolor.substr(4, 2) || "00", 16);
    var col = ((r * 299) + (g * 587) + (b * 114)) / 1000;
    return (col >= 128) ? 'black' : 'white';
  }
  oceanSurface(e) {
    this.oceanSurfaceToggle.emit(e.checked);
    this.oceanSurfaceState = e.checked;
  }
  reflections(e) {
    this.broadcastInfo({ src: 'controlpanel', event: 'togglereflection', data: e.checked, key: "" });
  }
  /**
   * allow ship dranging
   */
  allowShipDrag(e) {
    this.shipDragState = e.checked;
    this.allowDragShip.emit(e.checked);
  }
  /**
   * toggle Ocean Effect
   */
  toggleOcean(e) {
    this.broadcastInfo({ src: 'controlpanel', event: 'toggleocean', data: !e.checked, key: "" });
  }
  riskMangState: any = false;
  riskMangToggle() {
    this.riskMangState = true;
    this.broadcastInfo({ src: 'sidepanel', event: 'riskMangState', data: this.riskMangState, key: "" });
  }
  getBGLabel(state) {
    let idx = this.statusList.findIndex(e => e.status == state);
    if (idx > -1) {
      return this.statusList[idx].color;
    }
  }
  getMbsBGLabel(state) {
    let idx = this.mbsStatusList.findIndex(e => e.status == state);
    if (idx > -1) {
      return this.mbsStatusList[idx].color;
    }
  }
  getClassImg(shipClass) {
    return shipClass == 'Halifax' ? 'HAL' : shipClass == 'Dewolf' ? 'HDW' : shipClass == 'Kingston' ? 'KIN' : shipClass == 'Victoria' ? 'VIC' : shipClass == 'Orca' ? 'ORC' : shipClass == 'Asterix' ? 'AST' : shipClass == 'Protecteur' ? 'PRT' : shipClass == 'CSC' ? 'csc' : '';
  }

  shipDetailPanelClose() {
    this.handleShipDetailToggleOpen = !this.handleShipDetailToggleOpen;
    this.parentCatListIdx = null;
    this.detailPanelClose.emit();
  }
  infoToggleOpenHandle() {
    this.panelWidth = 0;
    this.nscInfoShowPanelOpen = !this.nscInfoShowPanelOpen;
    // this.showShipDetailPanelOpen = !this.showShipDetailPanelOpen;
    // this.handleShipDetailToggleOpen = !this.handleShipDetailToggleOpen
  }
  shipDetailToggleOpenHandle() {
    this.shipDetailHandleToggleOpen = !this.shipDetailHandleToggleOpen;
    if(this.shipDetailHandleToggleOpen){
    if(this.selectedKha && this.ctrlServe.matCurrentTab !== 'system'){
      this.ctrlServe.matCurrentTab = 'system';
      let kha = this.selectedKha;
      this.broadcastInfo({ src: 'controlpanel', event: 'reset_drag', data: "", key: 'system' });
      if (kha) {
        this.broadcastInfo({ src: 'sidepanel', event: 'changeshipcomp', data: this.ctrlServe.shipHazardList, key: 'system' });
      } else {
        this.broadcastInfo({ src: 'controlpanel', event: 'clearkha', data: null, key: 'system' });
      }
    }
    this.compSort();
  }
  }
  shipCompToggleOpenHandle(){
    this.shipCompHandleToggleOpen = !this.shipCompHandleToggleOpen;
    if(this.shipCompHandleToggleOpen){
      let comp = this.currentComp;
      this.ctrlServe.matCurrentTab = 'compartment';
      this.broadcastInfo({ src: 'controlpanel', event: 'reset_drag', data: "", key: 'compartment' });
      if (comp) {
        this.resetWallmode();
        this.broadcastInfo({ src: 'controlpanel', event: 'loadcompartment', data: comp, key: true });
      } else {
        this.broadcastInfo({ src: 'controlpanel', event: 'clearkha', data: null, key: 'compartment' });
      }
      this.sortCompartment('name');
    }
  }
  shipCompLoad(){
    // if(this.shipCompHandleToggleOpen){
      this.khaService.showMatObjectTag(undefined, false);
      this.ctrlServe.matCurrentTab = 'compartment';
      // this.sortCompartment('name');
  }
  registerLink(link) {
    if (link != '') {
      window.open(link)
    }
  }
  openDoc(link, route = null, comp = null) {
    if (route) {
      let url = '../../assets/doc/' + comp + '_' + link.key + '_' + route + '.xlsx';
      window.open(url);
    } else {
      if (link != '') {
        if (link == 'ERN') {
          this.broadcastInfo({ src: 'sidepanel', event: 'init_ern', data: 'ern', key: "" });
          setTimeout(() => {
            if (this.ctrlServe.selectedKha !== undefined) {
              this.nscHandleToggleOpen = true;
              this.ernHandleToggleOpen = false;
            } else {
              this.nscHandleToggleOpen = false;
              this.ernHandleToggleOpen = true;
            }
          }, 300);
        } else {
          window.open(link);
        }
      }
    }

  }
  /* * *
  * load component
  * * * */
  loadComponent(ship, comp, mode = null) {
    this.activeComp = comp;
    this.shipshowPanelOpen = false;
    this.drawershowPanelOpen = false;
    this.insshowPanelOpen = false;
    this.shipHandleToggleOpen = false;
    this.drawerHandleToggleOpen = false;
    this.insHandleToggleOpen = false;
    // this.showDControlPanelOpen = false;
    this.handleDControlToggleOpen = false;
    this.showShipDetailPanelOpen = false;
    this.shipDetailPanelOpen = false;
    this.shipDetailHandleToggleOpen = false;
    this.nscShowPanelOpen = false;
    this.ernShowPanelOpen = false;
    this.nscHandleToggleOpen = false;
    this.nscInfoShowPanelOpen = false;
    this.aopsErnShowPanelOpen = false;
    this.aopsErnHandleToggleOpen = false;
    this.activeIndex = [];
    if (this.activeComp == 'nsc') {
      // this.nscHandleToggleOpen = true;
      this.shipshowPanelOpen = true;
      this.drawershowPanelOpen = true;
      this.insshowPanelOpen = true;
      this.ernShowPanelOpen = false;
      if (mode === 'ern') {
        this.nscShowPanelOpen = false;
        this.ernShowPanelOpen = true;
        this.ernHandleToggleOpen = true;
        this.nscHandleToggleOpen = false;
      } else if(mode === 'aops'){
        this.ernShowPanelOpen = false;
        this.ernHandleToggleOpen = false;
        this.aopsErnShowPanelOpen = true;
        this.aopsErnHandleToggleOpen = true;
      }else {
        this.ernHandleToggleOpen = false;
        this.nscShowPanelOpen = true;
        this.ernShowPanelOpen = false;
      }
    } else if (this.activeComp == 'ship') {
      this.shipDetailPanelOpen = true;
      this.shipCompPanelOpen = true;
      // this.shipDetailHandleToggleOpen = true;
    } else if (this.activeComp == 'ern') {
      this.nscShowPanelOpen = false;
      this.shipshowPanelOpen = true;
      this.drawershowPanelOpen = true;
      this.insshowPanelOpen = true;
      this.ernShowPanelOpen = true;
      this.ernHandleToggleOpen = true;
      // comp = 'nsc';
    }
    setTimeout(() => {
      let obj = { ship: ship, comp: comp, mode: mode };
      this.loadComp.emit(obj);
    }, 200);
  }
  expandIdx: any;
  expandCloseList: any = [];
  systemKha: any = 'systems';
  expandCurrent(i) {
    this.expandIdx = i;
    this.matKhaTabIndex = 1;
    this.systemKha = "systems";
    this.comps[1].compList = this.comps[0].compList[i].systemList;
    this.onChangekhaComp(this.comps[0].compList[i])
    // let idx = this.expandIdx.findIndex(e => e == i);
    // if (idx > -1) {
    //   this.expandIdx.splice(idx, 1);
    //   let indx = this.expandCloseList.findIndex(e => e == i);
    //   if (indx == -1) {
    //     this.expandCloseList.push(i);
    //   } else {
    //     this.expandCloseList.splice(indx, 1);
    //   }
    // }
  }
  expandCurrentClass(event, i, shipClass) {
    if (event) {
      if (event.target.classList.contains('mat-slide-toggle-bar')) {
        return;
      }
    }
    if (this.selectedClass == i) {
      this.selectedClass = -1;
    } else {
      const found = this.hideShipClassList.some(el => el === shipClass);
      if (!found) {
        this.selectedClass = i;
      }
    }
  }
  expandAddCurrent(e, i) {
    if (e != 0) {
      if (this.expandIdx.length > 0) {
        let idx = this.expandIdx.findIndex(e => e == i);
        if (idx == -1) {
          if (this.expandCloseList > 0) {
            let indx = this.expandCloseList.findIndex(e => e == i);
            if (indx == -1) {
              // this.expandCloseList.splice(indx, 1);
              this.expandIdx.push(i)
            }
          }

        }
      } else {
        if (this.expandCloseList > 0) {
          let indx = this.expandCloseList.findIndex(e => e == i);
          if (indx == -1) {
            // this.expandCloseList.splice(indx, 1);
            this.expandIdx.push(i)
          }
        }
        this.expandIdx.push(i)
      }
    }
    let idx = this.expandIdx.findIndex(e => e == i);
    let indx = this.expandCloseList.findIndex(e => e == i);
    if (idx > -1 && indx == -1) {
      return true;
    } else {
      return false;
    }
  }

  /* * *
  * method for search
  * * */
  nscSearchValue(e) {
    if(this.nscSearch.length < 3) return;
    if (e.key === 'Backspace') {
      setTimeout(() => {
        this.broadcastInfo({ src: 'sidepanel', event: 'nscfilter', data: this.nscSearch, key: "" });
      }, 100)
    } else {
      this.broadcastInfo({ src: 'sidepanel', event: 'nscfilter', data: this.nscSearch, key: "" });
    }
  }

  /* * *
  * method for search clear
  * * */
  nscSearchClear() {
    this.nscSearch = "";
    this.broadcastInfo({ src: 'sidepanel', event: 'nscfilter', data: this.nscSearch, key: "" });
  }

  hideUnselected() {
    this.hideUnselectedState = !this.hideUnselectedState;
    this.broadcastInfo({ src: 'sidepanel', event: 'nscHideUnselected', data: this.hideUnselectedState, key: this.hideUnselectedState });
  }
  hideSubBranch() {
    this.hideSubBranchState = !this.hideSubBranchState;
    this.broadcastInfo({ src: 'sidepanel', event: 'nscHideSubBranch', data: this.hideSubBranchState, key: this.hideSubBranchState });
  }
  showPath() {
    this.showPathtoggleState = !this.showPathtoggleState;
    this.broadcastInfo({ src: 'sidepanel', event: 'nscShowPath', data: this.showPathtoggleState, key: this.showPathtoggleState });
  }

  /* * *
  * on change kha
  * * */
  selected = ""
  onChangekha(kha) {   
    this.selectedKha = kha.split(" ").join("").toLowerCase();
  }

  chooseExcelFile(e) {
    console.log("event", e);
    if (this.selectedKha) {
      var re = /(?:\.([^.]+))?$/;
      var ext = re.exec(e.target.files[0].name)[1];
      if (ext == 'xlsx' || ext == 'xls' || ext == 'xlsm' || ext == 'csv') {
        this.selectedFile = e.target;
        let kha = this.selectedKha.split(" ").join("").toLowerCase();
        this.formData.append('file', e.target.files[0]);
        this.formData.append('kha', kha);
        this.selectedFile = e.target.files[0].name;
        let url;
        if (kha.includes('ern')) {
          url = this.configService.project_config.ernNetworkUpload;
        }else if (kha.includes('aops')) {
          url = this.configService.project_config.aopsNetworkUpload;
        } else if (kha.startsWith('insa')) {
          url = this.configService.project_config.nsscNetworkmodel;
        } else {
          url = this.configService.project_config.networkUpload;
        }
        this.showBlockLoading = true;
        this.configService.uploadExcelData(url, this.formData, this.excelUploadSuccess);
      } else {
        this.openDialog.emit("Invalid File");
        return;
      }
    } else {
      this.openDialog.emit("Please Select Kha option !!");
      return;
    }
  }

  /* * * * *
  * check Json or Not
  * * * * * */
  hasJsonStructure(str) {
    if (typeof str !== 'string') return false;
    try {
      const result = JSON.parse(str);
      const type = Object.prototype.toString.call(result);
      return type === '[object Object]'
        || type === '[object Array]';
    } catch (err) {
      return false;
    }
  }

  /* * *
  * reset both file
  * * */
  resetFile() {
    this.formData = new FormData();
    this.selectedFile = 'No file chosen...';
    let elem: any = document.getElementById("fileupload");
    elem.value = "";
  }

  excelUploadSuccess = ((networkData) => {
    this.showBlockLoading = false;
    let isJson = this.hasJsonStructure(networkData);
    if (isJson) {
      networkData = JSON.parse(networkData);
      console.log("plan response : ", networkData);
      if (networkData['result'] == 'success') {
        console.log("networkData ", networkData['data']);
        this.resetFile();
        if (this.activeComp === 'nsc' && !this.selectedKha.includes('ern')) {
          this.activeIndex = [];
          this.broadcastInfo({ src: 'sidepanel', event: 'loaduploaddata', data: networkData['data'], key: "" });
          this.openDialog.emit('File Uploaded Successfully !!!');
        } else {
          this.openDialog.emit('File Uploaded Successfully !!!');
        }
      } else {
        this.resetFile();
        this.openDialog.emit('Unknown File Format !!!');
      }
    } else {
      this.resetFile();
      this.openDialog.emit('Unknown File Format !!!');
    }
  })

  getValidIndex(index) {
    index = index.split(" ").join("");
    return index ? true : false;
  }

  getActiveIndex(index) {
    return this.activeIndex.some(el => el === index);
  }

  ActiveIndex(node, i = null) {
    if(!i){
      i = this.networkData.findIndex(el => el.id == node.id);
    }
    const found = this.activeIndex.some(el => el === i);
    if (found) {
      let idx = this.activeIndex.findIndex(el => el === i);
      if (idx > -1) {
        this.activeIndex.splice(idx, 1);
        this.CommunicationServ.nodeUnSelect = true;
        this.broadcastInfo({ src: 'sidepanel', event: 'selectedindex', id: node['id'], key: false });
      }
    } else {
      this.activeIndex.push(i);
      this.CommunicationServ.nodeUnSelect = false;
      this.broadcastInfo({ src: 'sidepanel', event: 'selectedindex', id: node['id'], key: true });
    }
  }

  loadShipComponent(key, comp) {
    this.matrialShip = comp;
    if (comp == 'ship') {
      this.broadcastInfo({ src: 'sidepanel', event: 'update-MV-ScreenType', data: 'system', key: '' });
    }
    this.loadComponent(key, comp);
    setTimeout(() => { this.onChangekhaList(key) }, 400);

    if (this.activeComp == 'ship') {
      cancelAnimationFrame(animID);
      let anim = (timestamp) => {
        if (this.ctrlServe.isMaterialAppLoaded) {
          cancelAnimationFrame(animID);
          this.onKHATabChanged({ 'index': 1 });
        } else {
          animID = window.requestAnimationFrame(anim);
        }
      }
      var animID = window.requestAnimationFrame(anim);
    }
  }

  /* * *
  * method for change kha list
  * * */
  onChangekhaList(key) {
    this.selectedKha = (this.selectedKha ? this.selectedKha.split(" ").join("").toLowerCase() : this.selectedKha);
    if (this.selectedKha != key['key'].split(" ").join("").toLowerCase()) {
      // if (this.selectedShipid) {
      this.selectedKha = key['key'].split(" ").join("").toLowerCase();
      this.activeIndex = [];
      this.ctrlServe.selectedKha = this.selectedKha;
      this.ctrlServe.shipHazardList = key;
      this.broadcastInfo({ src: 'sidepanel', event: 'changensccomp', data: key, key: '' });
      // } else {
      //   this.openDialog.emit('Please Select Ship First !!!');
      // }
    }
  }


  /* * *
  * method for change kha comp from material screen
  * * */
  onChangeKHASystem(kha, sysComp) {
    for (let comp of this.comps[0]["compList"]) {
      if (comp['key'] === kha.split(" ").join("").toLowerCase()) {
        this.onChangekhaComp(comp, sysComp);
        break;
      }
    }
  }

  /* * *
  * method for change kha comp
  * * */
  onChangekhaComp(comp, sysComp = []) {
        this.khaService.showMatObjectTag('id', false);
    if (comp) {
      comp['system'] = comp['system'] ? comp['system'] : comp['area'];
      if (this.selectedKha) {
        if (this.selectedKha === comp['system'].split(" ").join("").toLowerCase()) {
          return
        }
      }

      this.selectedKha = comp['system'].split(" ").join("").toLowerCase();
      this.ctrlServe.selectedKha = this.selectedKha.split(" ").join("").toLowerCase();
      if (sysComp.length > 0) {
        this.onKHATabChanged({ 'index': 1 });
      }
      let idx = this.service['hazardList'].findIndex(el => { return el['area'].split(" ").join("").toLowerCase() === comp['system'].split(" ").join("").toLowerCase() })
      if (idx > -1) {
        this.ctrlServe.shipHazardList = this.service['hazardList'][idx];
      }
      this.broadcastInfo({ src: 'sidepanel', event: 'changeshipcomp', data: this.ctrlServe.shipHazardList, comp: sysComp });
      this.multiSelectKha = [];
      this.showAllSystems({checked: false});
      setTimeout(()=>{
        this.showAllSystems({checked: true});
      },100);
      this.multiKhaOption();
    }
  }

  /* * *
  * method for active ship status
  * * */
  getActiveShipStatus() {
    if (this.activeComp === 'nsc' || this.activeComp === 'ship') {
      return true;
    } else if (this.activeComp === 'fleet') {
      return false;
    } else {
      return false;
    }
  }

  currentComp
  /* *
  * method for load compartment
  * */
  loadCompartment(s) {
    let show = true;
    /* if (!this.currentComp) {
      this.currentComp = s;
    } else {
      if (this.currentComp.code === s.code) {
        this.currentComp = "";
        show = false;
      } else {
        this.currentComp = s;
      }
    } */
    this.shipComp['compList'].forEach(sys => {
      if (sys['code']==s.code) {
        sys.selected = !sys.selected;
        show=sys.selected;
        this.currentComp=show?s:"";
      }else{
        if(!this.slms_mode){
          sys.selected = false;
        }
      }
    });
    if(!this.slms_mode){
    this.resetWallmode();
    }
    this.broadcastInfo({ src: 'controlpanel', event: 'loadcompartment', data: s, key: show });
  }
  getCurrentComp(sys) {
    if (this.currentComp) {
      return this.currentComp.code == sys;
    } else {
      return false;
    }
  }
  checkSubSystemStatus() {
    let hideAll,hideSys,showAll;
    this.comps[0]["compList"].forEach(item => {
      if (item['key'] === this.selectedKha.split(" ").join("").toLowerCase()) {
        hideSys = item['systemList'].some(sys => sys['status'] == 'inactive');
        hideAll = item['systemList'].every(sys => sys['status'] == 'inactive');
        showAll = item['systemList'].every(sys => sys['status'] == 'active');
      }
    })
    this.showAllSystem = hideSys ? false : true;
    if (hideAll) {
     this.showAllSystems({checked: false});
    }
    if (showAll) {
      setTimeout(()=>{
        this.showAllSystems({checked: true});
      },1000);
    }
  }
  /* *
  * method for show/hide sub systems
  * */
  showHideSubSystem(e, s) {
    this.ctrlServe.selectedSysComp = [];
    this.ctrlServe.updateSysComps(s, e.checked);
    this.broadcastInfo({ src: 'controlpanel', event: 'showhidesubsystem', data: e.checked, key: s });
    if(this.multiSelectKha.length > 0){
      this.onKhaSelect();
    }
    this.checkSubSystemStatus();
  }
  showFocusSubSystem(s) {
    var subSystemActiveList = this.ctrlServe.showSysComp
    var subSystemActive = subSystemActiveList.firesafety;
    subSystemActive.forEach((item, index) => {
      if (item == s) {
        this.broadcastInfo({ src: 'controlpanel', event: 'focusSubSystem', data: 'true', key: s });
      }
    })
    // this.broadcastInfo({ src: 'controlpanel', event: 'focusSubSystem', data: e.checked, key: s });
  }
  /* *
  * method for show/hide Compartment
  * */
  showHideCompartment(e, sys) {
    if (this.currentComp.code === sys.code) {
      this.currentComp = "";
      sys.wallmode = !sys.wallmode;
      this.broadcastInfo({ src: 'controlpanel', event: 'showhidecompartment', data: e.checked, key: [sys.code] });
    } else {
      this.currentComp = sys;
      this.resetWallmode();
      this.broadcastInfo({ src: 'controlpanel', event: 'loadcompartment', data: sys, key: e.checked });
    }
  }

  /* *
  * method for load systems
  * */
  loadSystem(s) {
    let sel = this.selectedKha === s;
    if (sel) {
      return
    }
    this.broadcastInfo({ src: 'controlpanel', event: 'loadsystem', data: s, key: s });
  }

  /* *
  * method for KHA Tab change
  * */
  onKHATabChanged($event) {
    console.log("onKHATabChanged", $event);

    if ((this.matKhaTabIndex == 1 || this.matKhaTabIndex == 0) && $event.index == 0) {
      this.matKhaTabIndex = $event.index;
      if (this.ctrlServe.matCurrentTab !== 'compartment') {
        return;
      }
    }
    this.matKhaTabIndex = $event.index;

    if (this.matKhaTabIndex == 1) {
      if (this.selectedKha) {
        let idx = this.comps[0].compList.findIndex(e => e.key == this.selectedKha);
        if (idx > -1) {
          this.expandCurrent(idx)
        }
      }
      if (this.ctrlServe.matCurrentTab !== 'compartment') {
        return;
      }
    }
    let e = $event.index === 0 ? 'system' : 'system';
    e = $event.index === 2 ? "compartment" : e;
    if (e === 'system') {
      this.ctrlServe.matCurrentTab = 'system';
      let kha = this.selectedKha;
      this.broadcastInfo({ src: 'controlpanel', event: 'reset_drag', data: "", key: 'system' });
      if (kha) {
        this.broadcastInfo({ src: 'sidepanel', event: 'changeshipcomp', data: this.ctrlServe.shipHazardList, key: 'system' });
      } else {
        this.broadcastInfo({ src: 'controlpanel', event: 'clearkha', data: null, key: 'system' });
      }
    }
    if (e === 'compartment') {
      // this.khaService.showMatObjectTag(undefined, false);
      // this.ctrlServe.matCurrentTab = 'compartment';
      // let comp = this.currentComp;
      // this.broadcastInfo({ src: 'controlpanel', event: 'reset_drag', data: "", key: 'compartment' });
      // if (comp) {
      //   this.resetWallmode();
      //   this.broadcastInfo({ src: 'controlpanel', event: 'loadcompartment', data: comp, key: true });
      // } else {
      //   this.broadcastInfo({ src: 'controlpanel', event: 'clearkha', data: null, key: 'compartment' });
      // }
      // this.sortCompartment();
    }
    if (e === 'section') {
      this.ctrlServe.matCurrentTab = 'section';
      this.broadcastInfo({ src: 'controlpanel', event: 'reset_drag', data: "" });
      this.broadcastInfo({ src: 'controlpanel', event: 'clearkha', data: null, key: null });
    }
  }

  /* *
  * method for System Tab change
  * */
  systemRedStatusSate = true;
  systemYellowStatusSate = true;
  statusMode = false;
  onSystemTABChange(e) {
    if (e === 'systems') {
      this.statusMode = false;
      this.broadcastInfo({ src: 'controlpanel', event: 'showsystemcolors', data: null, key: 'system' });
    }
    if (e === 'status') {
      this.statusMode = true;
      let obj = { red: this.systemRedStatusSate, yellow: this.systemYellowStatusSate };
      this.broadcastInfo({ src: 'controlpanel', event: 'showsystemcolors', data: obj, key: 'status' });
    }
  }

  /* *
  * method for status toggle change
  * */
  onChangeStatusToggle(e, name) {
    if (name === 'red') {
      this.systemRedStatusSate = e.checked;
    }
    if (name === 'yellow') {
      this.systemYellowStatusSate = e.checked;
    }
    this.broadcastInfo({ src: 'controlpanel', event: 'showhideSubSystemStatus', data: name, key: e.checked });
  }

  /* *
  * method for showHideCompartmentWall
  * */
  showHideCompartmentWall(sys) {
    if (this.currentComp) {
      sys.wallmode = !sys.wallmode;
      this.broadcastInfo({ src: 'controlpanel', event: 'showhidecompartmentwall', data: !sys.wallmode, key: [sys.code] });
    }
  }
  /**
   * Show / Hide hull
   */
  showhull = true;
  showHideHull(e) {
    this.showhull = e.checked
    this.broadcastInfo({ src: 'controlpanel', event: 'togglehull', data: !this.showhull, key: "" });
  }
  /* *
  * method for reset wallmode
  * */
  resetWallmode() {
    // this.comps.forEach(comp => {
    //   if (comp['component'].toLowerCase() === 'compartments') {
        this.shipComp['compList'].forEach(sys => {
          if (sys['wallmode']) {
            sys.wallmode = !sys.wallmode;
          }
        });
    //   }
    // });
  }
  infoPanelToggle(e) {
    if (this.infoPanel == e) {
      delete this.infoPanel;
    } else {
      this.infoPanel = e;
    }

  }
  expandChild(e) {
    //   var mashed = this.networDataTree.reduce(function(m, cur, idx) {
    //     var found = false;
    //     for (var i =0; i < m.length; i++) {
    //         if (m[i].orderID == cur.orderID) {
    //             m[i].expand = true;
    //             found = true;
    //         }
    //     }

    //         m.push(cur)

    //     return m;
    // }, [])
    // this.= mashed;

  }
  //   deepSearchByKey(object, originalKey,id, matches = []){
  //     if(object.hasOwnProperty(originalKey) && object[originalKey] == id)
  //     return object;

  // for(var i=0; i<Object.keys(object).length; i++){
  //     if(typeof object[Object.keys(object)[i]] == id){
  //         var o = customFilter(object[Object.keys(object)[i]]);
  //         if(o != null)
  //             return o;
  //     }
  // }

  // return null;
  //   }

  toggleStoreComp(e){
    this.broadcastInfo({ src: 'sidepanel', event: 'store-compartments', data: e.checked, key: "" });
  }
  getElement(data) {
    if (data != undefined) {
      var elementList = data.split("/");
      if (elementList.length > 0) {
        var ele = '<ul>';
        elementList.forEach(element => {
          var str = element.split(/ +/)[0] == '' ? element.split(/ +/)[1] : element.split(/ +/)[0];
          var matches = str.match(/(\d+)/);
          var style = "";
          if (matches != null) {
            style = "indent"
          }
          if (ele != undefined) {
            ele += '<li id="' + style + '"><p>' + element + '<p></li>';
          } else {
            ele = '<li id="' + style + '"><p>' + element + '<p></li>';
          }
        });
        ele += '</ul>'
        // console.log(ele);
        if (ele != undefined) {
          // return ele;
          return this.html_sanitizer.bypassSecurityTrustHtml(ele);
        }

      } else {
        return "";
      }
    } else {
      return "";
    }

  }

  onNSCTabChanged(tab) {
    this.NSCTab = tab.tab.textLabel;
  }

  /************
   *  Component Sort Ascendine order
   *  */
  compSort() {
    console.log(this.comps);
    var key;
    this.comps.forEach((element, i) => {
      if (element.compList.length > 0) {
        if (element.component == "NSC KHA") {
          key = 'system';
        }
        if (element.component == "Compartments") {
          key = 'name';
        }
        this.comps[i].compList.sort(function (a, b) {

          return 0 - (a[key] > b[key] ? -1 : 1);
        });
      }

    });
    console.log(this.comps);
  }

  /* *
  *  toggle network tree
  * */
  toggleNetworkTree(e) {
    // if (e.checked) {
    //   this.broadcastInfo({ src: 'sidepanel', event: 'toggle_network_tree', data: e.checked, key: "" });
    // } else {
      this.broadcastInfo({ src: 'sidepanel', event: 'toggle_network_tree', data: e.checked, key: "" });
    // }
  }

  openMBSDoc(link) {
    if (link) {
      window.open(link);
    }
  }
  //   activeSystemElem:any;
  //   activeSystem(e){
  //     console.log(e);
  // if(this.activeSystemElem==e.model[0]){
  //   this.khaService.showMatObjectTag(e, false);
  //   delete this.activeSystemElem;
  // }else{
  //   this.activeSystemElem==e.model[0];
  //   this.khaService.showMatObjectTagMesh(e);
  // }

  //   }
  //   selectedMatObj:any;
  //   showMatObjTag(id,flag){
  //     if (this.selectedMatObj == id) {
  //       // this.broadcastInfo({ src: 'main', event: 'showLaydownPointer', data: 'laydownPointer', state: false, sub: id });
  //       this.khaService.showMatObjectTag(id, false);
  //       delete this.selectedMatObj
  //     } else {
  //       this.selectedMatObj = id;
  //       this.khaService.showMatObjectTag(id, true);

  //     }
  //   }
  structHull: any = 'none';
  structDecks: any = 'clear';
  structBulkheads: any = 'clear';
  structSections: any = 'clear';
  deckList: any = ['Z', '02', '01', '1', '2', '3', '4', '5', '6']

  showHideStruct(label, state) {
    console.log(label, state.checked);
    if (label.toLowerCase() == 'hull') {
      this.structHull = state;
    } else if (label.toLowerCase() == 'decks') {
      this.structDecks = state;
    } else if (label.toLowerCase() == 'bulkheads') {
      this.structBulkheads = state;
    } else if (label.toLowerCase() == 'sections') {
      this.structSections = state;
    }
    let data = state === 'clear' ? 'transparent' : state;
    data = state === 'none' ? 'hide' : data;
    this.broadcastInfo({ src: 'sidepanel', event: 'toggle-struct-state', data, key: label });
  }
  showAllSystem: boolean = true;
  showAllSystems(e) {
    let boo = e.checked
    this.showAllSystem = boo;
    this.broadcastInfo({ src: 'sidepanel', event: 'showhide-all-systems', data: boo, key: "" });
    if(!boo){
      this.resetSubSystems();
    }
    if(this.multiSelectKha.length > 0){
      this.onKhaSelect();
    }
  }
  resetSubSystems(){
    this.broadcastInfo({ src: 'sidepanel', event: 'reset-sub-systems', data: '', key: "" });
  }
  selectedDeck: any = '';
  onChangeDeck(value) {
    this.selectedDeck = value;
    this.structHull = 'none';
    this.structDecks = 'solid';
    this.structBulkheads = 'clear';
    this.structSections = 'clear';
    this.broadcastInfo({ src: 'sidepanel', event: 'filter-deck', data: value, key: "" });
  }
  onClearDeck() {
    this.selectedDeck = "";
    this.broadcastInfo({ src: 'sidepanel', event: 'filter-deck', data: "", key: "" });
  }

  ToggleToolOpenHanddle() {
    this.handleToolsToggleOpen = !this.handleToolsToggleOpen;
  }

  intensityData: any = 4;
  intensityFormatLabel(value: number) {
    return value;
  }
selectedVenue:any='';
  onIntensityChange(e) {
    // console.log("test",e.value);
    this.broadcastInfo({ src: 'sidepanel', event: 'intensity', data: e.value, key: "" });
  }
  section:any='';
  onChangeSection(){
    // this.khaService.applyDisplayRuleFor_Section(this.section);
    this.broadcastInfo({ src: 'sidepanel', event: 'applyDisplayRuleFor_Section', data: this.section });
  }
  onClearSection(){
    this.section ='';
  }

  showHideTarget(e) {
    console.log(this.comps, e);
    this.comps.forEach((element, indx) => {
      if (element.component == "Systems") {
        if (element.compList.length > 0) {
          element.compList.forEach((ele, idx) => {
            if (ele.name == e.name) {
              this.comps[indx].compList[idx]['targetStatus'] = !this.comps[indx].compList[idx]['targetStatus'];
              if (this.comps[indx].compList[idx]['targetStatus']) {
                this.showHideStruct('Hull', 'none');
                this.showHideStruct('Bulkheads', 'none');
                this.showHideStruct('Sections', 'none');
              } else {
                this.showHideStruct('Hull', 'none');
                this.showHideStruct('Bulkheads', 'clear');
                this.showHideStruct('Sections', 'clear');
              }
            }
          });
        }
      }
    });
  }

  showHideVisible(e) {
    console.log(this.comps, e);
    this.comps.forEach((element, indx) => {
      if (element.component == "Systems") {
        if (element.compList.length > 0) {
          element.compList.forEach((ele, idx) => {
            if (ele.name == e.name) {
              this.comps[indx].compList[idx]['visible'] = !this.comps[indx].compList[idx]['visible'];
              if (this.comps[indx].compList[idx]['visible']) {
                this.showHideStruct('Hull', 'clear');
                this.showHideStruct('Bulkheads', 'clear');
                this.showHideStruct('Sections', 'clear');
              } else {
                this.showHideStruct('Hull', 'none');
                this.showHideStruct('Bulkheads', 'clear');
                this.showHideStruct('Sections', 'clear');
              }
            }
          });
        }
      }
    });
  }

}

@Component({
  selector: 'document-dialog-model',
  templateUrl: 'document-dialog-model.html',
  styleUrls: ['./sidepanel.component.scss'],
})
export class DocumentDialogModel {
  pdfDetails: any = '';
  constructor(public dialogRef: MatDialogRef<DocumentDialogModel>, @Inject(MAT_DIALOG_DATA) public message: any) {
    console.log("message=", this.message);
    this.pdfDetails = this.message;
  }
}
