import { Injectable } from '@angular/core';
import * as Rx from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class CommunicationService {
  private instance: Rx.Subject<any>;
  private IframeInstance: Rx.Subject<any>;
  private iframeEvents: any = {};
  public nodeUnSelect: boolean = false;
  public showConnector = false;

  constructor() {
    window.addEventListener("message", (event) => {
      let obj = event['data'];
      if (obj) {
        let srcName = obj['iframeConnection'];
        if (!srcName) return;
        this.iframeEvents[srcName] = event;
        this.getIframeInstance().next(event['data']);
      }
    }, false);
  }

  createIframeInstance(data) {
    if (this.iframeEvents[data['iframeConnection']]) {
      let event = this.iframeEvents[data['iframeConnection']];
      event.source.postMessage(data, event.origin);
    } else {
      parent.postMessage(data, "*");
    }
  }

  public getIframeInstance(): Rx.Subject<any> {
    if (!this.IframeInstance) {
      this.IframeInstance = new Rx.Subject<any>();
    }
    return this.IframeInstance;
  }

  createInstance(): Rx.Subject<any> {
    const instance = new Rx.Subject<any>();
    return instance;
  }

  public getInstance(): Rx.Subject<any> {
    if (!this.instance) {
      this.instance = this.createInstance();
    }
    return this.instance;
  }
}
