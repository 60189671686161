import { Component, OnInit, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { CommunicationService } from "../services/communication.service";
import { LanguageService } from '../services/language.service';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss']
})
export class TagComponent implements OnInit {
  tagMiniList: any = [];
  @Input() tagMaxList: any = [];
  @Input() _disableTrans: any;
  popupZindex: any = 0;
  broadcastSubs: any;
  riskMangState:any=false; 
  // @Input() focusShipState: any = false;
  @Input() shipMetaData: any;
  @Output() shipDetailTag: EventEmitter<any> = new EventEmitter<any>();
  statusList: any = [{ 'status': 'Completed', 'color': '#99ccff' },
  { 'status': 'No Issues', 'color': '#006600' },
  { 'status': 'On Track minor issues', 'color': '#99cc00' },
  { 'status': 'Significant Issues successful delivery at high risk', 'color': '#ffc000' },
  { 'status': 'Major Issues preventing completion', 'color': '#ff6600' },
  { 'status': 'Critical issues preventing completion', 'color': '#ff0000' },
  { 'status': 'Not Started', 'color': '#ffffff' }]

  constructor(private CommunicationServ: CommunicationService, private translate: TranslateService, private language: LanguageService) {
    this.language.languageChange.subscribe(props => {
      console.log("language", props);
      this.translate.use(props);
    })
   }

  ngOnInit() {
    this.broadcastSubs = this.CommunicationServ.getInstance()
      .subscribe((data: any) => {
        /**
         * subcribe to any events broadcasted by
         * BroadcastService instance
         */
        if (data.src === "main") {
          if (data.event === 'triggernextlevel') {
            this.tagMaxList.forEach((ship) => {
              if (ship.name === data.key) {
                this.triggerLevel(ship, null);
              }
            });
          }
          if (data.event === 'updatetagelem') {
            this.updateTagElement(data.data);
          }
        }
        if (data.src === "sidepanel") {
          if (data.event === 'riskMangState') {
            this.riskMangState =data.data;
          }
        }
      })
  }

  broadcastInfo(data) {
    this.CommunicationServ.getInstance().next(data)
  }

  updateTagElement(elem) {
    const found = this.tagMiniList.some(el => el.ship === elem.ship);
    if (found) {
      this.tagMax(elem);
    }
  }

  tagMini(elem) {
    let idx = this.tagMaxList.findIndex(e => e.ship == elem.ship);
    if (idx > -1) {
      this.tagMaxList.splice(idx, 1);
      this.tagMiniList.push(elem);
    }
  }
  tagMax(elem) {

    let idx = this.tagMiniList.findIndex(e => e.ship == elem.ship);

    if (idx > -1) {
      // if (this.focusShipState) {
      //   this.triggerLevel(elem, idx);
      //   return
      // }
      this.tagMiniList.splice(idx, 1);
      this.popupZindex += 10;
      const found = this.tagMaxList.some(el => el.ship === elem.ship);
      if (!found) {
        this.tagMaxList.push(elem);
      }
      setTimeout(() => {
        document.getElementById('popup_' + elem.name).style.zIndex = this.popupZindex;
      }, 500);
    }
  }
  tagClose(elem) {
    // this.sensorPanel = false;
    // this.tagMaxList = [];
    // this.cameraPanel = false;
    let idx = this.tagMaxList.findIndex(e => e.ship == elem.ship);
    if (idx > -1) {
      // this.popupZindex -= this.popupZindex;
      this.tagMaxList[idx].expand = false;
      this.tagMaxList.splice(idx, 1);
    }
    let index = this.tagMiniList.findIndex(e => e.ship == elem.ship);
    if (index > -1) {

      this.tagMiniList.splice(index, 1);
    }
    this.broadcastInfo({ src: 'tag', event: 'closealllevel', ship: elem, key: elem.ship, state: null, sub: null });
    // this.broadcastInfo({ src: 'tag', event: 'deactivelist', key: elem.ship, state: null, sub: null });
  }
  iframeList = [];
  regLink(link) {
    let idx = this.iframeList.findIndex(e => e.ship == link.ship);
    if (idx > -1) {
      this.iframeList.splice(idx, 1);
      this.popupZindex += 10;
      this.iframeList.push(link);
      setTimeout(() => {
        document.getElementById('ifamepopup_' + link.ship).style.zIndex = this.popupZindex;
        document.getElementById('ifameObjTag_' + link.ship).innerHTML = '<iframe width="100%" height="100%" src="' + link.register + '"></iframe>';
      }, 500);
    } else {
      this.iframeList.push(link);
      setTimeout(() => {
        document.getElementById('ifamepopup_' + link.ship).style.zIndex = this.popupZindex;
        document.getElementById('ifameObjTag_' + link.ship).innerHTML = '<iframe width="100%" height="100%" src="' + link.register + '"></iframe>';
      }, 500);
    }
  }
  objectTagClose(elem) {
    let idx = this.iframeList.findIndex(e => e.ship == elem.ship);
    if (idx > -1) {
      // this.popupZindex -= this.popupZindex;
      this.iframeList.splice(idx, 1);
    }
    let index = this.iframeList.findIndex(e => e.ship == elem.ship);
    if (index > -1) {

      this.iframeList.splice(index, 1);
    }
  }
  renderLink(link) {
    setTimeout(() => {
      return link;
    }, 100);

  }
  expand(elem) {
    let idx = this.tagMaxList.findIndex(e => e.ship == elem.ship);
    if (idx > -1) {
      // this.popupZindex -= this.popupZindex;
      this.tagMaxList[idx].expand = !this.tagMaxList[idx].expand;
    }
  }
  triggerLevel(elem, n = null) {
    this.tagMini(elem);
    this.broadcastInfo({ src: 'tag', event: 'nextlevel', ship: elem, key: elem.ship, state: null, sub: null });
  }
  getContrastCol(hexcolor) {
    hexcolor = hexcolor.replace("#", "");
    if (hexcolor.length === 3) {
      hexcolor = hexcolor + hexcolor;
    }
    var r = parseInt(hexcolor.substr(0, 2) || "00", 16);
    var g = parseInt(hexcolor.substr(2, 2) || "00", 16);
    var b = parseInt(hexcolor.substr(4, 2) || "00", 16);
    var col = ((r * 299) + (g * 587) + (b * 114)) / 1000;
    return (col >= 128) ? 'black' : 'white';
  }
  getBGLabel(state) {
    let idx = this.statusList.findIndex(e => e.status == state);
    if (idx > -1) {
      var color={'background-color': this.statusList[idx].color + 'bd'}
       
      return color;
    }
  }
  getTextLabel(state) {
    let idx = this.statusList.findIndex(e => e.status == state);
    if (idx > -1) {
      var color={'color':this.getContrastCol(this.statusList[idx].color)}
       
      return color;
    }
  }
  riskManagePanelClose(){
    this.riskMangState = false; 
  }
  // @HostListener('document:mouseover', ['$event'])
  // mouseover(event) {
  //     if(event.target.matches('.expandHover')) {
  //         // alert('hover to editor div')
  //         let elem = this.tagMaxList[event.target.id.split('_')[1]]
  //       // console.log(event)
  //         this.expand(elem)
  //       //   var elem = document.getElementsByClassName('side_panel');
  //       // elem[0].classList.add('open');
  //     }
  // }
}
