import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, BehaviorSubject } from 'rxjs';

@Injectable()
export class ConfigService {
  accessToken: any;
  refershToken: any;
  private config: any;
  project_config: any;
  api_config: any;

  private _jsonURL = '../assets/project.config.json';

  constructor(private http: HttpClient) {
  }

  getJSON(url = null): Observable<any> {
    return this.http.get(url || this._jsonURL);
  }

  getProjectConfig() {
    let self = this;
    return new Promise((resolve, reject) => {
      if (self.config) {
        resolve(self.config)
      } else {
        self.getJSON().subscribe(data => {
          self.config = data[data["config"]];
          this.api_config = data;
          this.project_config = data[data["config"]];
          console.log("data config", this.project_config)
          resolve(self.config)
        },
          error => reject(error)
        );
      }
    })
  }

  loadFile(url) {
    let self = this;
    return new Promise((resolve, reject) => {
      self.getJSON(url).subscribe(data => {
        resolve(data)
      },
        error => reject(error)
      );
    })
  }

  /* * *
  * post api call
  * * */
  pullData(url, data): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(data);
    return this.http.post(url, body, { 'headers': headers })
  }

  /* * *
  * method for get Json data from server
  * * */
  getData(url, data) {
    let self = this;
    return new Promise((resolve, reject) => {
      self.pullData(url, data).subscribe(data => {
        resolve(data)
      },
        error => reject(error)
      );
    })
  }

  /* * * * * *
  * excel upload
  * * * * * */
  async uploadExcelData(url, formData, cbck) {
    var xhr = new XMLHttpRequest();
    var reponse = "";
    let auth = window.origin.includes("navyapp.canadacentral.cloudapp.azure.com") ? 'no' : this.api_config['authentication']; 
    xhr.open('POST', url, true);
    xhr.setRequestHeader("Authorization", "Bearer " + this.accessToken);
    xhr.setRequestHeader("Authentication", auth);
    xhr.onload = function (e) {
      reponse = xhr.responseText;
      cbck(reponse);
    };
    xhr.onreadystatechange = function (aEvt) {
      if (xhr.readyState == 4) {
        if (xhr.status == 200) {
          // alert("Excel Uploaded Successfully !!");
        } else {
          // alert("Excel Upload Falied !!");
        }
      }
    };
    xhr.send(formData);
  }


  /* *
  * material status api
  * */
  getApiCall(url): Observable<any> {
    return this.http.get(url);
  }
  postData(url, data): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(data);
    return this.http.post(url, body, { 'headers': headers })
  }
  putData(url, data): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    return this.http.put(url, data, { 'headers': headers })
  }
  deleteData(url: string): Observable<any> {
    return this.http.delete(url)
  }
  getMaterialStatusData() {
    let self = this;
    return new Promise((resolve, reject) => {
      self.getApiCall(this.project_config.materialApi).subscribe(data => {
        resolve(data);
      },
        error => reject(error)
      );
    })
  }
  createMaterialStatus(data) {
    let self = this;
    return new Promise((resolve, reject) => {
      self.postData(this.project_config.materialApi, data).subscribe(data => {
        resolve(data);
      },
        error => reject(error)
      );
    })
  }
  updateMaterialStatus(id: string, data) {
    let self = this;
    return new Promise((resolve, reject) => {
      self.putData(this.project_config.materialApi + id, data).subscribe(data => {
        resolve(data);
      },
        error => reject(error)
      );
    })
  }
  deleteMaterialStatus(id: string) {
    let self = this;
    return new Promise((resolve, reject) => {
      self.deleteData(this.project_config.materialApi + id).subscribe(data => {
        resolve(data);
      },
        error => reject(error)
      );
    })
  }


}
