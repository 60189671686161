import { ChangeDetectorRef, Component, EventEmitter, HostListener, Inject, OnInit, Output } from '@angular/core';
import { ConfigService } from '../services/config.service';
import { ControllerService } from '../services/controller.service';
import { CommunicationService } from "../services/communication.service";
import * as  shipData from "../../assets/appData/shipObjects.json";
import * as  shipConfig from "../../assets/appData/shipConfig.json";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LanguageService } from '../services/language.service';
import { TranslateService } from '@ngx-translate/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import * as ProjectConfig from '../../assets/project.config.json';
import * as io from 'socket.io-client';


export interface DialogData {
  data: string;
  error: any;
  type: any;
}
// export interface ship {
//   ship: string;
// }
// export interface KHA {
//   shipname: string;
// }

@Component({
  selector: 'app-landing-screen',
  templateUrl: './landing-screen.component.html',
  styleUrls: ['./landing-screen.component.scss']
})

export class LandingScreenComponent implements OnInit {
  @Output() materialCloseToggle: EventEmitter<any> = new EventEmitter<any>();
  projectConfig = ProjectConfig['default'];

  userId: any = '';
  userName: any = '';
  accountEmail: any = '';
  loginInfo: any = {};

  pdfPageNo: any;
  showOverlayPdfViewer: boolean = false;
  ernDefaultLoad: boolean = false;
  ernDisplay: boolean = false;
  aposDisplay: boolean = false;
  aopsDefaultLoad: boolean = false;
  mvScreenType: any = "";
  selectedCompartment: any = "";

  loadScreen: any = false;
  materialPopup: any = false
  shipPanelToggle: any = false;
  activeClassMaterial: any;
  activeKhaIdx: any;
  loadMaterial: any;
  showINSABannerScreen: boolean = false;

  nsscRightPanelOpen: boolean = false;
  nsscCoursePanelOpen: boolean = false;
  screenType: any = "";

  process_txt = "Processing...";
  materialLabel: any;
  pcsDemos = []
  selectedDemo = this.pcsDemos[0] || "";
  shipData: any = shipData['default']['ship'];
  shipConfig: any = shipConfig['default'];
  shipMetaData: any[];
  showLoading = false;
  disableTrans: boolean = false;
  shipDetailPanelData: any = '';
  shipDetailPanelState: boolean = false;
  showOverlayPopup: boolean = false;
  activeComp: any;
  shipHazardList: any = "";
  shipOrderKey = 'default';
  shipClassList = [];
  statusList: any = [];
  selectedKha: any = 'buoyancy';
  showAppLoading: boolean = false;
  showBlockLoading: boolean = false;
  controlPanelState: boolean = false;
  showExternalTools: boolean = true;
  isAppLoaded: boolean = false;
  shipDragState: boolean = true;
  hazardList: any = [];
  showPathState: any = false;
  shipMetaDataList: any;
  compSelected: any = '';
  ernSelected: any = '';
  khaSelected: any = '';
  stateObj: any = { ern: { showPathState: false }, nsc: { showPathState: false } };
  classOrderList = [
    { 'class': 'Victoria', 'order': 1, 'position': { x: 0, y: 7, z: -130 } },//50 high=75,low=-75
    { 'class': 'Halifax', 'order': 2, 'position': { x: 0, y: 0, z: -13 } },//50 high=250,low=-300
    { 'class': 'Dewolf', 'order': 3, 'position': { x: -0, y: 0, z: 130 } },//50 high=125,low=-125
    { 'class': 'Kingston', 'order': 4, 'position': { x: 0, y: 0, z: 240 } },//50 high=250,low=-300
    { 'class': 'Orca', 'order': 5, 'position': { x: 0, y: 0, z: 330 } },
    { 'class': 'CSC', 'order': 6, 'position': { x: 0, y: 0, z: 480 } },
    { 'class': 'Protecteur', 'order': 7, 'position': { x: 0, y: 0, z: 700 } }
  ]
  coastArea: any = 'fleet'
  coastAreaList = [
    { shipcode: "SSK 876", coast: 'pacific' },
    { shipcode: "SSK 877", coast: 'atlantic' },
    { shipcode: "SSK 878", coast: 'pacific' },
    { shipcode: "SSK 879", coast: 'pacific' },

    { shipcode: "FFH 330", coast: 'atlantic' },
    { shipcode: "FFH 331", coast: 'pacific' },
    { shipcode: "FFH 332", coast: 'atlantic' },
    { shipcode: "FFH 333", coast: 'atlantic' },
    { shipcode: "FFH 334", coast: 'pacific' },
    { shipcode: "FFH 335", coast: 'pacific' },
    { shipcode: "FFH 336", coast: 'atlantic' },
    { shipcode: "FFH 337", coast: 'atlantic' },
    { shipcode: "FFH 338", coast: 'pacific' },
    { shipcode: "FFH 339", coast: 'atlantic' },
    { shipcode: "FFH 340", coast: 'atlantic' },
    { shipcode: "FFH 341", coast: 'pacific' },

    { shipcode: "AOPV 430", coast: 'pacific' },
    { shipcode: "AOPV 431", coast: 'pacific' },
    { shipcode: "AOPV 432", coast: 'pacific' },
    { shipcode: "AOPV 433", coast: 'pacific' },
    { shipcode: "AOPV 434", coast: 'pacific' },
    { shipcode: "AOPV 435", coast: 'pacific' },

    { shipcode: "MM 700", coast: 'atlantic' },
    { shipcode: "MM 701", coast: 'atlantic' },
    { shipcode: "MM 702", coast: 'pacific' },
    { shipcode: "MM 703", coast: 'pacific' },
    { shipcode: "MM 704", coast: 'atlantic' },
    { shipcode: "MM 705", coast: 'pacific' },
    { shipcode: "MM 706", coast: 'pacific' },
    { shipcode: "MM 707", coast: 'atlantic' },
    { shipcode: "MM 708", coast: 'atlantic' },
    { shipcode: "MM 709", coast: 'pacific' },
    { shipcode: "MM 710", coast: 'pacific' },
    { shipcode: "MM 711", coast: 'atlantic' },

    { shipcode: "PCT 55", coast: 'pacific' },
    { shipcode: "PCT 56", coast: 'pacific' },
    { shipcode: "PCT 57", coast: 'pacific' },
    { shipcode: "PCT 58", coast: 'pacific' },
    { shipcode: "PCT 59", coast: 'pacific' },
    { shipcode: "PCT 60", coast: 'pacific' },
    { shipcode: "PCT 61", coast: 'pacific' },
    { shipcode: "PCT 62", coast: 'pacific' },

    { shipcode: "CSC", coast: 'pacific' },

    { shipcode: "AST", coast: 'pacific' },
    { shipcode: "AOR 509", coast: 'pacific' },
    { shipcode: "AOR 510", coast: 'pacific' }
  ];
  languageSelected: any = 'en';
  show_ern = false;
  initialize_ern = false;
  initialize_aops = false;
  broadcastSubs
  selectedErnNo: any = '';
  khaList: any;
  compartmentList: any;
  shipSearchState: boolean = false;
  materielSearchState: boolean = false;
  compFilterState: boolean = false;
  nscSearchState: boolean = false;
  certificationState: boolean = false;
  compTabState: boolean = false;
  shipSearchData: any = '';
  shipSearchControl = new FormControl();
  shipFilteredOptions: Observable<any>;
  ernFilterState: any = false;
  khaFilterState: boolean = false;
  shipInitLoading: boolean = false;
  ERN_list: any = [
    { name: "ERN Tree", key: 'ern', status: 'active' },
    { name: "Damage Control Systems", key: 'ern-damagecontrol', status: 'active' },
    { name: "Propulsion System", key: 'ern-propulsion', status: 'active' },
    { name: "Electrical Power", key: 'ern-electricalpower', status: 'active' },
    { name: "Marine", key: 'ern-marine', status: 'active' },
    { name: "Deck & Hull Equipment", key: 'ern-deckhull', status: 'active' }
  ]
  broadcastIframeSubs: any;
  slmsMode:any=false;
  // khaFilteredOptions: Observable<ship[]>;
  // ernFilteredOptions: Observable<ship[]>;
  // compFilteredOptions: Observable<ship[]>;
  constructor(public dialog: MatDialog,
    private ctrlServ: ControllerService,
    private configService: ConfigService,
    private CommunicationServ: CommunicationService,
    private cdr: ChangeDetectorRef,
    private translate: TranslateService, private language: LanguageService
  ) {
    this.shipHazardList = this.ctrlServ.shipHazardList;
    this.selectedKha = this.ctrlServ.selectedKha;
    this.statusList = this.ctrlServ.statusList;
    this.isAppLoaded = this.ctrlServ.isAppLoaded;
    this.hazardList = this.ctrlServ.service['hazardList'];
    this.language.languageChange.subscribe(props => {
      console.log("language", props);
      this.translate.use(props);
    });
  }
  getDropdownList() {
    this.ctrlServ.comps.forEach(e => {
      if (e.component == 'NSC KHA') {
        this.khaList = e.compList;
      }
      if (e.component == "Compartments") {
        this.compartmentList = e.compList;
      }

    })
  }
  ChangeCoast(e) {
    this.coastArea = e;
    this.coastAreaMapping();
    this.arrangeShipData(this.shipMetaData, true);
    this.mappingClass();
    this.broadcastInfo({ src: 'landingScreen', event: 'navyApp', data: this.shipMetaData, sub: null });
  }

  // @HostListener('window:message', ['$event'])
  // onmessage(e) {
  //   console.log(e)
  //   if (e) {
  //     if (e.data.for == "Translate") {
  //       this.languageSelected = e.data.data;
  //     }
  //     if (e.data.for == "loginInformation") {     
  //       this.userId = e.data.data['userId'];
  //       this.userName = e.data.data['userName'];
  //       this.accountEmail = e.data.data['accountEmail'];
  //       this.loginInfo = e.data.data; 
  //       this.configService.accessToken = e.data.data['accessToken'];
  //       this.configService.refershToken = e.data.data['refershToken'];
  //       console.log("navyscs loginInformation ");
  //       if(this.userId && this.userName){
  //         this.init_socket(); 
  //       }               
  //     }
  //   }
  // }

  broadcastInfo(data: any) {
    this.CommunicationServ.getInstance().next(data);
  }

  broadcastIframeInfo(data: any) {
    this.CommunicationServ.createIframeInstance(data);
  }

  private _shipFilter(value: any): any[] {
    const filterValue = value.toLowerCase();
    return this.shipMetaData.filter(option => option.ship.toLowerCase().includes(filterValue));
  }

  initIframeMessage() {  
    this.broadcastIframeInfo({ iframeConnection: 'scs-connection', event: 'scs-init', data: '', key: "" });
  }

  ngOnInit() {
    this.initIframeMessage();    
    this.shipFilteredOptions = this.shipSearchControl.valueChanges.pipe(
      startWith(''),
      map(value => this._shipFilter(value))
    );
    this.loadData();
    this.getQueryParamValues();
    this.broadcastSubs = this.CommunicationServ.getInstance()
      .subscribe((data: any) => {
        if (data.src === "networkmodel") {
          if (data.event === 'init-ern') {
            this.initialize_ern = true;
          }
          if (data.event === 'updateKha') {
            if (data['data']) {
              this.selectedKha = data['data'];
            }
          }
          if(data.event == 'nscAlert'){
            this.openDialog(data.data)
          }
        }
        if (data.src == "sidepanel") {
          if (data.event == 'init_ern') {
            if (this.ctrlServ.selectedKha) {
              this.showAppLoading = true;
              this.loadPortData('pyramid');
              this.ctrlServ.isNscAppLoaded = false;
              this.ctrlServ.ernDisplay = false;
              this.checkNSCAppLoaded();
            } else {
              this.ernDisplay = true;
              this.ernDefaultLoad = true;
              this.ctrlServ.ernDisplay = true;
              this.loadPortData(data.data);
            }
          }
          if (data.event === 'changensccomp') {
            let key = data['data'];
            this.selectedKha = key['key'].split(" ").join("").toLowerCase();
            this.ctrlServ.selectedKha = this.selectedKha;           
          }
          if (data.event == 'update-MV-ScreenType') {
            if(data['data']){
              this.mvScreenType = data['data'];
            }
          }
        }
        if (data.src === "khaservice") {
          if (data.event === 'update-compartments') {
            // this.comps[1].compList = data['data'];
            // console.log(this.ctrlServ.comps,',',data['data']);
            this.getDropdownList();
          }
          if (data.event == 'showMatObject') {

          }
        }
        if (data.src == 'material') {
          if (data.event == 'init_Load') {
            console.log('init_Load', data);
            this.shipInitLoading = data.state;
          }
          if (data.event == 'material_init_load') {
            console.log('material_init_load', data);
            this.shipInitLoading = data.state;
          }
          if(data.event=='pushNotification'){
            let message = data['data'];
            if(message){
              this.sendInfo(message);
            }           
          }
        }
      })

      this.broadcastIframeSubs = this.CommunicationServ.getIframeInstance()
      .subscribe((data: any) => {
        if (data.iframeConnection === "scs-connection") {
          if (data.event === 'Translate') {
            this.languageSelected = data.data;
          }
          if (data.event === 'loginInformation') {
            this.userId = data.data['userId'];
            this.userName = data.data['userName'];
            this.accountEmail = data.data['accountEmail'];
            this.loginInfo = data.data; 
            this.configService.accessToken = data.data['accessToken'];
            this.configService.refershToken = data.data['refershToken'];
            console.log("navyscs loginInformation ");
            if(this.userId && this.userName){
              this.init_socket(); 
            }               
          }        
        }
      })
  }

  /* * *
   * method for if NSC component loaded or not
   * * */
  setInterval;
  checkNSCAppLoaded() {
    let animation = (timer) => {
      if (this.ctrlServ.isNscAppLoaded) {
        this.broadcastInfo({ src: 'sidepanel', event: 'toggle_network_tree', data: true, key: "" });
        this.showAppLoading = !true;
        cancelAnimationFrame(this.setInterval);
      } else {
        this.setInterval = window.requestAnimationFrame(animation);
      }
    }
    this.setInterval = window.requestAnimationFrame(animation);
  }

  /**
   * get query params key value format
   */
  getKeyValueQueryParams(queryParams) {
    let keyvalue = {};
    queryParams.map(query => {
      let params = query.split("=")
      if (params.length > 0) {
        keyvalue[params[0].trim().toLowerCase()] = params[1];
      }
    })
    return keyvalue;
  }
  queryParams: any;
  getQueryParamValues() {
    this.nsscRightPanelOpen = false;
    this.nsscCoursePanelOpen = false;
    if (window.location.search) {
      this.queryParams = window.location.search.split('?')[1].split('&');
      for (var i = 0; i < this.queryParams.length; i++) {
        let searchUrl = this.queryParams[i].split("=");
        let url = searchUrl[1].toLowerCase();
        console.log("screenType :", searchUrl[0]);
        let keyValue = this.getKeyValueQueryParams(this.queryParams);
        console.log("queryParams key value:", keyValue);
        this.ctrlServ.ernDisplay = false;
        if (searchUrl[0] == 'screenType') {
          if (url == "fleet" || url == "material" || url == "nsc" || url == "ern" || url == "ern_kha" || url == "aops_kha" || url == "nssc_insa") {
            var loc = url == "fleet" ? 'fleet' : url == "material" ? "material" : url == "nsc" ? "pyramid" : url == "nssc_insa" ? "pyramid" : '';
            this.screenType = url;
            if (url === 'ern') {
              loc = url;
              this.ernDisplay = true;
              this.ernDefaultLoad = true;
              this.ctrlServ.ernDisplay = true;
            }
            if (url === 'aops_kha' && keyValue['aopscategory']) {
              loc = 'aops';
              this.aposDisplay = true;
              this.aopsDefaultLoad = true;
              this.ctrlServ.ernDisplay = false;
              this.ctrlServ.selectedAops = keyValue['aopscategory'].split(" ").join("").toLowerCase();
              setTimeout(() => {
                this.broadcastInfo({ src: 'networkmodel', event: 'updateAOPS', data: this.ctrlServ.selectedAops, key: '' });
              }, 1000);
            }
            if (url == "ern_kha" && keyValue['erncategory']) {
              loc = 'ern';
              if (keyValue['ernnumber']) {
                this.selectedErnNo = keyValue['ernnumber'];
              }
              this.ernDisplay = true;
              this.ctrlServ.ernDisplay = true;
              this.ernDefaultLoad = true;
              this.stateObj['ern']['showPathState'] = false;
              this.showPathState = false;
              this.ctrlServ.selectedErn = keyValue['erncategory'].split(" ").join("").toLowerCase();
              setTimeout(() => {
                this.broadcastInfo({ src: 'networkmodel', event: 'updateErn', data: this.ctrlServ.selectedErn, key: '' });
              }, 1000);
            }
            if (url == "nsc" && keyValue['kha']) {
              this.selectedKha = keyValue['kha'].split(" ").join("").toLowerCase();
              this.ctrlServ.selectedKha = keyValue['kha'].split(" ").join("").toLowerCase();
            }
            if (url == "nssc_insa") {
              this.ctrlServ.updateNSSCHazardData();
              loc = 'insa-banner';
              if (keyValue['kha']) {
                this.selectedKha = keyValue['kha'].split(" ").join("").toLowerCase();
                this.ctrlServ.selectedKha = keyValue['kha'].split(" ").join("").toLowerCase();
              } else {
                this.selectedKha = 'insa-buoyancy';
                this.ctrlServ.selectedKha = 'insa-buoyancy';
              }
              this.nsscRightPanelOpen = false;
              this.nsscCoursePanelOpen = true;
            }
            if (url == "material" && keyValue['kha']) {
              if (keyValue['comp']) {
                this.ctrlServ.selectedSysComp = decodeURIComponent(keyValue['comp']).split(",");
              } else {
                this.ctrlServ.selectedSysComp = [];
              }
              this.selectedKha = keyValue['kha'].split(" ").join("").toLowerCase();
              this.ctrlServ.selectedKha = keyValue['kha'].split(" ").join("").toLowerCase();
              this.mvScreenType = 'system';
              this.loadMaterialComponent();
            }
          } else {
            if (url.indexOf('commanding') > -1) {
              loc = 'fleet';
              this.shipSearchState = true;
            }
            if (url.indexOf('fleet_commanding') > -1) {
              loc = 'fleet';
              this.shipSearchState = true;
            }
            if (url.indexOf('certification') > -1) {
              loc = 'pyramid';
              this.nscSearchState = true;
              this.khaFilterState = true;
              this.certificationState = true
            }
            if (url.indexOf('nsc_ern') > -1) {
              loc = 'pyramid';
              this.nscSearchState = true;
              this.ernFilterState = true
            }
            if (url.indexOf('nsc_kha') > -1) {
              loc = 'pyramid';
              this.nscSearchState = true;
              this.khaFilterState = true;
            }
            if (url.indexOf('materiel_kha') > -1) {
              loc = 'material';

              if (keyValue['comp_name'] && keyValue['message']) {
                this.ctrlServ.selectedSysComments['name'] = keyValue['comp_name'];
                this.ctrlServ.selectedSysComments['message'] = decodeURIComponent(keyValue['message']);
              } else {
                this.ctrlServ.selectedSysComments = {};
              }

              this.ctrlServ.selectedSysComp = [];
              this.mvScreenType = 'system';
              this.loadMaterialComponent();
            }
            if (url.indexOf('materiel_comp') > -1) {
              if (keyValue['comp_code'] && keyValue['comp_name'] && keyValue['message']) {
                this.selectedCompartment = keyValue['comp_code'];
                this.ctrlServ.selectedCompComments['name'] = keyValue['comp_name'];
                this.ctrlServ.selectedCompComments['message'] = decodeURIComponent(keyValue['message']);
              } else if (keyValue['comp_code']) {
                this.selectedCompartment = keyValue['comp_code'];
                this.ctrlServ.selectedCompComments = {};
              } else {
                this.selectedCompartment = '';
                this.ctrlServ.selectedCompComments = {};
              }

              loc = 'material';
              this.ctrlServ.selectedSysComp = [];
              this.mvScreenType = 'compartment';
              this.loadMaterialComponent();
              this.compTabState = true;
            }
            if (url.indexOf('materiel_slms') > -1) {

              this.loadScreen = false;
              loc = 'material';
              this.activeComp = 'ship';
              this.showOverlayPopup = true;
              this.activeClassMaterial = 'Halifax';


              this.loadMaterialComponent();
              // setTimeout(() => {

              // this.selectedKha = url;
              // this.ctrlServ.selectedKha = this.selectedKha;
              // setTimeout(() => {
              // this.onLoadComponent({ship:'',comp:'ship',mode:null});
              // this.selectedKha='buoyancy';
              // this.ctrlServ.selectedKha=this.selectedKha;
              this.onLoadComponent({ ship: '', comp: 'ship', mode: null });
              // let idx = this.hazardList.findIndex(e => e.area.toLowerCase() == this.selectedKha)
              // this.broadcastInfo({ src: 'main', event: 'selectedKhaMateriel', key: this.hazardList[idx], state: null, sub: null });
              this.slmsMode = true;
              // }, 2000);
              // }, 1500);         

              return;
            }
            if (url.indexOf('materiel_commanding_kha') > -1) {
              loc = 'material';
              this.ctrlServ.selectedSysComp = [];
              this.mvScreenType = 'system';
              this.materielSearchState = false;
              this.khaFilterState = true;
              this.loadMaterialComponent();
            }
            if (url.indexOf('materiel_commanding_comp') > -1) {
              loc = 'material';
              this.ctrlServ.selectedSysComp = [];
              this.mvScreenType = 'compartment';
              this.loadMaterialComponent();
              // this.materielSearchState = true;
              // this.compFilterState = true;
              this.shipSearchState = false;
              this.compTabState = true;
              // this.getDropdownList()

            }
          }
          if (loc != '') {
            console.log(loc)
            this.loadPortData(loc);
          }
        } else {
          let searchUrl = this.queryParams[i].split("=");
          let url = searchUrl[1].toLowerCase().toLowerCase().replace(/%20/g, ' ');
          if (searchUrl[0].toLowerCase() == 'kha') {
            this.selectedKha = url;
            this.ctrlServ.selectedKha = this.selectedKha;
            setTimeout(() => {
              let idx = this.hazardList.findIndex(e => e.area.toLowerCase() == this.selectedKha)
              this.broadcastInfo({ src: 'main', event: 'selectedKhaMateriel', key: this.hazardList[idx], state: null, sub: null });
            }, 2000);
          }
          if (searchUrl[0].toLowerCase() == 'view') {
            // this.selectedKha='buoyancy';
            // this.ctrlServ.selectedKha = this.selectedKha;
            setTimeout(() => {
              this.slmsMode = true;
            }, 1000);
          }
        }
      }
    } else {
      /**
       * if no query params mens its taking insa
       */
      this.ctrlServ.updateNSSCHazardData();     
      this.selectedKha = 'insa-buoyancy';
      this.ctrlServ.selectedKha = 'insa-buoyancy';
      this.nsscRightPanelOpen = false;
      this.nsscCoursePanelOpen = true;
      this.loadPortData('insa-banner');
    }
    this.cdr.detectChanges();
  }

  ngAfterContentChecked() {  
    this.cdr.detectChanges();
  } 

  /* * * * *
  * init socket service
  * * * * * */
  socket: any;
  init_socket() {
    console.log('init_socket');
    var _this = this;
    let project_config = this.projectConfig[this.projectConfig['config']];
    let socketPath = project_config.socketport || 'http://localhost:3000';
    if (_this.userId) {
      let socketport = `${socketPath}?userId=${_this.userId}&username=${_this.userName}`;
      _this.socket = io.connect(socketport);
      _this.socket.on('connect', onConnect);
      _this.socket.on('receiveMsg', onMessage);
      _this.socket.on('disconnect', onDisconnect);
      _this.socket.on('connect_error', onError);
      _this.socket.on('reconnect_error', onError);

      function onConnect(evt) {
        console.log("socket connected!!");
        writeToScreen("CONNECTED");

        _this.socket.on("getInfo::SCS", message => {
          _this.getInfo(message);
        });
      }

      function onMessage(message) {
        console.log("receiveMsg :", message);
      }
      function onDisconnect(evt) {
        writeToScreen("DISCONNECTED");
        console.log("socket disconnected!!");
      }

      function onError(message) {
        writeToScreen('<span style="color: red;">ERROR:</span> ' + message);
      }

      function doSend(message) {
        writeToScreen("SENT: " + message);
        _this.socket.emit('sendMsg', message);
      }

      function writeToScreen(message) {
        var pre = document.createElement("p");
        pre.style.wordWrap = "break-word";
        pre.innerHTML = message;
        //output.appendChild(pre);
      }
    }
  }

  /**
   * method send info broadcast message through socket
   */
  sendInfo(obj) {
    if (this.socket !== undefined) {
      obj['userId'] = this.userId;
      obj['username'] = this.userName;
      obj['email'] = this.accountEmail;
      obj['app'] = 'scs';       
      this.socket.emit('sendInfo::SCS', obj);
    }
  }

  /**
   * method recive info broadcast message through socket
   */
  getInfo(data) {
    if (this.userId !== data['userId']) {

      if (data['app'] === 'scs' && data['type'] === 'material-status-change') {
        console.log("getInfo ", data);
        // let message = data['khasysname'] + " system component " + data['desc'] + " (" + data['code'] + ") " + "status changed as " + data['material'];
        this.broadcastInfo({ src: 'main', event: 'updateMaterialStatus', data: data, sub: null });
        if (data['message'].trim()) {
          this.openDialog(data['message']);
        }
      }

      if (data['app'] === 'scs' && data['type'] === 'compartment-comments') {
        console.log("getInfo ", data);
        if (data['message'].trim()) {
          this.openDialog(data['message']);
        }
      }

    }
  }

  /* * *
  * load ship data
  * * */
  loadData(def = false) {
    this.shipMetaData = [];
    let shipConfig = this.shipConfig[this.shipOrderKey];
    this.shipData.forEach(ship => {
      let shipName = ship['ship'].toLowerCase().split(" ").join("").replace(".", "").replace("'", "");
      Object.keys(shipConfig).forEach((key) => {
        let keyName = key.toLowerCase().split(" ").join("").replace(".", "").replace("'", "");
        let value = shipConfig[key].toLowerCase().split(" ").join("").replace(".", "").replace("'", "");
        if (shipName === keyName) {
          let statusArr = this.statusList.filter(el => el.id === value);
          if (statusArr.length > 0) {
            ship['_state'] = statusArr[0]['status'];
            this.shipMetaData.push(ship);
          }
        }
      });
    });
    console.log(this.shipMetaData);
    this.shipMetaDataList = this.shipMetaData;
    this.coastAreaMapping();
    this.arrangeShipData(this.shipMetaData, def);
    this.mappingClass();
  }
  coastAreaMapping() {
    this.shipMetaDataList.forEach((element, i) => {
      this.coastAreaList.forEach(ele => {
        if (element.number == ele.shipcode) {
          this.shipMetaDataList[i]['coast'] = ele.coast;
        }
      })
    });
    // this.shipMetaDataList=this.shipMetaData;
    this.filterCoast();
  }

  filterCoast() {
    var filterCoast = [];
    if (this.coastArea == 'fleet') {
      this.shipMetaData = this.shipMetaDataList;
    } else {
      this.shipMetaDataList.forEach(element => {
        if (element.coast == this.coastArea) {
          filterCoast.push(element)
        }
      });
      this.shipMetaData = filterCoast;
    }
    console.log('filterCoast', this.shipMetaData)
  }

  /* * *
  * Ship data color status based arranged
  * * */
  arrangeShipData(e, def) {
    var arrArg = []
    this.statusList.forEach(state => {
      e.forEach(element => {
        if (element['_state'] == state.status) {
          arrArg.push(element);
        }
      });
    });
    this.shipMetaData = arrArg;
    if (def) {
      this.broadcastInfo({ src: 'home', event: 'updateshiplist', key: "", data: this.shipMetaData });
    }
  }

  /* * * * *
  * Class mapping ship meta data
  * * * * * */
  mappingClass() {
    this.shipClassList = [];
    if (this.shipMetaData.length > 0) {
      this.classOrderList.forEach(ele => {
        this.shipMetaData.forEach(element => {
          if (this.shipClassList.indexOf(element.class) == -1) {
            if (ele.class == element.class) {
              this.shipClassList.push(element.class);
            }
          }
        });
      })
    }
  }

  /* * *
  * change ship order
  * * */
  onChangeShipOrder(key) {
    this.shipOrderKey = key;
    // this.shipDetailPanelData = '';
    this.shipDetailPanelState = false;
    this.loadData(true);
  }

  /* * *
  * change component
  * * */
  loadPortData(e) {
    this.shipInitLoading = false;
    if (e == 'fleet') {
      this.loadScreen = false;
      this.activeComp = 'fleet';
      this.shipPanelToggle = true;
    } else if (e == 'pyramid') {
      this.showBlockLoading = true;
      this.ctrlServ.ernDisplay = false;
      this.loadScreen = false;
      let data = { key: '', comp: 'nsc' };
      this.loadMaterial = data;
    } else if (e == 'ern') {
      this.showBlockLoading = true;
      this.ctrlServ.ernDisplay = true;
      this.loadScreen = false;
      let data = { key: '', comp: 'nsc', mode: 'ern' };
      this.loadMaterial = data;
    } else if (e == 'aops') {
      this.showBlockLoading = true;
      this.ctrlServ.ernDisplay = true;
      this.loadScreen = false;
      let data = { key: '', comp: 'nsc', mode: 'aops' };
      this.loadMaterial = data;
    } else if (e == 'material') {
      this.showBlockLoading = true;
      this.loadScreen = false;
      this.materialPopup = true;
      this.materialLabel = "Materiel";
    } else if (e == 'insa-banner') {
      this.showBlockLoading = true;
      this.ctrlServ.ernDisplay = false;
      this.loadScreen = false;
      this.showINSABannerScreen = true;
      // let data = { key: '', comp: 'nsc' };
      // this.loadMaterial = data;
    }

    this.cdr.detectChanges();
    this.broadcastInfo({ src: 'landing', event: 'activeComp', data: e, key: "" });
    this.broadcastInfo({ src: 'landingShip', event: 'activeComps', data: e, key: "" });
    setTimeout(() => { this.showBlockLoading = false }, 1000);
  }

  /* * *
  * active mat class
  * * */
  activeMatClass(e) {
    this.activeClassMaterial = e;
  }

  /* * *
  * active kha menu
  * * */
  activeMatKha(e) {
    this.activeKhaIdx = e;
  }

  /* * *
  * load material component
  * * */
  loadMaterialComponent(def = false) {
    if (this.activeComp == 'ern') {
      return
      // var data = { key: this.hazardList[this.activeKhaIdx], comp: 'nsc' };
    } else {
      this.shipInitLoading = true;
      this.materialPopup = false;
      this.showBlockLoading = true;
      var data = { key: this.hazardList[this.activeKhaIdx], comp: 'ship' };
    }

    if (def) {
      this.mvScreenType = 'system';
    }

    this.loadMaterial = data;
    setTimeout(() => { this.showBlockLoading = false }, 600);
  }

  /* * *
  * method for material button enable
  * * */
  getMaterialEnable() {
    if (this.activeClassMaterial != undefined) {
      return false;
    } else {
      return true
    }
  }

  /* * *
  * Material close
  * * */
  materialClose() {
    this.materialPopup = false;
    this.loadScreen = true;
    delete this.activeKhaIdx;
    delete this.loadMaterial;
    this.materialCloseToggle.emit()
  }

  /* * *
  *  class img
  * * */
  getClassImg(shipClass) {
    return shipClass == 'Halifax' ? 'HAL' : shipClass == 'Dewolf' ? 'HDW' : shipClass == 'Kingston' ? 'KIN' : shipClass == 'Victoria' ? 'VIC' : shipClass == 'Orca' ? 'ORC' : shipClass == 'Asterix' ? 'AST' : shipClass == 'Protecteur' ? 'PRT' : shipClass == 'CSC' ? 'csc' : '';
  }

  /* * *
  * ship detail panel
  * * */
  shipDetailPanel(data) {
    if (data.checked) {
      this.shipDetailPanelData = data.data;
      if (data['comp'] === 'nsc') {
        this.shipDetailPanelState = false;
        this.broadcastInfo({ src: 'home', event: 'loadnsccomp', data: this.shipDetailPanelData, key: '' });
      } else {
        this.shipDetailPanelState = true;
      }
    } else {
      // this.shipDetailPanelData = '';
      this.shipDetailPanelState = false;
    }
  }

  /* * *
  * load component for overlay popup
  * * * */
  onLoadComponent(e) {
    if (e.mode && e.mode === 'ern') {
      this.show_ern = true;
      this.broadcastInfo({ src: 'sidepanel', event: 'load_ern_data', data: true, key: "" });
      if (e.search) {
        this.broadcastInfo({ src: 'networkmodel', event: 'search-ern', data: e.search, key: "" });
      }
      if (!this.initialize_ern) {
        this.initialize_ern = true;
      } else {
        return;
      }
    } else if (e.mode && e.mode === 'aops') {
      this.show_ern = true;
      this.broadcastInfo({ src: 'sidepanel', event: 'load_ern_data', data: true, key: "" });
      if (e.search) {
        this.broadcastInfo({ src: 'networkmodel', event: 'search-ern', data: e.search, key: "" });
      }
      if (!this.initialize_aops) {
        this.initialize_aops = true;
      } else {
        return;
      }
    } else if (e.comp === 'nsc') {
      if (this.show_ern) {
        this.initialize_ern = false;
        this.initialize_aops = false;
        this.show_ern = !true;
        this.broadcastInfo({ src: 'sidepanel', event: 'load_ern_data', data: !true, key: "" });
        return;
      }
    }

    this.showBlockLoading = true;
    this.showOverlayPopup = true;
    this.activeComp = e.comp;
    this.shipHazardList = e.ship;
    if (this.shipHazardList) {
      this.selectedKha = this.shipHazardList['area'].split(" ").join("").toLowerCase();
      if (e.mode !== 'ern') {
        this.ctrlServ.selectedKha = this.selectedKha;
        this.ctrlServ.shipHazardList = this.shipHazardList;
      }
    }

    setTimeout(() => { this.showBlockLoading = false; }, 500);
  }

  /* * *
  * change component for overlay popup
  * * * */
  onChangeComponent(comp) {
    this.shipHazardList = this.ctrlServ.shipHazardList;
    if (comp === 'fleet') {
      this.showOverlayPopup = !true;
      this.activeComp = comp;
    } else {
      if (comp === 'ship') {
        if (this.shipHazardList) {
          this.showOverlayPopup = true;
          this.activeComp = comp;
        } else {
          this.showOverlayPopup = false;
          this.loadPortData("material");
        }
      } else {
        this.showOverlayPopup = true;
        if (comp === 'ern') {
          this.activeComp = 'nsc';
          if (!this.initialize_ern) {
            this.initialize_ern = true;
          }
          this.show_ern = true;
          this.broadcastInfo({ src: 'sidepanel', event: 'load_ern_data', data: true, key: "" });
        } else {
          this.activeComp = comp;
        }

      }
    }

  }

  /* * *
  * close popup overlay
  * * * */
  onClosePopup(e) {
    this.showOverlayPopup = !this.showOverlayPopup;
    this.activeComp = '';
  }

  /* * *
  * close ship detail panel
  * * * */
  shipDetailPanelClose(e) {
    // this.shipDetailPanelData = '';
    this.shipDetailPanelState = false;
  }

  /* * * * *
  * fshow path toggle
  * * * * * */
  showPathToggle(e: any) {
    let opt = this.ernDisplay ? "ern" : "nsc"
    this.showPathState = e;
    this.stateObj[opt]['showPathState'] = this.showPathState;
  }

  /* * *
  * method for control tool event
  * * */
  controlToolEvent(control) {
    this.broadcastInfo({ src: 'home', event: 'controlToolEvent', data: control, key: '' });
  }

  /* * *
  * ship detail panel
  * * */
  shipDetailTag(elem) {
    this.broadcastInfo({ src: 'home', event: 'shipDetailTag', data: elem, key: '' });
  }

  /* * *
  * control panel state
  * * */
  settingPanelState(e) {
    this.controlPanelState = e;
  }

  @HostListener('document:keyup', ['$event'])
  public handleKeyboardUpEvent(event: KeyboardEvent): void {
    const key = event.key.toString().toLowerCase();
    if (event.shiftKey && key === 't') {
      this.controlPanelState = true;
    }
  }

  /* * *
  * method for openDialog comp
  * * */
  openDialog(e): void {
    let width = '285px';
    let type = 'error';

    if (e == 'chartmodel') {
      type = 'chartmodel';
      width = '750px'
    }

    const dialogRef = this.dialog.open(PopupComponent, {
      panelClass: 'full-width-dialog',
      width: width,
      data: { value: '', error: e, type: type },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  /* * *
  * chartmodel
  * * */
  chartModelView() {
    this.openDialog('chartmodel');
  }

  /* * *
  * on close ern pane;
  * * */
  onErnClose() {
    this.ernDisplay = false;
    this.ctrlServ.ernDisplay = false;
    this.showPathState = this.stateObj['nsc']['showPathState'];
    this.loadPortData('pyramid');
  }

  /* * *
  * load Ern tree
  * * */
  onLoadERNTree(ernno) {
    this.ernDisplay = true;
    this.ctrlServ.ernDisplay = true;
    this.ernDefaultLoad = false;
    this.selectedErnNo = ernno;
    this.stateObj['ern']['showPathState'] = false;
    this.showPathState = false;
    this.loadPortData('ern');
  }

  /* * *
  * pdf viewer panel close
  * * */
  pdfViewerClose() {
    this.showOverlayPdfViewer = false;
  }

  /* * *
  * load pdf viewer
  * * */
  onLoadPdf(pageNo) {
    this.showOverlayPdfViewer = true;
    this.pdfPageNo = pageNo;
  }
  searchShipClear() {
    this.shipSearchData = '';
  }
  shipSearchPanelClose() {
    this.shipSearchState = false;
    this.materielSearchState = false;
    this.nscSearchState = false;
  }
  commandingShipSubmit() {
    // let idx= this.shipMetaData.findIndex(e=> e.name==this.shipSearchData);

    this.broadcastInfo({ src: 'main', event: 'showclickedship', key: this.shipSearchData, state: null, sub: null });
    this.shipSearchPanelClose();
  }
  onChangeShipSelect() {
    this.shipSearchPanelClose();
  }

  systemSubmit() {
    this.broadcastInfo({ src: 'main', event: 'showclickedship', key: this.shipSearchData, state: null, sub: null });
    if (this.ernFilterState) {
      this.broadcastInfo({ src: 'main', event: 'selectedErn', key: this.ernSelected, state: null, sub: null });
      this.shipSearchPanelClose();
    }
    if (this.khaFilterState) {
      this.selectedKha = this.khaSelected.split(" ").join("").toLowerCase();
      this.ctrlServ.selectedKha = this.selectedKha.split(" ").join("").toLowerCase();
      let idx = this.hazardList.findIndex(e => e.area == this.khaSelected)
      this.broadcastInfo({ src: 'main', event: 'selectedKha', key: this.hazardList[idx], state: null, sub: null });

      if (this.certificationState && this.nscSearchState) {
        this.broadcastInfo({ src: 'main', event: 'selectedKha', key: this.hazardList[idx], state: null, sub: null });
        this.shipSearchPanelClose();
      } else {

        this.broadcastInfo({ src: 'main', event: 'selectedKhaMateriel', key: this.hazardList[idx], state: null, sub: null });
        this.shipSearchPanelClose();
      }
    }
    if (this.compFilterState) {
      this.broadcastInfo({ src: 'main', event: 'selectedCompartment', key: this.compSelected, state: null, sub: null });
      this.shipSearchPanelClose();
    }
  }

  getAppStatus(){
    return this.ctrlServ.isMaterialAppLoaded;
  }

  /**
   * method for on load INSA
   */
  onLoadINSA() {
    let data = { key: '', comp: 'nsc' };
    this.loadMaterial = data;
    this.showINSABannerScreen = false;
    this.loadPortData('pyramid');
  }   
  
}

@Component({
  selector: 'popup-model',
  templateUrl: 'popup-model.html',
})
export class PopupComponent {
  constructor(
    public dialogRef: MatDialogRef<PopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, private translate: TranslateService, private language: LanguageService) {
    this.language.languageChange.subscribe(props => {
      console.log("language", props);
      this.translate.use(props);
    })
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  closeClick(): void {
    this.dialogRef.close();
  }

}

