import { Component, ViewChild, Output, EventEmitter, Input, HostListener, ElementRef } from '@angular/core';
import { MatSidenav } from '@angular/material';
import * as  menuList from "../../assets/appData/menuList.json";
import { ControllerService } from '../services/controller.service';
import { LanguageService } from '../services/language.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  @ViewChild(MatSidenav, { static: false }) sidenav: MatSidenav;
  @Output() selectedMenu = new EventEmitter<object>();
  @Output() closePopup = new EventEmitter<string>();
  @Output() changeComp = new EventEmitter<string>();
  @Input() activeComp: any = "";
  @Input() selectedKha: any = "";
  @Input() shipDetailPanelData: any = "";

  AppUrlList: any = menuList['default'];
  selectedMenuName: any = '';
  selectedMenuKey: any = 'cfb_halifax';
  isMenuOpened: boolean = false;
  isChildMenuOpend: boolean = false;
  childMenu: any = [];
  childRequestMenu: any = [];
  search: any = '';
  feelt: boolean = false;

  constructor(private ctrlServ: ControllerService,private translate: TranslateService, private language: LanguageService) {
    this.language.languageChange.subscribe(props => {
      console.log("language", props);
      this.translate.use(props);
    })
  }

  /* * * *01-12-2020
   * search object
   * * * * */
  searchValue(e) {
    console.log(e.target.value);
    this.search = e.target.value;
  }

  /* * * *01-12-2020
   * search clear
   * * * * */
  searchClear(e) {
    this.search = '';
  }

  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement) {
    if (targetElement) {
      if (targetElement.nodeName === 'APP-HEADER') {
        this.isMenuOpened = false;
      }
    }
  }

  /* * *
  * load component 
  * * * */
 
  loadComponent(e, comp) {
    this.changeComp.emit(comp);
  }

  closeOverlayPoup() {
    this.closePopup.emit();
  }

  isActiveShipComp() {
    this.selectedKha = this.ctrlServ.selectedKha;
    if (this.activeComp === 'nsc') {
      if (this.selectedKha != "" && this.selectedKha != undefined && this.shipDetailPanelData != "") {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  isActiveNscComp() {
    this.selectedKha = this.ctrlServ.selectedKha;
    if (this.activeComp === 'ship') {
      if (this.selectedKha != "" && this.selectedKha != undefined) {
        return (this.shipDetailPanelData === "" ? true : false);
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

}
