import { Component, OnInit, ViewChild, AfterViewInit, HostListener, Input, Output, EventEmitter, Inject } from '@angular/core';
import { ShapeCreatorService } from '../services/shape-creator.service';
import { ConfigService } from '../services/config.service';
import { ControllerService } from '../services/controller.service';
import { CommunicationService } from "../services/communication.service";
import { LanguageService } from '../services/language.service';
import { TranslateService } from '@ngx-translate/core';

declare var window;
declare var BABYLON;
var debug = false;
var _console = {
  "log": (...args) => {
    if (debug) {
      window.console.log(...args)
    }
  }
}

const constLabelWidth = 30; // 1555 - window.innerWidth;
const constLabelHeight = 25; // 815 - window.innerHeight;

@Component({
  selector: 'app-navyapp',
  templateUrl: './navyapp.component.html',
  styleUrls: ['./navyapp.component.scss']
})
export class NavyappComponent implements OnInit, AfterViewInit {
  @ViewChild('datauxview', { static: true }) public datauxview: any;
  @Input() shipMetaData: any = [];
  @Input() shipMetaDataList: any = [];
  @Input() classOrderList: any = [];
  @Input() set coastArea(v: any) {
    // this.initObjects();
  }
  @Output() materialCloseToggle: EventEmitter<any> = new EventEmitter<any>();
  @Output() shipDetailPanel: EventEmitter<any> = new EventEmitter<any>();

  shapeCreatorService: ShapeCreatorService;
  showLoading = false;
  doreset = false;
  styleLength: any = '';
  windowWidth: any = Math.round(window.innerWidth / 1.8);
  windowHeight: any = Math.round(window.innerWidth / 1.2);
  minWidth: any = Math.round(window.innerWidth / 4) + 'px';
  minHeight: any = Math.round(window.innerWidth / 4.8) + 'px';
  maxWidth: any = Math.round(window.innerWidth / 1.1) + 'px';
  maxHeight: any = Math.round(window.innerHeight / 1.1) + 'px';
  tagMaxList: any = [];
  camView = '#4';
  hoverTagDetail: any = '';
  sky;
  ocean;
  dfx
  level = 1;
  changeView = {
    '#4': '#1',
    '#1': '#2',
    '#2': '#3',
    '#3': '#4'
  };
  app_rendered = false;
  env_rendered = false;

  isShiftDown: boolean = false;
  shipHazardList: any = "";
  colorLabelState: any = true;
  activeTagList: any = [];
  tagintervalId: any;
  oceanSurfaceState: boolean = false;
  shipDragState: boolean = true;
  allElements: any = [];
  game_engine = null;
  view_dpr = null;
  shipClassList = [];
  statusList: any = [];
  selectedKha: any = 'Buoyancy';
  showBlockLoading: boolean = true;
  setInterval: any;
  isAppLoaded: boolean = false;

  tagStatus: any = false;
  pickElement: any = false;
  onHoverElem: any = false;
  pickEvent: boolean = false;
  selectedelem = '';
  multipleTagStatus: boolean = false;
  extras = ['mess10']
  hasWater = true;
  broadcastSubs: any;
  ships = [
    'CAL', 'VDQ', 'MON', 'VAN', 'REG', 'HAL', 'FRE', 'WIN', 'CHA', 'STJ', 'TOR', "OTT",
    'VIC', 'WSR', 'CHI', 'COR',
    'HDW', 'MAR', 'MAX', 'WIL', 'FRR', 'RHG',
    'KIN', 'GLA', 'NAN', 'EDM', 'YEL', 'MCT', 'SAS', 'SUM', 'SHA', 'WHI', 'GBY', 'BRA',
    'GRI', 'COU', 'CAI', 'REN', 'WOL', 'ORC', 'RAV', 'MOO',
    'CSC',
    'AST',
    "PRT", "PRE"
  ]
  ships_map = {
    /** Victoria */
    "HMCS Victoria": "VIC",
    "HMCS Windsor": "WSR",
    "HMCS Chicoutimi": "CHI",
    "HMCS Corner Brook": "COR",

    /** Halifax */
    "HMCS Calgary": "CAL",
    "HMCS Montreal": "MON",
    "HMCS St. John's": "STJ",
    "HMCS Vancouver": "VAN",
    "HMCS Charlottetown": "CHA",
    "HMCS Fredericton": "FRE",
    "HMCS Winnipeg": "WIN",
    "HMCS Halifax": "HAL",
    "HMCS Ville de Quebec": "VDQ",
    "HMCS Toronto": "TOR",
    "HMCS Regina": "REG",
    "HMCS Ottawa": "OTT",

    /** DeWolf */
    "HMCS Harry Dewolf": "HDW",
    "HMCS Margaret Brooke": "MAR",
    "HMCS Max Bernays": "MAX",
    "HMCS William Hall": "WIL",
    "HMCS Frédérick Rolette": "FRR",
    "HMCS Robert Hampton Gray": "RHG",

    /** Kingston */
    "HMCS Kingston": "KIN",
    "HMCS Glace Bay": "GLA",
    "HMCS Nanaimo": "NAN",
    "HMCS Edmonton": "EDM",
    "HMCS Yellowknife": "YEL",
    "HMCS Moncton": "MCT",
    "HMCS Saskatoon": "SAS",
    "HMCS Summerside": "SUM",
    "HMCS Shawinigan": "SHA",
    "HMCS Whitehorse": "WHI",
    "HMCS Goose Bay": "GBY",
    "HMCS Brandon": "BRA",

    /** Orca */
    "HMCS Grizzly": "GRI",
    "HMCS Cougar": "COU",
    "HMCS Caribou": "CAI",
    "HMCS Renard": "REN",
    "HMCS Wolf": "WOL",
    "HMCS Orca": "ORC",
    "HMCS Raven": "RAV",
    "HMCS Moose": "MOO",

    /** Aterix */
    "CSC": "CSC",

    /** Aterix */
    "MV Asterix": "AST",

    /** Protecteur */
    "HMCS Protecteur": "PRT",
    "HMCS Preserver": "PRE",

    /** others */
    "HMCS Oriole": ""//sail training vessel
  }

  constructor(
    private ctrlServ: ControllerService,
    private configService: ConfigService,
    private CommunicationServ: CommunicationService,
    private translate: TranslateService, private language: LanguageService
  ) {
    this.shipHazardList = this.ctrlServ.shipHazardList;
    this.selectedKha = this.ctrlServ.selectedKha;
    this.statusList = this.ctrlServ.statusList;
    this.windowWidth = Math.round(window.innerWidth / 2.8);
    this.windowHeight = Math.round(window.innerWidth / 4.5);
    this.language.languageChange.subscribe(props => {
      console.log("language", props);
      this.translate.use(props);
    })
  }

  broadcastInfo(data: any) {
    this.CommunicationServ.getInstance().next(data);
  }

  ngOnInit(): void {
    this.checkAppIsLoaded();
    this.broadcastSubs = this.CommunicationServ.getInstance()
      .subscribe((data) => {
        if (data.src === "controlpanel") {
          if (data.event === 'toggleocean') {
            let boo = data['data'];
            this.oceanSurfaceState = !boo;
            this.toggleOffOcean(boo);
          }
        }
        if (data.src === "controlpanel") {
          if (data.event === 'togglereflection') {
            let boo = data['data'];
            this.toggleOffReflection(boo);
          }
        }
        if (data.src === "sidepanel") {
          if (data.event === 'hideship') {
            let obj = data['data'];
            if (obj) {
              this.onHideShip(obj);
            }
          }
          if (data.event === 'hideclassallship') {
            let obj = data['data'];
            if (obj) {
              this.onHideClassAllShip(obj);
            }
          }
          if (data.event === 'showfilteredships') {
            let obj = data['data'];
            if (obj) {
              this.onShowFilteredShips(obj);
            }
          }
        }
        if (data.src === "home") {
          if (data.event === 'updateshiplist') {
            this.shipMetaData = data['data'];
            this.mappingCategory();
          }
          if (data.event === 'controlToolEvent') {
            let control = data['data'];
            if (control) {
              this.controlToolEvent(control);
            }
          }
          if (data.event === 'shipDetailTag') {
            let obj = data['data'];
            if (obj) {
              this.shipDetailTag(obj);
            }
          }
        }
        if (data.src == 'landingScreen') {
          if (data.event === 'navyApp') {
            this.shipMetaData = data.data;
            console.log(this.shipMetaData)
            // this.attchDynamicEvent();
            // this.mappingCategory();
            // this.coastMapping()
            // this.ngAfterViewInit()
            this.mappingCategory();
            this.coastMapping()
          }
        }
      })
  }

  /* * *
  * sample code to load the application venue using different data source
  * * */
  ngAfterViewInit(): void {
    this.configService.loadFile('../assets/license.info').then((info) => {
      this.configService.getProjectConfig().then((project_config: any) => {
        project_config.licence = info;
        this.datauxview.setProjectSettins(project_config);
        this.datauxview.loadCanvas('settings', 'json', (data, settingstatus) => {
          var setting_status = settingstatus;
          this.datauxview.yaw_3d = this.datauxview.yaw_2d = 270;
          this.datauxview.pitch_2d = 15
          if (!setting_status) {
            setting_status = this.attachDynamicObject(data, (settingstatus) => {
              if (settingstatus) {
                this.initObjects();
              }
            });
          } else {
            this.initObjects();
          }
        });
      }, (err) => { _console.log(err) });
    }, (err) => { alert("License not found!") })
  }

  track_label_tex = {
    "meshes": [{
      "position": [0, 0, 0],
      "rotation": [0, 0, 0],
      "scaling": [1, 1, 1],
      "isVisible": true,
      "isEnabled": true,
      "checkCollisions": false,
      "alphaIndex": 201,
      "billboardMode": 0,
      "receiveShadows": false,
      "name": "track",
      "id": "track",
      "positions": [],
      "normals": [],
      "uvs": [],
      "indices": []
    }]
  };

  /* * *
  * attach dynamic object
  * * */
  attachDynamicObject(data, callback) {
    var _this = this;
    const TERMINAL_ORIGIN = [-97.35, 33];
    this.shapeCreatorService = new ShapeCreatorService(TERMINAL_ORIGIN[0], TERMINAL_ORIGIN[1]);
    var trackWidth_met = 4;
    let trackgeom = _this.shapeCreatorService.MakeColourRect(1000, trackWidth_met, 32.0 / 1024.0, 1 - (828.0 / 1024.0));
    let _track = JSON.parse(JSON.stringify(_this.track_label_tex));
    let track = _track.meshes[0];
    track.name = track.name;
    track.id = track.name;
    track.positions = trackgeom.positions;
    track.normals = trackgeom.normals;
    track.uvs = trackgeom.uvs;
    track.indices = trackgeom.indices;
    data.shapes[track.name] = 'data:' + JSON.stringify(_track);
    return _this.datauxview.attachDynamicObject(data, callback);
  }

  /**
  * edited 17-12-20
  * @sathesh
  */
  initObjects() {
    this.showLoading = true;
    // load all the assets to be used information from socket
    this.datauxview.loadAssets('objects', 'json', (objectstatus) => {
      if (objectstatus) {
        let dfx = this.datauxview.getDatascape();
        this.dfx = dfx;

        this.datauxview.renderAssets('objects', 'json', (objectstatus) => {
          if (objectstatus) {
            this.allElements = objectstatus;
            this.ctrlServ.init(this.datauxview);
            this.setVenueLightIntensity();
            this.textureMode('solid');
            this.initCanvas();
            this.showLoading = !true;
            this.removeLoader();
            this.disableRQ();
            this.enableTopLevelDrag();

            if (!document.hidden) {
              this.addEnv();
            }
            this.updatePixel();
            this.attchDynamicEvent();
            this.app_rendered = true;
            setTimeout(() => {
              this.mappingCategory();
              this.coastMapping()
              this.isAppLoaded = true;
              this.ctrlServ.isAppLoaded = true;
            }, 1000);
          }
        }, (id, pointer) => this.attchObjectEvents(id, pointer))
      }
    });
  }
  coastMapping() {
    this.shipMetaDataList.forEach(el => {
      let idx = this.shipMetaData.findIndex(e => e.number == el.number);
      if (idx == -1) {
        let obj = { show: false, ship: el };
        this.onHideShip(obj);
      } else {
        let obj = { show: true, ship: el };
        this.onHideShip(obj);
      }
    });
  }
  /* * *
  * method for mapping category
  * * */
  mappingCategory() {
    var idx = 0;
    if (this.shipMetaData.length > 0) {
      this.classOrderList.forEach(ele => {
        var length = 0;
        var orderObj = [];
        this.shipMetaData.forEach((element, i) => {

          if (ele.class == element.class) {
            length++;
            orderObj.push(element);
          }
        });
        this.orderVenueShip(length, orderObj, ele.order, this.classOrderList[idx].position);
        if (orderObj.length > 0) {
          idx++;
        }

      })
    }
  }

  /* * *
  * order venue ship
  * * */
  orderVenueShip(count, orderObj, order, pos) {
    var arrList = [];
    count = (count === 1 ? 0 : count);
    let halfRange = count / 2;
    if (order % 2 == 0) {//even
      var value = halfRange * 50;
    } else if (Math.abs(order % 2) == 1) {
      var value = halfRange * 50 - 25;
    }
    orderObj.forEach((e, i) => {
      let dis = i * 50
      pos.x = value - dis;
      let shipElem = this.datauxview.getElementId(e.name);
      if (this.dfx) {
        this.dfx.modify(shipElem, { geometry: { position: pos } })
      }
    })
    setTimeout(() => {
      this.colorLabelHandle(true);
    }, 2500);
  }

  /* * * * *
  * attch event for dynamic created ship objects
  * * * * * */
  attchDynamicEvent() {
    console.log("attchDynamicEvent", this.shipMetaData)
    this.ships.forEach((key) => {
      let el = this.datauxview.getElementId(key);
      this.updateShipMeta(el);
      this.datauxview.getDatascape().attach(el, {
        actions: {
          hoverOn: [(evt, elem) => {
            // this.onHover(elem)
          }],
          hoverOff: [(evt, elem) => {
            // this.offHover(elem);
          }],
        }
      });
    })
  }

  /* * * * *
  * ship onhover tag will show if lable control is off
  * * * * * */
  onHover(elem) {
    console.log("hover", this.datauxview.isDragmove);
    if (!this.multipleTagStatus && !this.datauxview.isDragmove) {
      let el = this.datauxview.getDatascape().props(elem);
      if (this.tagMaxList.length > 0) {
        let idx = this.tagMaxList.findIndex(e => e.id == el.id);
        if (idx == -1) {
          // this.addObserver(el);
          this.hoverTagDetail = this.shipMappingClass(el.id);
          this.addObserver(elem);
        }
      } else {
        this.hoverTagDetail = this.shipMappingClass(el.id);
        this.addObserver(elem);
        // this.addObserver(el);
      }
      // this.hoverTagElement = el;
    }
  }

  /* * * * *
  * ship offhover shown tag will hide
  * * * * * */
  offHover(elem) {
    if (!this.multipleTagStatus) {
      let el = this.datauxview.getElementId(elem);
      let dom = document.getElementById('tooltip_' + this.hoverTagDetail.id);
      if (dom) {
        dom.style.display = "none";
        this.hoverTagDetail = '';
        this.removeObserver(el);
      }
    }
  }

  /* * *
  * remove default loader
  * * */
  removeLoader() {
    let ldiv = document.getElementById("babylonjsLoadingDiv");
    if (ldiv) {
      ldiv.remove();
    }
  }

  /* * *
  * show env
  * * */
  showEnv() {
    if (this.oceanmode) {
      if (this.ocean._mesh != null) {
        this.ocean._mesh.setEnabled(!false);
      }
    }
    else {
      if (this.ground != null) {
        this.ground.setEnabled(true);
      }
    }
    if (this.sky != null) {
      this.sky.setEnabled(true);
    }
  }

  /* * *
  * hide env
  * * */
  hideEnv() {
    if (this.oceanmode) {
      if (this.ocean._mesh != null) {
        this.ocean._mesh.setEnabled(false);
      }
    }
    else {
      if (this.ground != null) {
        this.ground.setEnabled(false);
      }
    }
    if (this.sky != null) {
      this.sky.setEnabled(false);
    }
  }

  /* * *
  * add env
  * * */
  addEnv() {
    this.sky = this.dfx.addSky('sky', { rayleigh: 1, inclination: 0.2 });

    let scene = this.dfx.getCamera().getScene();
    /**
     * to be removed on prod
     * only for dev
     */
    window['scope'] = this;
    window['dfx'] = this.dfx;
    window['scene'] = scene;
    this.toggleOffOcean(!true);
    /* let checkformesh = () => {
      if (this.ocean._mesh) {
        this.toggleOffOcean(true);
      } else {
        setTimeout(() => {
          checkformesh();
        }, 0)
      }
    }
    checkformesh(); */
    this.env_rendered = true;
  }
  addOcean() {
    let rl = [this.sky];
    this.ocean = this.dfx.addOcean("ocean", { renderList: rl, waveOpts: { col: [80 / 255, 160 / 255, 200 / 255] }, meshOpts: { subdiv: 32 * 8 }, texOpts: { fresnelSeparate: true, bumpSuperimpose: true, bumpAffectsReflection: true, bump: 0.25, bumptex: "./assets/scs/assets/env/waterbump.png" } });
    this.ocean.alpha = 0.9;
    this.ocean.colorBlendFactor2 = 0.5;
  }
  /**
   * remove rotationQuaternion property from models
   * to enable normal rotation property
   */
  disableRQ() {
    this.ships.forEach((e) => {
      let dfx = this.datauxview.getDatascape();
      let ship1 = this.datauxview.getElementId(e);
      let mesh = dfx.getElementMesh(ship1);
      mesh.rotationQuaternion = null;
    })
  }

  /**
  * Reset tag
  */
  resetTagViewMode() {
    this.tagStatus = false;
    this.pickElement = false;
    this.selectedelem = '';
  }

  objDrag: boolean = false;
  altKey: boolean = false;
  targetClicked: boolean = false;
  dragChange(e) {
    _console.log("drag", e);
    this.objDrag = e === true;
    if (!this.objDrag) {
      if (this.onHoverElem != '') {
        this.offHover(this.onHoverElem);
      }
      if (e === 'dblclick' || this.altKey) {
        this.onDoubleClick();
      }
    }
    this.pickEvent = false;
  }

  /**
  * double click event
  */
  onDoubleClick(d = null) {
    let dfx = this.getDatascape();
    if (dfx) {
      let hp = dfx.getHitPosition();
      if (hp.pickedPoint) {
        let target = { x: hp.pickedPoint.x, y: hp.pickedPoint.y, z: hp.pickedPoint.z };
        if (d) {
          this.moveCameraTo(null, { target, distance: d });
        } else {
          this.moveCameraTo(null, { target });
        }
      }
    }
  }

  /**
  * show color label
  */
  showColorLabel(flag, element) {
    let el = this.datauxview.getElementId(element);
    if (flag) {
      const found = this.activeTagList.some(el => el === element);
      if (!found) {
        this.activeTagList.push(element);
      }
      this.addObserver(el);
    } else {
      let dom = document.getElementById('colorLabel_' + element);
      if (dom) {
        let idx = this.activeTagList.findIndex(el => el === element);
        if (idx > -1) {
          this.activeTagList.splice(idx, 1);
        }
        dom.style.display = "none";
        this.removeObserver(el);
      }
    }
  }

  /**
  * add an Observer attach to 3d object
  */
  hasObs = {};
  addObserver(elem) {
    if (!elem) {
      return;
    }
    let id = this.dfx.props(elem).id
    if (this.hasObs[id]) {
      return
    }
    this.hasObs[id] = true
    this.datauxview.getDatascape().attach(elem, {
      actions: {},
      observer: {
        callback: this.assignTagProperties.bind(this),
        options: { no2D: false }
      }
    });
  };

  /**
   * Remove an Observer detach to 3d object
   */
  removeObserver(elem) {
    if (!elem) {
      return;
    }
    let id = this.dfx.props(elem).id
    if (this.hasObs[id]) {
      this.hasObs[id] = null
    }
    this.datauxview.getDatascape().detach(elem, {
      observer: {
        callback: this.assignTagProperties.bind(this),
        options: { no2D: false }
      }
    });
  }

  /***
   * tag observer properties
   */
  assignTagProperties(options) {
    const { id, position, position2D, uuid, tag, visibility } = Object.assign({}, options);
    let elem = this.datauxview.getElementId(id);
    let props = this.datauxview.getDatascape().props(elem);
    var pop = document.getElementById('colorLabel_' + id);
    if (this.colorLabelState) {
      if (props['profile']['material'] !== 'transparent') {
        this.objColorLabelObserver(pop, position2D, props);
      }
    }
  }

  /****
   * observable popup Tag
   */
  objColorLabelObserver(pop, position, element) {
    if (element != "") {
      const x = position[0];
      const y = position[1];
      var canvas = this.datauxview.rendererCanvas.nativeElement;
      const within_window = (x > 50 && x < canvas.width - 50) && (y > 50 && y <= canvas.height - 50);
      if (pop) {
        if (within_window) {
          pop.style.left = (Number(x) - constLabelWidth) + 'px';
          pop.style.bottom = (Number(y) + constLabelHeight) + 'px';
          pop.style.display = 'block';
          pop.style.position = 'absolute';
          pop.dataset.lastx = Number(x);
          pop.dataset.lasty = Number(y);
        } else {
          if (pop != null) {
            pop.style.display = 'none';
          }
        }
      } else {
        if (pop != null) {
          pop.style.display = 'none';
        }
      }
    }
  }

  @HostListener('contextmenu', ['$event'])
  public onRightClick(event): void {
    event.preventDefault();
  }

  land_mtl
  /****
   * setVenueLightIntensity
   */
  setVenueLightIntensity() {
    let dfx = this.datauxview.getDatascape();
    let scene = dfx.getCamera().getScene();
    let lights = scene.lights;
    lights[0].intensity = 2;
    lights[1].intensity = 2;
    lights[2].intensity = 2;
  }

  /****
   * applylandMtl
   */
  applylandMtl() {
    let dfx = this.datauxview.getDatascape();
    let m = this.datauxview.getElementId("land");
    let mesh = dfx.getElementMesh(m);
    mesh.material = this.land_mtl;
  }

  /**
   * edited 17-12-20
   * @sathesh
   */
  showHideLand(boo) {
    if (boo) {
      if (this.hasWater && this.level === 1)
        this.showEnv();
    } else {
      this.hideEnv();
    }
  }

  /**
   * edited 17-12-20
   * @sathesh
   */
  lightIntensity_tex() {
    let dfx = this.datauxview.getDatascape();
    let scene = dfx.getCamera().getScene();
    let lights = scene.lights;
    lights[0].intensity = 1;
    lights[1].intensity = 1;
    lights[2].intensity = 4;
  }

  /****
   * updateShipMeta
   */
  updateShipMeta(elem) {
    let el = this.datauxview.getDatascape().props(elem);
    let shipList = Object.keys(this.ships_map);
    let findMapShipIdx = shipList.findIndex(obj => this.ships_map[obj] === el.id);
    let Idx = this.shipMetaData.findIndex(obj => obj.ship === shipList[findMapShipIdx]);
    if (Idx > -1) {
      this.shipMetaData[Idx]['id'] = el.id;
    }
  }

  /****
   * shipMappingClass
   */
  shipMappingClass(id) {
    let Idx = this.shipMetaData.findIndex(obj => obj.id === id);
    if (Idx > -1) {
      return this.shipMetaData[Idx];
    }
  }

  /* * *
  * handle dpr change
  * * */
  updatePixel() {
    let scene = this.dfx.getCamera().getScene();
    this.game_engine = scene.getEngine();
    let anim = (t) => {
      if (this.view_dpr != window.devicePixelRatio) {
        this.view_dpr = window.devicePixelRatio;
        if (this.game_engine) {
          this.game_engine.setHardwareScalingLevel(1 / window.devicePixelRatio)
        }
      }
      requestAnimationFrame(anim)
    }
    requestAnimationFrame(anim)
  }

  @HostListener('window:resize', ['$event']) public onResize(event): void {
    console.log(event)
    console.log(event.target.innerWidth, event.target.innerHeight);
    +'px'
  }

  mouseDownElem: any = '';
  @HostListener('mousedown', ['$event']) public onMouseDown(event): void {
    this.mousestate = "down";
    // if (this.onHoverElem) {
    //   this.offHover(this.onHoverElem);
    // }
    if (event.target.className == 'mat-card') {
      if (event.target.childNodes[0].className == 'resizer') {
        this.mouseDown = true;
        this.mouseDownElem = event
      }
    }
  }

  mouseDown: any = false;
  @HostListener('mouseup', ['$event']) public onMouseUp(event): void {
    this.mouseDown = false;
    this.mousestate = "up";
    this.datauxview.isDragmove = false;
    // if (this.onHoverElem) {
    //   this.offHover(this.onHoverElem);
    // }
    if (this.mouseDownElem && this.mouseDownElem.target)
      this.mouseDownElem.target.style.resize = 'both'
  }

  /**
  * creating a key event to show/hide positioning tool
  */
  @HostListener('document:keydown', ['$event'])
  public handleKeyboardDownEvent(event: KeyboardEvent): void {
    this.altKey = event.altKey;
  }

  @HostListener('document:keyup', ['$event'])
  public handleKeyboardUpEvent(event: KeyboardEvent): void {
    const key = event.key.toString().toLowerCase();
    this.isShiftDown = false;
    this.altKey = false;
    if (((key === 'v') && event.shiftKey === true)) {
    } else if (((key === 's') && event.shiftKey === true)) {
    }
  }

  /**
  * Zoom event to zoom in / out the venue
  */
  isMouseDown
  mousestate = "up"
  Zoom(key) {
    this.isMouseDown = true;
    this.datauxview.startZoom(key);
  }
  stopZoom() {
    this.isMouseDown = !true;
    this.datauxview.stopZoom();
  }

  @HostListener('document:mousepress', ['$event'])
  public handleMouseDownEvent(event: MouseEvent): void {
    this.mousestate = "down";
    if (this.onHoverElem) {
      this.offHover(this.onHoverElem);
    }
  }
  @HostListener('document:mouseup', ['$event'])
  public handleMouseUpEvent(event: MouseEvent): void {
    if (this.isMouseDown) {
      this.targetClicked = true;
      this.stopZoom();
    }
    this.isMouseDown = !true;
    this.mousestate = "up";
    if (this.onHoverElem) {
      this.offHover(this.onHoverElem);
    }
    setTimeout(() => {
      this.targetClicked = !true;
    }, 750)
  }

  /**
  * Camera Position event to move the camera to different view / angle
  */
  viewMode = 'solid';
  viewObjectMode = 'texture';
  camviews = {
    "top": { "target": { "x": -39.183, "y": 0, "z": 301.257 }, "distance": 1000, "yaw": 270, "pitch": 89.9 },
    "left": { "target": { "x": -39.183, "y": 0, "z": 301.257 }, "distance": 880, "yaw": 270, "pitch": 35 },
    "right": { "target": { "x": -39.183, "y": 0, "z": 301.257 }, "distance": 880, "yaw": 89.9, "pitch": 35 },
    "back": { "target": { "x": -13.863, "y": 0, "z": 461.79 }, "distance": 980, "yaw": 180.9, "pitch": 35 },
    "front": { "target": { "x": -0.494, "y": 0, "z": 101.25 }, "distance": 980, "yaw": 0.009, "pitch": 35 },
    "home": { "target": { "x": -0.494, "y": 0, "z": 101.25 }, "distance": 980, "yaw": 0.009, "pitch": 35 },
  }

  /**
  * change cam view
  */
  changeCamView(v, vobj = false) {
    let target = { "x": 0, "y": 0, "z": 0 }
    let distance = 450;
    let view = vobj ? v : this.camviews[v];
    // view.target = target;
    // view.distance = distance;
    this.moveCameraTo(null, view)
  }

  /**
  * change control event
  */
  controlToolEvent(control) {
    if (control.name === 'changecamera') {
      this.camView = this.changeView[this.camView];
      this.onChangeCameraPosition(this.camView);
    } else if (control.name === 'drag') {
      // this.onChangeCameraPosition(control.name);
      this.onAllowShipDrag(control.state)
    } else if (control.name === 'drag_reset') {
      this.onChangeCameraPosition(control.name);
    } else if (control.name === 'home') {
      this.onChangeCameraPosition(control.name);
    } else if (control.name === '2D') {
      let dimension = control.state ? '2D' : '3D';
      this.onChangeCameraMode(dimension);
    } else if (control.name === 'zoomin' || control.name === 'zoomout') {
      let zoomPosition = control.name === 'zoomin' ? ']' : '[';
      this.Zoom(zoomPosition);
    } else if (control.name === 'heat map') {
      this.onChangeCameraPosition('heat_mode');
    } else if (control.name === 'play anim') {
      this.onChangeCameraPosition('play_anim');
    } else if (control.name === 'left') {
      this.onChangeCameraPosition(control.name);
    } else if (control.name === 'right') {
      this.onChangeCameraPosition(control.name);
    } else if (control.name === 'back') {
      this.onChangeCameraPosition(control.name);
    } else if (control.name === 'top') {
      this.onChangeCameraPosition(control.name);
    } else if (control.name === 'front') {
      this.onChangeCameraPosition(control.name);
    } else if (control.name === 'solid') {
      this.onChangeCameraPosition('solid');
    } else if (control.name === 'texture') {
      this.onChangeCameraPosition(control.name);
    } else if (control.name === 'wireframe') {
      this.onChangeCameraPosition(control.name);
    } else if (control.name === 'water') {
      //this.toggleOcean(control.state);
      this.toggleOffOcean(control.state);
    } else if (control.name == 'label') {
      this.colorLabelHandle(control.state);
    }
  }

  dragMode = false;
  operationMode = false;
  /**
  * change control event
  */
  onChangeCameraPosition(camView) {
    this.viewObjectMode = camView;
    if (camView == 'drag_reset') {
      this.mappingCategory()
      this.onChangeCameraPosition('home');
      return
    }
    if (camView === 'wireframe') {
      if (this.viewMode == camView) {
        return
      }
      if(this.shipFilterData.length>0){
        this.onShowFilteredShips(this.shipFilterData);
      }

      this.wireFrameMode(true);
      // let data = {show:true,ship:this.shipMetaDataList}
      // this.onHideClassAllShip(data);
      return
    }
    if (camView === 'solid') {
      if (this.viewMode == camView) {
        return
      }
      if(this.shipFilterData.length>0){
        this.onShowFilteredShips(this.shipFilterData);
      }

      //
      this.textureMode(camView);
      // let data = {show:true,ship:this.shipMetaDataList}
      // this.onHideClassAllShip(data);

      return
    }
    if (camView === 'texture') {
      if (this.viewMode == camView) {
        return
      }
      if(this.shipFilterData.length>0){
        this.onShowFilteredShips(this.shipFilterData);
      }
      this.textureMode(camView);
      // let data = {show:true,ship:this.shipMetaDataList}
      // this.onHideClassAllShip(data);
      return
    }
    if ((camView == 'left' || camView == 'right' || camView == 'back' || camView == 'top' || camView == 'front')) {
      this.changeCamView(camView);
      return;
    }
    if (this.cam_mode == '2D' && (camView == 'left' || camView == 'right' || camView == 'back')) {
      camView = 'ortho' + camView
    }
    this.datauxview.Camera(camView);
  }

  cam_mode = '3D';
  /**
  * Camera Position mode
  */
  onChangeCameraMode(camView) {
    this.cam_mode = camView;
    if (this.cam_mode == '2D') {
      this.onChangeCameraPosition('top');
    }
    this.datauxview.CameraMode(camView);
    if (this.cam_mode == '3D') {
      setTimeout(() => {
        this.onChangeCameraPosition('home');
      }, 1300);
    }
  }

  /**
  * toggle ocean
  */
  toggleOcean(boo = null) {
    //let dfx = this.getDatascape();
    //let elem = this.datauxview.getElementId('land');
    this.hasWater = !this.hasWater;
    if (boo !== null) {
      this.hasWater = boo;
    }
    if (this.hasWater) {
      if (this.level === 1)
        this.showEnv()
    } else {
      this.hideEnv()
    }
  }

  /**
   * edited 17-12-20
   * @sathesh
   */
  env_black = { r: 0.22, g: 0.22, b: 0.22, a: 1 };
  env_sky = { r: 0.5, g: 0.5, b: 0.5, a: 1 };
  env_black_a = { r: 0.22, g: 0.22, b: 0.22, a: 1 };
  envID
  animEnv() {
    let m = this.env_sky.r - this.env_black.r;
    let time = 100;
    let dm = m / time;
    let r = this.env_black_a.r;
    let g = this.env_black_a.g;
    let b = this.env_black_a.b;
    let datascape = this.datauxview.getDatascape();
    let scene = datascape.getCamera().getScene();
    let land = this.datauxview.getElementId('land');
    if (this.envID) {
      return;
    }
    this.showHideLand(!true);
    this.envID = setInterval(() => {
      r = r + dm;
      g = g + dm;
      b = b + dm;
      scene.clearColor.r = r;
      scene.clearColor.g = g;
      scene.clearColor.b = b;
      this.env_black_a = { r, g, b, a: 1 };
      if (r >= this.env_sky.r) {
        this.stopEnvAnim();
      }
    }, 1)
  }

  /**
  * stop env animation
  */
  stopEnvAnim(reset = false) {
    let datascape = this.datauxview.getDatascape();
    let scene = datascape.getCamera().getScene();
    let land = this.datauxview.getElementId('land');
    clearInterval(this.envID);
    this.envID = null;
    if (reset) {
      scene.clearColor.r = this.env_black.r;
      scene.clearColor.g = this.env_black.g;
      scene.clearColor.b = this.env_black.b;
      this.env_black_a = this.clone(this.env_black);
      this.showHideLand(true);
    }
  }

  /**
  * wireFrameMode
  */
  wireFrameMode(boo) {
    let datascape = this.datauxview.getDatascape();
    let scene = datascape.getCamera().getScene();
    if (boo && this.viewMode !== 'solid') {
      this.textureMode('solid', 'wireframe');
    } else if (boo) {
      this.viewMode = 'wireframe';
    }
    scene.forceWireframe = boo;
  }

  /**
  * textureMode
  */
  textureMode(mode = 'texture', v = null) {
    this.resetTagViewMode();
    this.wireFrameMode(false);
    if (mode == 'solid') {
      this.showLoading = true;
      setTimeout(() => {
        this.doreset = true;
        this.showLoading = !true;
        this.doreset = false;
        if (v) {
          setTimeout(() => {
            this.viewMode = v;
            let data = {show:true,ship:this.shipMetaDataList};
            this.onHideClassAllShip(data);
          }, 200)
        } else {
          this.viewMode = mode;
          let data = {show:true,ship:this.shipMetaDataList};
          this.onHideClassAllShip(data);
        }
      }, 200)
    }
    if (mode == 'texture') {
      this.stopEnvAnim(true);
      this.lightIntensity_tex()
      this.viewMode = mode;
    }
  }

  @HostListener('document:click', ['$event']) onMouseClick(e) {
    let isCanvas = e.target.nodeName === 'CANVAS';
  }

  /**
   * edited 17-12-20
   * @sathesh
   */
  getAverageCenter(m) {
    let meshes = m.getChildMeshes();
    let l = meshes.length;
    let c = [0, 0, 0]
    meshes.forEach((mesh) => {
      let pt = mesh.getBoundingInfo().boundingSphere.centerWorld
      c[0] += pt.x; c[1] += pt.y; c[2] += pt.z;
    });
    return { x: c[0] / l, y: c[1] / l, z: c[2] / l }
  }

  /**
   * edited 17-12-20
   * @sathesh
   */
  getMaxRadius(m) {
    let meshes = m.getChildMeshes();
    let l = meshes.length;
    let c = Number.MIN_VALUE
    meshes.forEach((mesh) => {
      let pt = mesh.getBoundingInfo().boundingSphere.radiusWorld;
      c = Math.max(c, pt)
    });
    return c;
  }
  /**
  * edited 17-12-20
  * @sathesh
  */
  getElemCenter(el) {
    let dfx = this.datauxview.getDatascape();
    let id = dfx.props(el).id;
    let target;
    let mesh = dfx.getElementMesh(el);
    if (this.extras.includes(id)) {
      target = this.getAverageCenter(mesh);
    } else {
      let c = mesh.getBoundingInfo().boundingSphere.centerWorld;
      target = { x: c.x, y: c.y, z: c.z }
    }
    return target;
  }
  /**
   * edited 17-12-20
   * @sathesh
   */
  getElemRadius(el) {
    let dfx = this.datauxview.getDatascape();
    let id = dfx.props(el).id;
    let mesh = dfx.getElementMesh(el);
    let c = this.extras.includes(id) ? this.getMaxRadius(mesh) : mesh.getBoundingInfo().boundingSphere.radiusWorld;
    return c;
  }

  dradius = 2.63 * 10;
  /**
  * change camera mode
  */
  moveCameraTo(el, view = null) {
    let dfx = this.datauxview.getDatascape();
    let camera = dfx.getCamera();
    let _view = view;
    if (!view) {
      let dr = this.getElemRadius(el);
      let target = this.getElemCenter(el);
      let ds = dr / Math.tan(camera.fov / 2);
      ds = Math.max(20, ds);
      _view = { distance: ds, target }
    }
    dfx.moveCamera(_view);
  }

  /**
  * get datascape
  */
  getDatascape() {
    return this.datauxview.getDatascape();
  }

  /**
  * initCanvas
  */
  initCanvas() {
    var canvas = document;
    canvas.addEventListener("pointerdown", (event) => {
      this.mousestate = 'down';
      if (event.which == 1) {
        this.rightclick = false;
      }
    })
    canvas.addEventListener("pointerup", (event) => {
      this.mousestate = 'up';
      if (event.which == 1) {
        this.rightclick = true;
      }
    })
  }

  /**
  * clone
  */
  clone(obj) {
    return JSON.parse(JSON.stringify(obj));
  }

  /**
   * edited 17-12-20
   * @sathesh
   */
  hideAllShips() {
    let dfx = this.datauxview.getDatascape();
    this.ships.forEach((e) => {
      let ship = this.datauxview.getElementId(e);
      dfx.modify(ship, { profile: 'invisible' });
    })
  }

  /**
  * show all ships
  */
  showAllShips() {
    let dfx = this.datauxview.getDatascape();
    this.ships.forEach((e) => {
      let ship = this.datauxview.getElementId(e);
      //dfx.modify(ship,{profile:'invisible'});
      dfx.modify(ship, { profile: 'regular' });
    })
  }

  /**
  * method for check visible mode
  */
  isCompVisible(c) {
    let comp = this.datauxview.getElementId(c);
    let dfx = this.getDatascape();
    let props = dfx.props(comp);
    let vis = props.profile.visible;
    return vis;
  }

  /**
   * edited 17-12-20
   * @sathesh
   */
  shipDragobj: any = {};
  rightclick: boolean = true;
  enableTopLevelDrag() {
    let dfx = this.datauxview.getDatascape();
    this.ships.forEach((key) => {
      let child = this.datauxview.getElementId(key);
      let mesh = dfx.getElementMesh(child)
      let b = dfx.getDragBehavior([0, 1, 0]);
      b.useObjectOrienationForDragging = false;
      b.updateDragPlane = false;
      mesh.addBehavior(b);
      b.onDragObservable.add((event) => {
        if (this.rightclick) {
          b.releaseDrag();
          this.rightclick = true;
          this.onDoubleClick()
        }
      })
      this.shipDragobj[key] = [mesh, b];
    });
  }

  /**
  * remove top level drag
  */
  removeTopLevelDrag() {
    let keys = Object.keys(this.shipDragobj);
    keys.forEach((key) => {
      if (this.shipDragobj[key]) {
        this.shipDragobj[key][0].removeBehavior(this.shipDragobj[key][1]);
        this.shipDragobj[key][1] = null;
      }
    });
  }

  /**
   * Drag Update
   */
  @HostListener('document:pointerup', ['$event'])
  public handlePointerUpEvent(event: MouseEvent): void {
    let t: any = event.target
    if (t.nodeName === 'CANVAS') {
      if (this.dragMode) {
        // this.reapplyDrag();
      }
    }
  }

  /**
  * ship detail tag
  */
  shipDetailTag(elem) {
    console.log(this.mouseDown, this.mousestate, this.datauxview.isDragmove)
    if (this.tagMaxList.length > 0) {
      // var previousTag = this.tagMaxList[0];
      // let dom = document.getElementById('popup_' + previousTag.name);
      let el = this.datauxview.getElementId(elem.name);
      if (this.multipleTagStatus) {
        let dom = document.getElementById('tooltip_' + elem.id);
        if (dom) {
          dom.style.display = "none";
          this.removeObserver(el);
        }
      }
      // this.tagMaxList = [];
      let idx = this.tagMaxList.findIndex(e => e.ship == elem.ship);
      if (idx == -1) {
        this.tagMaxList.push(elem);
      }
    } else {
      this.tagMaxList.push(elem);
    }
    let el = this.datauxview.getElementId(elem.name);
    this.addObserver(el);
    this.broadcastInfo({ src: 'main', event: 'updatetagelem', data: elem, key: '', state: null, sub: null });
  }

  /* * *
   * ship objects events handle
   * * */
  hitPosition: any;
  attchObjectEvents(id, pointer) {
    if (pointer === 'pickDown') {
      this.hitPosition = this.datauxview.getDatascape().getHitPosition().pickedPoint;
    }
    if (pointer === 'pickUp') {
      if (this.datauxview.getDatascape().getHitPosition().pickedPoint.x == this.hitPosition.x && this.datauxview.getDatascape().getHitPosition().pickedPoint.y == this.hitPosition.y) {
        let el = this.datauxview.getElementId(id);
        if (id == this.hoverTagDetail.id) {
          this.offHover(el)
        }
        this.shipMetaData.forEach((ship, index) => {
          if (ship.name === id) {
            this.shipDetailPanel.emit({ checked: true, data: ship });
            this.broadcastInfo({ src: 'main', event: 'showclickedship', key: id, state: null, sub: null });
          }
        });
      }
      this.datauxview.isDragmove = false;
      console.log(this.datauxview.isDragmove)
    }
    if (pointer === 'pickDouble') {
      this.broadcastInfo({ src: 'main', event: 'triggernextlevel', key: id, state: null, sub: null });
    }
  }

  /* * * *
  * Hide the current active color tag
  * * * */
  hideActiveColorTag() {
    this.activeTagList.forEach(el => {
      let dom = document.getElementById('colorLabel_' + el);
      if (dom) {
        if (dom.style.display === "block") {
          dom.style.display = "none";
        }
      }
    });
  }

  /* * *
  * show all label
  * * * */
  colorLabelHandle(state) {
    this.colorLabelState = state;
    if (state) {
      this.tagintervalId = setInterval(() => { this.hideActiveColorTag(); }, 500);
    } else {
      clearInterval(this.tagintervalId);
    }
    this.shipMetaData.forEach(element => {
      this.showColorLabel(state, element.id);
    });
  }

  @HostListener('document:visibilitychange', []) public visibilitychange(): void {
    if (document.hidden) {
      return;
    }
    if (!this.env_rendered) {
      if (this.dfx && this.app_rendered) {
        this.addEnv();//
      }
    }
  }

  /* * *
  * get mesh method
  * * * */
  getMesh(id) {
    let el = this.datauxview.getElementId(id);
    let mesh = this.dfx.getElementMesh(el);
    return mesh;
  }
  /**
   * TODO move BABYLON method to dataux
   * @param hide
   */
  ground
  oceanmode = true;
  toggleOffOcean(hide = true) {
    if (!this.ground) {
      this.ground = this.getMesh('ground');
      this.ground.material = this.getGroundMaterial();
    }
    this.oceanmode = !hide;
    if (hide) {
      if (this.ocean) {
        if (this.ocean._mesh != null) {
          this.ocean._mesh.setEnabled(false);
        }
      }
      if (this.ground != null) {
        this.ground.setEnabled(true);
      }
    }
    else {
      if (this.ocean) {
        if (this.ocean._mesh != null) {
          this.ocean._mesh.setEnabled(true);
        }
      } else {
        this.addOcean();
      }
      if (this.ground != null) {
        this.ground.setEnabled(false);
      }
    }
  }

  /* * *
  * getGroundMaterial
  * * * */
  getGroundMaterial() {
    let gm = new BABYLON.GridMaterial("ocean_grid", window['scene']);
    gm.mainColor = new BABYLON.Color3(0.2, 0.44, 0.57);
    gm.lineColor = new BABYLON.Color3(0.3, 0.54, 0.67);
    return gm;
  }

  /* * *
  * toggle OffReflection
  * * * */
  toggleOffReflection(hide = true) {
  }

  /* * *
  * allow ship dragging
  * * * */
  onAllowShipDrag(allow) {
    this.shipDragState = allow;
    if (allow) {
      this.enableTopLevelDrag();
    } else {
      this.removeTopLevelDrag();
    }
  }

  /* * *
  * hide selected ship
  * * * */
  hideShipList: any = [];
  onHideShip(e) {
    let show = e.show;
    let ship = e.ship;
    let shipid = ship.id;
    if (show) {
      let idx = this.hideShipList.findIndex(el => el === ship.id);
      if (idx > -1) {
        this.hideShipList.splice(idx, 1);
      }
      this.ctrlServ.showElement(shipid);
      this.showColorLabel(show, shipid);
    } else {
      let found = this.hideShipList.some(el => el === shipid);
      if (!found) {
        this.hideShipList.push(shipid);
      }
      this.ctrlServ.hideElement(shipid);
      this.showColorLabel(show, shipid);
    }
  }

  /* * *
  * hide ship respected class
  * * * */
  onHideClassAllShip(e) {
    let show = e.show;
    let shipList = e.ship;
    shipList.forEach(ship => {
      let shipid = ship.id;
      if (show) {
        let idx = this.hideShipList.findIndex(el => el === ship.id);
        if (idx > -1) {
          this.hideShipList.splice(idx, 1);
        }
        this.ctrlServ.showElement(shipid);
        this.showColorLabel(show, shipid);
      } else {
        let found = this.hideShipList.some(el => el === shipid);
        if (!found) {
          this.hideShipList.push(shipid);
        }
        this.ctrlServ.hideElement(shipid);
        this.showColorLabel(show, shipid);
      }
    });
  }

  /* * * * *
  * method searched ships only to show
  * * * * * */
  shipFilterData:any=[];
  onShowFilteredShips(filterdData) {
    this.shipFilterData=filterdData;
    let transObj = [];
    if (filterdData !== null) {
      if (filterdData.length > 0) {
        transObj = this.shipMetaData.filter(function (ship) {
          return filterdData.every(function (data) {
            return data.id !== ship.id;
          });
        })
      } else {
        transObj = this.shipMetaData;
      }
      this.showObjects(filterdData);
      this.transparentObjects(transObj);
    } else {
      this.showAllShips();
    }
  }

  /* * * * *
  * filter ship object show
  * * * * * */
  showObjects(obj) {
    obj.forEach(ship => {
      this.ctrlServ.showElement(ship.id);
    });
  }

  /* * * * *
  * filter ship object show other objects transparent
  * * * * * */
  transparentObjects(obj) {
    obj.forEach(ship => {   
      if(this.viewObjectMode == 'wireframe'){
        this.ctrlServ.hideElement(ship.id);
      }else{
        this.ctrlServ.transparentElement(ship.id);
      }
    
    });
  }

  /* * *
   * method id default config is loadded/not
   * * */
  checkAppIsLoaded() {
    let animation = (timer) => {
      if (this.isAppLoaded) {
        setTimeout(() => { this.showBlockLoading = false }, 1000);
        cancelAnimationFrame(this.setInterval);
      } else {
        this.setInterval = window.requestAnimationFrame(animation);
      }
    }
    this.setInterval = window.requestAnimationFrame(animation);
  }

  /* * *
   * method destroy all data's/events
   * * */
  ngOnDestroy() {
    let dfx = this.dfx;
    dfx.stop();
    dfx.dispose();
    this.broadcastSubs.unsubscribe();
  }


}

