import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Certificate } from 'crypto';
import { CommunicationService } from "../services/communication.service";
import { ControllerService } from '../services/controller.service';
import { ConfigService } from '../services/config.service';
import { KHASystemService } from '../services/systems/kha-systems.service';
import { LanguageService } from '../services/language.service';
import { TranslateService } from '@ngx-translate/core';
import { validateVerticalPosition } from '@angular/cdk/overlay';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-ship-detail',
  templateUrl: './ship-detail.component.html',
  styleUrls: ['./ship-detail.component.scss']
})
export class ShipDetailComponent implements OnInit {
  @ViewChild('datauxview', { static: true }) public datauxview: any;
  shipData: any = '';
  @Input() mvScreenType: any = "";
  @Input() shipHazardList: any = "";
  @Input() selectedKha: any = "";
  broadcastIframeSubs: any;
  @Input() set shipDetailPanelData(v: any) {
    if (v != '') {
      this.shipData = v;
    }
  }
  @Input() set mode(v: any) {
    this.slms_mode=v;
  }
  imgSrc: any;
  broadcastSubs: any;
  showLoading: boolean = false;
  selectedDemo: string = "";
  process_txt: string = "Processing ";
  statusList: any = [];
  initialized = false;
  dfx;
  scene;
  shipCompartment: any;
  camviews = "";
  ernOverlayMode: boolean = false;
  overlayHeading: any = "";
  overlaySubHeading: any = "";
  loadUrl: any;
  khaConfig
  materialStatusWindow: boolean = false;
  compCommentWindow: boolean = false;
  arrControlBtnProps = [
    { name: 'home', icon: 'home', state: true },
    { name: '2D', icon: 'dimension', state: false },
    { name: 'zoomin', icon: 'add', state: true },
    { name: 'zoomout', icon: 'remove', state: true },
    { name: 'drag', icon: 'swipe', state: false, ticon: 'do_not_touch' },
    { name: 'reset', icon: 'refresh', state: false, ticon: 'refresh' },
  ];
  khaComps: any;
  statusMode: boolean = false;
  ship_settings = {
    "mats": "./systems_material.gltf",
    "ernmodel": "./ern_fleetway_halifax.glb"
  }
  ship_settings_slms = {
    "mats": "./systems_material.gltf",
    "ernmodel": "./slms/ern_fleetway_halifax.glb"
  }
  ship_objects = {
    "shapes": [{
      "mhfx": {
        "model": "root_ern_fleetway_halifax",
        "material": "custom-included",
        "size": 1
      }
    }],
    "objects": [
      {
        "type": "static",
        "parent": "",
        "name": "ehfx",
        "model": "mhfx",
        "place": {
          "pos": {
            "x": 0,
            "y": 0,
            "z": 0
          },
          "rot": {
            "x": 0,
            "y": 0,
            "z": 0
          }
        }
      }
    ]
  }
  statusColorCode: any = [{ "name": "Red", "key": "red", "color": "#ff0000" }, { "name": "Yellow", "key": "yellow", "color": "#ffff00" }, { "name": "Transparent", "key": "transparent", "color": "#ababb2" }];
  selectedStatus: any = '';
  statusMessage: any = '';
  slms_mode: boolean = !true;//default false
  constructor(private CommunicationServ: CommunicationService,
    private ctrlServ: ControllerService,
    private configService: ConfigService,
    private _khaConfig: KHASystemService,
    private translate: TranslateService,
    private language: LanguageService,
    private domSanitizer: DomSanitizer,
  ) {
    // this.broadcastInfo({ src: 'materiel', event: 'init_Load', state: true,});
    this.khaConfig = this._khaConfig;
    this.statusList = this.ctrlServ.statusList;
    this.khaComps = this.ctrlServ.comps;

    this.language.languageChange.subscribe(props => {
      console.log("language", props);
      this.translate.use(props);
    })
  }

  buttonclick(row, index) {
    this.arrControlBtnProps[index].state = !this.arrControlBtnProps[index].state;
    this.controlToolEvent(row);
  }

  controlToolEvent(control) {
    if (control.name === 'home') {
      let currentTab = this.ctrlServ.matCurrentTab;
      if (currentTab === 'compartment') {
        if (this.camviews) {
          this.moveCameraTo(null, this.camviews);
        } else {
          this.onChangeCameraPosition(control.name);
        }
      } else {
        this.onChangeCameraPosition(control.name);
      }
    } else if (control.name === '2D') {
      let dimension = control.state ? '2D' : '3D';
      this.onChangeCameraMode(dimension);
    } else if (control.name === 'zoomin' || control.name === 'zoomout') {
      let zoomPosition = control.name === 'zoomin' ? ']' : '[';
      this.Zoom(zoomPosition);
    } else if (control.name === 'drag') {
      if (control.state) {
        this.khaConfig.dragMode = true;
        this.khaConfig.enableDrag();
      } else {
        this.khaConfig.dragMode = false;
        this.khaConfig.removeDragAll();
      }
    } else if (control.name === 'reset') {
      this.khaConfig.clearDrag();
      if (this.arrControlBtnProps[4].state) {
        this.khaConfig.enableDrag();
      }
    }
  }

  cam_mode = '3D';
  /**
  * onChange camera mode
  * * */
  onChangeCameraMode(camView) {
    this.cam_mode = camView;
    if (this.cam_mode == '2D') {
      this.onChangeCameraPosition('top');
    }
    this.datauxview.CameraMode(camView);
    if (this.cam_mode == '3D') {
      if (this.khaConfig.khamode === 'system') {
        setTimeout(() => {
          this.onChangeCameraPosition('home');
        }, 1300);
      }
    }
  }

  /**
  * onChange camera position
  * * */
  onChangeCameraPosition(camView) {
    if (camView === 'home') {
      this.onChangeCamView(camView);
    }
    if (this.cam_mode == '2D') {
      camView = 'ortho' + camView
    }
    this.datauxview.Camera(camView);
  }

  /**
  * onChange camera view
  */
  onChangeCamView(v, vobj = false) {
    let currentTab = this.ctrlServ.matCurrentTab;
    if (currentTab !== 'section') {
      this.moveCameraTo(null, v)
    }
  }

  /**
  * move camera
  */
  moveCameraTo(el, view = null) {
    let dfx = this.datauxview.getDatascape();
    let _view = view;
    dfx.moveCamera(_view);
  }

  /**
  * Zoom event to zoom in / out the venue
  */
  isMouseDown
  mousestate = "up"
  Zoom(key) {
    this.isMouseDown = true;
    this.datauxview.startZoom(key);
  }
  stopZoom() {
    this.isMouseDown = !true;
    this.datauxview.stopZoom();
  }
  @HostListener('document:pointerdown', ['$event'])
  handlePointerDownEvent(event: MouseEvent) {
    // console.log(event)
    this.mousestate = "down"
  }
  @HostListener('document:pointermove', ['$event'])
  handlePointereMoveEvent(event: MouseEvent) {
    if (this.mousestate === 'down') {
      this.mousestate = "drag";
    }
  }
  @HostListener('document:pointerup', ['$event'])
  handlePointereUpEvent(event: MouseEvent) {
    if (this.mousestate === 'down') {
      if ((<HTMLInputElement>event.target).id == "renderCanvas") {
        if (event.which === 1 || event.button === 0) {
          this.canvasPickedObject(event);
        }
      }
    }
    this.mousestate = "up";
  }

  @HostListener('document:mouseup', ['$event'])
  handleMouseUpEvent(event: MouseEvent) {
    if (this.isMouseDown) {
      this.stopZoom();
    }
    this.mousestate = "up"
  }
  canvasPickedObject(e) {
    const hit = this.dfx.getHitPosition();
    let mesh = hit.pickedMesh;
    if (mesh != null) {
      let str = mesh.name;
      let narr = str.split(".");
      let nstr = narr[narr.length - 1];
      let a = nstr.split("+")
      let name = a[1] ? (a[1] + " (" + (a[0].split("_")[1]) + ")") : a[0];
      console.log("nstr ", nstr);
      this.khaConfig.selectedTagMesh = nstr;
      delete this.selectedKhaSystems;
      let dom: any = document.getElementsByClassName("mat_nm_label")[0];
      if (dom) {
        dom.style.display = "none";
      }
      this.getSelectedKhaSystemsLabel(mesh, a);
      this.khaConfig.createMatObject('cube', hit, name);
    } else {
      this.selectedKhaSystems = null
      this.khaConfig.showMatObjectTag(mesh, false);
    }
  }
  selectedKhaSystems: any;
  materialmap: any = {}
  getSelectedKhaSystemsLabel(e, a) {
    this.selectedKhaSystems = { color: "#00000000" };
    let code = a[1] ? ((a[0].split("_")[1])) : "--";
    let desc = a[1] ? (a[1]) : a[0];
    let mat = e.mid;
    this.selectedKhaSystems.id = e['id'];
    this.selectedKhaSystems.code = code;
    this.selectedKhaSystems.desc = desc;

    if (this.materialmap[mat]) {
      this.selectedKhaSystems.color = this.materialmap[mat]
    }
    if (this.statusMode) {
      let colors = { "red": "#ff0000", "yellow": "#ffff00", "transparent": "#ababb2" };
      let statusColor = this.getStatusColorCode(e['id']);
      if (statusColor) {
        this.selectedStatus = statusColor;
        let color = colors[statusColor];
        this.materialmap[mat] = color;
        this.selectedKhaSystems.color = color;
      }
    } else {
      if (mat && !this.materialmap[mat]) {
        let m = this.scene.getMaterialByName(mat);
        let c = m.albedoColor;
        let r = Math.round(c.r * 255);
        let g = Math.round(c.g * 255);
        let b = Math.round(c.b * 255);
        let hex = this.rgbToHex(r, g, b);
        this.materialmap[mat] = hex;
        this.selectedKhaSystems.color = hex;
      }
    }
  }

  getStatusColorCode(id) {
    let material;
    for (let mat of Object.keys(this.khaConfig.systemSubComps)) {
      let comps = this.khaConfig.systemSubComps[mat];
      const found = comps.some(m => m.id === id);
      if (found) {
        material = mat;
        break;
      }
    }
    return material;
  }

  setVenueLightIntensity(i = 4) {
    this.dfx = this.datauxview.getDatascape();
    this.scene = this.dfx.getCamera().getScene();
    let lights = this.scene.lights;
    lights.forEach((light) => {
      light.intensity = i;
    })
  }

  /* * *
  * method id default config is loadded/not
  * * */
  checkAppIsLoaded() {
    let animation = (timer) => {
      if (this.isMaterialAppLoaded) {
        // setTimeout(() => {
        // this.showBlockLoading = false;
        this.broadcastInfo({ src: 'material', event: 'material_init_load', state: false, key: "" });
        //  },600);
        cancelAnimationFrame(this.setInterval);
      } else {
        this.setInterval = window.requestAnimationFrame(animation);
      }
    }
    this.setInterval = window.requestAnimationFrame(animation);
  }

  initIframeMessage() {
    this.broadcastIframeInfo({ iframeConnection: 'init-nsc-connection', event: 'nsc-init', data: '', key: "" });
  }

  ngOnInit() {
    this.initIframeMessage();
    this.showBlockLoading = true;
    this.ctrlServ.isMaterialAppLoaded = false;
    this.checkAppIsLoaded();
    window['shipcomp'] = this;
    if (this.shipHazardList != undefined) {
      this.selectedKha = this.shipHazardList['area'].split(" ").join("").toLowerCase();
      this.ctrlServ.selectedKha = this.selectedKha;
    }

    this.broadcastSubs = this.CommunicationServ.getInstance()
      .subscribe((data: any) => {
        if (data.src === "khaservice") {
          if (data.event === 'showloader') {
            let obj = data['data'];
            this.showLoading = true;
            this.selectedDemo = obj;
          }
          if (data.event === 'hideloader') {
            let obj = data['data'];
            this.showLoading = !true;
            this.selectedDemo = "";
            if(obj){
              this.shipCompartment=obj;
            }

          }
          if (data.event === 'updatehomeview') {
            if (data['data']) {
              this.camviews = data['data'];
            }
          }
          if (data.event === 'loadERNTreeModel') {
            if (data['data']) {
              this.onLoadERNTree(data);
            }
          }
          if (data.event === 'loadNSCTreeModel') {
            if (data['data']) {
              this.onLoadNSCTree(data);
            }
          }
        }
        if (data.src === "controlpanel") {
          if (data.event === 'loadcompartment') {
            this.shipCompartment = data['data'];
            this.commentWindowClose();
            if (data['key']) {
              // this.loadERNTree();
            }
          }
          if (data.event === 'reset_drag') {
            if (data['key']) {
              this.shipCompartment = (data['key'] === 'system' ? null : this.shipCompartment);
            }
          }
          if (data.event === "reset_drag") {
            if (this.arrControlBtnProps[4].state) {
              this.arrControlBtnProps[4].state = !this.arrControlBtnProps[4].state;
              this.khaConfig.dragMode = false;
              this.khaConfig.clearDrag();
            }
          }
          if (data.event == 'showhidesubsystem') {
            console.log(data, this.selectedKhaSystems);
            if (!data.data && this.selectedKhaSystems) {
              if (data.key[0] == this.selectedKhaSystems.model[0]) {
                this.khaConfig.showMatObjectTag(null, false);
              }
            }
          }
          if (data.event === 'showsystemcolors') {
            this.khaConfig.showMatObjectTag(null, false);
            this.statusMode = data['key'] == 'status' ? true : false;
          }
        }
        if (data.src === "sidepanel") {
          if (data.event === 'changeshipcomp') {
            if (this.khaConfig.datauxview) {
              this.selectedKha = this.ctrlServ.selectedKha;
              if (data['comp'] !== undefined) {
                this.ctrlServ.selectedSysComp = data.comp;
              }
              this.shipHazardList = data['data'];
              console.log('qwer', this.shipHazardList)
              this.showLoading = true;
              let area = this.selectedKha.split(" ").join("").toLowerCase();
              this.khaConfig.khamode = "system";
              this.khaConfig.resetSubSystems();
              this.khaConfig.changeKHA(area);
              // setTimeout(() => {
              this.onChangeCameraPosition('home');
              // }, 2500);

            }
          }
          if (data.event === 'toggle-struct-state') {
            let key = data['key'];
            let val = data['data'];
            this.khaConfig.struct_state_current[key] = val;
            this.khaConfig.applyDisplayRuleFor_Struct(key);
          }
          if (data.event === 'toggle-bg') {
            let val = data['data'];
            this.khaConfig.changeBackgroundColor(val);
          }
          if (data.event === 'filter-deck') {
            let val = data['data'];
            this.khaConfig.filter_deck = val === '' ? null : val;
            this.khaConfig.applyDisplayRuleFor_Deck()
          }
          if (data.event === 'showhide-all-systems') {
            let val = data['data'];
            this.khaConfig.showHideSubSystemAll(val)
          }
          if (data.event == 'intensity') {
            this.setVenueLightIntensity(data.data);
          }
          if (data.event === 'reset-sub-systems') {
            this.khaConfig.resetSubSystems();
          }
          if (data.event === 'show-multiple-systems') {
            let khaList = data['data'];
            this.khaConfig.showHideMultipleSystems(khaList);
          }
          if (data.event === 'store-compartments') {
            let boo = data['data'];
            this.khaConfig.clear_compartments = !boo;
          }
        }
        if (data.src === "main") {
          if (data.event === 'updateMaterialStatus') {
            let obj = data['data'];
            if (obj) {
              this.updateMaterialServerData(obj);
            }
          }
        }
      })

      this.broadcastIframeSubs = this.CommunicationServ.getIframeInstance()
      .subscribe((data: any) => {
        if (data.iframeConnection === "init-nsc-connection") {
          if (data.event === 'onChangeNSCTree') {
            let obj = data.data;
            if (obj) {
              this.toggleSysComp(obj);
            }
          }
        }
      })
  }

  updateMaterialServerData(data) {
    this.getMaterialStatusData(() => {
      if (!this.showBlockLoading) {
        this.khaConfig.getSystemCompStatus((boo) => {
          if (boo === true && this.statusMode === true && this.khaConfig.khasystem === data['khasystem']) {
            this.broadcastInfo({ src: 'material', event: 'change-material-status', data: '', sub: null });
          }
        })
      }
    });
  }

  loadShipImg() {
    if (this.selectedKha != '') {
      if (this.selectedKha) {
        let area = this.selectedKha.split(" ").join("").toLowerCase();
        if (area === 'firesafety') {
          this.imgSrc = '../../assets/images/fire.png';
        } else if (area === 'buoyancy') {
          this.imgSrc = '../../assets/images/buoyancy.png';
        } else if (area === 'eer') {
          this.imgSrc = '../../assets/images/eer.png';
        } else {
          this.imgSrc = '';
        }
      }
    }
    setTimeout(() => { this.showLoading = false }, 700);
  }

  getBGLabel(state) {
    let idx = this.statusList.findIndex(e => e.status == state);
    if (idx > -1) {
      return this.statusList[idx].color;
    }
  }

  ngAfterViewInit(): void {
    this.configService.loadFile('../assets/license.info').then((info) => {
      this.configService.getProjectConfig().then((project_config: any) => {
        project_config.licence = info;
        this.datauxview.setProjectSettins(project_config);
        this.datauxview.loadCanvas('settings-systems', 'json', (data, settingstatus) => {
          this.initShipObjects();
        }, (err) => { console.log(err) });
      }, (err) => { alert("License not found!") })

    })
  }

  /* * * * *
  * init objects
  * loading all the static objects from objects.json
  * * * * * */
  initObjects(cbck) {
    this.datauxview.loadAssets('objects-systems', 'json', (objectstatus) => {
      if (objectstatus) {
        this.datauxview.renderAssets('objects-systems', 'json', (objectstatus) => {
          if (objectstatus) {
            this.dfx = this.datauxview.getDatascape();
            cbck();
          }
        }, () => { })
      }
    });
  }

  /* * * * *
  * init dynamic ship objects
  * loading all the dynamic objects from objects.json
  * * * * * */
  showBlockLoading: boolean = false;
  setInterval: any;
  isMaterialAppLoaded: boolean = false;
  initShipObjects() {
    this.initObjects(() => {
      if (this.mvScreenType == 'system') {
        this.loadShipObjects((boo) => {
          //this.setVenueLightIntensity();
          this.getMaterialStatusData(() => {
            /* this.initSystemsModel();
            this.initialized = true;
            this.isMaterialAppLoaded = true;
            this.ctrlServ.isMaterialAppLoaded = true;
            this.showBlockLoading = false; */
            this.initView()
          })
        })
      }

      if (this.mvScreenType == "compartment") {
        if(this.slms_mode){
          this.loadShipObjects((boo)=>{
            this.initView()
          },this.ship_settings_slms)
        }else{
          this.initView()
        }

      }
    })
  }
    initView(){
    this.setVenueLightIntensity();
        this.initSystemsModel();
        this.initialized = true;
        this.isMaterialAppLoaded = true;
        this.ctrlServ.isMaterialAppLoaded = true;
        this.showBlockLoading = false;
  }

  /* * *
  * load dynamic ship settings and objects
  * * */
  allShipElements: any;
  loadShipObjects(cbck = null,settings=null) {
    this.dfx.addDynamicShapes(settings||this.ship_settings).then(() => {
      let shapes = this.ship_objects['shapes'];
      let objects = this.ship_objects['objects'];
      this.datauxview._createModels(shapes, (boo) => {
        this.datauxview.addElements(objects, (portObjects) => {
          if (cbck) {
            this.allShipElements = portObjects;
            cbck(true);
          }
        }, (id, pointer) => { })
      }, (id, pointer) => { })
    });
  }

  /**
   * init system model
   */
  initSystemsModel() {
    window['sys_scope'] = this;
    this.khaConfig.scene = this.scene;
    this.khaConfig.game_engine = this.scene.getEngine();
    this.khaConfig.dfx = this.dfx;
    this.khaConfig.datauxview = this.datauxview;
    if (this.mvScreenType == 'system') {
      this.processHFX();
      if (this.selectedKha) {
        let sys = this.selectedKha.split(" ").join("").toLowerCase();
        this.khaConfig.initSystems(sys);
      }
    }
    if (this.mvScreenType == "compartment") {
      if(this.slms_mode){
        //this.broadcastInfo({ src: 'khaservice', event: 'appmode', data: true});
        this.processHFX_slms();
        this.khaConfig.slms_mode=this.slms_mode;
        let ehfx = this.datauxview.getElementId('ehfx');
        let hfx=this.dfx.getElementMesh(ehfx)
        this.khaConfig.khasystemship = hfx.getChildren()[0];
        this.khaConfig.getSysDefalutPosition(this.khaConfig.khasystemship);
        //this.dfx.setElementScale(ehfx, [1, 1, -1]);
        this.khaConfig.applyDisplayRule_Struct(false,this.khaConfig.struct_state_slms_1);
      }
      this.khaConfig.processandloadComparment();
    }
    // }
  }
  processHFX_slms() {
    let ehfx = this.datauxview.getElementId("ehfx");
    let hfx = this.dfx.getElementMesh(ehfx);
    hfx.scaling.z = -1;
    this.processShipMaterial();
    hfx.getChildren()[0].getChildMeshes().forEach((mesh) => {
      let mat = mesh.material ? mesh.material.name : "";
      mesh.mid = mat;
    })
  }
  processHFX() {
    let ehfx = this.datauxview.getElementId("ehfx");
    let hfx = this.dfx.getElementMesh(ehfx);
    hfx.scaling.z = -1;
    hfx.getChildren()[0].setEnabled(false);
    let scene = this.scene;
    /* scene.materials.forEach((m) => {
      if (m.metallic) {
        m.metallic = 0;
      }
      if(m.roughness){
        m.roughness = 0.5;
      }
    }) */
    this.processShipMaterial();
    hfx.getChildren()[1].getChildMeshes().forEach((mesh) => {
      let mat = mesh.material ? mesh.material.name : "";
      mesh.mid = mat;
    })
  }
  processShipMaterial() {
    this.scene.materials.forEach((mat) => {
      if (mat.getClassName().includes("PBR")) {
        mat.specularIntensity = 0;
        mat.metallic = 0;
        mat.roughness = 1;
        mat.ambientColor = this.dfx.color3([1, 1, 1]);
      }
    })
    // setTimeout(() => {
    //   this.broadcastInfo({ src: 'material', event: 'init_Load', state: false,});
    // }, 2500);
  }
  cancelSystemLoading() {
    this.khaConfig.cancelSystemLoading()
  }
  canvasDragChange: any = false;
  dragChange(e) {
    this.canvasDragChange = e;
  }
  ngOnDestroy() {
    this.broadcastSubs.unsubscribe()
    this.khaConfig.destroy();
    let dfx = this.dfx;
    dfx.stop();
    dfx.dispose();
  }
  /* * * * *
  * method for communicate event instance with data to access all components
  * * * * * */
  broadcastInfo(data: any) {
    this.CommunicationServ.getInstance().next(data);
  }

  broadcastIframeInfo(data: any) {
    this.CommunicationServ.createIframeInstance(data);
  }

  getClassImg(shipClass) {
    return shipClass == 'Halifax' ? 'HAL' : shipClass == 'Dewolf' ? 'HDW' : shipClass == 'Kingston' ? 'KIN' : shipClass == 'Victoria' ? 'VIC' : shipClass == 'Orca' ? 'ORC' : shipClass == 'Asterix' ? 'AST' : shipClass == 'Protecteur' ? 'PRT' : shipClass == 'CSC' ? 'csc' : '';
  }
  infoToggleOpenHandle() {
    // this.infoToggleState = !this.infoToggleState;
    this.broadcastInfo({ src: 'networkmodel', event: 'sidepanel', state: null, sub: null });
  }

  toggleSysComp(data) {
    if (this.selectedKha === data['kha']) {
      if (data['comp']) {
        let comp = data['comp'].split(",");
        this.ctrlServ.selectedSysComp = comp;
        this.ctrlServ.showSysComp = comp;
        // this.khaConfig.toggleSysComp(comp);
        this.khaConfig.showHideSubSystem(this.ctrlServ.selectedSysComp, true, true);
      }
    } else {
      let comp = data['comp'].split(",");
      this.ctrlServ.selectedSysComp = comp;
      this.ctrlServ.showSysComp = comp;
      this.broadcastInfo({ src: 'material', event: 'onChangeKHASystem', data: data['kha'], comp: comp });
    }
  }
  loadERNTree() {
    this.overlayHeading = "NSSC";
    this.ernOverlayMode = true;
    this.loadUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(window.origin + "/?screenType=ern");
  }
  onLoadERNTree(data) {
    this.overlayHeading = "NSSC";
    this.ernOverlayMode = true;
    let ernCategory = data['data'];
    let ernNumber = data['ern']
    this.loadUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(window.origin + "/?screenType=ern_kha&erncategory=" + ernCategory + "&ernnumber=" + ernNumber);
  }
  onLoadNSCTree(data) {
    let selectedKha = data['data'];
    if (selectedKha) {
      this.broadcastIframeInfo({ iframeConnection: 'init-nsc-connection', event: 'onChangeNSCTree', data: selectedKha, key: "" });
    }
  }
  onCloseOverlay() {
    this.ernOverlayMode = !true;
  }
  /**
   * utility functions
   */
  private componentToHex(c) {
    var hex = c.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
  }

  rgbToHex(r, g, b) {
    return "#" + this.componentToHex(r) + this.componentToHex(g) + this.componentToHex(b);
  }

  khasystem_name: any = "";
  openStatusWindow() {
    if (this.statusMode) {
      if (this.selectedKhaSystems['desc'] === this.ctrlServ.selectedSysComments['name']) {
        this.statusMessage = this.ctrlServ.selectedSysComments['message'];
        this.statusChange = true;
      } else {
        this.statusMessage = '';
        this.statusChange = false;
      }
      let kha = { "buoyancy": "Buoyancy", "firesafety": "Fire Safety", "eer": "EER" }
      this.khasystem_name = kha[this.khaConfig.khasystem];
      this.materialStatusWindow = !this.materialStatusWindow;
    }
  }

  statusWindowClose() {
    this.statusChange = false;
    // this.statusMessage = '';
    this.materialStatusWindow = false;
  }

  statusChange = false;
  onChangeStatus(status) {
    if (this.selectedStatus != status) {
      this.selectedStatus = status;
      this.statusChange = true;
    } else {
      this.statusChange = false;
    }
  }

  onMessage(e) {
    if (this.statusMessage) {
      this.statusChange = true;
    } else {
      this.statusChange = false;
    }
  }

  /* * *
  * method for get material status data from server
  * * */
  getMaterialStatusData(cbck) {
    this.khaConfig.MaterialStatusData = [];
    this.configService.getMaterialStatusData().then((data: any) => {
      if (data) {
        this.khaConfig.MaterialStatusData = data;
        console.log("MaterialStatusData ", this.khaConfig.MaterialStatusData);
        cbck()
      } else {
        console.log('MaterialStatusData data not found !!');
        cbck()
      }
    }).catch((error) => {
      console.log("error ", error);
      cbck();
    });
  }

  addEditMaterialStatus(id, material) {
    const item = this.khaConfig.MaterialStatusData.find(el => el['name'] === id && el['khasystem'] === this.khaConfig.khasystem);
    if (item) {
      let obj = { "name": id, "material": material, "khasystem": this.khaConfig.khasystem };
      this.configService.updateMaterialStatus(item['_id'], obj).then(data => {
        if (data) {
          obj['_id'] = item['_id'];
          console.log("material status updated ", [obj]);
          this.updateMaterialStatusData([obj]);
          let message = encodeURIComponent(this.statusMessage);
          let link = window.origin + '/?screenType=materiel_kha&KHA=' + this.khaConfig.khasystem + '&comp_name=' + this.selectedKhaSystems['desc'] + '&message=' + message;
          let _obj = Object.assign({}, this.selectedKhaSystems, { 'type': "material-status-change", 'message': this.statusMessage, 'link': link, 'material': this.selectedStatus, "khasystem": this.khaConfig.khasystem, "khasysname": this.khasystem_name })
          this.broadcastInfo({ src: 'material', event: 'pushNotification', data: _obj, sub: null });
        }
      }).catch((error) => {
        console.log("error ", error)
      });
    } else {
      let obj = { "name": id, "material": material, "khasystem": this.khaConfig.khasystem };
      this.configService.createMaterialStatus(obj).then(data => {
        if (data) {
          console.log("material status created ", data);
          this.updateMaterialStatusData(data['ops']);
          let message = encodeURIComponent(this.statusMessage);
          let link = window.origin + '/?screenType=materiel_kha&KHA=' + this.khaConfig.khasystem + '&comp_name=' + this.selectedKhaSystems['desc'] + '&message=' + message;
          let obj = Object.assign({}, this.selectedKhaSystems, { 'type': "material-status-change", 'message': this.statusMessage, 'link': link, 'material': this.selectedStatus, "khasystem": this.khaConfig.khasystem, "khasysname": this.khasystem_name })
          this.broadcastInfo({ src: 'material', event: 'pushNotification', data: obj, sub: null });
        }
      }).catch((error) => {
        console.log("error ", error)
      });
    }
  }

  updateMaterialStatusData(data) {
    data.forEach(mat => {
      const idx = this.khaConfig.MaterialStatusData.findIndex(el => el._id === mat._id);
      if (idx > -1) {
        this.khaConfig.MaterialStatusData[idx]['name'] = mat['name'];
        this.khaConfig.MaterialStatusData[idx]['material'] = mat['material'];
        this.khaConfig.MaterialStatusData[idx]['khasystem'] = mat['khasystem'];
      } else {
        this.khaConfig.MaterialStatusData.push(mat);
      }
    });
  }

  updateMaterialStatus() {
    let colors = { "red": "#ff0000", "yellow": "#ffff00", "transparent": "#ababb2" };
    let mat = this.getStatusColorCode(this.selectedKhaSystems.id);
    if (mat && this.selectedStatus) {
      let subSystems = this.khaConfig.systemSubComps[mat];
      if (subSystems.length > 0) {
        const idx = subSystems.findIndex(m => m.id === this.selectedKhaSystems.id);
        if (idx > -1) {
          let item = subSystems[idx];
          this.khaConfig.systemSubComps[this.selectedStatus].push(item);
          subSystems.splice(idx, 1);
          this.selectedKhaSystems.color = colors[this.selectedStatus];
          this.khaConfig.systemSubComps[mat] = subSystems;
          this.khaConfig.updateSystemStatus(item, this.selectedStatus);
          this.statusWindowClose();
          this.addEditMaterialStatus(this.selectedKhaSystems.id, this.selectedStatus);
        }
      }
    }
  }

  compComments: any = '';
  openCommentWindow() {
    if (this.selectedKhaSystems['desc'] === this.ctrlServ.selectedCompComments['name']) {
      this.compComments = this.ctrlServ.selectedCompComments['message'];
      this.isCommentTyped = true;
    } else {
      this.compComments = '';
      this.isCommentTyped = false;
    }
    this.compCommentWindow = !this.compCommentWindow;
  }

  commentWindowClose() {
    this.compCommentWindow = false;
  }

  isCommentTyped = false;
  onComment(e) {
    if (this.compComments) {
      this.isCommentTyped = true;
    } else {
      this.isCommentTyped = false;
    }
  }

  onSaveComment() {
    this.commentWindowClose();
    let message = encodeURIComponent(this.compComments);
    let link = window.origin + '/?screenType=materiel_comp&comp_code=' + this.shipCompartment['code'] + '&comp_name=' + this.selectedKhaSystems['desc'] + '&message=' + message;
    let obj = Object.assign({}, this.selectedKhaSystems, { 'type': "compartment-comments", 'message': this.compComments, 'link': link })
    this.broadcastInfo({ src: 'material', event: 'pushNotification', data: obj, sub: null });
  }

}
