import { Injectable } from '@angular/core';

class Vector2D {
  public x: number;
  public y: number;

  public constructor(_x: number, _y: number ) {
    this.x = _x;
    this.y = _y;
  }
}



class Vector3D {
  public x: number;
  public y: number;
  public z: number;

  public constructor(_x: number, _y: number, _z: number ) {
    this.x = _x;
    this.y = _y;
    this.z = _z;
  }

  public addCoordinates(_vector: Vector3D) {
    this.x += _vector.x;
    this.y += _vector.y;
    this.z += _vector.z;
  }

  public sumCoordinates(_vector: Vector3D) {
    return new Vector3D(this.x + _vector.x, this.y + _vector.y, this.z + _vector.z );
  }

  public subCoordinates(_vector: Vector3D): Vector3D {
    return new Vector3D(this.x - _vector.x, this.y - _vector.y, this.z - _vector.z );
  }

  public factorCoordinates(_factor: number): Vector3D {
    return new Vector3D(this.x  * _factor, this.y * _factor, this.z * _factor );
  }

  public distance(_vector: Vector3D): number {
    return Math.sqrt( Math.pow( this.x - _vector.x, 2 ) + Math.pow( this.y - _vector.y, 2 ) + Math.pow( this.z - _vector.z, 2 ) ) ;
  }

  public Normalize( ): Vector3D {
    let len: number;
    len = Math.sqrt( Math.pow( this.x, 2 ) + Math.pow( this.y, 2 ) + Math.pow( this.z, 2 ) );
    return new Vector3D( this.x / len, this.y / len, this.z / len);
  }
}

class GeoCoord extends Vector2D {

  public GetMetCoord(): MetCoord {
    const x = ( this.x - LatLonFactors.origin_deg.x ) * LatLonFactors.degrees_lon_to_metres;
    const y = 0;
    const z = ( this.y - LatLonFactors.origin_deg.y ) * LatLonFactors.degrees_lat_to_metres;
    return new MetCoord(x, y, z);
  }

}

class MetCoord extends Vector3D {
 
  public GetGeoCoord(): GeoCoord {
    return null;
  }

}


class LatLonFactors {


  static origin_deg: GeoCoord;

  static degrees_lon_to_metres: number;
  static degrees_lat_to_metres: number;
  static longitude_compression: number;

  

  private xmin: number;
  private xmax: number;
  private ymin: number;
  private ymax: number;
  private zmin: number;
  private zmax: number;
  /**
   * constructor
   */
  public constructor(_origin: GeoCoord) {

    LatLonFactors.origin_deg = _origin;
    this.Calculate();

  }

  public Calculate(): string {

    const m1 = 111132.92;
    let m2 = -559.82;
    let m3 = 1.175;
    let m4 = -0.0023;
    let p1 = 111412.84;
    let p2 = -93.5;
    let p3 = 0.118;
    let lat = LatLonFactors.origin_deg.y * Math.PI / 180;

    LatLonFactors.degrees_lat_to_metres = m1 + ( m2 * Math.cos(2 * lat) ) + (m3 * Math.cos(4 * lat)) + (m4 * Math.cos(6 * lat));
    LatLonFactors.degrees_lon_to_metres = (p1 * Math.cos(lat)) + ( p2 * Math.cos(3 * lat)) + (p3 * Math.cos(5 * lat));
    LatLonFactors.longitude_compression = LatLonFactors.degrees_lon_to_metres / LatLonFactors.degrees_lat_to_metres;

    this.xmin = LatLonFactors.origin_deg.x - 500 / LatLonFactors.degrees_lon_to_metres;
    this.xmax = LatLonFactors.origin_deg.x + 500 / LatLonFactors.degrees_lat_to_metres;

    this.ymin = 0;
    this.ymax = 375;

    this.zmin = LatLonFactors.origin_deg.y - 500 / LatLonFactors.degrees_lat_to_metres;
    this.zmax = LatLonFactors.origin_deg.y + 500 / LatLonFactors.degrees_lat_to_metres;

    console.log(LatLonFactors.degrees_lat_to_metres, '-' , LatLonFactors.degrees_lon_to_metres);
    console.log(this);
    return '';
  }
}


class GeoObject {

  private _MetCoord: Array<MetCoord> = new Array<MetCoord>();
  private _GeoCoord: Array<GeoCoord> = new Array<GeoCoord>();
  public min_points = 4;
  public first_point = 0;

  public constructor( _coord: Array<any>) {

      if (_coord !== null && _coord.length > 0 ) {

      if (_coord[0] instanceof MetCoord ) {
        _coord.forEach(element => {
          this._MetCoord.push(element);
        });
        // this._MetCoord = Object.assign(<MetCoord> _coord);
      }

      if (_coord[0] instanceof GeoCoord ) {
        // this._GeoCoord = Object.assign(<GeoCoord> _coord);
        _coord.forEach(element => {
          this._GeoCoord.push(element);
        });
      }
    }
  }



  public ValidatePoints( _points: Array<any>) {
    
    if ( _points.length >= this.min_points ) {
      if ( _points.length === this.min_points ) {
          _points.push( _points[this.first_point] );
      }
    }
  }

  public GetMetCoord(): Array<MetCoord> {

      if ( this._MetCoord === undefined || this._MetCoord.length === 0 ) {

        if ( this._GeoCoord === undefined ) {
            return null;
        }

        this.ValidatePoints( this._GeoCoord);

        this._GeoCoord.forEach(element => {
          this._MetCoord.push( element.GetMetCoord() );
        });

    }

      return this._MetCoord;
  }

  public GetGeoCoord(): Array<GeoCoord> {

    if ( this._GeoCoord === undefined ) {

      if ( this._MetCoord === undefined ) {
        return null;
      }

  }

    return this._GeoCoord;
  }
}

class MeshFace {
  public i: number;
  public j: number;
  public k: number;

  public constructor(_i, _j, _k) {
    this.i = _i;
    this.j = _j;
    this.k = _k;
  }
}

class SubmeshData {

  public faces: Array<MeshFace> = new Array<MeshFace>();
  public materialIdx: number;
  public centreSum: Vector3D = new Vector3D(0, 0, 0);

  public constructor() {

  }
}




class Vertex {

  public oPosition: Vector2D;

}


class NormalUvVertex {
  public oNormal: Vector3D;
  public oPositon: Vector3D;
  public oUv: Vector2D;

  public constructor(_position: Vector3D, _normal: Vector3D, _uv: Vector2D) {
    this.oPositon = _position;
    this.oNormal = _normal;
    this.oUv = _uv;
  }

  public NormalUvVertex(_position: Vector3D, _normal: Vector3D, _uv: Vector2D) {

    this.oPositon = _position;
    this.oNormal = _normal;
    this.oUv = _uv;

  }

}

class BBox {
  static minPt: Vector3D = new Vector3D(0, 0, 0);
  static maxPt: Vector3D = new Vector3D(0, 0, 0);

  public constructor(_minX, _minY, _minZ, _maxX, _maxY, _maxZ) {
    BBox.minPt.x = _minX;
    BBox.minPt.y = _minY;
    BBox.minPt.z = _minZ;

    BBox.maxPt.x = _maxX;
    BBox.maxPt.y = _maxY;
    BBox.maxPt.z = _maxZ;
  }

  static Update( _Pt: Vector3D) {
    BBox.minPt.x = Math.min( BBox.minPt.x, _Pt.x);
    BBox.minPt.y = Math.min( BBox.minPt.x, _Pt.y);
    BBox.minPt.z = Math.min( BBox.minPt.x, _Pt.z);

    BBox.maxPt.x = Math.max( BBox.maxPt.x, _Pt.x);
    BBox.maxPt.y = Math.max( BBox.maxPt.x, _Pt.y);
    BBox.maxPt.z = Math.max( BBox.maxPt.x, _Pt.z);
  }

  static Dimensions(): Vector3D {
    return BBox.maxPt.subCoordinates( BBox.minPt);
  }
}

class MeshData {

  public oVertices: Array<NormalUvVertex> = new Array<NormalUvVertex>();
  public oSubMeshes: Array<SubmeshData> = new Array<SubmeshData>();
  
  private bAddedface: boolean;

  public constructor() {
    this.bAddedface = false;
  }

  public GetNVerts(): number {
    return this.oVertices.length;
  }

  public AddVert( _normaluvVertx: NormalUvVertex): number {
    this.oVertices.push( _normaluvVertx );
    BBox.Update(_normaluvVertx.oPositon);
    return this.oVertices.length;
  }

  public AddFace(_i: number, _j: number, _k: number, _mtlIdx = 0): number {
    this.bAddedface = true;

    // not sure what is this for, just adding as per tom's code
    let tmpSubMeshdata: SubmeshData;
    /*
    this.oSubMeshes.forEach(element => {
      if ( _mtlIdx === element.materialIdx) {
        // break;
      }
    });
    */
   
    if ( _mtlIdx >= this.oSubMeshes.length ){
      this.oSubMeshes.push( new SubmeshData());
    }

    tmpSubMeshdata = this.oSubMeshes[_mtlIdx];
    tmpSubMeshdata.faces.push( new MeshFace( _i, _j, _k) );

    tmpSubMeshdata.materialIdx = _mtlIdx;
    tmpSubMeshdata.centreSum.addCoordinates( this.oVertices[_i].oPositon );
    tmpSubMeshdata.centreSum.addCoordinates( this.oVertices[_j].oPositon );
    tmpSubMeshdata.centreSum.addCoordinates( this.oVertices[_k].oPositon );

    return this.oSubMeshes.length;
  }

  public AssignMeshToModel(): any {
    return null;
  }

  public FaceNormal(): any {
    return null;
  }

}


class BabylonShape {
  public sName: string;
  public sID: string;
  public oBabylon: any = {};

  public constructor(_sName, _sID) {
      this.sName = _sName;
      this.sID = _sID;
  }


}


class ShapeCreator {

  public oUV: Vector2D;
  // public oMeshData: Array<MeshData> = new Array<MeshData>();
  public oMeshData: MeshData = new MeshData();
  public oNormal: Vector3D;

  public constructor(_uv: Vector2D = null) {
    this.InitShape(_uv);
  }

  public InitShape(_uv: Vector2D = null) {

    if ( _uv !== null ) {
      this.oUV = _uv;
    } else {
      this.oUV = new Vector2D( 92 / 1024.0, (1024 - 340) / 1024.0 );
    }

    this.oNormal = new Vector3D(0, 1, 0);
    // this.oMeshData = _meshData;
  }

  public BabylonQuadsFromGeo(_geoObjects: Array<GeoObject>): MeshData {

    let nVerts = 0;
    const vxOffset = this.oMeshData.GetNVerts();

    _geoObjects.forEach(elementGeoObject => {

      const arrMetCoord = elementGeoObject.GetMetCoord();

      for (let arridx = 0; arridx < arrMetCoord.length - 1; arridx++) {
        this.oMeshData.AddVert( new NormalUvVertex(arrMetCoord[arridx], this.oNormal, this.oUV) );
      }
      const numFaces = arrMetCoord.length - 3;
      let s= nVerts;
      for(let i=0;i<numFaces;i++){
      this.oMeshData.AddFace( vxOffset + nVerts + (i+2), vxOffset + nVerts + (i+1), vxOffset + nVerts + 0 );
      //this.oMeshData.AddFace( vxOffset + nVerts + 3, vxOffset + nVerts + 2, vxOffset + nVerts + 0 );
      }
      nVerts = nVerts +(numFaces+2);

    });
    return this.oMeshData;
  }


}

class GroundCreator extends ShapeCreator {
  public BabylonQuadsFromGeo(_geoObjects: Array<GeoObject>): MeshData {
/*
    const heading_deg = 0;
    const length_met: number = BBox.Dimensions().z;
    const width_met: number = BBox.Dimensions().x;
    const pos_met: MetCoord = new MetCoord(0, 0, 0);

    const halfCentreLine: Vector3D = new Vector3D( 0.5 * length_met * Math.sin( heading_deg * Math.PI / 180) , 0,
                                                  0.5 * length_met * Math.cos( heading_deg * Math.PI / 180) );


                                                  const halfCross_met: Vector3D = new Vector3D( 0.5 * width_met * Math.sin( heading_deg * Math.PI / 180) , 0,
    0.5 * width_met * Math.cos( heading_deg * Math.PI / 180) );

    
    
    const arrPoints: Array<MetCoord> = new Array<MetCoord>();
    

    arrPoints.push( pos_met.subCoordinates( halfCross_met ).subCoordinates (halfCentreLine)  );
    arrPoints.push( pos_met.subCoordinates( halfCross_met ).sumCoordinates (halfCentreLine)  );
    arrPoints.push( pos_met.sumCoordinates( halfCross_met ).sumCoordinates (halfCentreLine)  );
    arrPoints.push( pos_met.sumCoordinates( halfCross_met ).subCoordinates (halfCentreLine)  );
    arrPoints.push( pos_met.subCoordinates( halfCross_met ).subCoordinates (halfCentreLine)  );

    _geoObjects.push( arrPoints );

    for( let igr = 0; igr < arrPoints.length; igr++) {
      const metres: Vector3D = arrPoints[igr];
      let latlon: Vector2D;
      latlon.x = metres.x / LatLonFactors.degrees_lon_to_metres + LatLonFactors.origin_deg.x;
      latlon.y = metres.x / LatLonFactors.degrees_lat_to_metres + LatLonFactors.origin_deg.y;
      arrPoints.push( latlon);
    }
*/
    return this.oMeshData;
  }
}

class SlotsCreator extends ShapeCreator {

  private start0_met: Vector3D;
  private start1_met: Vector3D;
  private end2_met: Vector3D;
  private end3_met: Vector3D;

  public WhichSideIsWhich( _geoObject: GeoObject) {
    this.start0_met = _geoObject.GetMetCoord()[0];
    this.start1_met = _geoObject.GetMetCoord()[1];
    this.end2_met = _geoObject.GetMetCoord()[2];
    this.end3_met = _geoObject.GetMetCoord()[3];

    let endLen_met: number;
    let sideLen_met: number;
    endLen_met = this.start1_met.distance( this.start0_met);
    sideLen_met = this.end2_met.distance( this.start1_met);

    if ( endLen_met > sideLen_met ) {
      let tmp: Vector3D;
      tmp = this.start0_met;
      this.start0_met = this.start1_met;
      this.start1_met = this.end2_met;
      this.end2_met = this.end3_met;
      this.end3_met = tmp;
    }
  }

  

  public BabylonQuadsFromGeo(_geoObjects: Array<GeoObject>): MeshData {

    let nVerts = 0;
    let startDir: Vector3D;
    let sideDir: Vector3D;
    const SLOT_OUTLINE_WIDTH_MET = 0.25;

    const vxOffset = this.oMeshData.GetNVerts();

    _geoObjects.forEach(elementGeoObject => {

      const arrMetCoord = elementGeoObject.GetMetCoord();

      this.WhichSideIsWhich( elementGeoObject);
      startDir = this.start1_met.subCoordinates( this.start0_met ).Normalize();
      sideDir = this.end2_met.subCoordinates( this.start1_met ).Normalize();


      const mesh: Vector3D[][] = [[], [], [], []];

      mesh[0][0] = this.start0_met.subCoordinates( startDir.factorCoordinates( SLOT_OUTLINE_WIDTH_MET * 0.5) );
      mesh[0][1] = this.start0_met.sumCoordinates( startDir.factorCoordinates( SLOT_OUTLINE_WIDTH_MET * 1.5) );
      mesh[0][2] = this.start1_met.subCoordinates( startDir.factorCoordinates( SLOT_OUTLINE_WIDTH_MET * 1.5) );
      mesh[0][3] = this.start1_met.sumCoordinates( startDir.factorCoordinates( SLOT_OUTLINE_WIDTH_MET * 0.5) );

      mesh[1][0] = mesh[0][0].sumCoordinates( sideDir.factorCoordinates( SLOT_OUTLINE_WIDTH_MET * 2) );
      mesh[1][1] = mesh[0][1].sumCoordinates( sideDir.factorCoordinates( SLOT_OUTLINE_WIDTH_MET * 2) );
      mesh[1][2] = mesh[0][2].sumCoordinates( sideDir.factorCoordinates( SLOT_OUTLINE_WIDTH_MET * 2) );
      mesh[1][3] = mesh[0][3].sumCoordinates( sideDir.factorCoordinates( SLOT_OUTLINE_WIDTH_MET * 2) );
/*
      mesh[3][0] = this.end3_met.subCoordinates( startDir.factorCoordinates( SLOT_OUTLINE_WIDTH_MET * 0.5).sumCoordinates( sideDir.factorCoordinates(SLOT_OUTLINE_WIDTH_MET) ) );
      mesh[3][1] = this.end3_met.sumCoordinates( startDir.factorCoordinates( SLOT_OUTLINE_WIDTH_MET * 1.5).sumCoordinates( sideDir.factorCoordinates(SLOT_OUTLINE_WIDTH_MET) ) );
      mesh[3][2] = this.end2_met.subCoordinates( startDir.factorCoordinates( SLOT_OUTLINE_WIDTH_MET * 1.5).sumCoordinates( sideDir.factorCoordinates(SLOT_OUTLINE_WIDTH_MET) ) );
      mesh[3][3] = this.end2_met.sumCoordinates( startDir.factorCoordinates( SLOT_OUTLINE_WIDTH_MET * 0.5).sumCoordinates( sideDir.factorCoordinates(SLOT_OUTLINE_WIDTH_MET) ) );
*/
let sp_05=startDir.factorCoordinates( SLOT_OUTLINE_WIDTH_MET * 0.5);
      let sp_15=startDir.factorCoordinates( SLOT_OUTLINE_WIDTH_MET * 1.5);
      let sd=sideDir.factorCoordinates(SLOT_OUTLINE_WIDTH_MET);
      let p1=sp_05.sumCoordinates(sd);
      let p2=sp_15.sumCoordinates(sd);
      mesh[3][0] = this.end3_met.subCoordinates( sp_05 ).sumCoordinates(sd);
      mesh[3][1] = this.end3_met.sumCoordinates( sp_15 ).sumCoordinates(sd);
      mesh[3][2] = this.end2_met.subCoordinates( sp_15).sumCoordinates(sd);
      mesh[3][3] = this.end2_met.sumCoordinates( sp_05 ).sumCoordinates(sd);
      
      mesh[2][0] = mesh[3][0].subCoordinates( sideDir.factorCoordinates( SLOT_OUTLINE_WIDTH_MET * 2) );
      mesh[2][1] = mesh[3][1].subCoordinates( sideDir.factorCoordinates( SLOT_OUTLINE_WIDTH_MET * 2) );
      mesh[2][2] = mesh[3][2].subCoordinates( sideDir.factorCoordinates( SLOT_OUTLINE_WIDTH_MET * 2) );
      mesh[2][3] = mesh[3][3].subCoordinates( sideDir.factorCoordinates( SLOT_OUTLINE_WIDTH_MET * 2) );

      const texu: number[] = [];
      texu[0] = 20 / 200.0;
      texu[1] = 40 / 200.0;
      texu[2] = 160 / 200.0;
      texu[3] = 179 / 200.0;

      const texv: number[] = [];
      texv[0] = 20 / 200.0;
      texv[1] = 40 / 200.0;
      texv[2] = 160 / 200.0;
      texv[3] = 179 / 200.0;

      const vix: number[][] = [[4], [4], [4], [4]];

      for ( let ix = 0; ix < 4; ix++) {
        for ( let iz = 0; iz < 4; iz++) {
            const pos: Vector3D = mesh[ix][iz];
            const uv: Vector2D = new Vector2D( texu[ix], texv[iz] );

            this.oMeshData.AddVert( new NormalUvVertex(pos, this.oNormal, uv) );
            vix[ix][iz] = nVerts;
            nVerts++;
        }
      }

      for ( let ix = 1; ix < 4; ix++) {
        for ( let iz = 1; iz < 4; iz++) {
          this.oMeshData.AddFace( vix[ix - 1][iz - 1], vix[ix][iz - 1], vix[ix][iz] );
          this.oMeshData.AddFace( vix[ix - 1][iz - 1], vix[ix][iz], vix[ix - 1][iz] );
        }
      }

    });
    return this.oMeshData;
  }
}

class BabylonWriter {

  // private oBabylon: BabylonObject = new BabylonObject();
  // private arrBabylonJson: any = new Array<any>();
  // private oMeshData: MeshData = new MeshData();
  private arrBabylonShapes = new Array<BabylonShape>();

  public constructor() {

  }
  private getAlphaIndex(id){
    let idx=100;
    switch (id){
      case "slots":
        idx=500;
        break;
        case "areas":
        idx=400;
        break;
        case "lots":
        idx=300;
        break;
        case "storageTracks":
        idx=2000;
        break;
        case "stripTracks":
        idx=2000;//999999
        break;
        case "trackSegments":
        idx=2100;//999999
        break;
        case "inGates":
        idx=999999;//999999
        break;
        case "outGates":
        idx=999999;//999999
        break;
    }
    return idx;
  }
  
  public addBabylonShape(_name, _id, _meshData): any {


    if ( this.arrBabylonShapes[_id] === undefined ) {
      this.arrBabylonShapes[_id] = new BabylonShape(_name, _id);
      // this.oBabylon = this.arrBabylonShapes[_id].oBabylon;
    }

    this.UpdateMeshData(_name, _id);
    // this.oMeshData = _meshData;
    this.UpdateNormalUVVertex(_id, _meshData.oVertices);
    // not sure why only first element is used to construct
    this.UpdateIndices(_id, _meshData.oSubMeshes[0]);

    return this.arrBabylonShapes[_id];
  }

  public getBabylonShape(_id): any {

    if ( this.arrBabylonShapes[_id] === undefined ) {
      this.arrBabylonShapes[_id] = new BabylonShape(_id, _id);
      // this.oBabylon = this.arrBabylonShapes[_id].oBabylon;
    }

    return this.arrBabylonShapes[_id];
  }

  public UpdateNormalUVVertex( _id, _vertices: Array<NormalUvVertex>) {
    const _positions = new Array<number>();
    const _normals = new Array<number>();
    const _uv = new Array<number>();

    // let oBabylon = this.getBabylonShape(_id);

    _vertices.forEach(element => {
      // this.oBabylon['positions'] = element.oPositon;
      _positions.push(element.oPositon.x);
      _positions.push(element.oPositon.y);
      _positions.push(element.oPositon.z);

      _normals.push(element.oNormal.x);
      _normals.push(element.oNormal.y);
      _normals.push(element.oNormal.z);

      _uv.push(element.oUv.x);
      _uv.push(element.oUv.y);
    });

    this.arrBabylonShapes[_id].oBabylon['positions'] = _positions;
    this.arrBabylonShapes[_id].oBabylon['normals'] = _normals;
    this.arrBabylonShapes[_id].oBabylon['uvs'] = _uv;
    this.arrBabylonShapes[_id].oBabylon['alphaIndex'] = this.getAlphaIndex(_id);
  }

  public UpdateIndices(_id, _submesh: SubmeshData) {
    const _indices = new Array<number>();
    _submesh.faces.forEach(element => {
      _indices.push( element.i );
      _indices.push( element.j );
      _indices.push( element.k );
    });
    this.arrBabylonShapes[_id].oBabylon['indices'] = _indices;
  }

  public UpdateMeshData(_name, _id) {


    this.arrBabylonShapes[_id].oBabylon['name'] = _name;
    this.arrBabylonShapes[_id].oBabylon['id'] = _name;
    this.arrBabylonShapes[_id].oBabylon['aphaindex'] = 500;
    this.arrBabylonShapes[_id].oBabylon['position'] = [0, 0, 0];
    this.arrBabylonShapes[_id].oBabylon['rotation'] = [0, 0, 0];
    this.arrBabylonShapes[_id].oBabylon['scaling'] = [1, 1, 1];
    this.arrBabylonShapes[_id].oBabylon['isVisible'] = true;
    this.arrBabylonShapes[_id].oBabylon['isEnabled'] = true;
    this.arrBabylonShapes[_id].oBabylon['checkCollisions'] = false;
    this.arrBabylonShapes[_id].oBabylon['billboardMode'] = 0;
    this.arrBabylonShapes[_id].oBabylon['receiveShadows'] = false;
  }


  public GetJsonString(_id): string {
    let _json: any;
    _json = {'meshes': [this.arrBabylonShapes[_id].oBabylon]};
    //console.log(_json);
    return JSON.stringify(_json);
  }

}


@Injectable()
export class ShapeCreatorService {
  _LatLonFactors: LatLonFactors;
  _DxCoord: GeoObject;
  oGeoObjects: Array<GeoObject> = new Array<GeoObject>();
  arrLotsGeoObjects: Array<GeoObject> = new Array<GeoObject>();
  arrGatesGeoObjects: Array<GeoObject> = new Array<GeoObject>();
  BBox = new BBox(-750, 0, -1800, 1000, 750, 1800);

  
  waterUV: Vector2D = new Vector2D(12 / 1024.0 , ( 1024 - 292 ) / 1024.0);
  landUV: Vector2D = new Vector2D(12/1024.0,(1024-340)/1024.0);
  lotsUV: Vector2D = new Vector2D(28/1024.0,(1024-340)/1024.0);
  areasUV: Vector2D = new Vector2D(44/1024.0,(1024-340)/1024.0);
  slotsUV: Vector2D = new Vector2D(60/1024.0,(1024-340)/1024.0);
  stripsUV: Vector2D = new Vector2D(76/1024.0,(1024-340)/1024.0);
  storesUV: Vector2D = new Vector2D(92/1024.0,(1024-340)/1024.0);
  outlinesUV: Vector2D = new Vector2D(107/1024.0,(1024-340)/1024.0);
  segsUV: Vector2D = new Vector2D(871/1024.0,(1024- 30)/1024.0);
  outGatesUV: Vector2D = new Vector2D(28/1024.0,(1024-324)/1024.0);
  inGatesUV: Vector2D = new Vector2D(28/1024.0,(1024-308)/1024.0);
  edgesUV: Vector2D = new Vector2D(76/1024.0,(1024-308)/1024.0);
  waypointsUV: Vector2D = new Vector2D(44/1024.0,(1024-308)/1024.0);

  jsonUV: any = {
    'default': new Vector2D(0,0),
    'landUV': new Vector2D(12 / 1024.0, ( 1024 - 340 ) / 1024.0),
    'lots': new Vector2D(28 / 1024.0 , ( 1024 - 340 ) / 1024.0),
    'areas': new Vector2D(44 / 1024.0, ( 1024 - 340 ) / 1024.0),
    'areasUV': new Vector2D(44/1024.0,(1024-340)/1024.0),
    'slots': new Vector2D(60 / 1024.0 , ( 1024 - 340 ) / 1024.0),
    'storageTracks': new Vector2D(92 / 1024.0, ( 1024 - 340 ) / 1024.0),
    'stripTracks': new Vector2D(76 / 1024.0 , ( 1024 - 340 ) / 1024.0),
    'inGates': new Vector2D(28 / 1024.0, ( 1024 - 308 ) / 1024.0),
    'outGates': new Vector2D(28 / 1024.0 , ( 1024 - 324 ) / 1024.0),
    'trackSegments': new Vector2D(871 / 1024.0, ( 1024 - 30 ) / 1024.0)
  };

  // var defuv: any = [];
  /**
   * constructor
*/


  public constructor(x, y) {

    this._LatLonFactors = new LatLonFactors(new GeoCoord(x, y) );
    // this._LatLonFactors.Calculate(_origin);

  }

  public GetMetCoordinates(_arrcoord) {
    const _arrGeoCoord: Array<GeoCoord> = new Array<GeoCoord>();
    _arrcoord.forEach(_coord => {
      // const _points: string[] = _coord.split(',');
      _arrGeoCoord.push( new GeoCoord( Number(_coord[0]), Number(_coord[1])));//
    });

    return _arrGeoCoord[0].GetMetCoord();
  }

  public ConstructTerminalShape(_id, _name, _arrGeoLines): string {
    const arrGeoObjects: Array<GeoObject> = new Array<GeoObject>();

    Object.entries(_arrGeoLines).forEach(
      ([key, value]) => {
        let _arrcoord
        if(value.hasOwnProperty('coords')){
          _arrcoord = value['coords'];
        }else{
          _arrcoord = value['geometry']['coordinates'][0];
        }
        
        const _arrGeoCoord: Array<GeoCoord> = new Array<GeoCoord>();
        _arrcoord.forEach(_coord => {
          // const _points: string[] = _coord.split(',');
          _arrGeoCoord.push( new GeoCoord( Number(_coord[0]), Number(_coord[1])));
        });
  
        arrGeoObjects.push(new GeoObject(_arrGeoCoord));
  
      });
    const oBabylonWriter = new BabylonWriter();
    let oMeshData: MeshData;

    let oLotsShapeCreator;
    if ( _id === 'slots' ) {
      oLotsShapeCreator = new SlotsCreator(); //SlotsCreator();
    } else {
      oLotsShapeCreator = new ShapeCreator();
    }

    let uv: Vector2D;
    if ( this.jsonUV[_id] === undefined) {
      uv = this.jsonUV['default'];
    } else {
      uv = this.jsonUV[_id];
    }
    oLotsShapeCreator.InitShape(uv);
    oMeshData = oLotsShapeCreator.BabylonQuadsFromGeo(arrGeoObjects);
    oBabylonWriter.addBabylonShape(_name, _id, oMeshData);
    //console.log(oBabylonWriter.GetJsonString(_id));
    // this.oJsonService.saveJson(oBabylonWriter.GetJsonString(_id), _id + '.babylon');

    // return 'OK';
    return oBabylonWriter.GetJsonString(_id);
  }

  public ConstructTerminalShapeFromStr(_id, _name, _arrGeoLines): string {
    const arrGeoObjects: Array<GeoObject> = new Array<GeoObject>();

    _arrGeoLines.forEach(element => {
      const _arrcoord: string[] = element.split(';');
      const _arrGeoCoord: Array<GeoCoord> = new Array<GeoCoord>();
      _arrcoord.forEach(_coord => {
        const _points: string[] = _coord.split(',');
        _arrGeoCoord.push( new GeoCoord( Number(_points[0]), Number(_points[1])));
      });

      arrGeoObjects.push(new GeoObject(_arrGeoCoord));
    });

    // this.oJsonService = new JsonService(this.oHttp);
    const oBabylonWriter = new BabylonWriter();
    let oMeshData: MeshData;

    let oLotsShapeCreator;
    if ( _id === 'slots' ) {
      oLotsShapeCreator = new SlotsCreator(); //SlotsCreator();
    } else {
      oLotsShapeCreator = new ShapeCreator();
    }

    let uv: Vector2D;
    if ( this.jsonUV[_id] === undefined) {
      uv = this.jsonUV['default'];
    } else {
      uv = this.jsonUV[_id];
    }
    oLotsShapeCreator.InitShape(uv);
    oMeshData = oLotsShapeCreator.BabylonQuadsFromGeo(arrGeoObjects);
    oBabylonWriter.addBabylonShape(_name, _id, oMeshData);
    //console.log(oBabylonWriter.GetJsonString(_id));
    // this.oJsonService.saveJson(oBabylonWriter.GetJsonString(_id), _id + '.babylon');

    // return 'OK';
    return oBabylonWriter.GetJsonString(_id);
  }

  public MakeColourRect(groundXDim_met, groundZDim_met, u, v) {
    let mesh = { positions: [], normals: [], uvs: [], indices: [] };
    var left = -groundXDim_met / 2;
    var right = +groundXDim_met / 2;
    var front = +groundZDim_met / 2;
    var back = -groundZDim_met / 2;
    var positions = [
        left, 0, front,
        left, 0, back,
        right, 0, back,
        right, 0, front,
    ];
    var normals = [
        0, 1, 0,
        0, 1, 0,
        0, 1, 0,
        0, 1, 0,
    ];
    var uvs = [
        u, v,
        u, v,
        u, v,
        u, v,
    ];
    var indices = [
        0, 1, 2,
        0, 2, 3,
    ];
    mesh.positions = positions;
    mesh.normals = normals;
    mesh.uvs = uvs;
    mesh.indices = indices;
    return mesh;
  };

}
