import { Component, Input, Output, EventEmitter ,AfterViewInit, DoCheck, HostListener, ViewChild, ChangeDetectorRef} from '@angular/core';
import { MatSelectionList } from '@angular/material';
import { SpeedDialFabPosition } from '../speed-dial-fab/speed-dial-fab.component';
import { LanguageService } from '../services/language.service';
import { TranslateService } from '@ngx-translate/core';

export interface Element {
  name: string;
  icon: any;
  state:boolean;
  ticon?
}

const ELEMENT_DATA: Element[] = [

  { name: 'play anim', icon: 'play_arrow',state:false,ticon:'pause'},
  { name: 'heat map', icon: 'layers',state:true,ticon:'layers_clear'},
  { name: 'home', icon: 'home',state:true},
  { name: '2D', icon: false,state:true},
  { name: 'zoomin', icon: 'add',state:true},
  { name: 'zoomout', icon: 'remove',state:true},
  { name: 'left', icon: 'left',state:false},
  { name: 'right', icon: 'right',state:false},
  { name: 'back', icon: 'back',state:false},
  { name: 'wireframe', icon: 'left',state:false},
  { name: 'solid', icon: 'right',state:false},
  { name: 'texture', icon: 'back',state:false},
  { name: 'camera', icon: 'video_camera_on',state:true,ticon:'video_camera_off'},
  { name: 'top', icon: 'top',state:false},
  { name: 'label', icon: 'label',state:false},
  { name: 'sensor', icon: 'wifi',state:true},
  { name: 'target', icon: 'gps',state:true},
  { name: 'drag', icon: 'anchor',state:false},
  { name: 'drag_reset', icon: 'water',state:true},
  { name: 'front', icon: 'front',state:false}
];



@Component({
  selector: 'app-positiontools',
  templateUrl: './positiontools.component.html',
  styleUrls: ['./positiontools.component.scss']
})
export class PositiontoolsComponent implements AfterViewInit, DoCheck {


  @ViewChild (MatSelectionList ,{ static: true }) obj: MatSelectionList;

  displayedColumns: string[] = ['position'];
  canvasobjects = [];
  dataSource = ELEMENT_DATA;
  version = 'Ver 1.0';
  listbuttonsclick =  false;
  panelstatus = false;
  animStatus:string;
  disableTransCtrl=false;
  @Output() positionToolEvent = new EventEmitter<string>();
  @Output() changeCameraMode = new EventEmitter<string>();
  @Output() getElementProperties = new EventEmitter<string>();
  @Output() changeObjectPosition = new EventEmitter<object>();
  @Output() changeObjectSize = new EventEmitter<object>();
  @Output() changeObjectOrientation = new EventEmitter<object>();
  @Output() zoom = new EventEmitter<string>();
  @Output() trigger = new EventEmitter<object>();
  @Output() cameraHideShow = new EventEmitter<any>();
  @Output() sensorHideShow = new EventEmitter<any>();
  @Output() targetHideShow = new EventEmitter<any>();
  @Output() multiTagState = new EventEmitter<any>();
  @Input() getElements;
  @Input() selElement;
  @Input() positiontool;
  @Input() socketcontroler;
  @Input() showAnim;
  @Input() set anim_status(v : string) {

    this.animStatus=v;
    if(v==='stopped'){
      this.dataSource[0].state=true;
    }else if(v==='paused'){
      this.dataSource[0].state=true;
    }else if(v==='playing'){
      this.dataSource[0].state=!true;
    }
  }
  @Input() set _disableTrans(v: any) {
    this.disableTransCtrl = v;

  }
  @Input() set shipDocked(v: any) {
    this.dataSource[17].state=v;

  }
  private speedDialFabButtons =[

    { name: 'play anim', icon: 'play_arrow',},
    { name: 'heat map', icon: 'layers',state:true,ticon:'layers_clear'},
    { name: 'home', icon: 'home',state:true},
    { name: '2D', icon: false,state:true},
    { name: 'zoomin', icon: 'add',state:true},
    { name: 'zoomout', icon: 'remove',state:true},
    { name: 'left', icon: 'left',state:false},
    { name: 'right', icon: 'right',state:false},
    { name: 'back', icon: 'back',state:false},
    { name: 'wireframe', icon: 'left',state:false},
    { name: 'solid', icon: 'right',state:false},
    { name: 'texture', icon: 'back',state:false},
    { name: 'camera', icon: 'video_camera_on',state:true,ticon:'video_camera_off'},
    { name: 'top', icon: 'top',state:false},
    { name: 'label', icon: 'label',state:false},
    { name: 'sensor', icon: 'wifi',state:true},
    { name: 'target', icon: 'gps',state:true}
  ];
  windowWidth:any=window.innerWidth;
  windowHeight:any=window.innerHeight;
  mobileControlTool:any=false;
  controlToggleOpen:any=false;
  camView = '#4';
  searchTerm ='';
  changeView = {
                '#4': '#1',
                '#1': '#2',
                '#2': '#3',
                '#3': '#4'
              };
  constructor(private cdr: ChangeDetectorRef,private translate: TranslateService, private language: LanguageService) {
    console.log("this.dataSource",this.dataSource);
    this.windowWidth=window.innerWidth;
    this.windowHeight=window.innerHeight;
    if(this.windowWidth<760){
      this.mobileControlTool=true;
    }
    this.language.languageChange.subscribe(props => {
      console.log("language", props);
      this.translate.use(props);
    })
  }
  showControlTool(){
    this.controlToggleOpen = !this.controlToggleOpen;
    this.mobileControlTool = !this.mobileControlTool;
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.windowWidth=event.target.innerWidth;
    this.windowHeight=event.target.innerHeight;
    if(this.windowWidth<760){
      this.mobileControlTool=true;
    }else{
      this.mobileControlTool=false;
    }
  }
  ngAfterViewInit(): void {
    this.panelstatus = true;
    this.cdr.detectChanges();
  }

  searchmode = false;
  ngDoCheck(): void {
    // fetch elements
    if(!this.searchmode){
      this.canvasobjects = this.getElements;
    }
  }

  closetool(){
    this.positiontool = false;
  }

  closecontant(){
    this.panelstatus = true;
    this.socketcontroler = false;
  }

  opencontant(callback){
    this.panelstatus = false;
    if(!this.socketcontroler){
      this.socketcontroler = true;
    }
    callback(this.socketcontroler);
  }
  viewMode:any='texture';
  buttonclick(row,index){

    if(row.name === 'changecamera'){
      this.camView = this.changeView[this.camView];
      this.positionToolEvent.next(this.camView);
    } else if(row.name === 'drag') {
      this.dataSource[index].state=!this.dataSource[index].state;
      this.positionToolEvent.next(row.name);
    } else if(row.name === 'drag_reset') {
      this.dataSource[index].state=!this.dataSource[index].state;
      this.positionToolEvent.next(row.name);
    } else if(row.name === 'home') {
      this.positionToolEvent.next(row.name);
    } else if(row.name === '2D') {
      this.changeCameraMode.next(row.name);
      this.dataSource[index].name ='3D';
    } else if(row.name === '3D') {
      this.changeCameraMode.next(row.name);
      this.dataSource[index].name ='2D';
    } else if(row.name === 'zoomin') {
      this.zoom.next(']');
    } else if(row.name === 'zoomout') {
      this.zoom.next('[');
    } else if(row.name === 'xwireframe') {
      this.changeWireframeMode(index);
    }else if(row.name === 'heat map') {
      this.dataSource[index].state=!this.dataSource[index].state;
      this.positionToolEvent.next('heat_mode');

    }else if(row.name === 'play anim') {
      this.dataSource[index].state=!this.dataSource[index].state;
      this.positionToolEvent.next('play_anim');

    }else if(row.name === 'left') {
      this.positionToolEvent.next('left');
    }else if(row.name === 'right') {
      this.positionToolEvent.next('right');
    }else if(row.name === 'back') {
      this.positionToolEvent.next('back');
    }else if(row.name === 'top') {
      this.positionToolEvent.next('top');
    }else if(row.name === 'front') {
      this.positionToolEvent.next('front');
    }else if(row.name === 'solid') {
      let idx =  this.dataSource.findIndex(e=>e.name == 'label');
      if(idx>-1){
        this.dataSource[idx].state = false;
      }
      let index =  this.dataSource.findIndex(e=>e.name == 'sensor');
      if(index>-1){
        this.dataSource[index].state = true;
      }
      let cameraIdx =  this.dataSource.findIndex(e=>e.name == 'camera');
      if(cameraIdx>-1){
        this.dataSource[cameraIdx].state = true;
      }
      let targIdx =  this.dataSource.findIndex(e=>e.name == 'target');
      if(targIdx>-1){
        this.dataSource[targIdx].state = true;
      }
      this.positionToolEvent.next('solid');
      this.viewMode = 'solid';
    }else if(row.name === 'texture') {
      let idx =  this.dataSource.findIndex(e=>e.name == 'label');
      if(idx>-1){
        this.dataSource[idx].state = false;
      }
      let index =  this.dataSource.findIndex(e=>e.name == 'sensor');
      if(index>-1){
        this.dataSource[index].state = true;
      }
      let cameraIdx =  this.dataSource.findIndex(e=>e.name == 'camera');
      if(cameraIdx>-1){
        this.dataSource[cameraIdx].state = true;
      }
      let targIdx =  this.dataSource.findIndex(e=>e.name == 'target');
      if(targIdx>-1){
        this.dataSource[targIdx].state = true;
      }
      this.positionToolEvent.next('texture');
      this.viewMode = 'texture';
    }else if(row.name === 'wireframe') {
      let idx =  this.dataSource.findIndex(e=>e.name == 'label');
      if(idx>-1){
        this.dataSource[idx].state = false;
      }
      let index =  this.dataSource.findIndex(e=>e.name == 'sensor');
      if(index>-1){
        this.dataSource[index].state = true;
      }
      let cameraIdx =  this.dataSource.findIndex(e=>e.name == 'camera');
      if(cameraIdx>-1){
        this.dataSource[cameraIdx].state = true;
      }
      let targIdx =  this.dataSource.findIndex(e=>e.name == 'target');
      if(targIdx>-1){
        this.dataSource[targIdx].state = true;
      }
      this.positionToolEvent.next('wireframe');
      this.viewMode = 'wireframe';
    }else if(row.name == 'camera'){
      this.dataSource[index].state=!this.dataSource[index].state;
      this.cameraHideShow.next(this.dataSource[index].state);
    }else if(row.name == 'sensor'){
      this.dataSource[index].state=!this.dataSource[index].state;
      this.sensorHideShow.next(this.dataSource[index].state);

    }else if(row.name == 'label'){
      //if(this.viewMode == 'solid')
      this.dataSource[index].state=!this.dataSource[index].state;
      this.multiTagState.next({state:this.dataSource[index].state});
    }else if(row.name == 'target'){
      this.dataSource[index].state=!this.dataSource[index].state;
      this.targetHideShow.next(this.dataSource[index].state);

    }
  }

  _wireframemode = false;
  changeWireframeMode(index){
    if(!this._wireframemode){
      // this.dataSource[index].icon = 'signal_cellular_null';
      this._wireframemode = true;
    }else if(this._wireframemode){
      // this.dataSource[index].icon = 'signal_cellular_4_bar';
      this._wireframemode = false;
    }
  }

  getWireFrameStatus(){
    return this._wireframemode;
  }


  searchElements(searchElem) {
    this.searchmode = true;
    this.canvasobjects = [];
    this.canvasobjects = this.getElements.filter(function(tag) {
        return tag.indexOf(searchElem) >= 0;
    });
    console.log("canvasobjects.length :: ",this.canvasobjects.length);
  }

  activeList;
  showObject(obj_name, index) {
    if(this.activeList != index){
      this.activeList = index;
      this.getElementProperties.next(obj_name);
    }else{
      this.activeList = null;
      this.getElementProperties.next(obj_name);
    }
  }


  locateShape(shapeCoords): any {
    const newLocation = (shapeCoords as String).split(',' , 3);
    const x = Number(newLocation[0].split(':', 2)[1]); // scaleFactor * Math.sin(2 * percnt) / 2 * 0.005 + cx;
    const y = Number(newLocation[1].split(':', 2)[1]); // sscaleFactor * Math.cos(percnt) * 0.004 + cz;
    const z = Number(newLocation[2].split(':', 2)[1]); // sMath.sin(percnt) * 40 + 80;  // altitude
    const geopos = { 'position': { 'x': x, 'y': y, 'z': z }};
    this.changeObjectPosition.next(geopos);
  }


  changeShapeSize(shapeSize): any {
    const newSize = Number(shapeSize);
    const geoSize = {'size': newSize};
    this.changeObjectSize.next(geoSize);
   }

   changeShapeOrientation(shapeOrientation): any {
    console.log("shapeOrientation : ",shapeOrientation);
    const newSize = Number(shapeOrientation);
    const geoSize = {'orientation': newSize};
    this.changeObjectOrientation.next(geoSize);
   }
  triggerkey(key){
    this.trigger.next(key);
  }
  ////////fab
  // private speedDialFabButtons = [
  //   {
  //     icon: 'timeline',
  //     tooltip: 'Do some timeline here'
  //   },
  //   {
  //     icon: 'view_headline',
  //     tooltip: 'Do some headline here'
  //   },
  //   {
  //     icon: 'room',
  //     tooltip: 'get some room here'
  //   },
  //   {
  //     icon: 'lightbulb_outline',
  //     tooltip: 'Do some outline here'
  //   },
  //   {
  //     icon: 'lock',
  //     tooltip: 'Do lock down'
  //   }
  // ];

  SpeedDialFabPosition = SpeedDialFabPosition;
  speedDialFabColumnDirection = 'column';
  speedDialFabPosition = SpeedDialFabPosition.Bottom;
  speedDialFabPositionClassName = 'speed-dial-container-top';

  onPositionChange(position: SpeedDialFabPosition) {
    switch(position) {
      case SpeedDialFabPosition.Bottom:
        this.speedDialFabPositionClassName = 'speed-dial-container-bottom';
        this.speedDialFabColumnDirection = 'column-reverse';
        break;
      default:
        this.speedDialFabPositionClassName = 'speed-dial-container-top';
        this.speedDialFabColumnDirection = 'column';
    }
  }

}
