import { Injectable, HostListener } from '@angular/core';
import * as hfx_model_dir from "../../../assets/scs/data/hfx_model_dir.json";
import * as systems from "../../../assets/scs/data/kha_systems.json";
import * as compartments from "../../../assets/scs/data/kha_compartments.json";
import * as ernMapping from "../../../assets/scs/data/ern_mapping.json";
import { CommunicationService } from "../../services/communication.service";
import { ControllerService } from "../../services/controller.service";
import { element } from 'protractor';
declare var BABYLON;
const constLabelWidth = 120; // 1555 - window.innerWidth;
const constLabelHeight = 75; // 815 - window.innerHeight;

@Injectable()
export class KHASystemService {
  private config: any;
  distance: any = 25;
  datauxview;
  dfx;
  game_engine;
  scene;
  selectedDemo;
  rightclick: boolean = true;
  isDrag: boolean = false;
  khamode = "system";
  systemSubComps: any = {};
  broadcastSubs
  khasystem;
  khamaterials;
  khadisplayrules;
  hideWall = false;
  MaterialStatusData: any = [];
  clear_compartments: boolean = true;
  multi_compartments: boolean = !true;//default - false
  private _jsonURL = '../assets/project.config.json';
  slms_mode=false;
  constructor(private CommunicationServ: CommunicationService, private ctrlServ: ControllerService) {
    this.broadcastSubs = this.CommunicationServ.getInstance()
      .subscribe((data) => {
        if (data.src === "sidepanel") {
          if (data.event === 'applyDisplayRuleFor_Section') {
            this.applyDisplayRuleFor_Section(data.data);
          }
          if (data.event === 'showMatObjectTag') {
            this.showMatObjectTag(data.key, data.data);
          }
        }
        if (data.src === "controlpanel") {
          if (data.event === 'reset_drag') {
            let c = data['key'];
            if (c === 'system') {
              this.showSystemShip(true);
            }
            if (c === 'compartment') {
              !this.slms_mode?this.showSystemShip(false):null;
            }
          }
          if (data.event === 'loadcompartment') {
            let c = data['data'];
            this.khamode = "compartment";
            c.wallmode=this.slms_mode;
            this.processandloadComparment(c.code, data['key']);
          }
          if (data.event === 'loadsystem') {
            let c = data['data'];
            this.khamode = "system"
            this.changeKHA(c);
          }
          if (data.event === 'loadsection') {
            this.khamode = "section"
            let c = data['data'];
            //-todo
          }
          if (data.event === 'showhidesubsystem') {
            let boo = data['data'];
            this.showHideSubSystem(data['key'], true);
          }
          if (data.event === 'focusSubSystem') {
            let boo = data['data'];
            this.focusSubSystem(data['key'])
          }
          if (data.event === 'showhidecompartment') {
            let boo = data['data'];
            this.showHideCompartment(data['key'], boo);
          }
          if (data.event === 'showhidecompartmentwall') {
            let boo = data['data'];
            this.showHideCompartmentWall(data['key'], boo);
          }
          if (data.event === 'clearkha') {
            this.khamode = data['key']
            if (this.datauxview) {
              this.clearAllElements();
            }
          }
          if (data.event === 'togglehull') {
            let boo = data['data'];
            this.hideWall = boo;
            //this.showHideHull();
          }
          if (data.event === 'showsystemcolors') {
            let sysmode = data['key'];
            if (sysmode === 'system') {
              let el = this.getChild(this.khasystemship, "ERNs");
              this.applyMaterial(null, 'regular', null, el);
              this.hideWall = false;

              if (this.ctrlServ.selectedSysComp.length > 0) {
                // this.toggleSysComp(this.ctrlServ.selectedSysComp);
              } else {
                let boo = this.ctrlServ.showSysComp[this.khasystem].length > 0 ? true : false;
                if (boo) {
                  let s = this.ctrlServ.showSysComp[this.khasystem];
                  if (!s) return;
                  this.showERNObjectFor(s, false);
                } else {
                  this.resetSubSystems();
                }
              }

              //this.showHideHull();
              //this.showHideERNCmpt(false);
              //this.showAllComps(this.khasystemship);
              //this.ctrlServ.updateComps([])
            }
            if (sysmode === 'status') {
              //this.showAllComps(this.khasystemship);
              this.showAllSubSystems();
              // this.ctrlServ.updateComps([]);
              let status = data['data'];
              console.log(" showsystemcolors  ")
              this.loadSystemStatus(status);
            }
          }
          if (data.event === 'showhideSubSystemStatus') {
            let key = data['data'];
            if (key) {
              this.toggleSubSystemStatus(key, data['key']);
            }
          }
        }
      })
    this.initCanvas();
  }

  /**
  * initCanvas
  */
  selectedHitPosition: any;
  initCanvas() {
    var canvas = document;
    canvas.addEventListener("pointerdown", (event) => {
      if (event.which == 1) {
        this.isDrag = true;
        this.rightclick = false;
      }
      if (event.which == 3) {
        this.isDrag = true;
        this.rightclick = true;
        try {
          if (this.dfx) {
            const hit = this.dfx.getHitPosition();
            let mesh = hit.pickedMesh;
            if (mesh != null) {
              let view = { "target": hit.pickedPoint, "distance": 90, "yaw": 205, "pitch": 12 };
              let dfx = this.datauxview.getDatascape();
              let cameraProps = dfx.getCamera();
              view['distance'] = Number(cameraProps['distance']['value'].toFixed(4));
              view['yaw'] = Number(cameraProps['yaw_deg']['value'].toFixed(4));
              view['pitch'] = Number(cameraProps['pitch_deg']['value'].toFixed(4));
              this.selectedHitPosition = view;
            }
          }
        } catch (e) {
          console.log(e);
        }
      }
    })
    canvas.addEventListener("pointerup", (event) => {
      if (event.which == 1) {
        this.isDrag = false;
        this.rightclick = false;
      }
      if (event.which == 3) {
        this.isDrag = false;
        setTimeout(() => {
          this.rightclick = false;
          delete this.selectedHitPosition;
        }, 500);
      }
    })
  }

  broadcastInfo(data: any) {
    this.CommunicationServ.getInstance().next(data);
  }
  /**
  * move camera
  */
  moveCameraTo(el, view = null) {
    let dfx = this.datauxview.getDatascape();
    let _view = view;
    dfx.moveCamera(_view);
  }

  clearScene() {
    let scene = this.scene;
    scene.meshes.forEach((m) => {
      m.dispose();
    })
  }
  clearAllElements(hideship = false) {
    let dx = this.datauxview
    if (!dx) {
      return;
    }
    let elems = dx.getElementsid();
    let dfx = dx.getDatascape();
    elems.forEach((id) => {
      if (!id.includes('ehfx')) {
        let el = dx.getElementId(id);
        dfx.remove(el);
        delete dx._allElements[id]
      }
    })
    // this.clearScene();
  }
  meshes;
  changeKHA(sys) {
    this.clearAllElements();
    this.processSystems(sys);
  }

  loadShipSystems(arr, cbck = null) {
    let dfx = this.datauxview.getDatascape();
    let prefix = window.location.host.includes('localhost') ? 'http://localhost:5000' : "";
    //prefix='https://navyscs.azurewebsites.net';
    prefix = 'https://nodesdms.azurewebsites.net';
    let prop = {
      "type": "static",
      "parent": "",
      "name": "land2",
      "model": "land2",
      "place": {
        "pos": { "x": 0, "y": 0, "z": 0 },
        "rot": { "x": 0, "y": 0, "z": 0 }
      }
    };
    let def = {};
    let models = [];
    let elements = [];
    arr.forEach((m, i) => {
      def['system_' + i] = prefix + m
      let narr = m.split("/")
      let name = narr[narr.length - 1];
      name = name.split(".glb")[0];
      let model = 'root_' + name;
      let nobj = {};
      nobj[name] = { "model": model, "material": "custom-included", "size": 1 }
      models.push(nobj);
      let p = Object.assign({}, prop, { "name": name, "model": name })
      elements.push(p);
    })
    dfx.addDynamicShapes(def).then(() => {
      console.log("GLB loaded")
      this.datauxview._createModels(models, (boo) => {
        this.datauxview.addElements(elements, (objs) => {

          let scene = this.scene;
          scene.materials.forEach((m) => {
            if (m.metallic) {
              m.metallic = 0;
              m.roughness = 0.5;
            }
          })
          //this.showLoading = !true;
          this.broadcastInfo({ src: 'khaservice', event: 'hideloader', data: "" });
          if (cbck) {

            cbck(objs);

          }
        }, (id, pointer) => { })
      }, (id, pointer) => { })
    })
  }

  findObject(obj: any = {}, key, values, strict = false) {
    const result = [];
    const recursiveSearch = (obj: any = {}) => {
      if (!obj || typeof obj !== 'object') {
        return;
      };
      values.forEach((value) => {
        if (strict) {
          if (obj[key] === (value)) {
            result.push(obj);
          };
        } else {
          if (obj[key].includes(value)) {
            result.push(obj);
          };
        }

      });
      if (obj.type != 'system') {
        let nodes = obj.compartments || obj.systems;
        nodes.forEach(function (node) {
          recursiveSearch(node);
        });
      }

    }
    recursiveSearch(obj);
    return result;
  }

  systems_store = {}
  processSystem(dir, s, mats, cbck) {
    let dfx = this.datauxview.getDatascape()
    this.broadcastInfo({ src: 'khaservice', event: 'showloader', data: s });
    this.selectedDemo = s;
    let arr = null;//this.findObject(dir,"name",[s])||[];
    let narr = ["/HFX_by_systems/HFX_" + s + ".glb"];//["/systems/"+dir+"/"+s+"/"+s+".glb"];
    //arr.forEach((item)=>{narr.push(item.path.split('./').join("/"))})
    let mat = mats[s];
    this.loadShipSystems(narr, (objs) => {
      this.systems_store[s] = objs;
      objs.forEach((obj) => {
        let el = this.datauxview.getElementId(obj.name);
        if (el) {
          this.enableMouseEvent(el);
          this.postProcessSystem(dir, s, el);
          if (mat) {
            this.applyMaterial(el, mat, dir);
          } else {
            if (dir === 'eer') {
              this.applyMaterial(el, null, dir);
            }
          }
          dfx.setElementScale(el, [1, 1, -1]);
        }
      })
      cbck();
    });
  }

  getSystem(sys) {
    let sarr = systems['default'][sys];
    return sarr;
  }

  getSystemFiles(sys) {
    let dir = hfx_model_dir['default'];
    let arr = this.findObject(dir, "name", [sys]) || [];
    let narr = [];
    arr.forEach((item) => { narr.push(item.path) })
    return narr;
  }
  cancelLoading = false;
  xprocessSystems(sys) {
    this.ctrlServ.updateSysComps([], true);
    this.cancelLoading = false;
    let sarr = systems['default'][sys];
    let mats = systems['default']['materials'][sys] || {};
    this.khasystem = sys;
    this.khamaterials = mats;
    if (!sarr) {
      console.log("Systems for " + sys + " not available!")
      return
    }
    let l = sarr.length;
    let c = 0;
    let dir = null;//hfx_model_dir['default'];
    this.systems_store = {};
    this.clearDrag();
    let nextsystem = (i) => {
      if (this.cancelLoading) {
        return;
      }
      this.processSystem(sys, sarr[i], mats, () => {
        c++
        if (c < l) {
          setTimeout(() => {
            nextsystem(c)
          })
        } else {
          if (this.dragMode) {
            this.enableDrag();
          }
          if (this.ctrlServ.selectedSysComp.length > 0) {
            this.toggleSysComp(this.ctrlServ.selectedSysComp);
          } else {
            this.toggleSysComp(this.ctrlServ.showSysComp);
          }
          this.createMatObject('cube', { pickedPoint: { x: 0, y: 0, z: 0 } }, '');
        }
      })
    }
    setTimeout(() => {
      nextsystem(c)
    })
  }
  cancelSystemLoading() {
    this.broadcastInfo({ src: 'khaservice', event: 'cancel-loading', data: "" });
    this.cancelLoading = true;
    this.broadcastInfo({ src: 'khaservice', event: 'hideloader', data: "" });
  }
  eventInitialized = false;
  processSystems(sys) {
    this.khasystem = sys;
    this.khadisplayrules = systems['default']['rules'][sys];
    let ehfx = this.datauxview.getElementId("ehfx");
    this.khasystemship = this.dfx.getElementMesh(ehfx).getChildren()[1];
    this.getSysDefalutPosition(this.khasystemship);
    this.broadcastInfo({ src: 'khaservice', event: 'hideloader', data: "" });
    this.applyDisplayRule_Struct();
    this.hideAllSubSystems();
    this.showAllSubSystems();
    this.getSystemCompStatus();
    if (this.ctrlServ.selectedSysComp.length > 0) {
      this.showHideSubSystem(this.ctrlServ.selectedSysComp, true, true);
    } else {
      let systems = Object.keys(this.khadisplayrules);
      this.ctrlServ.updateSysComps(systems, true);
    }
    if (!this.eventInitialized) {
      this.eventInitialized = true;
      this.enableMouseEvent(ehfx);
    }
    if (this.dragMode) {
      this.enableDrag();
    }
    this.createMatObject('cube', { pickedPoint: { x: 0, y: 0, z: 0 } }, '');
  }
  mergeIfMultiRules(s) {
    if (s.length === 1) {
      return this.khadisplayrules ? this.khadisplayrules[s[0]] : null;;
    }
    let multi_rule = {
      "show": {
        "Hull": { "limits": [] },
        "Superstruct": { "limits": [] },
        "Compartments": { "limits": [] },
        "ERNs": { "limits": [] }
      }
      , "hide": {
      }
      , "highlight": {
      }
      , "focus": { "target": { "x": 1.22, "y": 13.22, "z": -90.22 }, "distance": 20, "yaw": -111, "pitch": 43 }
    }
    s.forEach((o, idx) => {
      let rules = this.khadisplayrules ? this.khadisplayrules[o] : null;
      if (rules) {
        if (idx === s.length - 1) {
          multi_rule.focus = rules.focus;
        }
        if (rules.show) {
          let keys = Object.keys(rules.show)
          keys.forEach((key) => {
            if (!(key === 'Hull' || key === 'Superstruct')) {
              if (!multi_rule.show[key]) {
                multi_rule.show[key] = { "limits": [] }
              }
              let l = multi_rule.show[key] ? multi_rule.show[key].limits : [];
              multi_rule.show[key].limits = l.concat(rules.show[key].limits);
            }
          })
        }
        if (rules.highlight) {
          let keys = Object.keys(rules.highlight)
          keys.forEach((key) => {
            if (!(key === 'Hull' || key === 'Superstruct')) {
              if (!multi_rule.highlight[key]) {
                multi_rule.highlight[key] = { "limits": [] }
              }
              let l = multi_rule.highlight[key] ? multi_rule.highlight[key].limits : [];
              multi_rule.highlight[key].limits = l.concat(rules.highlight[key].limits);
            }
          })
        }
      }
    })
    return multi_rule;
  }
  showHideSubSystem(_s, boo, nsc = false) {
    let s = this.ctrlServ.showSysComp[this.khasystem];
    if (nsc) {
      s = _s;
      this.ctrlServ.updateComps(s);
      this.ctrlServ.updateSysComps(s, true);
    }
    if (!s) {
      return;
    }
    if (!boo || s.length === 0) {
      this.resetSubSystems();
      return
    }
    //this.ctrlServ.updateComps(s);
    /* if(s.length===1){
      this.showERNObjectFor(s[0]);
    }else{
      s.forEach((item)=>{
        this.showERNObjectFor(item,false);
      })
    } */
    this.showERNObjectFor(s);
  }
  showHideSubSystemAll(boo) {
    let systems = Object.keys(this.khadisplayrules);
    if (boo) {
      this.ctrlServ.updateComps(systems);
      this.ctrlServ.updateSysComps(systems, boo);
      this.showERNObjectFor(systems, !boo);
    } else {
      this.ctrlServ.updateComps([]);
      this.ctrlServ.updateSysComps(systems, boo);
      this.showAllSubSystems();
      // this.datauxview.Camera('home');
    }
  }
  resetSubSystems() {
    if (this.khasystemship) {
      this.ctrlServ.updateComps([]);
      // this.showAllSubSystems();
      this.hideAllSubSystems();
      if (this.hideWall) {
        //this.showHideHull();
      }
    }
  }
  focusSubSystem(id) {
    let rules = this.khadisplayrules ? this.khadisplayrules[id[0]] : null;
    if (rules) {
      let foc = rules.focus
      this.moveCameraTo(null, foc);
    }
  }
  showHideMultipleSystems(khaList) {
    if (khaList.length > 0) {
      let boo = this.ctrlServ.showSysComp[this.khasystem].length > 0 ? true : false;
      if (boo) {
        let s = this.ctrlServ.showSysComp[this.khasystem];
        if (!s) return;
        this.showERNObjectFor(s, false);
      } else {
        this.resetSubSystems();
      }
      khaList.forEach(_sys => {
        let sys = _sys['kha'];
        if (_sys.status) {
          let khadisplayrules = systems['default']['rules'][sys];
          for (let m in khadisplayrules) {
            let rules = khadisplayrules[m];
            this.highlightComps(this.khasystemship, rules.highlight);
          }
        }
      });
    }
  }
  xshowHideSubSystem(s, boo) {
    s.forEach((_s) => {
      let objs = this.systems_store[_s];
      objs.forEach((obj) => {
        let el = this.datauxview.getElementId(obj.name);
        if (el) {
          // let mesh = this.dfx.getElementMesh(el).getChildren()[0];
          // mesh.setEnabled(boo);

          let mat = this.khamaterials[_s];
          if (!boo) {
            //mat=mat+"_transparent";
            mat = "transparent";
          }
          this.applyMaterial(el, mat, this.khasystem);

          //let profile=boo?"regular":"transparent";
          //this.dfx.modify(el,{profile})
          /* let mesh = this.dfx.getElementMesh(el).getChildren()[0];
          mesh.setEnabled(boo); */
          /* mesh.getChildMeshes().forEach((m) => {
            m.isVisible = boo;
          }) */
        }
      })
    })
  }
  showHideCompartment(s, boo) {
    s.forEach((_s) => {
      let objs = this.compartments_store[_s];
      objs.forEach((obj) => {
        let el = this.datauxview.getElementId(obj.name);
        if (el) {
          let mesh = this.dfx.getElementMesh(el).getChildren()[0];
          mesh.setEnabled(boo);
        }
      })
    })
  }
  compartment_filters=['a__Anonymous','Boat_and_Boat_Handling','Cableways_Envelope','Ceilings','Deck_Coverings','Default','Format-Title_Block-Rev_Column','Landing_Markings','Linings','Maintenance_Envelope','Maintenance_Envelope_DFX','Minor_Structure','Text-Notes-Tables']
  showHideCompartmentWall(s, boo) {
    s.forEach((_s) => {
      let objs = this.compartments_store[_s];
      objs.forEach((obj) => {
        let el = this.datauxview.getElementId(obj.name);
        if (el) {
          let mesh = this.dfx.getElementMesh(el).getChildren()[0];
          let meshes = mesh.getChildMeshes();
          meshes.forEach((m) => {
            let str = m.name.toLowerCase();
            let act = str.includes('bulkheads_') || str.includes("_plating") || str.includes("_stiffeners") || str.includes("geometry") || str.includes("door") || str.includes("insulation")
            if(!act){
              act=this.compartment_filters.some((item)=>{
                let s = item.toLowerCase();
                return str.includes(s);
              })
            }

            if (act) {
              if (m.isEnabled()) {
                m.isVisible = boo;
              }
            }
          })
        }
      })
    })
  }

  /* getCompartments(){
    let dir=hfx_model_dir['default'];
    let arr=this.findObject(dir,"type",['compartment'])||[];
    let obj={};
    this.compartments_list=[]
    arr.forEach((item)=>{
      obj[item.name]=item;
      this.compartments_list.push(item.name);
    })
    this.compartments_store = obj;
    return arr;
  } */

  getCompartments() {
    let dir = compartments['default'];
    let arr = Object.keys(dir);
    //let obj={};
    this.compartments_list = [];
    arr.forEach((item) => {
      //obj[item]=dir[item];
      this.compartments_list.push({ name: dir[item], code: item, selected:false});
    })
    //this.compartments_store=dir;
    return arr;
  }

  hideAllCompartments() {
    Object.keys(this.compartments_store).forEach((c) => {
      let objs = this.compartments_store[c];
      objs.forEach(obj => {
        this.hide(obj.name);
      });
    })
  }

  showCompartments(c,boo=true) {
    let objs = this.compartments_store[c];
    objs.forEach(obj => {
      if(boo){
        this.show(obj.name);
      }else{
        this.hide(obj.name);
      }

    });
  }

  compartments_store = {};
  compartments_list;
  processandloadComparment(c = null, boo = false) {
    if (!this.compartments_list) {
      this.getCompartments();
      this.broadcastInfo({ src: 'khaservice', event: 'update-compartments', data: this.compartments_list });
    }
    this.showMatObjectTag(null, false);
    // if clear_compartments true every component it will fresh load
    if (this.clear_compartments) {
      this.clearAllElements();
      if (boo) {
        !this.slms_mode?this.showSystemShip(false):null;
        this.loadCompartment(c);
      }
    } else {
      if(!this.multi_compartments){
        this.hideAllCompartments();
      }
      if (boo) {
        if (this.compartments_store[c]) {
          this.showCompartments(c);
          setTimeout(() => {
            this.updateHomePosition();
          }, 1000);
        } else {
          !this.slms_mode?this.showSystemShip(false):null;
          this.loadCompartment(c);
        }
      }else{
        if(this.multi_compartments){
          this.showCompartments(c,false);
        }
      }
    }
  }
  loadCompartment(c, cbck = null) {
    if (this.compartments_list && compartments['default'][c]) {
      console.log("loadCompartment ")
      let narr = ["/HFX_by_compartments/HFX_" + c + ".glb"];
      this.broadcastInfo({ src: 'khaservice', event: 'showloader', data: "compartment " + c });
      if (this.clear_compartments) {
        this.compartments_store = {};
      }
      this.clearDrag();
      this.loadShipSystems(narr, (objs) => {
        this.compartments_store[c] = objs;
        objs.forEach((obj) => {
          let el = this.datauxview.getElementId(obj.name);
          if (el) {
            this.enableMouseEvent(el);
            //this.enableDrag(obj.name);
            this.dfx.setElementScale(el, [1, 1, -1]);
            this.focusElement(el);
            if(this.slms_mode){
              this.showHideCompartmentWall([c],false);
            }
            setTimeout(() => {
              if (this.dragMode) {
                this.enableDrag();
              }
              this.updateHomePosition();
            }, 1000);
          }
        })
        this.broadcastInfo({ src: 'khaservice', event: 'hideloader', data: "" });
        if (cbck) {
          cbck();
        }
      });
    } else {
      alert("Data for compartment " + c + " not available!")
    }
  }
  /**
   *
   * load compartments of a Deck
   */
  loadCompartmentsInDeck(d){
    this.multi_compartments=true;
    let comps=this.getCompartmentsInDeck(d);
    let loop =()=>{
      if(!comps.length){
        this.updateHomePosition();
        this.broadcastInfo({ src: 'khaservice', event: 'hideloader', data: d });
        return
      }
      let c = comps.shift()
      if (compartments['default'][c]) {
        let narr = ["/HFX_by_compartments/HFX_" + c + ".glb"];
        this.broadcastInfo({ src: 'khaservice', event: 'showloader', data: "compartment " + c });
        this.loadShipSystems(narr, (objs) => {
          this.compartments_store[c] = objs;
          objs.forEach((obj) => {
            let el = this.datauxview.getElementId(obj.name);
            if (el) {
              this.enableMouseEvent(el);
              this.dfx.setElementScale(el, [1, 1, -1]);
              //this.focusElement(el);
              setTimeout(() => {
                if (this.dragMode) {
                  this.enableDrag();
                }

              }, 1000);
            }
          })
          loop();
        });
      } else {
        alert("Data for compartment " + c + " not available!")
      }
    }
    loop()
  }
  getCompartmentsInDeck(d){
    if (!this.compartments_list) {
      this.getCompartments();
    }

    let comps=[]
    this.compartments_list.forEach((c)=>{
      let code = c.code.substr(0,1);
      if(code==='0'){
        code = c.code.substr(0,2);
      }
      if(code&&code===d){
        comps.push(c.code);
      }
    })
    return comps;
  }
  /* * * *
  * show the object
  * * * */
  show(name) {
    let el = this.datauxview.getElementId(name);
    if (el) {
      var _obj = { "profile": 'regular' };
      this.dfx.modify(el, _obj);
      this.focusElement(el);
    }
  }
  /* * * *
  * hide the object
  * * * */
  hide(name) {
    let el = this.datauxview.getElementId(name);
    if (el) {
      var _obj = { "profile": 'invisible' };
      this.dfx.modify(el, _obj);
    }
  }

  toggleSubSystemStatus(comp, boo) {
    let subSystems = this.systemSubComps[comp];
    if (subSystems.length > 0) {
      let mat;
      subSystems.forEach(m => {
        if (boo) {
          mat = this.scene.getMaterialByName(comp);
        } else {
          mat = this.scene.getMaterialByName('transparent');
        }
        if (mat) {
          if (m['mesh'].material) {
            m['mesh'].material = mat;
          }
        }
      })
    }
  }


  /**
   * method for get material component status
   */
  getSystemCompStatus(cbck = null) {
    try {
      let self = this;
      let mid = "transparent";
      let colors = ['red', 'yellow', 'transparent'];
      this.systemSubComps = { 'red': [], 'yellow': [], 'transparent': [] };
      let mesh = this.getChild(this.khasystemship, "ERNs");
      let transparent_mat = this.scene.getMaterialByName(mid);
      let red_mat = this.scene.getMaterialByName('red');
      let yellow_mat = this.scene.getMaterialByName('yellow');
      // let material = { 'red': red_mat, 'yellow': yellow_mat, 'transparent': transparent_mat };

      if (transparent_mat && red_mat && yellow_mat) {
        mesh.getChildMeshes().forEach((m) => {
          if (m.material) {
            const item = this.MaterialStatusData.find(el => el.name === m.id && el.khasystem === this.khasystem);
            if (item) {
              let mat = item['material'] || 'transparent';
              let obj = { id: m.id, mesh: m }
              self.systemSubComps[mat].push(obj);
            } else {
              let mat = colors[Math.floor(Math.random() * colors.length)];
              let obj = { id: m.id, mesh: m }
              self.systemSubComps[mat].push(obj);
            }
          }
        })
      }
      if (cbck) {
        cbck(true);
      }
    } catch (e) {
      console.log(e);
      if (cbck) {
        cbck(true);
      }
    }
  }

  /**
   * method for apply material for subcomponents
   */
  statusMode = false;
  loadSystemStatus(status) {
    console.log("system status change");
    this.statusMode = true;
    Object.keys(this.systemSubComps).forEach((comp) => {
      let boo = status[comp];
      let subSystems = this.systemSubComps[comp];
      if (subSystems.length > 0) {
        let mat;
        subSystems.forEach(m => {
          if (boo) {
            mat = this.scene.getMaterialByName(comp);
          } else {
            mat = this.scene.getMaterialByName('transparent');
          }
          if (mat) {
            if (m['mesh'].material) {
              m['mesh'].material = mat;
            }
          }
        })
      }
    })
  }

  /**
   * method for update status for selected comp
   */
  updateSystemStatus(m, status) {
    let mat = this.scene.getMaterialByName(status);
    if (mat) {
      if (m['mesh'].material) {
        m['mesh'].material = mat;
      }
    }
  }

  /**
   * method for show only selected comp
   */
  toggleSysComp(_comps) {
    for (let sys of Object.keys(this.systems_store)) {
      let objs = this.systems_store[sys];
      if (objs) {
        objs.forEach((obj) => {
          let el = this.datauxview.getElementId(obj.name);
          if (el) {
            let boo = false;
            let mat;
            let comp = _comps.find(el => obj.name.endsWith(el))
            if (comp) {
              boo = true;
              mat = this.khamaterials[comp];
            }
            if (!boo) {
              //mat=mat+"_transparent";
              mat = "transparent";
            }
            /*let mesh = this.dfx.getElementMesh(el).getChildren()[0];
            mesh.setEnabled(boo);*/
            this.applyMaterial(el, mat, this.khasystem);
          }
        })
      }
    }
    this.ctrlServ.updateComps(_comps);
  }

  /**
   * method for get current target for compartment home
   */
  updateHomePosition() {
    let dfx = this.datauxview.getDatascape();
    let cameraProps = dfx.getCamera();
    let view = { "target": { "x": -1.1, "y": 10, "z": -30 }, "distance": 60, "yaw": 140, "pitch": 12 };
    view['target']['x'] = Number(cameraProps['targetX']['value'].toFixed(4));
    view['target']['y'] = Number(cameraProps['targetY']['value'].toFixed(4));
    view['target']['z'] = Number(cameraProps['targetZ']['value'].toFixed(4));
    view['distance'] = Number(cameraProps['distance']['value'].toFixed(4));
    view['yaw'] = Number(cameraProps['yaw_deg']['value'].toFixed(4));
    view['pitch'] = Number(cameraProps['pitch_deg']['value'].toFixed(4));
    this.broadcastInfo({ src: 'khaservice', event: 'updatehomeview', data: view });
  }

  initSystems(sys = 'firesafety') {
    // this.processandloadComparment();
    this.processSystems(sys)
  }

  shipDefPosition: any = {};
  getSysDefalutPosition(mesh) {
    this.shipDefPosition = {};
    let obj = {};
    let dfx = this.datauxview.getDatascape();
    let meshes = mesh.getChildren();
    meshes.forEach((msh) => {
      let _meshes = msh.getChildMeshes();
      _meshes.forEach((m) => {
        let mname = this.getMeshName(m.name);
        obj[mname] = m.position;
      });
    });
    this.shipDefPosition = JSON.parse(JSON.stringify(obj));
  }

  shipDragobj: any = {};
  enableElementDrag(key, children = true) {
    let dfx = this.datauxview.getDatascape();
    let child = this.datauxview.getElementId(key);
    if (!child) {
      return
    }
    let mesh = dfx.getElementMesh(child).getChildren()[0];
    if (!mesh) {
      return
    }
    let meshes = children ? mesh.getChildren() : [mesh];
    meshes.forEach((m) => {
      let mname = this.getMeshName(m.name)
      let b = dfx.getDragBehavior([0, 1, 0]);
      b.useObjectOrienationForDragging = false;
      b.updateDragPlane = false;
      m.addBehavior(b);
      b.onDragObservable.add((event) => {
        this.isDrag = true;
        if (this.rightclick) {
          b.releaseDrag();
          this.rightclick = true;
          this.isDrag = false;
        }
      })
      let dragStartPos = {}
      b.onDragStartObservable.add((event) => {
        let pos = m.position;
        dragStartPos = JSON.parse(JSON.stringify(pos))
      })
      b.onDragEndObservable.add((event) => {
        let endPos = m.position;
        let x = this.getDifference(dragStartPos['x'], endPos.x);
        let y = this.getDifference(dragStartPos['y'], endPos.y);
        let z = this.getDifference(dragStartPos['z'], endPos.z);
        if (x <= 0 || y <= 0 || z <= 0) {
          this.moveCameraTo(null, this.selectedHitPosition);
        }
      })
      this.shipDragobj[mname] = [m, b];
    })
  }
  getDifference(a, b) {
    return a > b ? a - b : b - a;
    // return Math.abs(a - b);
  }
  enableSystemDrag(mesh) {
    let dfx = this.datauxview.getDatascape();
    let meshes = mesh.getChildren();
    meshes.forEach((msh) => {
      let _meshes = msh.getChildMeshes();
      _meshes.forEach((m) => {
        let mname = this.getMeshName(m.name)
        let b = dfx.getDragBehavior([0, 1, 0]);
        b.useObjectOrienationForDragging = false;
        b.updateDragPlane = false;
        m.addBehavior(b);
        b.onDragObservable.add((event) => {
          this.isDrag = true;
          if (this.rightclick) {
            b.releaseDrag();
            this.rightclick = true;
            this.isDrag = false;
          }
        })
        let dragStartPos = {}
        b.onDragStartObservable.add((event) => {
          let rot = m.rotation;
          let pos = m.position;
          dragStartPos = JSON.parse(JSON.stringify(pos))
        })
        b.onDragEndObservable.add((event) => {
          let rot = m.rotation;
          let endPos = m.position;
          let x = this.getDifference(dragStartPos['x'], endPos.x);
          let y = this.getDifference(dragStartPos['y'], endPos.y);
          let z = this.getDifference(dragStartPos['z'], endPos.z);
          // console.log(" difference ", { x, y, z });
          if (x > 0 || y > 0 || z > 0) {
            console.log("updateTagPosition", m, this.shipTagMesh);
            if (this.shipTagMesh) {
              if (m.id == this.shipTagMesh.id) {
                this.updateTagPosition({ x, y, z }, dragStartPos, endPos);
              }
            } else {
              this.updateTagPosition({ x, y, z }, dragStartPos, endPos);
            }

          } else {
            this.moveCameraTo(null, this.selectedHitPosition);
          }
        })
        this.shipDragobj[mname] = [m, b];
      })
    })
  }

  dragMode = false;
  enableDrag() {
    //this.clearDrag();
    //this.dragMode=true;
    let store = this.khamode === 'system' ? this.systems_store : this.compartments_store;
    if (this.khamode === 'system') {
      this.enableSystemDrag(this.khasystemship);
      return;
    }
    for (let m in store) {
      let objs = store[m];
      objs.forEach((obj) => {
        this.enableElementDrag(obj.name, this.khamode === 'compartment')
      })
    }
  }
  removeDragAll() {
    //this.dragMode=false;
    let keys = Object.keys(this.shipDragobj);
    keys.forEach((key) => {
      if (this.shipDragobj[key]) {
        this.shipDragobj[key][0].removeBehavior(this.shipDragobj[key][1]);
        this.shipDragobj[key][1] = null;
      }
    });
  }
  resetDragElements() {
    let keys = Object.keys(this.shipDragobj);
    keys.forEach((key) => {
      let m = this.shipDragobj[key];
      let pos = this.khamode === 'system' ? this.shipDefPosition[key] : { x: 0, y: 0, z: 0 };
      if (this.khamode === 'system') {
        this.sysmoveDefTagPosition();
      }
      if (m) {
        m[0].position.x = Number(pos.x.toFixed(4));
        m[0].position.y = Number(pos.y.toFixed(4));
        m[0].position.z = Number(pos.z.toFixed(4));
      }
    });
  }
  clearDrag(boo = true) {
    this.removeDragAll();
    if (boo) {
      this.resetDragElements();
    }
    this.shipDragobj = {};
  }
  applyMaterial(el, mid, sys, _mesh = null, _pick = null) {
    let mesh = _mesh ? _mesh : this.dfx.getElementMesh(el);
    if (sys === 'eer') {
      this.applyMaterialForEER(mesh);
    }
    let _mid = mid
    if (_mesh && mid === 'regular') {
      _mid = _mesh.mid ? _mesh.mid : 'transparent';
    }
    let mat = this.scene.getMaterialByName(_mid);
    if (mat) {
      if (mesh.material) {
        mesh.material = mat;
        if (_pick !== null) {
          mesh.isPickable = _pick
        }
      }
      mesh.getChildMeshes().forEach((m) => {
        if (m.material) {
          if (_mesh && mid === 'regular') {
            _mid = m.mid ? m.mid : _mid;
            mat = this.scene.getMaterialByName(_mid);
          }
          m.material = mat;
          if (_pick !== null) {
            m.isPickable = _pick
          }
        }
      })
    }
  }

  applySubSystemMaterial(mesh, status, mid, sys) {
    let self = this;
    let colors = ['red', 'yellow', 'transparent'];
    //let mesh = this.dfx.getElementMesh(el);
    /* if (sys === 'eer') {
      this.applyMaterialForEER(mesh);
    } */
    let transparent_mat = this.scene.getMaterialByName(mid);
    let red_mat = this.scene.getMaterialByName('red');
    let yellow_mat = this.scene.getMaterialByName('yellow');
    let material = { 'red': red_mat, 'yellow': yellow_mat, 'transparent': transparent_mat };

    if (transparent_mat && red_mat && yellow_mat) {
      if (mesh.material) {
        mesh.material = transparent_mat;
      }
      mesh.getChildMeshes().forEach((m) => {
        if (m.material) {
          let mat = colors[Math.floor(Math.random() * colors.length)];
          self.systemSubComps[mat].push(m);
          let boo = status[mat];
          if (boo) {
            m.material = material[mat];
          } else {
            m.material = material['transparent'];
          }
        }
      })
    }
    // console.log("systemSubComps ", this.systemSubComps)
  }

  /**
  * method for get center position of child mesh
  */
  getAverageCenter(m) {
    let meshes = m.getChildMeshes();
    let l = meshes.length;
    let c = [0, 0, 0]
    meshes.forEach((mesh) => {
      let pt = mesh.getBoundingInfo().boundingSphere.centerWorld
      c[0] += pt.x; c[1] += pt.y; c[2] += pt.z;
    });
    return { x: c[0] / l, y: c[1] / l, z: c[2] / l }
  }

  /* * * * *
  * focus particluar element
  * * * * * */
  focusElement(elem) {
    if (elem) {
      let dfx = this.datauxview.getDatascape();
      let mesh = dfx.getElementMesh(elem);
      let def = { "target": this.getAverageCenter(mesh), "distance": this.distance };
      dfx.moveCamera(def);
    }
  }

  applyMaterialForEER(m) {
    let mats = systems['default']['materials']['eer'];
    let meshes = m.getChildren()[0].getChildren();
    meshes.forEach((mesh) => {
      let d = this.getDeckCode(mesh.name);
      let mat = this.scene.getMaterialByName(mats[d]);
      if (mat) {
        if (mesh.material) {
          mesh.material = mat;
        }
        mesh.getChildMeshes().forEach((m) => {
          if (m.material) {
            m.material = mat;
          }
        })
      }
    })
  }

  postProcessSystem(sys, sub, obj) {
    this.enableMouseEvent(obj);
    let limits = systems['default']['limits'];
    let pro;
    if (limits) {
      pro = limits[sys];
    }
    if (pro) {
      let decks = pro["decks"];
      let compt = pro["compartments"];
      let sublimit = pro["sublimit"];
      let hasSubLim = sublimit && sublimit[sub];
      let m = this.dfx.getElementMesh(obj).getChildren()[0];
      let meshes = m.getChildren();
      meshes.forEach((mesh) => {
        let d = this.getDeckCode(mesh.name);
        let c = this.getCompartmentCode(mesh.name);
        let boo = true;
        if (compt) {
          boo = compt.includes(c);
        } else if (decks) {
          boo = decks.includes(d);
        }
        if (hasSubLim) {
          if (sublimit["compartments"]) {
            boo = sublimit["compartments"].includes(c);
          } else if (sublimit["decks"]) {
            boo = sublimit["decks"].includes(d);
          }
        }
        mesh.setEnabled(boo);
      })
    }
  }
  hasCompartmentCode(n) {
    let boo = true;
    return (n.includes("HFX_"))
  }
  getDeckCode(name) {
    if (!this.hasCompartmentCode(name)) {
      return name;
    }
    let c = this.getCompartmentCode(name);
    if (!c) {
      return null;
    }
    let d = c.substr(0, 1)
    d = d === '0' ? c.substr(0, 2) : d;
    return d;
  }

  getCompartmentCode(name) {
    let str: any = String(name).split(".");
    str = str[str.length - 1];
    let arr = str.split("_");
    let c = arr[1].split("+")[0];
    return c;
  }

  destroy() {
    this.broadcastSubs.unsubscribe();
    this.clearDrag()
    this.clearAllElements();
  }
  profiles: any = {
    "regular": { material: 'transparentZ', transition: 0, 'selectable': false },
    "transparent": { material: 'transparentZ' },
    "red": { material: 'red' }
  }
  getDynamicCubeConfig(shape, id) {
    let dfx = this.dfx;
    let opt = {
      shape: shape,
      id: id,
      size: 1,
      profiles: this.profiles,
    }
    return dfx.model(opt);
  }
  onHoverElem
  enableMouseEvent(el) {
    this.dfx.attach(el, {
      actions: {
        hoverOn: [(evt, elem) => {
          if (this.isDrag === false) {
            // this.onHover(evt);
            // this.addObserver(elem)
          }
        }],
        hoverOff: [(evt, elem) => {
          //this.offHover();
        }],
        pickLeft: [(evt, elem) => {
          if (evt.button === 0) {
            // let props = this.dfx.props(el);
            // let comp = props.id;
            // let systems = ernMapping['default'];
            // let selectedKha = this.ctrlServ.selectedKha;
            // if (selectedKha) {
            //   let components = systems[selectedKha];
            //   for (let key of Object.keys(components)) {
            //     if (comp.includes(key)) {
            //       let ernCategory = (Object.keys(components[key])[0])
            //       // let ernNumber = components[key][ernCategory];
            //       // this.ctrlServ.selectedErn = ernCategory;
            //       // this.broadcastInfo({ src: 'khaservice', event: 'loadERNTreeModel', data: ernCategory, ern: ernNumber });
            //       this.broadcastInfo({ src: 'khaservice', event: 'loadNSCTreeModel', data: ernCategory });
            //       break;
            //     }
            //   }
            // }
          }
        }],
      }
    });
  }

  getTagVal(a, b) {
    if (Math.sign(a) === 1) {
      return a + b;
    } else if (Math.sign(a) === 0) {
      if (Math.sign(b) === 0) {
        return a;
      } else {
        return a + b;
      }
    } else {
      return a - b;
    }
  }

  updateTagPosition(dragPos, startPos, endPos) {
    let elem = this.datauxview.getElementId('materialPointer');
    if (elem) {
      let pos = { x: 0, y: 0, z: 0 };
      if (startPos['x'] < endPos['x']) {
        if (Math.sign(this.sysTagPickedPoint['x']) === 1) {
          pos.x = this.sysTagPickedPoint['x'] - dragPos['x'];
        } else {
          pos.x = this.sysTagPickedPoint['x'] - dragPos['x'];
        }
      } else {
        if (Math.sign(this.sysTagPickedPoint['x']) === -1) {
          pos.x = Math.sign(endPos['x']) === -1 ? this.sysTagPickedPoint['x'] + dragPos['x'] : this.sysTagPickedPoint['x'] + dragPos['x'];
        } else {
          pos.x = this.sysTagPickedPoint['x'] + dragPos['x'];
        }
      }
      pos.y = this.getTagVal(this.sysTagPickedPoint['y'], dragPos['y']);
      if (startPos['z'] < endPos['z']) {
        if (Math.sign(this.sysTagPickedPoint['z']) === 1) {
          pos.z = this.sysTagPickedPoint['z'] - dragPos['z'];
        } else {
          pos.z = this.sysTagPickedPoint['z'] + dragPos['z'];
        }
      } else {
        if (Math.sign(this.sysTagPickedPoint['z']) === -1) {
          pos.z = Math.sign(endPos['z']) === -1 ? this.sysTagPickedPoint['z'] - dragPos['z'] : this.sysTagPickedPoint['z'] - dragPos['z'];
        } else {
          pos.z = this.sysTagPickedPoint['z'] - dragPos['z'];
        }
      }
      // console.log("updateTagPosition ", this.sysTagPickedPoint, dragPos, pos);
      this.sysTagPickedPoint['x'] = pos.x;
      this.sysTagPickedPoint['y'] = pos.y;
      this.sysTagPickedPoint['z'] = pos.z;
      this.dfx.modify(elem, { 'geometry': { 'orientation': { x: 0, y: 0, z: 0 }, position: { x: pos.x, y: pos.y, z: pos.z } } });
    }
  }

  sysmoveDefTagPosition() {
    let elem = this.datauxview.getElementId('materialPointer');
    if (elem) {
      let pos = { x: 0, y: 0, z: 0 };
      pos['x'] = this.sysDefTagPickedPoint['x'];
      pos['y'] = this.sysDefTagPickedPoint['y'];
      pos['z'] = this.sysDefTagPickedPoint['z'];
      this.sysTagPickedPoint['x'] = this.sysDefTagPickedPoint['x'];
      this.sysTagPickedPoint['y'] = this.sysDefTagPickedPoint['y'];
      this.sysTagPickedPoint['z'] = this.sysDefTagPickedPoint['z'];
      this.dfx.modify(elem, { 'geometry': { 'orientation': { x: 0, y: 0, z: 0 }, position: { x: pos.x, y: pos.y, z: pos.z } } });
    }
  }

  selectedTagMesh: any;
  sysTagPickedPoint: any = {};
  sysDefTagPickedPoint: any = {};
  shipTagMesh: any;
  createMatObject(shape, e, props) {
    console.log("createMatObject", e, props)
    if (this.datauxview) {
      var elem = this.datauxview.getElementId('materialPointer');
      var pos = e.pickedPoint;
      this.sysTagPickedPoint = pos;
      this.sysDefTagPickedPoint = JSON.parse(JSON.stringify(pos));
      var size = 0.1;
      this.shipTagMesh = e.pickedMesh;
      if (!elem) {
        let profiles = this.profiles;
        let settings = { 'geometry': { 'orientation': { x: 0, y: 0, z: 0 }, position: { x: pos.x, y: pos.y, z: pos.z }, size: size } };
        this.datauxview.addElement(shape, 'materialPointer', profiles, settings);
        let buildModel = this.datauxview.getElementId('materialPointer');
        this.dfx.modify(buildModel, { profile: 'regular' });
      } else {
        if (this.selectedMatObjTag) {
          // if (this.selectedMatObjTag.toLowerCase().indexOf(props.toLowerCase()) > -1) {
          let dom: any = document.getElementsByClassName("mat_nm_label")[0];
          if (dom) {
            dom.style.display = "none";
            delete this.selectedMatObjTag;
            // delete this.shipTagMesh;
            this.removeObserver(elem);
          }
          // }
        }
        this.dfx.modify(elem, { 'geometry': { 'orientation': { x: 0, y: 0, z: 0 }, position: { x: pos.x, y: pos.y, z: pos.z }, size: size } });
      }
      // setTimeout(() => {
      console.log("showMatObjectTag ", props)
      this.showMatObjectTag(props, true);
      // }, 1000);
    }
  }
  showMatObjectTagMesh(e) {
    let elemId = 'HFX_' + e.model[0];
    let el = this.datauxview.getElementId(elemId);
    if (!el) {
      return;
    }
    if (el) {
      this.showMatObjectTag(e.name, true);
    }
  }
  /* * * *
  * show only selected laydown tag
  * * * */
  selectedMatObjTag: any;
  showMatObjectTag(element, flag) {
    if (!this.datauxview) {
      return;
    }
    let el = this.datauxview.getElementId('materialPointer');
    console.log("el----- : ", el);

    if (!el) {
      return;
    }
    if (flag) {
      if (this.selectedMatObjTag) {
        if (this.selectedMatObjTag.toLowerCase().indexOf(element.toLowerCase()) > -1) {
          let dom: any = document.getElementsByClassName("mat_nm_label")[0];
          if (dom) {
            dom.style.display = "none";
            delete this.selectedMatObjTag;
            delete this.shipTagMesh;
            this.removeObserver(el);
          }
        } else {
          this.selectedMatObjTag = element;
          this.addObserver(el);
        }
      } else {
        this.selectedMatObjTag = element;
        this.addObserver(el);
      }
    } else {
      let dom: any = document.getElementsByClassName("mat_nm_label")[0];
      if (dom) {
        dom.style.display = "none";
        delete this.selectedMatObjTag;
        delete this.shipTagMesh;
        this.removeObserver(el);
      }
    }
  }
  /**
* add an Observer attach to 3d object
*/
  //  materielElem:any;
  addObserver(elem) {
    if (!elem) {
      return;
    }
    this.datauxview.getDatascape().attach(elem, {
      actions: {},
      observer: {
        callback: this.assignTagProperties.bind(this),
        options: { no2D: false }
      }
    });
  };

  /**
   * Remove an Observer detach to 3d object
   */
  removeObserver(elem) {
    if (!elem) {
      return;
    }
    this.datauxview.getDatascape().detach(elem, {
      observer: {
        callback: this.assignTagProperties.bind(this),
        options: { no2D: false }
      }
    });
  }

  /***
   * tag observer properties
   */
  assignTagProperties(options) {
    const { id, position, position2D, uuid, tag, visibility } = Object.assign({}, options);
    let elem = this.datauxview.getElementId(id);
    let props = this.datauxview.getDatascape().props(elem);
    var pop = document.getElementsByClassName("mat_nm_label")[0];
    if (this.selectedMatObjTag) {
      if (props.id == "materialPointer") {
        this.objObserver(pop, position2D, props);
      }
    }
  }

  /****
   * observable popup Tag
   */
  objObserver(pop, position, element) {
    if (element != "") {
      const x = position[0];
      const y = position[1];
      var canvas = this.datauxview.rendererCanvas.nativeElement;
      const within_window = (x > 50 && x < canvas.width - 50) && (y > 50 && y <= canvas.height - 50);
      if (pop) {
        if (within_window) {
          pop.style.left = (Number(x) - constLabelWidth) + 'px';
          pop.style.bottom = (Number(y) + constLabelHeight) + 'px';
          // pop.innerHTML = this.selectedMatObjTag;
          pop.style.display = 'block';
          pop.style.position = 'absolute';
          pop.dataset.lastx = Number(x);
          pop.dataset.lasty = Number(y);
        } else {
          // if (pop != null) {
          pop.style.display = 'none';
          // }
        }
      } else {
        if (pop != null) {
          pop.style.display = 'none';
        }
      }
    }
  }
  matSystem: any;
  onHover(evt, el) {
    let nl: any = document.getElementsByClassName("mat_nm_label")[0];
    // let mesh = evt.meshUnderPointer
    let mesh = this.dfx.getElementMesh(el);


    // if (mesh) {
    //   if(this.materielElem){
    //     if(this.materielElem==mesh.name){
    //       nl.style.display = "none";
    //       delete this.materielElem;
    //     }else{
    //       this.materielElem= mesh.name;
    //       let name = mesh.name.split('-')[1]
    //       nl.innerHTML = name;
    //       nl.style.display = "block";
    //       let rec = nl.getBoundingClientRect();
    //       nl.style.left = evt.x + "px";
    //       nl.style.top = (evt.y - rec.height) - 10 + "px";
    //     }
    //         }else{
    //           this.materielElem= mesh.name;
    //           let name = mesh.name.split('-')[1]
    //           nl.innerHTML = name;
    //           nl.style.display = "block";
    //           let rec = nl.getBoundingClientRect();
    //           nl.style.left = evt.x + "px";
    //           nl.style.top = (evt.y - rec.height) - 10 + "px";
    //         }
    //   // let str = mesh.name;
    //   // let narr = str.split(".");
    //   // let nstr = narr[narr.length - 1];
    //   // let a = nstr.split("+")
    //   // let name = a[1] + " (" + (a[0].split("_")[1]) + ")";

    // }
  }
  offHover() {
    let nl: any = document.getElementsByClassName("mat_nm_label")[0];
    nl.style.display = "none"
  }
  getMeshName(n) {
    if (!n) {
      return 'unknown'
    }
    let arr = n.split(".");
    if (arr.length === 1) {
      return arr[0]
    }
    if (arr.length === 0) {
      return "unknown"
    }
    return arr[arr.length - 1];
  }
  /**
   * V3 related code - start
   */
  getChild(mesh, c) {
    let arr = mesh.getChildren();
    let a = null;
    arr.some((m) => {
      let name = this.getMeshName(m.name);
      if (c === name) {
        a = m;
        return
      }
    })
    return a
  }
  getNodeFrom(parent, tree) {
    if (!tree.length) {
      return parent;
    } else {
      let node = tree.shift();
      let child = this.getChild(parent, node);
      return this.getNodeFrom(child, tree);
    }
  }
  showCompsERN(node, filter) {

    let c = node.getChildren();
    c.forEach((m) => {
      let name = this.getMeshName(m.name);
      let deck = this.getDeckCode(name);
      if (deck === name && m.getChildren().length) {
        this.showCompsERN(m, filter);
      } else {
        if (filter.includes(deck) || filter.length === 0) {
          // n.setEnabled(false)
          this.enableComps(m, true);
        } else {
          // n.setEnabled(true);
          this.enableComps(m, false);
        }
      }

    })

  }
  showComps(hfx, obj) {
    let keys = Object.keys(obj);
    keys.forEach((key) => {
      let skip = key === 'Hull' || key === 'Superstruct';
      if (skip) {
        return false;
      }
      let node = this.getChild(hfx, key);
      let subnodes = node.getChildren();
      let filter = obj[key].limits.slice();
      filter = this.applyDeckFilter(key, this.filter_deck, filter)
      subnodes.forEach((n) => {
        if (key === 'ERNs') {
          this.showCompsERN(n, filter)
        } else {
          let name = this.getMeshName(n.name);
          let deck = key === 'Compartments' ? this.getDeckCode(name) : name;
          if (filter.includes(deck) || filter.length === 0) {
            // n.setEnabled(false)
            this.enableComps(n, true);
          } else {
            // n.setEnabled(true);
            this.enableComps(n, false);
          }
        }

      })
    })
  }
  hideComps(hfx, obj) {
    let keys = Object.keys(obj);
    keys.forEach((key) => {
      let node = this.getChild(hfx, key);
      let subnodes = node.getChildren();
      let filter = obj[key].limits;
      filter.forEach((n) => {
        let comp = this.getNodeFrom(node, n.slice(0));
        if (comp) {
          comp.setEnabled(false);
          //this.enableComps(comp,false)
        }
      })
    })
  }
  highlightComps(hfx, obj) {
    let keys = Object.keys(obj);
    keys.forEach((key) => {
      let node = this.getChild(hfx, key);
      let filter = obj[key].limits;
      filter.forEach((n) => {
        let _mesh = this.getNodeFrom(node, n.slice(0));
        if (_mesh) {
          /* _mesh.outlineWidth = 0.025;
          _mesh.outlineColor = BABYLON.Color3.Teal();
          _mesh.renderOutline = true; */
          this.enableComps(_mesh, true);
          /* _mesh.getChildMeshes().forEach((mesh) => {

            mesh.outlineWidth = 0.025;
            mesh.outlineColor = BABYLON.Color3.Teal();
            mesh.renderOutline = true;
          }); */
        }
      })
    })
  }
  hideAllComps(hfx) {
    let meshes = hfx.getChildMeshes();
    meshes.forEach((mesh) => {
      if (mesh.setEnabled) {
        mesh.setEnabled(false);
        mesh.vstate = !true
      }
      if (mesh.renderOutline) {
        mesh.renderOutline = false;
      }
    })
  }
  showAllComps(parent) {
    let meshes = parent.getChildren();
    meshes.forEach((mesh) => {
      mesh.setEnabled(true);
      mesh.vstate = true
      if (mesh.renderOutline) {
        mesh.renderOutline = false;
      }
      this.showAllComps(mesh);
    })
  }
  showAllSubSystems() {
    if (this.filter_deck) {
      this.showSystemsFDeck()
      return
    }
    /* let ern = this.getChild(this.khasystemship, 'ERNs');
    let ern_ex = this.getChild(this.khasystemship, 'ERNs_exterior');
    let cmprt = this.getChild(this.khasystemship, 'Compartments');
    this.showAllComps(ern);
    this.showAllComps(ern_ex);
    this.showAllComps(cmprt); */
    if (this.khadisplayrules) {
      for (let m in this.khadisplayrules) {
        let rules = this.khadisplayrules[m];
        this.highlightComps(this.khasystemship, rules.highlight);
      }
    }
  }
  hideAllSubSystems() {
    let ern = this.getChild(this.khasystemship, 'ERNs');
    let ern_ex = this.getChild(this.khasystemship, 'ERNs_exterior');
    let cmprt = this.getChild(this.khasystemship, 'Compartments');
    this.hideAllComps(ern);
    this.hideAllComps(ern_ex);
    this.hideAllComps(cmprt);
  }
  showSystemInDeck(parent) {
    let meshes = parent.getChildMeshes();
    let boo = true;
    meshes.forEach((mesh) => {
      let name = this.getMeshName(mesh.name);
      let deck = this.getDeckCode(name);
      boo = deck === this.filter_deck;
      if (mesh.vstate) {
        mesh.setEnabled(boo);

      }
    })
  }
  showSystemsFDeck() {
    let ern = this.getChild(this.khasystemship, 'ERNs');
    let ern_ex = this.getChild(this.khasystemship, 'ERNs_exterior');
    let cmprt = this.getChild(this.khasystemship, 'Compartments');
    this.showSystemInDeck(ern);
    this.showSystemInDeck(ern_ex);
    this.showSystemInDeck(cmprt);
  }
  khasystemship
  showERNObjectFor(id, boo = true) {
    let rules = this.mergeIfMultiRules(id);//this.khadisplayrules?this.khadisplayrules[id]:null;
    if (!rules) {
      return;
    }
    let hfx = this.khasystemship;
    if (boo) {
      //this.hideAllComps(hfx);
      this.moveCameraTo(null, rules.focus);
    }
    //this.showComps(hfx, rules.show)
    // this.hideComps(hfx,rules.hide)
    this.hideAllSubSystems();
    this.highlightComps(hfx, rules.highlight);
    /* if(id.length>1&&!this.hideWall){
      this.hideWall=true;
      this.broadcastInfo({ src: 'khaservice', event: 'showhull', data: true });
    } */
    /* if(this.hideWall){
      this.showHideHull()
    } */


  }
  showSystemShip(boo) {
    if (!this.datauxview) {
      return
    }
    let ehfx = this.datauxview.getElementId("ehfx");
    if (ehfx) {
      let hfx = this.khasystemship || this.dfx.getElementMesh(ehfx).getChildren()[1];;
      hfx.setEnabled(boo);
    }
  }
  enableComps(parent, boo) {
    parent.setEnabled(boo);
    parent.vstate = boo;
    parent.getChildMeshes().forEach((m) => {
      m.setEnabled(boo);
      m.vstate = boo;
    })
  }
  ship_struct_comps = {
    "Hull": {
      "Hull": [["_exterior_port"], ["_exterior_starboard"]],
      "Superstruct": [
        ["deck_1", "exterior_1"], ["deck_1", "exterior_1_tops"], ["deck_1", "exterior_1J"], ["deck_1", "exterior_1J_tops"]
        , ["deck_01", "exterior_01"], ["deck_01", "exterior_01_tops"], ["deck_01", "exterior_01J"], ["deck_01", "exterior_01J_tops"]
        , ["deck_02", "exterior_02E"]
        , ["funnel"], ["intakes"], ["RAS_trunk"]
      ]
    },
    "Sections": {
      "Hull": [["watertight_bulkheads"]]
    }
  }
  filter_deck = null;
  struct_state_def = {
    "Hull": "hide",
    "Decks": "transparent",
    "Bulkheads": "transparent",
    "Sections": "transparent"
  }
  struct_state_deck = {
    "Hull": "hide",
    "Decks": "solid",
    "Bulkheads": "transparent",
    "Sections": "transparent"
  }
  struct_state_slms_1 = {
    "Bulkheads": "solid",
    "Decks": "solid",
    "Hull": "hide",
    "Sections": "transparent"
  }
  struct_state_current = this.clone(this.struct_state_def);
  applyDisplayRule_Struct(def = false, disp = null) {
    if (def) {
      this.struct_state_current = this.clone(this.struct_state_def);
    }
    let o = disp ? disp : this.struct_state_current;
    this.struct_state_current=o;
    for (let m in o) {
      this["applyState_" + m](o[m]);
    }

  }
  applyDisplayRuleFor_Struct(m) {
    let s = this.struct_state_current[m];
    if (s) {
      if(this.slms_mode&&m==='Decks'){
        this.applyState_Decks_slms(s);
      }else{
        this["applyState_" + m](s);
      }

    }
  }
  applyDisplayRuleFor_Deck() {
    if(this.slms_mode){
      this.applyState_Decks_slms(this.struct_state_slms_1["Decks"]);
      return;
    }
    let rule = this.filter_deck ? this.struct_state_deck : this.struct_state_def;
    this.applyDisplayRule_Struct(false, this.struct_state_deck);
    //this.hideAllComps(this.khasystemship);
    //this.showERNObjectFor("");
    this.showAllSubSystems();
    this.showHideSubSystem("", true);
    if (this.filter_deck) {
      this.showSystemsFDeck();
    }
  }
  applyDeckFilter(key, d, filter) {
    let f = filter;
    if (d !== null) {
      if (key === 'ERNs' || key === 'Compartments') {
        f = filter.includes(d) ? [d] : [];
      }
    }
    return f;
  }
  applyState_Hull(state = 'solid') {
    let prf = state === 'solid' ? 'regular' : state;
    let hull = this.getChild(this.khasystemship, 'Hull');
    let hcomps = ["_exterior_port", "_exterior_starboard"]
    hcomps.forEach((comp) => {
      let c = this.getChild(hull, comp);
      this.applyCompState(c, prf);
    })
    let ss = this.getChild(this.khasystemship, 'Superstruct');
    //hcomps = ["deck_1", "deck_01", "deck_02", "funnel", "intakes", "RAS_trunk", "flight_deck", "forecastle", "weatherdeck_mid"]
    hcomps = [ "deck_1", "deck_01", "deck_02","funnel", "intakes", "RAS_trunk"]
    hcomps.forEach((comp) => {
      if (comp.includes('deck_') && comp !== "weatherdeck_mid") {
        let cs = this.getChild(ss, comp);
        cs.getChildren().forEach((c) => {
          let name = this.getMeshName(c.name);
          if (name.includes("exterior") /* ||name.includes("interior") || name.includes("hangar_top") || name.includes("bridge_top") || name.includes("bridge_wings") */) {
            this.applyCompState(c, prf);
          }
        })
      } else {
        let c = this.getChild(ss, comp);
        this.applyCompState(c, prf);
      }

    })
  }
  applyState_Decks(state = 'solid') {
    let prf = state === 'solid' ? 'regular' : state;
    let hull = this.getChild(this.khasystemship, 'Hull');
    let hcomps = ["deck_2", "deck_3", "deck_4", "deck_5"]
    let filter='deck_';
    hcomps.forEach((comp) => {
      let c = this.getChild(hull, comp);
      this.applyCompState(c, prf);
      if (comp.includes(filter)) {
        let cs = this.getChild(hull, comp);
        if(!cs){
          return false;
        }
        cs.getChildren().forEach((c) => {
          let name = this.getMeshName(c.name);
          if (name.includes("deck_")) {
            this.applyCompState(c, prf);
          }
        })
      } else {
        let c = this.getChild(hull, comp);
        this.applyCompState(c, prf);
      }

    })
    let ss = this.getChild(this.khasystemship, 'Superstruct');
    hcomps = ["deck_1", "deck_01", "deck_02"]

    hcomps.forEach((comp) => {
      if (comp.includes(filter)) {
        let cs = this.getChild(ss, comp);
        if(!cs){
          return false;
        }
        cs.getChildren().forEach((c) => {
          let name = this.getMeshName(c.name);
          if (name.includes("deck_")) {
            this.applyCompState(c, prf);
          }
        })
      } else {
        let c = this.getChild(ss, comp);
        this.applyCompState(c, prf);
      }

    })
  }
  applyState_Bulkheads(state = 'solid') {
    let prf = state === 'solid' ? 'regular' : state;
    let hull = this.getChild(this.khasystemship, 'Hull');
    let hcomps = ["deck_2", "deck_3", "deck_4", "deck_5"]
    let filter='deck_';
    hcomps.forEach((comp) => {
      if (comp.includes(filter)) {
        let cs = this.getChild(hull, comp);
        if(!cs){
          return false;
        }
        cs.getChildren().forEach((c) => {
          let name = this.getMeshName(c.name);
          if (name.includes("bulkheads_")) {
            this.applyCompState(c, prf);
          }
        })
      } else {
        let c = this.getChild(hull, comp);
        this.applyCompState(c, prf);
      }

    })
    let ss = this.getChild(this.khasystemship, 'Superstruct');
    hcomps = ["deck_1", "deck_01", "deck_02"]
    if(this.filter_deck&&this.slms_mode){
      hcomps=['deck_'+this.filter_deck];
    }
    hcomps.forEach((comp) => {
      if (comp.includes('deck_')) {
        let cs = this.getChild(ss, comp);
        if(!cs){
          return false;
        }
        cs.getChildren().forEach((c) => {
          let name = this.getMeshName(c.name);
          if (name.includes("bulkheads_")) {
            this.applyCompState(c, prf);
          }
        })
      } else {
        let c = this.getChild(ss, comp);
        this.applyCompState(c, prf);
      }

    })
  }
  applyState_Decks_slms(state = 'solid') {
    let prf = state === 'solid' ? 'regular' : state;
    let hull = this.getChild(this.khasystemship, 'Hull');
    let hcomps = ["deck_2", "deck_3", "deck_4", "deck_5"]
    let filter='deck_'+(this.filter_deck||"");
    hcomps.forEach((comp) => {
      let c = this.getChild(hull, comp);
      this.applyCompState(c, prf);
      if (comp.includes(filter)) {
        let cs = this.getChild(hull, comp);
        if(!cs){
          return false;
        }
        cs.getChildren().forEach((c) => {
          this.applyCompState(c, prf);
        })
      } else {
        let c = this.getChild(hull, comp);
        this.applyCompState(c, this.filter_deck?'hide':prf);
      }

    })
    let ss = this.getChild(this.khasystemship, 'Superstruct');
    hcomps = ["deck_1", "deck_01", "deck_02"]

    hcomps.forEach((comp) => {
      let c = this.getChild(ss, comp);
      this.applyCompState(c, prf);
      if (comp.includes(filter)) {
        let cs = this.getChild(ss, comp);
        if(!cs){
          return false;
        }
        cs.getChildren().forEach((c) => {
          //this.applyCompState(c, prf);
          if (!c.name.includes("exterior")){
            this.applyCompState(c, prf);
          }
        })
      } else {
        let c = this.getChild(ss, comp);
        this.applyCompState(c, this.filter_deck?'hide':prf);
      }

    })
  }
  applyState_Sections(state = 'solid') {
    let prf = state === 'solid' ? 'regular' : state;
    let hull = this.getChild(this.khasystemship, 'Hull');
    let c = this.getChild(hull, 'watertight_bulkheads');
    this.applyCompState(c, prf);
  }
  showHideERNCmpt(boo) {
    let prf = boo ? 'transparent' : 'regular';
    let hull = this.getChild(this.khasystemship, 'ERNs_exterior');
    //hull.setEnabled(!this.hideWall);
    this.applyMaterial(null, prf, null, hull, !this.hideWall);
    let ss = this.getChild(this.khasystemship, 'Compartments');
    //ss.setEnabled(!this.hideWall);
    this.applyMaterial(null, prf, null, ss, !this.hideWall);
  }
  applyCompState(c, prf) {
    if (prf === 'hide') {
      c.setEnabled(false);
    } else {
      c.setEnabled(true);
      this.applyMaterial(null, prf, null, c, prf === 'regular');
    }
  }
  /**
   * V3 related code - end
   */
  /**
   *
   * change background color
   */
  bg_state = {
    "black": { r: 0, g: 0, b: 0 },
    "darkblue": { r: 0.08, g: 0.08, b: 0.16 },
    "grey": { r: 0.35, g: 0.35, b: 0.35 }
  }
  changeBackgroundColor(s) {
    let col = this.bg_state[s];
    if (col) {
      for (let m in col) {
        this.scene.clearColor[m] = col[m];
      }
    }
  }
  resetBackgroundColor() {
    this.changeBackgroundColor('black');
  }
  clone(obj) {
    return JSON.parse(JSON.stringify(obj));
  }
  applyDisplayRuleFor_Section(name) {
    // console.log("systems_store",this.compartments_store,name,this.compartments_list );
    //   objs.forEach((obj) => {
    //     let el = this.datauxview.getElementId(obj.name);
    //   let m = this.dfx.getElementMesh(obj).getChildren()[0];
    //   let meshes = m.getChildren();
    //   meshes.forEach((mesh) => {
    //     let d = this.getDeckCode(mesh.name);
    //   }
    // }
    this.compartments_list.forEach(element => {
      // console.log(element);
      if (element.code.toLowerCase().includes(name.toLowerCase())) {
        // console.log();
        if (element.code[1].toLowerCase() == name.toLowerCase()) {
          //console.log(this.getSectionCode('1ADAT'));
        }
      }
    });
    // console.log(this.getSectionCode(name))
  }
  getSectionCode(c) {
    if (!c) {
      return null;
    }
    let d = c.substr(0, 1);
    d = d === '0' ? c.substr(2, 1) : c.substr(1, 1);
    return d;
  }
}
