import { Component, OnInit, ViewChild , ElementRef, AfterViewInit, HostListener} from '@angular/core';
import { MatIconRegistry } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';


// _this;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  constructor(private matIconRegistry:MatIconRegistry, private domSanitizer:DomSanitizer){
    this.matIconRegistry.addSvgIcon(
      `video_camera_on`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/video-camera.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `video_camera_off`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/video-camera-strike.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `2d-icon`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/2d-icon.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `3d-icon`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/3d-icon.svg")
    );
  }
}
