import { Component, EventEmitter, OnInit, Output, ViewChild, Input, HostListener, ChangeDetectorRef } from '@angular/core';
import { NSCModel } from "../services/graphFDL/nsc";
import { ConfigService } from '../services/config.service';
import { CommunicationService } from "../services/communication.service";
import { ControllerService } from '../services/controller.service';
import { ThrowStmt } from '@angular/compiler';
import { LanguageService } from '../services/language.service';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer } from '@angular/platform-browser';
import { element } from 'protractor';

@Component({
  selector: 'app-network-model',
  templateUrl: './network-model.component.html',
  styleUrls: ['./network-model.component.scss']
})
export class NetworkModelComponent implements OnInit {
  shipData: any = "";
  @ViewChild('datauxview', { static: true }) public datauxview: any;
  @Output() showPathToggle = new EventEmitter<string>();
  @Output() openDialog: EventEmitter<any> = new EventEmitter<any>();
  @Output() chartModelView: EventEmitter<any> = new EventEmitter<any>();
  @Output() loadERNTree: EventEmitter<any> = new EventEmitter<any>();
  @Output() loadAOPSTree: EventEmitter<any> = new EventEmitter<any>();
  @Output() ernClose: EventEmitter<any> = new EventEmitter<any>();
  @Output() loadPdf: EventEmitter<any> = new EventEmitter<any>();
  @Input() shipHazardList: any = "";
  @Input() selectedKha: any = 'Buoyancy';
  @Input() screenType: any = "";

  broadcastIframeSubs: any;
  @Input() set showPathState(v: any) {
    this.arrControlBtnProps[9].state = v;
  }
  @Input() set shipDetailPanelData(v: any) {
    if (v != '') {
      this.shipData = v;
    }
  }
  @Input() set _mode(v: any) {
    if(v !== undefined){
      if(v == 'ern'){
        this.ernMode = true;
        this.aopsMode = false;
        this.ern = 'ern';
      }else if(v == 'aops'){
        this.ernMode = false;
        this.aopsMode = true;
        this.ern = 'aops'
      } else{
        this.ernMode = false;
        this.aopsMode = false;
        this.ern = ''
      } 
    }   
  }
  @Input() show_ern: boolean = false;
  @Input() ernDefaultLoad: boolean = false;
  @Input() selectedErnNo: any = '';
  ernMode
  aopsMode
  broadcastSubs: any;
  activeComps: any;
  initialized = false;
  pyramidModal: boolean = false;
  toastState: any = false;
  land_mtl
  nmCompList: any = [];
  nmCompMaxList: any = [];
  nmCompMiniList: any = [];
  distance: any = 100;
  selectedNodeElem: any = '';
  search: any = '';
  filterKey: any = [];
  markedElement: any = [];
  networkData: any = [];
  clrstatusList: any = [];
  hiddenNodeLevels: any = [];
  statusList: any = [];
  nscLevel7Trees: any = ['insa-structure', 'insa-buoyancy']; 

  statusNSCList: any = [
    { 'status': 'KHA', 'color': '#ff0000', "visible": false, showhide: false, level: 0 },
    { 'status': 'Tier 1 Goal', 'color': '#D9D848', "visible": true, showhide: true, level: 1 },
    { 'status': 'Regs', 'color': '#6CB7EA', "visible": true, showhide: true, level: 2 },
    { 'status': 'Tier 2 FOs', 'color': '#F2A4FF', "visible": true, showhide: true, level: 3 },
    { 'status': 'Tier 3 PRs', 'color': '#ACB0E2', "visible": true, showhide: true, level: 4 },
    { 'status': 'Tier 4 Sol’ns', 'color': '#C5E0B4', "visible": true, showhide: true, level: 5 }
  ];

  statusListLevel6: any = [
    { 'status': 'KHA', 'color': '#08077E', "visible": false, showhide: false, level: 0, nsscLevel: [0], show: false },
    { 'status': 'Tier 0', 'color': '#08077E', "visible": true, showhide: true, level: 1, nsscLevel: [1], show: false  },
    { 'status': 'Tier 1', 'color': '#8093F1', "visible": true, showhide: true, level: 2, nsscLevel: [1, 2], show: true  },
    { 'status': 'Tier 2 FOs', 'color': '#7A918D', "visible": true, showhide: true, level: 3, nsscLevel: [3], show: true  },
    { 'status': 'Tier 3 PRs', 'color': '#755B69', "visible": true, showhide: true, level: 4, nsscLevel: [4, 5], show: true  },
    { 'status': 'Tier 4', 'color': '#AFA8BA', "visible": true, showhide: true, level: 5, nsscLevel: [5], show: false  },
    { 'status': 'Tier 4 Sol’ns', 'color': '#AFA8BA', "visible": true, showhide: true, level: 6, nsscLevel: [6], show: true  }
  ];

  statusListLevel7: any = [
    { 'status': 'KHA', 'color': '#08077E', "visible": false, showhide: false, level: 0, nsscLevel: [0], show: false  },
    { 'status': 'Tier 0', 'color': '#08077E', "visible": true, showhide: true, level: 1, nsscLevel: [1], show: false  },
    { 'status': 'Tier 1', 'color': '#8093F1', "visible": true, showhide: true, level: 2, nsscLevel: [1, 2], show: true  },
    { 'status': 'Tier 2 FOs', 'color': '#7A918D', "visible": true, showhide: true, level: 3, nsscLevel: [3], show: true  },
    { 'status': 'Tier 3 PRs', 'color': '#755B69', "visible": true, showhide: true, level: 4, nsscLevel: [4, 5, 6], show: true  },
    { 'status': 'Tier 4', 'color': '#AFA8BA', "visible": true, showhide: true, level: 5, nsscLevel: [5], show: false  },
    { 'status': 'Tier 5', 'color': '#AFA8BA', "visible": true, showhide: true, level: 6, nsscLevel: [6], show: false  },
    { 'status': 'Tier 4 Sol’ns', 'color': '#AFA8BA', "visible": true, showhide: true, level: 7, nsscLevel: [7], show: true  }
  ];

  pyramidMaxList: any = [];
  pyramidMiniList: any = [];
  windowWidth: any = Math.round(window.innerWidth / 1.8);
  windowHeight: any = Math.round(window.innerWidth / 1.2);
  minWidth: any = Math.round(window.innerWidth / 4) + 'px';
  minHeight: any = Math.round(window.innerWidth / 4.8) + 'px';
  maxWidth: any = Math.round(window.innerWidth / 1.1) + 'px';
  maxHeight: any = Math.round(window.innerHeight / 1.1) + 'px';
  cardWidth: any = Math.round(window.innerWidth / 3) + 'px';
  cardHeight: any = Math.round(window.innerWidth / 2.8) + 'px';
  //popup card
  nmWindowWidth: any = Math.round(window.innerWidth / 3.8);
  nmWindowHeight: any = Math.round(window.innerWidth / 3.2);
  nmMinWidth: any = '425px';//Math.round(window.innerWidth / 4.5) + 'px';
  nmMinHeight: any = Math.round(window.innerWidth / 6.8) + 'px';
  nmMaxWidth: any = Math.round(window.innerWidth / 3.1) + 'px';
  nmMaxHeight: any = Math.round(window.innerHeight / 3.1) + 'px';
  nmCardWidth: any = Math.round(window.innerWidth / 4.5) + 'px';
  /**control tools to be moved */
  maxList: any = [];
  hideUnselectedState: boolean = false;
  hideSubBranchState: boolean = false;
  process_txt = "Processing...";
  showBlockLoading: boolean = false;
  defaultNSCCompLoad: boolean = true;
  moreDetail: any;
  ernDisplay = false;
  ern = "";
  stateObj: any = { networkData: [], nmCompMiniList: [] };
  ernCardHeight: any = 200;
  sysOverlayMode: boolean = false;
  sysViewMaximizeTag: boolean = false;
  overlayHeading: any = "";
  overlaySubHeading: any = "";
  loadUrl: any;
  controlPath: any;

  arrControlBtnProps = [
    { name: 'play anim', icon: 'play_arrow', state: false, ticon: 'pause' },
    { name: 'heat map', icon: 'layers', state: true, ticon: 'layers_clear' },
    { name: 'home', icon: 'home', state: true },
    { name: '2D', icon: 'dimension', state: false },
    { name: 'zoomin', icon: 'add', state: true },
    { name: 'zoomout', icon: 'remove', state: true },
    { name: 'drag', icon: 'swipe', state: false, ticon: 'do_not_touch' },
    { name: 'rotate', icon: 'sync', state: false, ticon: 'sync_disabled' },
    { name: 'reset', icon: 'refresh', state: false, ticon: 'refresh' },
    { name: 'path', icon: 'south', state: false, ticon: 'alt_route' },
  ];

  buttonclick(row, index) {
    this.arrControlBtnProps[index].state = !this.arrControlBtnProps[index].state;
    this.controlToolEvent(row);
  }

  controlToolEvent(control) {
    if (control.name === 'home') {
      this.onChangeCameraPosition(control.name);
    } else if (control.name === '2D') {
      let dimension = control.state ? '2D' : '3D';
      this.onChangeCameraMode(dimension);
    } else if (control.name === 'zoomin' || control.name === 'zoomout') {
      let zoomPosition = control.name === 'zoomin' ? ']' : '[';
      this.Zoom(zoomPosition);
    } else if (control.name === 'drag') {
      if (this.arrControlBtnProps[7].state) {
        this.arrControlBtnProps[7].state = !this.arrControlBtnProps[7].state;
        this.nscmodel.levelRotMode = this.arrControlBtnProps[7].state;
        this.nscmodel.removeNodeDrag();
      }
      if (control.state) {
        this.nscmodel.enableNodeDrag();
      } else {
        this.nscmodel.removeNodeDrag();
      }
    } else if (control.name === 'rotate') {
      if (this.arrControlBtnProps[6].state) {
        this.arrControlBtnProps[6].state = !this.arrControlBtnProps[6].state;
        this.nscmodel.removeNodeDrag();
      }
      if (control.state) {
        this.nscmodel.levelRotMode = control.state;
        this.nscmodel.enableNodeDrag();
      } else {
        this.nscmodel.levelRotMode = control.state;
        this.nscmodel.removeNodeDrag();
      }
    } else if (control.name === 'reset') {
      this.nscmodel.resetConeTree();
      this.pyramidClose();
      if (this.maxList.length > 0) {
        this.nmCompClose(this.maxList[0]);
      }
      this.nmCompMiniList = [];
    } else if (control.name === 'path') {
      this.nscmodel.showConnector = control.state;
      this.CommunicationServ.showConnector = control.state;
      this.showPathToggle.emit(control.state);
      this.broadcastInfo({ src: 'networkmodel', event: 'updatepathdata', data: control.state, key: "" });
    }
  }

  cam_mode = '3D'
  onChangeCameraMode(camView) {
    this.cam_mode = camView;
    if (this.cam_mode == '2D') {
      this.onChangeCameraPosition('top');
    }
    this.datauxview.CameraMode(camView);
    if (this.cam_mode == '3D') {
      setTimeout(() => {
        this.onChangeCameraPosition('home');
      }, 1300);

    }
  }

  onChangeCameraPosition(camView) {
    if (camView === 'home') {
      this.changeCamView(camView);
    }else{
      if (this.cam_mode == '2D') {
        camView = 'ortho' + camView
      }
      this.datauxview.Camera(camView);
    }   
  }

  changeCamView(v, vobj = false) {
    let target = { "x": 0, "y": 0, "z": 0 }
    let distance = 450;
    let view = v;
    this.moveCameraTo(null, view)
  }
  /**
  * Zoom event to zoom in / out the venue
  */
  isMouseDown
  mousestate = "up"
  Zoom(key) {
    this.isMouseDown = true;
    this.datauxview.startZoom(key);
  }
  stopZoom() {
    this.isMouseDown = !true;
    this.datauxview.stopZoom();
  }
  @HostListener('document:mouseup', ['$event'])
  handleMouseUpEvent(event: MouseEvent) {
    if (this.isMouseDown) {
      this.stopZoom();
    }
    this.isMouseDown = !true;
    this.mousestate = "up";
  }

  /**control tools end */
  nscmodel
  constructor(private cdr: ChangeDetectorRef, protected html_sanitizer: DomSanitizer, private ctrlServe: ControllerService, private translate: TranslateService, private language: LanguageService,  private CommunicationServ: CommunicationService, /* private nscmodel: NSCModel, */ private configService: ConfigService) {
    window['nscscope'] = this;
    this.nscmodel = new NSCModel(this.configService, this.CommunicationServ, this.ern);
    this.clrstatusList = this.ctrlServe.statusList;
    this.language.languageChange.subscribe(props => {
      console.log("language", props);
      this.translate.use(props);
    })
  }

  setVenueLightIntensity() {
    let dfx = this.datauxview.getDatascape();
    let scene = dfx.getCamera().getScene();
    let lights = scene.lights;
    let m = this.datauxview.getElementId("land");
    let mesh = dfx.getElementMesh(m);
    let glass_mtl = scene.getMaterialByID("glass_mtl");
    let glass_mtl2 = dfx.getStandardMaterial("glass_mtl2");
    let tex = glass_mtl.diffuseTexture;//dfx.getTexture("/assets/rov/assets/base.png");
    glass_mtl2.diffuseColor.r = 0.35;
    glass_mtl2.diffuseColor.g = 0.35;
    glass_mtl2.diffuseColor.b = 0.35;
    glass_mtl2.opacityTexture = tex;
    glass_mtl2.opacityTexture.getAlphaFromRGB = true;
    mesh.material = glass_mtl2;
    this.land_mtl = glass_mtl2;
  }

  /* * *
  * if poup text has "/" break into new line
  * * */
  processPopupText() {
    this.nmCompList.forEach(node => {
      node['text'] = node['text'].split("/").join("\n");
    });
  }

  /**
   * method if chart model hidden state try to switch nsc model reset controls
   */
  resetNSCChartModel() {
    this.statusList.forEach(el => {
      if (el.level === 0) {
        el.visible = false;
      } else {
        el.visible = true;
      }
    });
    this.nscmodel.transparentNodes = [];
  }

  /* * *
  * get network data from the server
  * * */
  onLoadServerData(reload = false, ern = null) {
    this.onChangeCameraPosition('home');
    this.showBlockLoading = true;
    this.networkData = [];
    this.maxList = [];
    this.nmCompMiniList = [];
    this.resetNSCChartModel();
    let url = this.configService.project_config.networkReadApi;
    let kha;
    if (ern) {
      kha = ern;
      this.toggleGroundMat(false);
      //kha = this.ctrlServe.selectedKha.split(" ").join("").toLowerCase();
    } else {
      this.toggleGroundMat(true);
      kha = this.ctrlServe.selectedKha.split(" ").join("").toLowerCase() || "buoyancy";
    }
    
    this.updateTreeLevelToggle();

    this.configService.getData(url, { 'kha': kha })
      .then((networkData) => {
        console.log("onLoadServerData ", networkData);
        if (networkData['result'] !== 'failed') {
          if ((networkData['vertices'].length > 0) && (networkData['edges'].length > 0)) {
            this.networkData = networkData;
            this.rearrangeNetworkDataIndex();
            this.nmCompList = networkData['vertices'];
            // this.processPopupText();
            this.getFilterKey();
            if (reload) {
              this.reloadNetworkData();
            } else {
              this.initNetworkModel();
            }
            this.showBlockLoading = false;
            this.ctrlServe.isNscAppLoaded = true;
            this.broadcastInfo({ src: 'networkmodel', event: 'updatenetworkdata', data: this.networkData, key: "" });
            setTimeout(() => {
              this.showSelectedERN();
            }, 1000);
          } else {
            this.showBlockLoading = false;
            this.ctrlServe.isNscAppLoaded = true;
            this.openDialog.emit('network data not found !!');
          }
        } else {
          this.showBlockLoading = false;
          this.ctrlServe.isNscAppLoaded = true;
          this.openDialog.emit('network data not found !!');
        }
      })
      .catch(error => {
        console.log("API Falied ", error);
        this.showBlockLoading = false;
        this.ctrlServe.isNscAppLoaded = true;
      });
  }

  /* * *
  * get network data from the local file
  * * */
  rearrangeNetworkDataIndex() {
    let narr = [];
    this.networkData['vertices'].forEach((n) => {
      let idx = n.id - 1;
      narr[idx] = n;
    })
    this.networkData['vertices'] = narr;
  }

  /* * *
  * selected ern will show
  * * */
  showSelectedERN() {
    if (this.ern) {
      let networkData = this.networkData['vertices'];
      if (this.selectedErnNo) {
        let item = networkData.find(el => el['btext'].trim() === this.selectedErnNo.trim());
        if (item) {
          this.broadcastInfo({ src: 'networkmodel', event: 'updateselectednode', data: item['id'], key: '' });
        }
      }
    }
  }

  /* * *
  * get network data from the local file
  * * */
  loadLocalData() {
    this.configService.loadFile('../../../assets/scs/data/network.json').then((networkData) => {
      console.log("loadLocalData ", networkData);
      if (networkData['result'] !== 'failed') {
        if ((networkData['vertices'].length > 0) && (networkData['edges'].length > 0)) {
          this.networkData = networkData;
          this.rearrangeNetworkDataIndex();
          this.nmCompList = networkData['vertices'];
          // this.processPopupText();
          this.getFilterKey();
          this.initNetworkModel();
        } else {
          this.openDialog.emit('network data not found !!');
        }
      } else {
        this.openDialog.emit('network data not found !!');
      }
    })
  }

  /* * *
  * get network data from upload
  * * */
  reloadNetworkData() {
    this.nscmodel.clearConeTree();
    this.CommunicationServ.showConnector = false;    
    this.initNetworkModel();
  }

  /* * *
  * init objects
  * * */
  initObjects() {
    this.datauxview.loadAssets('objects-network', 'json', (objectstatus) => {
      if (objectstatus) {
        this.datauxview.renderAssets('objects-network', 'json', (objectstatus) => {
          if (objectstatus) {
            this.initialized = true;
            this.setVenueLightIntensity();
            if (this.ctrlServe.selectedKha !== undefined && !this.ernMode && !this.aopsMode) {
              this.showBlockLoading = true;
              this.nscmodel.ern = '';
              this.onLoadServerData();
            } else if (this.ernMode && !this.aopsMode) {
              this.showBlockLoading = true;
              this.nscmodel.ern = 'ern';
              this.onLoadServerData(!this.defaultNSCCompLoad, this.ctrlServe.selectedErn);
            } else if (!this.ernMode && this.aopsMode) {
              this.showBlockLoading = true;
              this.nscmodel.ern = 'aops';
              this.onLoadServerData(!this.defaultNSCCompLoad, this.ctrlServe.selectedErn);
            }
          }
        }, (id, pointer) => { });
      }
    });
  }

  /* * *
  * method for get filter keys
  * * */
  getFilterKey() {
    if (this.networkData['vertices'].length > 0) {
      this.filterKey = Object.keys(this.networkData['vertices'][0]);
    } else {
      this.filterKey = ['id'];
    }
  }

  /* * *
  * method for show levels of tree hide/show
  * * */
  updateTreeLevelToggle() {
    this.nscShowAll = true;
    if (this.screenType === 'nsc') {
      this.statusList = this.statusNSCList;
    } else {
      this.statusList = this.nscLevel7Trees.includes(this.selectedKha) ? this.statusListLevel7 : this.statusListLevel6;
    }
  }

  ngOnInit() {  
    this.updateTreeLevelToggle();
    this.ctrlServe.isNscAppLoaded = false;
    this.broadcastSubs = this.CommunicationServ.getInstance()
      .subscribe((data: any) => {
        if (data.src === "sidepanel") {
          if (data.event === 'nscfilter') {
            this.search = data.data;                     
            this.onSearchValue();
            setTimeout(()=>{
              this.onChangeCameraPosition('home');
            }, 500);  
          }
          let key
          if (data.event === 'nscHideUnselected') {
            key = this.nscmodel.hideUnselectedState = data.data;

          } else if (data.event === 'nscHideSubBranch') {
            key = this.nscmodel.hideSubBranchState = data.data;

          } else if (data.event === 'nscShowPath') {
            key = this.nscmodel.showPathState = data.data;
            this.arrControlBtnProps[9].state = data.data;
          }
          if (this.nscmodel.current_vid) {
            this.handleTreeState({ key, id: this.nscmodel.current_vid }, data.event)
          }
          if (data.event === 'loaduploaddata') {
            this.networkData = data['data'];
            this.maxList = [];
            this.nmCompMiniList = [];
            this.reloadNetworkData();
          }
          if (data.event === 'selectedindex') {
            if((this.ctrlServe.ernDisplay === false && this.ern === '') || (this.ctrlServe.ernDisplay === true && this.ern === 'ern') || (this.ctrlServe.ernDisplay === false && this.ern === 'aops')){
              this.selectedNode(data);
              let id = 'vert_' + data.id;
              let el = this.datauxview._allElements[id];
              this.nscmodel.showConnector = false;              
              if (this.arrControlBtnProps[9].state) {
                this.nscmodel.showConnector = true;               
                data = { event: data.event, id: data.id, key: 'true', src: data.src }
                this.broadcastInfo({ src: 'networkmodel', event: 'updatelastelem', data: el, key: '' });
              } else {
                this.broadcastInfo({ src: 'networkmodel', event: 'updatelastelem', data: el, key: '' });
              }
            }
          }
          if (data.event === 'changensccomp') {
            let key = data['data'];
            this.shipHazardList = key;
            this.selectedKha = key['key'].split(" ").join("").toLowerCase();
            if (this.selectedKha !== undefined) {
              this.showBlockLoading = true;
              this.onLoadServerData(!this.defaultNSCCompLoad);
            }
          }
          if (data.event === 'load_ern_data') {
            this.ernDisplay = data['data'];
            let key = data['key'];
            if (key) {
              // this.ern = 'ern';
              // this.ernMode = true;
              // this.nscmodel.ern = 'ern';
              if (this.lastSelectedNode) {
                this.broadcastInfo({ src: 'networkmodel', event: 'updateselectednode', data: this.lastSelectedNode, key: '' });
              }
              this.showBlockLoading = true;
              this.onLoadServerData(!this.defaultNSCCompLoad, key)
            }
            if (!this.ernDisplay) {
              this.show_ern = false;
              this.updateKhaData();
            }
          }
          if (data.event === 'load_aops_data') {
            // this.ernDisplay = data['data'];
            let key = data['key'];
            if (key) {            
              if (this.lastSelectedNode) {
                this.broadcastInfo({ src: 'networkmodel', event: 'updateselectednode', data: this.lastSelectedNode, key: '' });
              }
              this.showBlockLoading = true;
              this.onLoadServerData(!this.defaultNSCCompLoad, key)
            }           
          }
          if (data.event === 'toggle_network_tree') {
            if (data['data']) {
              this.loadERNTreeModel();
            } else {
              this.ernPanelClose();
            }
          }
          if (data.event === 'toggle_aops_tree') {
            if (data['data']) {
              this.loadAOPSTreeModel();
            } else {
              this.ernPanelClose();
            }
          }
        }
        if (data.src === "home") {
          if (data.event === 'loadnsccomp') {
            this.shipData = data['data'];
            if (this.selectedKha !== undefined && this.shipData !== "") {
              this.showBlockLoading = true;
              this.onLoadServerData(!this.defaultNSCCompLoad);
            }
          }
        }
        if (data.src === "landingShip") {
          if (data.event === 'activeComps') {
            this.activeComps = data.data;
          }
        }
        if (data.src === "graphicNode") {
          if (data.event === 'nodeSelected') {
            var state = this.checkMiniList(data);
            if (!state) {
              this.lastSelectedNode = data['id'];
              return;
            }
            this.nscmodel.showConnector = false;           
            if (this.arrControlBtnProps[9].state) {
              this.nscmodel.showConnector = true;             
            }
            if (this.maxList.length > 0) {
              if (this.maxList[0]['id'] == data.id) {
                this.lastSelectedNode = data['id'];
                return;
              }
              let id = 'vert_' + this.maxList[0]['id'];
              let el = this.datauxview._allElements[id];
              this.maxList = [];
              this.hightlightNode(false, el);
              let idx = this.maxList.findIndex(ele => ele.id == data.id);
              if (idx == -1) {
                this.maxList.push(data);
                let id = 'vert_' + data.id;
                let el = this.datauxview._allElements[id];
                this.nscmodel.nodeList = el;
                this.lastSelectedNode = data['id'];
              }
            } else {
              this.maxList.push(data);
              let id = 'vert_' + data.id;
              let el = this.datauxview._allElements[id];
              this.nscmodel.nodeList = el;
              this.lastSelectedNode = data.id;
            }
            this.broadcastInfo({ src: 'networkmodel', event: 'unselectednode', data: data['id'], key: true });
          }
        }
        // if (data.event == 'changensccomp' && data.event == 'updatenetworkdata' && data.event == "loadnsccomp" && data.event == "nodeunselected") {
        // }else{
        //   this.controlNodes(data);
        // }
        if (data.event !== 'changensccomp' && data.event !== 'updatenetworkdata' && data.event !== "loadnsccomp" && data.event !== "nodeunselected" && data.event !== "load_ern_data" && data.event !== "toggle_network_tree" && data.event !== "updateselectednode") {
          this.controlNodes(data);
          this.hiddenNodeLevels.forEach(level => {
            this.nscmodel.showHideBranch(this.statusList[level].visible, level, this.hiddenNodeLevels);
          });
        }
      })

      this.broadcastIframeSubs = this.CommunicationServ.getIframeInstance()
      .subscribe((data: any) => {
        if (data.iframeConnection === "init-nsc-connection") {        
          if (data.event === 'onChangeNSCTree') {
            this.onChangeNSCTree(data['data']);
          }        
        }
      })
  }

  /* * *
  * selected node to highligh
  * * */
  lastSelectedNode: any = '';
  selectedNode(data) {
    let id = 'vert_' + data['id'];
    let flag = data['key'];
    let el = this.datauxview._allElements[id];
    if (flag) {
      if (this.lastSelectedNode) {
        if (document.getElementById(this.ern + "nmMiniAdd_" + this.lastSelectedNode)) {
          if (document.getElementById(this.ern + "nmMaxLabel_" + this.lastSelectedNode).style.display != 'none') {
            document.getElementById(this.ern + "nmMiniAdd_" + this.lastSelectedNode).click();
          }
        }
      }
      var state = this.checkMiniList(data);
      if (!state) {
        this.lastSelectedNode = data['id'];
        return;
      }
      // this.nscmodel.showConnector = false;
      // if (this.arrControlBtnProps[9].state) {
      this.nscmodel.showConnector = true;      
      // }
      this.showTag(el);
      this.lastSelectedNode = data['id'];
      this.broadcastInfo({ src: 'graphicNode', event: 'nodeSelected', id: data['id'], key: true });
    } else {
      this.nmCompClose(data);
    }
  }

  checkMiniList(data) {
    var state = false;
    if (this.nmCompMiniList.length > 0) {
      let idx = this.nmCompMiniList.findIndex(e => e.id == data.id);
      if (idx > -1) {
        let indx = this.nmCompList.findIndex(e => e.id == data.id);
        this.nmPopupModel(this.nmCompList[indx])
        // let id = 'vert_' + data['id'];
        // let el = this.datauxview._allElements[id];
        // this.hightlightNode(false, el);
        // if (this.maxList.length > 0) {
        //   let id = 'vert_' + this.maxList[0]['id'];
        //   let el = this.datauxview._allElements[id];
        //   this.hightlightNode(false, el);
        //   this.maxList = [];
        // }
        this.hiddenNodeLevels.forEach(level => {
          this.nscmodel.showHideBranch(this.statusList[level].visible, level, this.hiddenNodeLevels);
        });
      } else {
        state = true;
      }
    } else {
      state = true;
    }
    return state;
  }

  controlNodes(data) {
    this.nscmodel.showAllNodes();
    this.maxList.forEach(element => {
      this.nscmodel.current_vid = data.key ? element.id : null;
      let ctl = this.nscmodel.hideUnselectedState ? "nscHideUnselected" : "";
      ctl = this.nscmodel.hideSubBranchState ? "nscHideSubBranch" : ctl;
      ctl = this.nscmodel.showPathState ? "nscShowPath" : ctl;
      element['key'] = data.key;
      this.handleTreeState(element, ctl);
    });
  }

  handleTreeState(data, ctl) {
    if (ctl == 'nscHideUnselected') {
      if (data.key) {
        this.nscmodel.hideAllNodes();
        this.nscmodel.showNode(data.id);
      } else {
        this.nscmodel.showAllNodes();
      }
    } else if (ctl == 'nscHideSubBranch') {
      if (data.key) {
        this.nscmodel.showHideSubBranch(!data.key, data.id);
      } else {
        this.nscmodel.showAllNodes();
      }
    } else if (ctl == 'nscShowPath') {
      if (data.key) {
        this.nscmodel.showNodePath(parseInt(data.id));
      } else {
        this.nscmodel.showAllNodes();
      }
    }
  }

  ngAfterViewInit(): void {
    this.configService.loadFile('../assets/license.info').then((info) => {
      this.configService.getProjectConfig().then((project_config: any) => {
        project_config.licence = info;
        this.datauxview.setProjectSettins(project_config);
        this.datauxview.loadCanvas('settings-network', 'json', (data, settingstatus) => {
          this.getAppStatus();
        }, (err) => { console.log(err) });
      }, (err) => { alert("License not found!") })
    })
  }

  setInterval
  /**
   * method for get app status
   */
  getAppStatus() {
    let animation = (timer) => {
      console.log("getAppStatus ", this.ernMode)
      if (this.ernMode != undefined) {
        if (this.ern) {
          if (this.ernDefaultLoad) {
            this.ernDefaultLoad = !true;
            this.showBlockLoading = true;
            setTimeout(() => { this.initObjects() }, 6000);
          } else {
            this.initObjects();
            cancelAnimationFrame(this.setInterval);
          }
        } else {
          this.initObjects();
          cancelAnimationFrame(this.setInterval);
        }
        cancelAnimationFrame(this.setInterval);
      } else {
        this.setInterval = window.requestAnimationFrame(animation);
      }
    }
    this.setInterval = window.requestAnimationFrame(animation);
  }

  getBGLabel(state) {
    let idx = this.clrstatusList.findIndex(e => e.status == state);
    if (idx > -1) {
      return this.clrstatusList[idx].color;
    }
  }

  moveCameraTo(el, view = null) {
    let dfx = this.datauxview.getDatascape();
    let _view = view;
    if (view == 'home') {
      _view = this.datauxview.cameraview['home'];
      if (this.selectedKha === 'insa-engineering') {
        _view['distance'] = 430;
      } else if (this.selectedKha === 'insa-seamanship') {
        _view['distance'] = 380;
      } else {
        _view['distance'] = 275;
      }
      dfx.moveCamera(_view);
    } else {
      dfx.moveCamera(_view);
    }
  }

  /**
  * init network model
  */
  initNetworkModel() {
    this.defaultNSCCompLoad = false;
    let dfx = this.datauxview.getDatascape();//
    let scene = dfx.getCamera().getScene();
    this.nscmodel.scene = scene;
    this.nscmodel.game_engine = scene.getEngine();
    this.nscmodel.dfx = dfx;
    this.nscmodel.datauxview = this.datauxview;
    this.nscmodel.networkData = this.networkData
    // this.moveCameraTo(null,{target:{x: -11.2, y: -82.2, z: -3.2},distance:281})
    this.nscmodel.renderNetworkModel();
    this.nscmodel.updateNodeLabel();
  }

  dragChange(e) { }
  ngOnDestroy() {
    if (this.ctrlServe.selectedKha) {
      this.nscmodel.destroy();
      let dfx = this.datauxview.getDatascape();
      dfx.stop();
      dfx.dispose();
    }
    this.nscmodel.clearAnimationFrame();  
    this.broadcastSubs.unsubscribe();
  }
  pyramidClose() {
    this.pyramidMaxList = [];
    this.pyramidMiniList = [];
    this.pyramidModal = false;
    this.broadcastInfo({ src: 'networkPanel', event: 'pyramidPop', data: this.pyramidModal, key: false });
  }
  pyramidMiniAdd() {
    this.pyramidMaxList = [];
    this.pyramidMiniList.push('chart');
  }

  chartModel_pop() {
    // this.chartModelView.emit();
    this.pyramidMaxList = [];
    if (this.pyramidMiniList.length > 0) {
      this.pyramidMiniList = [];
    }
    this.pyramidMaxList.push('chart');
    this.pyramidModal = !this.pyramidModal;
    // if (this.maxList.length > 0) {
    //   this.nmCompClose(this.maxList[0]);
    // }
    // this.nmCompMiniList = [];
    this.broadcastInfo({ src: 'networkPanel', event: 'pyramidPop', data: this.pyramidModal, key: false });
  }

  /**
   * method for get hidden chart levels
   */
  getHiddenChartLevels() {
    let obj = [];
    this.statusList.forEach(el => {
      if (el.level !== 0) {
        if (!el.visible) {
          obj.push(el.level)
        }
      }
    });
    return obj;
  }

  chartModel(i, l = null) {
    // if(i==='pop'){
    //   this.chartModelView.emit();
    //   return;
    // }
    // this.statusList[i].visible=!this.statusList[i].visible;
    // this.nscmodel.showHideBranch(this.statusList[i].visible,l);
    // chartModel(i,l=null){
    if (!this.statusList[i]) {
      return
    }
    this.statusList[i].visible = !this.statusList[i].visible;
    let hiddenLevels = this.getHiddenChartLevels();
    this.hiddenNodeLevels = this.getHiddenChartLevels();
    this.nscmodel.showHideBranch(this.statusList[i].visible, l, hiddenLevels);
    // }
  }

  nscAlert(){
    this.broadcastInfo({ src: 'networkmodel', event: 'nscAlert', data: 'Ship goals', key: '' });
  }
  
  nscShowAll:any=true;
  nscShowAllState() {
    if (this.statusList.length == 0) {
      return
    }
    this.nscShowAll = true;
    this.statusList.forEach((element, i) => {

      this.statusList[i].visible = this.nscShowAll;
      let hiddenLevels = this.getHiddenChartLevels();
      this.hiddenNodeLevels = this.getHiddenChartLevels();
      this.nscmodel.showHideBranch(this.statusList[i].visible, i, hiddenLevels);
    });
  }
  activeNSClevel: any
  chartNSSCModel(chartModel = [], level) {
    if (this.statusList.length == 0 && chartModel.length == 0) {
      return
    }
    if (this.activeNSClevel == level) {
      let chartIndex = this.statusList.findIndex(e => e.visible == false && e.level != 0);
      if (chartIndex > -1) {
        delete this.activeNSClevel;
        this.nscShowAllState();
      }
    }else{
      this.activeNSClevel = level;
      this.statusList.forEach((element, i) => {
        let chartIndex = chartModel.findIndex(e => e == i);
        if (chartIndex == -1) {
          this.statusList[i].visible = false;
        } else {
          this.statusList[i].visible = true;
        }
        let hiddenLevels = this.getHiddenChartLevels();
        this.hiddenNodeLevels = this.getHiddenChartLevels();
        this.nscmodel.showHideBranch(this.statusList[i].visible, i, hiddenLevels);
      });
    }
  }

  /* * *
  * method for get active tags
  * * */
  getActiveTagLeg() {
    let activeNode = [];
    let elem = document.querySelectorAll('div.nmComp-chart');
    elem.forEach(el => {
      if (el['style']['display'] == 'inline-block') {
        activeNode.push(el.id);
      }
    });
    return activeNode;
  }

  /* * * * *
  * method for communicate event instance with data to access all components
  * * * * * */
  broadcastInfo(data: any) {
    this.CommunicationServ.getInstance().next(data);
  }

  broadcastIframeInfo(data: any) {
    this.CommunicationServ.createIframeInstance(data);
  }

  infoToggleState: any = false;
  infoToggleOpenHandle() {
    // this.infoToggleState = !this.infoToggleState;
    this.broadcastInfo({ src: 'networkmodel', event: 'sidepanel', state: null, sub: null });
  }

  nmCompClose(ele) {
    this.nscmodel.showConnector = false;   
    this.CommunicationServ.nodeUnSelect = true;
    if (this.arrControlBtnProps[9].state) {
      this.nscmodel.showConnector = true;
      this.CommunicationServ.showConnector = true; 
    }
    let idx = this.nmCompMiniList.findIndex(e => e.id == ele.id);

    if (idx > -1) {
      this.nmCompMiniList.splice(idx, 1);
      this.nscmodel.showNode(ele.id);
    } else {
      let elem = document.getElementById(this.ern + "nmMaxLabel_" + ele['id']);
      if (elem != null) {
        elem.style.display = 'none';
      }
      this.nscmodel.showNode(ele.id);
    }
    let indx = this.maxList.findIndex(e => e.id == ele.id);
    if (indx > -1) {
      this.maxList.splice(indx, 1);
      if (this.maxList.length <= 0) {
        this.lastSelectedNode = '';
      }
    }
    if (this.maxList.length == 0 && this.nmCompMiniList.length == 0) {
      // this.broadcastInfo({ src: 'sidepanel', event: 'nscHideUnselected', data: false, key: false });
      // this.broadcastInfo({ src: 'sidepanel', event: 'nscHideSubBranch', data: false, key: false });
      // this.broadcastInfo({ src: 'sidepanel', event: 'nscShowPath', data: false, key: false });
      this.nscmodel.showAllNodes();
      this.nscmodel.current_vid = null
    }
    // code while close tag remove highlighted circle
    let id = 'vert_' + ele['id'];
    let el = this.datauxview._allElements[id];
    this.hightlightNode(false, el);
    this.broadcastInfo({ src: 'networkmodel', event: 'unselectednode', data: ele['id'], key: false });
    this.controlNodes({ key: true, id: ele.id });
    this.hiddenNodeLevels.forEach(level => {
      this.nscmodel.showHideBranch(this.statusList[level].visible, level, this.hiddenNodeLevels);
    });
    // let activeNode = this.getActiveTagLeg();
    // // if (activeNode.length <= 0) {
    // //   this.datauxview.Camera('home');
    // // }
  }

  nmPopupModel(ele) {
    this.nscmodel.showConnector = false;    
    if (this.arrControlBtnProps[9].state) {
      this.nscmodel.showConnector = true;
      this.CommunicationServ.showConnector = true; 
    }
    let idx = this.nmCompMiniList.findIndex(e => e.id == ele.id);
    if (idx > -1) {
      this.nmCompMiniList.splice(idx, 1);
      let elem = document.getElementById(this.ern + "nmMaxLabel_" + ele['id']);
      if (elem != null) {
        elem.style.display = 'block';
      }
      // le  ctl=this.nscmodel.hideUnselectedState?"nscHideUnselected":"";
      // ctl=this.nscmodel.hideSubBranchState?"nscHideSubBranch":ctl;
      // ctl=this.nscmodel.showPathState?"nscShowPath":ctl;
      // this.handleTreeState(ele,ctl);
      if (this.maxList.length > 0) {
        // this.nmCompMiniAdd(this.maxList[0])
        let e = document.getElementById(this.ern + "nmMaxLabel_" + this.maxList[0]['id']);
        if (e != null) {
          e.style.display = 'none';
        }
        let id = this.nmCompList.findIndex(e => e.id == this.maxList[0]['id']);
        this.nmCompMiniList.push(this.nmCompList[id]);
        //   let idx = this.maxList.findIndex(e => e.id == ele.id);
        //   if (idx == -1) {
        //     this.maxList.push(ele);
        //     // this.nscmodel.showNode(data.id);
        let eleId = 'vert_' + this.maxList[0].id;
        let elId = this.datauxview._allElements[eleId];
        this.broadcastInfo({ src: 'networkmodel', event: 'unselectednode', data: this.maxList[0].id, key: false });
        this.hightlightNode(false, elId);
        this.maxList = [];
      }
      // } else {
      this.maxList.push(ele);
      // }
      let id = 'vert_' + ele.id;
      let el = this.datauxview._allElements[id];
      this.nscmodel.nodeList = el;
      // this.nscmodel.renderConnector();
      this.hightlightNode(true, el);
      this.broadcastInfo({ src: 'networkmodel', event: 'unselectednode', data: ele['id'], key: true });
      this.controlNodes({ key: true });
      this.hiddenNodeLevels.forEach(level => {
        this.nscmodel.showHideBranch(this.statusList[level].visible, level, this.hiddenNodeLevels);
      });
    }
  }

  nmCompMiniAdd(e) {
    let elem = document.getElementById(this.ern + "nmMaxLabel_" + e['id']);
    if (elem != null) {
      elem.style.display = 'none';
    }
    // this.nmCompMiniList=[];
    let idx = this.nmCompMiniList.findIndex(ele => ele.id == e.id);
    if (idx == -1) {
      this.nmCompMiniList.push(e);
      let indx = this.maxList.findIndex(ele => ele.id == e.id);
      if (indx > -1) {
        let eleId = 'vert_' + this.maxList[indx].id;
        let elId = this.datauxview._allElements[eleId];
        this.hightlightNode(false, elId);
        this.broadcastInfo({ src: 'networkmodel', event: 'unselectednode', data: this.maxList[indx].id, key: false });
        this.maxList.splice(indx, 1);
        this.controlNodes({ key: true, id: e.id });
      }
      this.hiddenNodeLevels.forEach(level => {
        this.nscmodel.showHideBranch(this.statusList[level].visible, level, this.hiddenNodeLevels);
      });
    }
  }

  lineShow() {
    // this.nscmodel.showConnector = true;
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    // console.log(event, document.getElementById('pyramid_popup').style.display)
    if(!event.path) return;
    let parentIdx = event.path.findIndex(e => e.id == 'pyramid_popup')
    let idx = event.path.findIndex(e => e.id == 'pyramid_child')
    if (idx == -1 && parentIdx > -1) {
      // document.getElementById('pyramid_popup').style.display='none'
      this.pyramidClose()
    }
    // var element = document.getElementById('pyramid_popup')
    // if(this.nativeElement.contains(event.target)) {
    //   this.text = "clicked inside";
    // } else {
    //   this.text = "clicked outside";
    // }
  }

  /* * *
  * method for searched nodes need to highlights
  * * */
  onSearchValue() {
    let networkData = this.networkData['vertices'];
    var filterData = [];
    if (this.search == '') {
      filterData = null;
    } else {
      if (networkData.length > 0) {
        for (let i = 0; i < networkData.length; i++) {
          this.filterKey.forEach((key) => {
            if (networkData[i][key].toString().toLowerCase().indexOf(this.search.toLowerCase()) > -1) {
              const found = filterData.some(elem => elem.id === networkData[i]['id'])
              if (!found) {
                filterData.push(networkData[i]);
              }
            }
          });
        }
      }
    }
    this.onFilteredData(filterData)
  }

  /* * *
  * method for searched node needs to be highlighted
  * * */
  onFilteredData(filterData) {
    let networkData = this.networkData['vertices'];
    let obj = [];
    this.clearMarkedText();
    if (filterData !== null) {
      if (filterData.length > 0) {
        obj = networkData.filter(function (node) {
          return filterData.every(function (elem) {
            return node['id'] !== elem['id']
          })
        })
        this.showNodeHighlight(filterData);
        this.removeNodeHighlights(obj);
        if (this.nscmodel.hideUnselectedState) {
          this.nscmodel.hideAllNodes();
          if (obj.length > 0) {
            obj.forEach(e => {
              this.nscmodel.showNode(e.id)
            })
          }
        }
      } else {
        this.showAllNodes();
      }
      this.highlightText();          
    } else {     
      this.showAllNodes();     
    }
  }

  /* * *
  * method for reset selected node
  * * */
  restSelectedNode(cbck) {
    if (this.selectedNodeElem) {
      this.nmCompClose(this.selectedNodeElem);
      this.selectedNodeElem = '';
      setTimeout(() => { cbck() })
    } else {
      cbck();
    }
  }

  /* * *
  * method for show highlights nodes
  * * */
  showNodeHighlight(obj) {
    this.restSelectedNode(() => {
      if (obj.length === 1) {
        let id = 'vert_' + obj[0]['id'];
        let el = this.datauxview._allElements[id];
        this.selectedNodeElem = obj[0];
        this.showTag(el, true);
      } else {
        obj.forEach(ele => {
          let id = 'vert_' + ele['id'];
          let el = this.datauxview._allElements[id];
          this.hightlightNode(true, el);
        });
      }
    });
  }

  /* * *
  * method for clear marked text
  * * */
  clearMarkedText() {
    this.markedElement.forEach(id => {
      this.nmCompList.forEach(node => {
        if (id === node['id']) {
          node['text'] = node['text'].replace("<mark>", "");
          node['text'] = node['text'].replace("</mark>", "");
        }
      })
    });
    this.markedElement = [];
  }

  /* * *
  * method for highlights searched text
  * * */
  highlightText() {
    if (this.search != "") {
      this.nmCompList.forEach(node => {
        if (node['text'].includes(this.search)) {
          let found = this.markedElement.some(el => el === node['id']);
          if (!found) {
            this.markedElement.push(node['id']);
            node['text'] = node['text'].replace("<mark>", "");
            node['text'] = node['text'].replace("</mark>", "");
            node['text'] = node['text'].replace(this.search, '<mark>' + this.search + '</mark>');
          }
        }
      });
    }
  }

  /* * *
  * method for remove highlights nodes
  * * */
  removeNodeHighlights(obj) {
    obj.forEach(ele => {
      let id = 'vert_' + ele['id'];
      let el = this.datauxview._allElements[id];
      this.hightlightNode(false, el);
    });
  }

  /* * *
  * method for show all nodes
  * * */
  showAllNodes() {
    this.restSelectedNode(() => {
      this.networkData['vertices'].forEach(ele => {
        let id = 'vert_' + ele['id'];
        let el = this.datauxview._allElements[id];
        this.hightlightNode(false, el);
      });
    });
  }

  /* * * * *
  * focus particluar element
  * * * * * */
  focusElement(elem) {
    if (elem) {
      let dfx = this.datauxview.getDatascape();
      let def = { "target": dfx.getAbsElemCenter(elem), "distance": this.distance };
      dfx.moveCamera(def);
    }
  }

  /* * *
  * method for highlights show/hide
  * * */
  hightlightNode(boo, el, col = [1, 0.5, 0], w = 0.15) {
    let dfx = this.datauxview.getDatascape();
    let mesh = dfx.getElementMesh(el);
    if (boo) {
      mesh.outlineColor.r = col[0] || 0;
      mesh.outlineColor.g = col[1] || 0;
      mesh.outlineColor.b = col[2] || 0;
      mesh.outlineWidth = w;
      if (!this.CommunicationServ.showConnector) {
        this.focusElement(el);
      }
    } else {
      mesh.outlineWidth = 0;
      if (this.CommunicationServ.nodeUnSelect) {
        if (!this.CommunicationServ.showConnector) {
          this.CommunicationServ.nodeUnSelect = false;
          this.onChangeCameraPosition('home');
        }
      }
    }
    mesh.renderOutline = boo;
  }

  /* * *
  * method for show node tag
  * * */
  showTag(el, def = false) {
    let tagElem;
    let dfx = this.datauxview.getDatascape();
    try {
      let props1 = dfx.props(el);
      var miniElem = document.getElementById(this.ern + "nmMiniLabel_" + props1['id'].split('_')[1]);
      if (def) {
        this.focusElement(el);
      }
      this.hightlightNode(true, el);
      if (!tagElem) {
        tagElem = document.getElementById(this.ern + "nmMaxLabel_" + props1['id'].split('_')[1]);
      }
      let offB = -6;//30;
      let offL = 5;
      let ldom: any = tagElem;
      let pobj = dfx.getElementPosition(el);
      if (pobj.visibility) {
        let pt = pobj.position2D;
        if (ldom != null) {
          ldom.style.display = "inline-block";
          if (miniElem) {
            ldom.style.display = "none";
          }
        }
      } else {
        if (ldom != null) {
          ldom.style.display = "none";
        }
      }
    } catch (e) {
      console.log(e)
    }
  };

  /* * *
  * method for Active Pyramid Screen
  * * */
  isActivePyramidScreen() {
    if (this.ctrlServe.selectedKha !== undefined) {
      return false;
    } else {
      return false;
    }
  }

  /* * *
  * method for Active Legend
  * * */
  isActiveLegend() {
    if (this.ctrlServe.selectedKha !== undefined && this.show_ern != true) {
      return true;
    } else {
      return false;
    }
  }

  /* * *
  * method for Active Controls
  * * */
  isActiveControl() {
    if (this.ern) {
      if (this.show_ern == true) {
        return true;
      } else {
        return false;
      }
    } else {
      if (this.ctrlServe.selectedKha !== undefined && this.show_ern != true) {
        return true;
      } else {
        return false;
      }
    }
  }

  getClassImg(shipClass) {
    return shipClass == 'Halifax' ? 'HAL' : shipClass == 'Dewolf' ? 'HDW' : shipClass == 'Kingston' ? 'KIN' : shipClass == 'Victoria' ? 'VIC' : shipClass == 'Orca' ? 'ORC' : shipClass == 'Asterix' ? 'AST' : shipClass == 'Protecteur' ? 'PRT' : shipClass == 'CSC' ? 'csc' : '';
  }

  getULHeight(id) {
    var elem = document.querySelector('#nmComp_Maxpopup_content_' + id);
    if (elem) {
      // console.log(elem)
      return elem.children[0].clientHeight > 150;
    } else {
      return false;
    }

  }

  // dropHandler(e) {
  //   console.log(e)
  // }

  getElementValue(data) {
    if (data != undefined) {
      var elementList = data.split("/");
      return elementList;
    }
  }

  getElement(data) {
    if (data != undefined) {
      try {
        var elementList = data.split(" / ");
        if(elementList.length === 1){
          let idName = 'cardContent';
          var ele = '<div id="' + idName + '">';
          elementList.forEach(element => {
            var str = element.split(/ +/)[0] == '' ? element.split(/ +/)[1] : element.split(/ +/)[0];
            var matches = str.match(/(\d+)/);
            var style = "";
            if (matches != null) {
              style = "indent"
            }
            if (ele != undefined) {
              ele += '<span id="' + style + '"><span>' + element + '</span></span>';
            } else {
              ele = '<span id="' + style + '"><span>' + element + '</span></span>';
            }
          });
          ele += '</div>'          
          if (ele != undefined) {
            return ele;           
          }
        }
        else if (elementList.length > 0) {
          let idName = 'cardContent';
          var ele = '<ul id="' + idName + '">';
          elementList.forEach(element => {
            var str = element.split(/ +/)[0] == '' ? element.split(/ +/)[1] : element.split(/ +/)[0];
            var matches = str.match(/(\d+)/);
            var style = "";
            if (matches != null) {
              style = "indent"
            }
            if (ele != undefined) {
              ele += '<li id="' + style + '"><p>' + element + '<p></li>';
            } else {
              ele = '<li id="' + style + '"><p>' + element + '<p></li>';
            }
          });
          ele += '</ul>'
          // console.log(ele);
          if (ele != undefined) {
            return ele;
            // return this.html_sanitizer.bypassSecurityTrustHtml(ele);
          }
        } else {
          return "";
        }
      } catch (e) {
        return "";
      }
    } else {
      return "";
    }
  }

  toggleGroundMat(nsc) {
    let dfx = this.datauxview.getDatascape();
    let g = this.datauxview.getElementId("land2");
    let scene = dfx.getCamera().getScene();
    let mat1 = scene.getMaterialByID("ground");
    let mat2 = scene.getMaterialByID("ground_mat");
    let mat = nsc ? mat1 : mat2;
    let m = dfx.getElementMesh(g);
    m.material = mat;
  }

  copy(token) {
    this.toastState = true;
    token.select();
    document.execCommand('copy');
    token.setSelectionRange(0, 0);
    this.toastState = false;
  }

  calculateHeight(item) {
    if (item['ern_category']) {
      if (item['ern_category'].trim()) {
        let height = 24 * item['ern_category'].split("/").length;
        this.ernCardHeight = 230 + height;
        return true;
      } else {
        return false;
      }
    } else {
      if (item.level == 5) {
        if(this.selectedKha === 'buoyancy'){
          let height = 24 * 3;
          this.ernCardHeight = 230 + height + 10;
        }else{
          let height = 24 * 6;
          this.ernCardHeight = 230 + height + 10;
        }
        return true;
      } else {
        return false;
      }
    }
  }

  getActiveErnElem(item) {
    if (item['ern_category']) {
      if (item['ern_category'].trim()) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

  getActiveErn() {
    let index = null;
    if (this.maxList.length > 0) {
      let id = this.maxList[0].id;
      let itemm = this.nmCompList.find(el => el.id === id);
      if (itemm) {
        index = itemm['ern_category'].split("/").findIndex(el => el.split(" ").join("") === 'MachineryControl&SurveillenceSystems')
      }
      return index;
    }
  }

  /* *
   * load ern tree
   */
  loadERNTreeModel(ernno = null) {
    // this.selectedErnNo = ernno;
    ernno = (ernno == null ? null : ernno.trim())
    this.stateObj['lastSelectedNode'] = this.lastSelectedNode ? this.lastSelectedNode : null;
    this.stateObj['networkData'] = this.networkData;
    this.stateObj['nmCompMiniList'] = this.nmCompMiniList;
    this.loadERNTree.emit(ernno);
  }

  loadAOPSTreeModel(ernno = null){
    ernno = (ernno == null ? null : ernno.trim())
    this.stateObj['lastSelectedNode'] = this.lastSelectedNode ? this.lastSelectedNode : null;
    this.stateObj['networkData'] = this.networkData;
    this.stateObj['nmCompMiniList'] = this.nmCompMiniList;
    this.loadAOPSTree.emit(ernno);
  }

  /* *
  * on load ern tree
  */
  onLoadERNTree(item, i, ernno) {
    ernno = ernno.trim();
    let ernCategory = "";
    if (item['ern_category']) {
      let category = item['ern_category'].split("/");
      let ern = category[i].toLowerCase().split(" ").join("");
      this.ctrlServe.ernMappingList.forEach(el => {
        let name = el['name'].toLowerCase().split(" ").join("");
        if (name.includes(ern)) {
          ernCategory = el['key']
        }
      })
    }
    if (ernCategory) {
      this.ctrlServe.selectedErn = ernCategory;
      this.broadcastInfo({ src: 'networkmodel', event: 'updateErn', data: ernCategory, key: '' });
      this.loadERNTreeModel(ernno);
    }
  }

  /* *
  * on load ern tree
  */
  _onLoadERNTree(ernKha, ernno) {
    ernno = ernno.trim();
    let ernCategory = "";
    if (ernKha) {
      let ern = ernKha.toLowerCase().split(" ").join("");
      this.ctrlServe.ernMappingList.forEach(el => {
        let name = el['name'].toLowerCase().split(" ").join("");
        if (name.includes(ern)) {
          ernCategory = el['key']
        }
      })
    }
    if (ernCategory) {
      this.ctrlServe.selectedErn = ernCategory;
      this.broadcastInfo({ src: 'networkmodel', event: 'updateErn', data: ernCategory, key: '' });
      this.loadERNTreeModel(ernno);
    }
  }

 

  /* *
  * on change NSC tree from Material screen through iframe postmessage
  */
  onChangeNSCTree(kha) {
    if (this.selectedKha !== kha) {
      this.selectedKha = kha;
      this.ctrlServe.selectedKha = kha;
      this.onLoadServerData(!this.defaultNSCCompLoad);
      this.sysViewMinimize();
      this.broadcastInfo({ src: 'networkmodel', event: 'updateKha', data: this.selectedKha, key: '' });
    } else {
      this.sysViewMinimize();
    }
  }

  /* *
  * on load system component
  */
  sysEventSource = false;
  onLoadSys(kha, ...comp) {
    this.overlayHeading = "Ship - " + kha;
    let _kha = kha.split(" ").join("").toLowerCase();
    this.sysOverlayMode = true;
    comp = comp.filter(e => e.trim());
    let sysComp = comp.join(",");
    if (!this.sysEventSource) {
      this.sysEventSource = true;
      this.loadUrl = this.html_sanitizer.bypassSecurityTrustResourceUrl(window.origin + "/?screenType=material&KHA=" + _kha + "&comp=" + sysComp);
    } else {
      if (this.ctrlServe.selectedKha !== _kha || this.ctrlServe.selectedSysComp.join(",") !== sysComp) {
        let obj = { screenType: 'material', 'kha': _kha, 'comp': sysComp };       
        this.broadcastIframeInfo({ iframeConnection: 'init-nsc-connection', event: 'onChangeSystem', data: obj, key: "" });
      }
    }
  }

  /* *
  * on load system component
  */
  groupComps = {
    "firesafety": ["HALON System","Automatic Water Spray","AFFF System","Twin Agent (TAU)","Fine Water Spray"],
    "buoyancy": ["Eductors"],
    "eer": ["EEBDs","Life Rafts","Life Buoys"]
  }
  khaNames = { "buoyancy": "Buoyancy", "firesafety": "Fire Safety", "eer": "EER" };
  onLoadGroupSysComp() {
    let kha = this.khaNames[this.selectedKha];
    let comp = this.groupComps[this.selectedKha];
    this.overlayHeading = "Ship - " + kha;
    let _kha = kha.split(" ").join("").toLowerCase();
    this.sysOverlayMode = true;
    comp = comp.filter(e => e.trim());
    let sysComp = comp.join(",");
    if (!this.sysEventSource) {
      this.sysEventSource = true;
      this.loadUrl = this.html_sanitizer.bypassSecurityTrustResourceUrl(window.origin + "/?screenType=material&KHA=" + _kha + "&comp=" + sysComp);
    } else {
      if (this.ctrlServe.selectedKha !== _kha || this.ctrlServe.selectedSysComp.join(",") !== sysComp) {
        let obj = { screenType: 'material', 'kha': _kha, 'comp': sysComp };        
        this.broadcastIframeInfo({ iframeConnection: 'init-nsc-connection', event: 'onChangeSystem', data: obj, key: "" });
      }
    }
  }

  sysViewMinimize() {
    this.sysOverlayMode = false;
    this.sysViewMaximizeTag = true;
  }

  sysViewMaximize() {
    this.sysOverlayMode = true;
    this.sysViewMaximizeTag = !true;
  }

  sysViewMinimizeClose() {
    this.sysOverlayMode = !true;
    this.sysViewMaximizeTag = !true;
  }

  sysViewClose() {
    this.sysOverlayMode = !true;
  }

  /**
   * close ern tree
   */
  ernPanelClose() {
    this.ernDisplay = !true;
    this.ernMode = false;
    if(this.lastSelectedNode){
      this.broadcastInfo({ src: 'networkmodel', event: 'updateselectednode', data: this.lastSelectedNode, key: '' });      
    }  
    this.ernClose.emit();
  }

  /**
   * load pdf Viewer
   */
  loadPdfViewer(pageNo) {
    pageNo = Number(pageNo);
    this.loadPdf.emit(pageNo);
  }

  /**
   * update index data as per instance
   */
  updateKhaData() {
    if (!Array.isArray(this.stateObj['networkData'])) {
      this.networkData = this.stateObj['networkData'];
      if ((this.networkData['vertices'].length > 0) && (this.networkData['edges'].length > 0)) {
        this.nmCompList = this.networkData['vertices'];
        this.nmCompMiniList = this.stateObj['nmCompMiniList'];
        this.toggleGroundMat(true);
        this.nscmodel.startNetworkBuild();
        this.broadcastInfo({ src: 'networkmodel', event: 'updatenetworkdata', data: this.networkData, key: "" });
        if (this.stateObj['lastSelectedNode']) {
          setTimeout(() => {
            this.lastSelectedNode = this.stateObj['lastSelectedNode'];  
            this.broadcastInfo({ src: 'networkmodel', event: 'updateselectednode', data: this.stateObj['lastSelectedNode'], key: '' });
          }, 500)
        } else {
          this.broadcastInfo({ src: 'networkmodel', event: 'updatelastelem', data: null, key: '' });
        }
      }
    } else {
      this.nmCompList = [];
      this.networkData = [];
      let networkData = {};
      networkData['vertices'] = [];
      networkData['edges'] = [];
      this.toggleGroundMat(true);
      this.nscmodel.resetLabel = true;
      this.nscmodel.clearConeTree();
      this.nscmodel.resetLabel = false;
      this.broadcastInfo({ src: 'networkmodel', event: 'updatenetworkdata', data: networkData, key: "" });
    }
  }

  openDoc() {
    if (this.ctrlServe.selectedKha) {
      let url = '../../assets/doc/' + this.ctrlServe.selectedKha + '.xlsx';
      window.open(url)
    }
  }

  openERNDoc(ern) {
    for (let item of this.ctrlServe.ernMappingList) {
      let name = item['name'].toLowerCase().split(" ").join("");
      ern = ern.toLowerCase().split(" ").join("");
      if (name.includes(ern)) {
        let ernCategory = item['key']
        if (ernCategory) {
          let url = '../../assets/doc/ern/' + ernCategory + '.xlsx';
          window.open(url);
          break;
        }
      }
    }
  }

  /**
   * method for show 6 level pyrmid or level 7   
   */
  showHidePyrmidLevel() {
    return this.nscLevel7Trees.includes(this.selectedKha);
  }

  /**
   * method for show/hide doc   
   */
  isShowDocLink(ern, item) {
    if (ern == '' && item.level == '5' && this.screenType === 'nsc') {
      return true
    } else {
      return false;
    }
  }

  isShowNSC(ern, item) {
    return ern == '' && this.screenType === 'nsc' ? true : false;
  }

}
