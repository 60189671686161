import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { PdfViewerComponent } from 'ng2-pdf-viewer';

@Component({
  selector: 'app-pdf-viewer-search',
  templateUrl: './pdf-viewer-search.component.html',
  styleUrls: ['./pdf-viewer-search.component.scss']
})
export class PdfViewerSearchComponent implements OnInit {
  @ViewChild(PdfViewerComponent, { static: false }) pdfComponent: PdfViewerComponent;
  @Output() pdfViewerClose = new EventEmitter<string>();

  pdfPageNo: number = 1;
  @Input() set _pdfPageNo(pageNo: any) {
    if (pageNo != undefined) {
      console.log("pdfPageNo ", Number(pageNo))
      this.pdfPageNo = Number(pageNo);
    }
  }

  pdfSrc = "../../assets/doc/LR_Naval_Ship_Rules_compressed.pdf";
  // pdfSrc = "../../assets/doc/LR_Naval_Ship_Rules_page_removed.pdf";
  renderText = true;
  originalSize = false;
  fitToPage = false;
  showAll = true;
  autoresize = false;
  showBorders = false;
  renderTextModes = [0, 1, 2];
  renderTextMode = 1;
  rotation = 0;
  zoom = 1;
  zoomScale = 'page-width';
  zoomScales = ['page-width', 'page-fit', 'page-height'];
  pdfQuery = '';
  totalPages: number;
  showLoading: boolean = false;

  constructor(private _domElement: ElementRef, private cdr: ChangeDetectorRef) { }

  ngOnInit() {
  }

  zoomIn() {
    this.zoom += 0.05;
  }

  zoomOut() {
    if (this.zoom > 0.05)
      this.zoom -= 0.05;
  }

  rotateDoc() {
    this.rotation += 90;
  }

  // Event for search operation
  searchQueryChanged(newQuery: string) {
    if (newQuery !== this.pdfQuery) {
      this.pdfQuery = newQuery;
      this.pdfComponent.pdfFindController.executeCommand('find', {
        query: this.pdfQuery,
        highlightAll: true,
        caseSensitive: false,
        findPrevious: undefined,
        phraseSearch: true,
      });
    } else {
      this.pdfComponent.pdfFindController.executeCommand('findagain', {
        query: this.pdfQuery,
        highlightAll: true
      });
    }
  }

  // Event handler when new PDF file is selected
  onFileSelected() {
    const $pdf: any = document.querySelector('#file');
    if (typeof FileReader !== 'undefined') {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.pdfSrc = e.target.result;
      };
      reader.readAsArrayBuffer($pdf.files[0]);
    }
  }

  afterLoadComplete(pdfData) {
    // Setting total number of pages   
    // this.totalPages = event._pdfInfo.numPages;
  }
  pageRendered(event) {
    // console.log('pageRendered', event);
  }
  textLayerRendered(event) {
    // console.log('textLayerRendered', event);
  }
  onError(event) {
    console.error('onError', event);
  }
  onProgress(event) {
    // console.log('onProgress', event);    
  }

  pagechanging(pageNo: number) {
    this.pdfPageNo = pageNo;
    this.cdr.detectChanges();
  }

  scrollToPage(newPage: number) {
    const page: HTMLDivElement = this._domElement.nativeElement.querySelector(`pdf-viewer .page[data-page-number="${newPage}"]`);
    if (page) {
      const scrollbar = this._domElement.nativeElement.querySelector('pdf-viewer .ng2-pdf-viewer-container');
      scrollbar.scrollTo({
        top: Math.max(0, page.offsetTop),
        behavior: 'smooth'
      });
    }
  }

  pdfViewerPanelClose() {
    this.pdfViewerClose.emit();
  }

}
