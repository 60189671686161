import { Component, Input, Output, EventEmitter, AfterViewInit, DoCheck, ViewChild, ChangeDetectorRef } from '@angular/core';
import { ControllerService } from '../services/controller.service';
import { LanguageService } from '../services/language.service';
import { TranslateService } from '@ngx-translate/core';
import { CommunicationService } from "../services/communication.service";

export interface Element {
  name: string;
  icon: any;
  state: boolean;
  ticon?
}

const CONTROL_BUTTON_PROPS: Element[] = [
  { name: 'play anim', icon: 'play_arrow', state: false, ticon: 'pause' },
  { name: 'heat map', icon: 'layers', state: true, ticon: 'layers_clear' },
  { name: 'home', icon: 'home', state: true },
  { name: '2D', icon: 'dimension', state: false },
  { name: 'zoomin', icon: 'add', state: true },
  { name: 'zoomout', icon: 'remove', state: true },
  { name: 'left', icon: 'left', state: false },
  { name: 'right', icon: 'right', state: false },
  { name: 'back', icon: 'back', state: false },
  { name: 'wireframe', icon: 'left', state: false },
  { name: 'solid', icon: 'right', state: false },
  { name: 'texture', icon: 'back', state: false },
  { name: 'camera', icon: 'video_camera_on', state: true, ticon: 'video_camera_off' },
  { name: 'top', icon: 'top', state: false },
  { name: 'label', icon: 'label', state: true },
  { name: 'sensor', icon: 'wifi', state: true },
  { name: 'target', icon: 'gps', state: true },
  { name: 'drag', icon: 'swipe', state: true, ticon: 'do_not_touch' },
  { name: 'drag_reset', icon: 'water', state: true },
  { name: 'front', icon: 'front', state: false },
  { name: 'water', icon: 'water', state: false },
];
@Component({
  selector: 'app-controltools',
  templateUrl: './controltools.component.html',
  styleUrls: ['./controltools.component.scss']
})
export class ControltoolsComponent implements AfterViewInit, DoCheck {
  arrControlBtnProps = CONTROL_BUTTON_PROPS;
  canvasobjects: any = []
  disableTransCtrl = false;
  viewMode: any = 'texture';
  _wireframemode = false;
  activeComp: any = "";
  broadcastSubs: any;
  

  @Output() controlToolEvent = new EventEmitter<string>();
  @Output() getElementProperties = new EventEmitter<string>();
  @Output() changeObjectPosition = new EventEmitter<object>();
  @Output() changeObjectSize = new EventEmitter<object>();
  @Output() changeObjectOrientation = new EventEmitter<object>();

  @Output() selectedMenu = new EventEmitter<object>();
  @Output() closePopup = new EventEmitter<string>();
  @Output() changeComp = new EventEmitter<string>();
  // @Input() activeComp: any = ""; 

  @Output() trigger = new EventEmitter<object>();
  @Output() loadComp = new EventEmitter<object>();
  @Input() getElements;
  @Input() level;
  @Input() set _disableTrans(v: any) {
    this.disableTransCtrl = v;
  }
  @Input() set shipDragState(v: any) {
    this.arrControlBtnProps[17].state = v;
  }
  statusList: any = [];
  activeComps: any = "";

  constructor(private cdr: ChangeDetectorRef, private ctrlServ: ControllerService,private translate: TranslateService, private language: LanguageService, private CommunicationServ: CommunicationService) {
    this.statusList = this.ctrlServ.statusList;
    this.language.languageChange.subscribe(props => {
      console.log("language", props);
      this.translate.use(props);
    })
    this.broadcastSubs = this.CommunicationServ.getInstance()
      .subscribe((data: any) => {
        if (data.src === "landing") {
          if (data.event === 'activeComp') {
            console.log("data id : ", data.data)
            this.activeComps = data.data;
            
          }
        }
      })
  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }

  ngDoCheck(): void {
    this.canvasobjects = this.getElements;
  }

  buttonclick(row, index) {
    console.log(row, index)
    if (row.name === 'solid') {
      // let idx = this.arrControlBtnProps.findIndex(e => e.name == 'label');
      // if (idx > -1) {
      //   this.arrControlBtnProps[idx].state = false;
        
      // }
      let index = this.arrControlBtnProps.findIndex(e => e.name == 'sensor');
      if (index > -1) {
        this.arrControlBtnProps[index].state = true;
      }
      let cameraIdx = this.arrControlBtnProps.findIndex(e => e.name == 'camera');
      if (cameraIdx > -1) {
        this.arrControlBtnProps[cameraIdx].state = true;
      }
      let targIdx = this.arrControlBtnProps.findIndex(e => e.name == 'target');
      if (targIdx > -1) {
        this.arrControlBtnProps[targIdx].state = true;
      }
      this.controlToolEvent.next(row);
      this.viewMode = 'solid';
    } else if (row.name === 'texture') {
      // let idx = this.arrControlBtnProps.findIndex(e => e.name == 'label');
      // if (idx > -1) {
      //   this.arrControlBtnProps[idx].state = false;
      // }
      let index = this.arrControlBtnProps.findIndex(e => e.name == 'sensor');
      if (index > -1) {
        this.arrControlBtnProps[index].state = true;
      }
      let cameraIdx = this.arrControlBtnProps.findIndex(e => e.name == 'camera');
      if (cameraIdx > -1) {
        this.arrControlBtnProps[cameraIdx].state = true;
      }
      let targIdx = this.arrControlBtnProps.findIndex(e => e.name == 'target');
      if (targIdx > -1) {
        this.arrControlBtnProps[targIdx].state = true;
      }
      this.controlToolEvent.next(row);
      this.viewMode = 'texture';
    } else if (row.name === 'wireframe') {
      // let idx = this.arrControlBtnProps.findIndex(e => e.name == 'label');
      // if (idx > -1) {
      //   this.arrControlBtnProps[idx].state = false;
      // }
      let index = this.arrControlBtnProps.findIndex(e => e.name == 'sensor');
      if (index > -1) {
        this.arrControlBtnProps[index].state = true;
      }
      let cameraIdx = this.arrControlBtnProps.findIndex(e => e.name == 'camera');
      if (cameraIdx > -1) {
        this.arrControlBtnProps[cameraIdx].state = true;
      }
      let targIdx = this.arrControlBtnProps.findIndex(e => e.name == 'target');
      if (targIdx > -1) {
        this.arrControlBtnProps[targIdx].state = true;
      }
      this.controlToolEvent.next(row);
      this.viewMode = 'wireframe';
    } else if (row.name === 'xwireframe') {
      this.changeWireframeMode(index);
    } else {
      this.arrControlBtnProps[index].state = !this.arrControlBtnProps[index].state;
      this.controlToolEvent.next(row);
    }
  }

  changeWireframeMode(index) {
    if (!this._wireframemode) {
      // this.arrControlBtnProps[index].icon = 'signal_cellular_null';
      this._wireframemode = true;
    } else if (this._wireframemode) {
      // this.arrControlBtnProps[index].icon = 'signal_cellular_4_bar';
      this._wireframemode = false;
    }
  }

  getWireFrameStatus() {
    return this._wireframemode;
  }

  /* * *
  * load component 
  * * * */
  loadComponent(e, comp) {
    this.activeComp = comp;
    this.loadComp.emit(comp);
  }

}
