import { Component, OnInit,AfterViewInit } from '@angular/core';
import { LanguageService } from '../services/language.service';
import { TranslateService } from '@ngx-translate/core';
import { CommunicationService } from "../services/communication.service";
import { ControllerService } from '../services/controller.service';
import { KHASystemService } from "../services/systems/kha-systems.service";
@Component({
  selector: 'app-slms-sidepanel',
  templateUrl: './slms-sidepanel.component.html',
  styleUrls: ['./slms-sidepanel.component.scss']
})
export class SlmsSidepanelComponent implements OnInit, AfterViewInit {
  compartList:any=[];
  panelWidth: any = '0px';
  slmsHandleToggleOpen:boolean=true;
  slmsPanelOpen:any=true;
  slmsControlPosition:any='Right';
  languageSelected: any = 'English';
  structHull: any = 'none';
  structDecks: any = 'solid';
  structBulkheads: any = 'clear';
  structSections: any = 'clear';
  deckList: any = ['Z', '02', '01', '1', '2', '3', '4', '5', '6'];
  search:any='';
  selectedDeck: any = '';
  shipComp:any;
  shadowActive: boolean = false;
  broadcastSubs:any;
  compartmentList:any=[];
  currentComp;
  slms_mode;
  constructor( public khaConfig: KHASystemService,private ctrlServe: ControllerService, private translate: TranslateService, private language: LanguageService,private CommunicationServ: CommunicationService) {
    this.translate.addLangs(['en', 'fr']);
    this.translate.setDefaultLang('en');
    this.translate.use('en');
    this.shipComp = this.ctrlServe.shipComp;
   }

   onChangeLanguage(langName) {
    // let langKey;
    if (langName == 'English') {
      this.language.changeLanguage('fr');
      this.translate.use('fr');
      this.languageSelected = 'French';
      // this.nxtLanguage = 'French';
    }
    if (langName == 'French') {
      this.language.changeLanguage('en');
      this.translate.use('en');
      // this.nxtLanguage = 'English';
      this.languageSelected = 'English';
    }
  }

  ngOnInit() {
    this.broadcastSubs = this.CommunicationServ.getInstance()
    .subscribe((data: any) => {
    if (data.src === "khaservice") {
      if (data.event === 'update-compartments') {
        this.shipComp['compList']=data['data'];
        // this.shipComp = this.ctrlServe.shipComp;
       
        this.compartmentList=this.shipComp['compList'];
        this.sortCompartment('name');
        console.log("this.shipComp",this.shipComp)
        // console.log("this.shipComp",this.shipComp)
        // this.khaConfig.filter_deck =  null;
        //     this.khaConfig.applyDisplayRuleFor_Deck()
        // this.comps.forEach((element, i) => {
        //   if (element.component.toLowerCase() == 'compartments') {
        //     this.comps[i].compList = data['data'];
        //   }
        // });
      }
  }
})
  }


  ngAfterViewInit() {
    // this.broadcastInfo({ src: 'controlpanel', event: 'loadcompartment', data: null, key: false });
    // this.shipComp = this.ctrlServe.shipComp;
    // console.log("this.shipComp",this.shipComp)
  }

  getPanelwidth(e) {
    let elem = document.getElementById(e);
    return 'translateX(' + elem.offsetWidth + 'px)';
  }

  slmsToggleOpenHandle(){
    this.slmsHandleToggleOpen=!this.slmsHandleToggleOpen;
  }

  showHideStruct(label, state) {
    console.log(label, state.checked);
    if (label.toLowerCase() == 'hull') {
      this.structHull = state;
    } else if (label.toLowerCase() == 'decks') {
      this.structDecks = state;
    } else if (label.toLowerCase() == 'bulkheads') {
      this.structBulkheads = state;
    } else if (label.toLowerCase() == 'sections') {
      this.structSections = state;
    }
    let data = state === 'clear' ? 'transparent' : state;
    data = state === 'none' ? 'hide' : data;
    this.broadcastInfo({ src: 'sidepanel', event: 'toggle-struct-state', data, key: label });
  }

  compartmentDeckList:any;
  onChangeDeck(value) {
    this.selectedDeck = value;
    // this.structHull = 'none';
    // this.structDecks = 'solid';
    // this.structBulkheads = 'clear';
    // this.structSections = 'clear';
    // this.broadcastInfo({ src: 'sidepanel', event: 'filter-deck', data: value, key: "" });
    var deckFliter = [];
    if(value.indexOf('0')>-1){
      deckFliter = this.shipComp['compList'].filter(element => element.code.substring(0, 2).toLowerCase()==value.toLowerCase());
    }else{
      deckFliter =this.shipComp['compList'].filter(element => element.code.substring(0, 1).toLowerCase()==value.toLowerCase());
    }
    this.compartmentDeckList = deckFliter;
    this.compartmentList=deckFliter;
    if(this.search!=''){
      this.searchValue();
    }
    this.broadcastInfo({ src: 'sidepanel', event: 'filter-deck', data: value, key: "" });
  }
  onClearDeck() {
    this.selectedDeck = "";
    delete this.compartmentDeckList;
    this.compartmentList=this.shipComp['compList'];
    if(this.search!=''){
      this.searchValue();
    }
    this.broadcastInfo({ src: 'sidepanel', event: 'filter-deck', data: "", key: "" });
  }
  shadowAdd(e) {
    this.shadowActive = e.checked
  }
  showFocusSubSystem(s) {
    // var subSystemActiveList = this.ctrlServe.showSysComp
    // var subSystemActive = subSystemActiveList.firesafety;
    // subSystemActive.forEach((item, index) => {
    //   if (item == s) {
    //     this.broadcastInfo({ src: 'controlpanel', event: 'focusSubSystem', data: 'true', key: s });
    //   }
    // })
    // this.broadcastInfo({ src: 'controlpanel', event: 'focusSubSystem', data: e.checked, key: s });
  }
    /* *
  * method for show/hide sub systems
  * */
    showHideSubSystem(e, i) {
      this.compartmentList[i]['status']=e.status=='active'?'inactive':'active';
      // this.ctrlServe.selectedSysComp = [];
      // this.ctrlServe.updateSysComps(s, e.checked);
      // this.broadcastInfo({ src: 'controlpanel', event: 'showhidesubsystem', data: e.checked, key: s });
      // if(this.multiSelectKha.length > 0){
      //   this.onKhaSelect();
      // }
      // this.checkSubSystemStatus();
    }
    checkSubSystemStatus() {
      // let hideAll,hideSys,showAll;
      // this.comps[0]["compList"].forEach(item => {
      //   if (item['key'] === this.selectedKha.split(" ").join("").toLowerCase()) {
      //     hideSys = item['systemList'].some(sys => sys['status'] == 'inactive');
      //     hideAll = item['systemList'].every(sys => sys['status'] == 'inactive');
      //     showAll = item['systemList'].every(sys => sys['status'] == 'active');
      //   }
      // })
      // this.showAllSystem = hideSys ? false : true;
      // if (hideAll) {
      //  this.showAllSystems({checked: false});
      // }
      // if (showAll) {
      //   setTimeout(()=>{
      //     this.showAllSystems({checked: true});
      //   },1000);
      // }
    }
    loadCompartment(s) {
      let show = true;
      this.shipComp['compList'].forEach(sys => {
        if (sys['code']==s.code) {
          sys.selected = !sys.selected;
          show=sys.selected;
          this.currentComp=show?s:"";
        }else{
          if(!this.slms_mode){
            sys.selected = false;
          }
        }
      });
      if(!this.slms_mode){
      this.resetWallmode();
      }
      this.broadcastInfo({ src: 'controlpanel', event: 'loadcompartment', data: s, key: show });
    }
    resetWallmode() {
      this.shipComp['compList'].forEach(sys => {
        if (sys['wallmode']) {
          sys.wallmode = !sys.wallmode;
        }
      });
    }
    /* *
  * method for showHideCompartmentWall
  * */
  showHideCompartmentWall(sys) {
    if (this.currentComp) {
      sys.wallmode = !sys.wallmode;
      this.broadcastInfo({ src: 'controlpanel', event: 'showhidecompartmentwall', data: !sys.wallmode, key: [sys.code] });
    }
  }
filterComp:any=['code','name'];
  filterCompartment(e){
    this.filterComp=e;
  }
  sortComp:any;
  sortCompartment(e) {
    this.sortComp = e;
    // this.comps.forEach((element,i) => {
    //   if(element.component=='Compartments'){
    if (this.compartmentList.length > 0) {
      this.compartmentList.sort((a, b) => a[e] < b[e] ? -1 : a[e] > b[e] ? 1 : 0);
    }
    //   }
    // });
  }
  searchValue() {
    var filterData = [];
    var compartment;
    if(this.selectedDeck!=''){
      compartment=this.compartmentDeckList;
    }else{
      compartment=this.shipComp['compList'];
    }
    if (this.search == '') {
      filterData = compartment;
    } else {
      if (compartment.length > 0) {
        for (let i = 0; i < compartment.length; i++) {
          this.filterComp.forEach((key) => {
            if (compartment[i][key].toLowerCase().indexOf(this.search.toLowerCase()) > -1) {
              const found = filterData.some(ship => ship.code === compartment[i]['code'])
              if (!found) {
                filterData.push(compartment[i]);
              }
            }
          })
        }
      }
     }
    this.compartmentList = filterData;

  }

  searchClear(){
    this.search='';
    if(this.selectedDeck!=''){
      this.compartmentList=this.compartmentDeckList;
    }else{
      this.compartmentList=this.shipComp['compList'];
    }
  }
  /* * * * *
  * method for communicate event instance with data to access all components
  * * * * * */
    broadcastInfo(data: any) {
      this.CommunicationServ.getInstance().next(data);
    }

}
