import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class DropFileService {
  public _elementToMonitor: HTMLElement;
  public _fileCallback: Function;
  constructor(private http: HttpClient) {
  }
  private _dragEnterHandler: (e: any) => void;
  private _dragOverHandler: (e: any) => void;
  private _dropHandler: (e: any) => void;
  monitorElementForDragNDrop(elementToMonitor: HTMLElement): void {
    if (elementToMonitor) {
      this._elementToMonitor = elementToMonitor;

      this._dragEnterHandler = (e) => { this.drag(e); };
      this._dragOverHandler = (e) => { this.drag(e); };
      this._dropHandler = (e) => { this.drop(e); };

      this._elementToMonitor.addEventListener("dragenter", this._dragEnterHandler, false);
      this._elementToMonitor.addEventListener("dragover", this._dragOverHandler, false);
      this._elementToMonitor.addEventListener("drop", this._dropHandler, false);
    }
  }
  public dispose() {
    if (!this._elementToMonitor) {
      return;
    }

    this._elementToMonitor.removeEventListener("dragenter", this._dragEnterHandler);
    this._elementToMonitor.removeEventListener("dragover", this._dragOverHandler);
    this._elementToMonitor.removeEventListener("drop", this._dropHandler);
  }
  private drag(e: DragEvent): void {
    e.stopPropagation();
    e.preventDefault();
  }

  private drop(eventDrop: DragEvent): void {
    eventDrop.stopPropagation();
    eventDrop.preventDefault();

    this.loadFiles(eventDrop);
  }
  private _filesToLoad
  loadFiles(event: any): void {
    // Handling data transfer via drag'n'drop
    if (event && event.dataTransfer && event.dataTransfer.files) {
      this._filesToLoad = event.dataTransfer.files;
    }

    // Handling files from input files
    if (event && event.target && event.target.files) {
      this._filesToLoad = event.target.files;
    }

    if (!this._filesToLoad || this._filesToLoad.length === 0) {
      return;
    }

    if (this._filesToLoad && this._filesToLoad.length > 0) {
      if (this._fileCallback) {
        this._fileCallback(this._filesToLoad[0])
      }
      return this._filesToLoad[0];

    }
  }
  max=0;
  min=0;
  points_arr=[];
  q=0
  processPCLines(lines) {

    let rows = lines.split("\n");

    let chunk="";
    const len = rows.length;
    let i = 0;
    let rlen;
    let c=0;
    let points=[];
    //console.log('len',len);
    for (; i < len; i++) {
      let cols = rows[i].split(" ");

      let l = cols.length;
      //console.log(lines[i],cols.length);
      if(l<3){
        continue
      }
      if (c === 0) {
        rlen = cols.length;
      }
      if (i === len - 1) {
        if (cols.length!=rlen) {
          chunk = lines[i];
        }
      }
      let x = parseFloat(Number(cols[0]).toFixed(4))
      let y = parseFloat(Number(cols[2]).toFixed(4))
      let z = parseFloat(Number(cols[1]).toFixed(4))
      this.min=Math.min(this.min,y)
      this.max=Math.max(this.max,y)
      points=points.concat([x,y,z]);
      c++
    }

    return {points,chunk};
  }
  constructPCS(mode, dfx, pcs, points = null) {
    if (mode === 'add') {

    } else if (mode === 'build') {
      dfx.constructPCS({ pcs_mode: 'build', pcs });
    }
  }
  renderPCS(pcs, file, dfx,cbck) {
    let chunkline = "";
    let time = Date.now();
    let done = false;
    this.min=10e+10;
    this.max=-10e+10;
    this.points_arr=[];
    this.parseFile(file, {
      chunk_size: 64 * 1024,
      binary: false,
      chunk_read_callback: (data) => {
        let parsedata = chunkline + data;
        let l = this.processPCLines(parsedata);
        chunkline = "";
        if (l.chunk) {
          chunkline = l.chunk;
        }
        if (done) {

        } else {
          console.log(l.points.length)
          //dfx.constructPCS({size:1,offset:[0,0,0],points:p.pos,pcs_type:'points',color:'mono',pointcolor:[0.61,0.61,1,1]})
          this.points_arr=this.points_arr.concat(l.points);
        }
      },
      error_callback: (err) => {
        console.log(err)
      },
      success: (file) => {
        time = Date.now() - time;
        time = time / (1000 * 60);
        done = true;
        console.log("DONE")
          cbck(this.points_arr,this.max,this.min)
        console.log("File ", file.name, " parsed in ", time, 'mins')
      }
    })
  }
  parseFile(file, options) {
    let opts = typeof options === 'undefined' ? {} : options;
    let fileSize = file.size;
    let chunkSize = typeof opts['chunk_size'] === 'undefined' ? 64 * 1024 : parseInt(opts['chunk_size']); // bytes
    let binary = typeof opts['binary'] === 'undefined' ? false : opts['binary'] == true;
    let offset = 0;
    let self = this; // we need a reference to the current object
    let readBlock = null;
    let chunkReadCallback = typeof opts['chunk_read_callback'] === 'function' ? opts['chunk_read_callback'] : function () { };
    let chunkErrorCallback = typeof opts['error_callback'] === 'function' ? opts['error_callback'] : function () { };
    let success = typeof opts['success'] === 'function' ? opts['success'] : function () { };

    let onLoadHandler = function (evt) {
      if (evt.target.error == null) {
        offset += binary ? evt.target.result.byteLength : evt.target.result.length;
        chunkReadCallback(evt.target.result);
      } else {
        chunkErrorCallback(evt.target.error);
        return;
      }
      if (offset >= fileSize) {
        success(file);
        return;
      }

      readBlock(offset, chunkSize, file);
    }

    readBlock = function (_offset, length, _file) {
      let r = new FileReader();
      let blob = _file.slice(_offset, length + _offset);
      r.onload = onLoadHandler;
      if (binary) {
        r.readAsArrayBuffer(blob);
      } else {
        r.readAsText(blob);
      }
    }

    readBlock(offset, chunkSize, file);
  }

}
